import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background-color: #ffffff;
    height: calc(100vh + 10px);
    width: 100%;
    flex: 1;
    //overflow: scroll;
`

export const PreviewHeader = styled.div`
    display: flex;
    background-color: ${props => props.backgroundColor};
    height: 60px;
    width: 100%;
    justify-content: center;
    align-items: center;

    label{
        color: ${props => props.color};
        font-size: 18px;
    }
`

export const PreviewTitle = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0px;

    label{
        color: ${props => props.color};
        font-size: 18px;
        opacity: 0.5;
    }

    p{
        color: ${props => props.color};
        font-size: 32px;
        margin: 0px;
        padding: 0px;
    }

`

export const PreviewBody = styled.div`
    display: flex;
    background-color: ${props => props.backgroundColor};
    width: 100%;
    flex-direction: column;
    height: max-content;
    border-radius: 4px;
    
`

export const PreviewProducts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 20px;
 
`

export const PreviewCard = styled.div`
    display: flex;
    min-height: 80px;
    background-color: ${props => props.backgroundColor};
    border-radius: 8px;
    align-items: center;
    padding: 0px 20px;
    justify-content: space-between;

    label{
        color: ${props => props.color};
    }

    span{
        display: flex;
        min-width: 120px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: ${props => props.backgroundColorButton};

        label{
            font-size: 13px;
            color: ${props => props.colorButton};
        }
    }
`

export const AlertSave = styled.div`
    background-color: #242527 ;
    display: flex ;
    width: 700px ;
    bottom: 100px;
    position: fixed ;
    height: 60px ;
    align-items: center ;
    padding: 0px 10px ;
    border-radius: 4px ;
    gap: 10px;

    img{
        width: 44px ;
        height: 44px ;
    }

    span{
        display: flex ;
        flex: 1;
        //background-color: yellow ;
        justify-content: space-between ;

        label{
            font-size: 14px ;
            display: flex ;
            align-items: center ;
            font-size: 14px ;
            color: #fff;

        }

        div{
            display: flex ;
            gap: 10px;
            align-items: center ;

            label{
                font-size: 14px ;
                height: 35px ;
                border: 1px solid #FFFFFF;
                border-radius: 4px;
                display: flex ;
                align-items: center ;
                padding: 0px 10px ;
                color: white ;
                font-size: 14px  !important;
                cursor: pointer;
            }

            span{
                background-color: #00803B ;
                border: none ;
                outline: none ;
                cursor: pointer;
                height: 35px ;
                border-radius: 4px ;
                width: 90px ;
                color: white ;
                font-size: 14px ;
                display: flex ;
                align-items: center ;
                justify-content: center ;
            }
        }
    }
`

export const BoxActive = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    span{
        display: flex;
        //background-color: red;
        align-items: center;
        justify-content: space-between;

        img{
            margin-right: 10px;
        }
        
    }

`

export const ItemActive = styled.div`
    display: flex;
    width: 30px;
    height: 20px;
    background-color: ${props => props.active ? "#00803B" : "gray"};
    align-items: center;
    padding: 0px 5px;
    border-radius: 5px;
    justify-content: ${props => props.active ? "right" : "left"};
    cursor: pointer;

    span{
        display: flex;
        height: 10px;
        width: 10px;
        background-color: white;
        border-radius: 2px;
    }
`

export const TitleSteps = styled.div`
    display: flex;
    flex-direction: column;

    p{
        margin: 0px;
        padding: 0px;
        font-size: 32px;
        font-weight: 600;
        color: #323232;
    }

    label{
        font-size: 12px;
        color: #323232;
    }
`

export const Steps = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;

    label{
        color: #323232;
        strong{
            cursor: pointer;
            :hover{
                text-decoration: underline;
            }
        }
    }
`

export const BoxPreviewContent = styled.div`
    gap: 20px;
    display: flex;
    width: 100%;
    height: max-content ;
    flex-direction: column;

    @media (max-width: 1600px) {
       //flex-direction: column;
    }
    
`
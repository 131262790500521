import { Container, Infor, List, ListItems, Search, Title } from './styled'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CardNew from '../card-new';
import { platforms } from "../../pages/home-new/dataCards";


const ListIntegrations = () => {

    const statusPlatforms = useSelector(state => state.reducerGetPlatforms)
    const dispatch = useDispatch()

    const [statusNuvemshop, setStatusNuvemshop] = useState(false)
    const [statusShopify, setStatusShopify] = useState(false)
    const [statusYampi, setStatusYampi] = useState(false)
    const [statusSak, setStatusSak] = useState(false)

    useEffect(() => {
        console.log("statusPlatforms", statusPlatforms)
    }, [statusPlatforms])

    //useEffect(() => { dispatch({ type: "SAGA_GET_PLATFORMS_INTEGRATION" }) }, [])


    useEffect(() => {
        if (statusPlatforms.data.length > 0) {
            for (let item of statusPlatforms.data) {
                switch (item?.platform?.toLowerCase()) {
                    case "nuvemshop": setStatusNuvemshop(true)
                        break;
                    case "shopify": setStatusShopify(true)
                        break;
                    case "yampi": setStatusYampi(true)
                        break;
                    case "sak": setStatusSak(true)
                        break;
                    default: break;
                }
            }
        } else {
            setStatusNuvemshop(false)
            setStatusShopify(false)
            setStatusYampi(false)
            setStatusSak(false)
        }
    }, [statusPlatforms])

    return (
        <Container>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">{"Integrações"}</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {"Integre sua plataforma de e-commerce para gerenciar seus produtos digitais. É possível combinar com o SAK,  usando whatsapp e melhorando a experiência do cliente."}
                        </p>
                    </div>
                </div>
            </div>

            <List>
                <ListItems>
                    <CardNew item={platforms.nuvemshop} install={statusNuvemshop} />
                    <CardNew item={platforms.shopify} install={statusShopify} />
                    <CardNew item={platforms.yampi} install={statusYampi} />
                    <CardNew item={platforms.sak} empreender={true} install={statusSak} />
                </ListItems>
            </List>
        </Container>
    )
}

export default ListIntegrations
import styled from "styled-components";

export const BoxLoading = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  //height: 100% ;
  //background-color: yellow ;
  /* margin-top: 50px; */

  span {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  width: calc(99%);
  display: flex;
  justify-content: center;
  /* background: red; */
  ${(props) =>
    props.first &&
    `
  justify-content: flex-start;
  `}
  ${(props) =>
    props.last &&
    `
  justify-content: flex-end;
  `}
`;

export const Block = styled.div`
  background-color: white;
  height: 209px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: space-around;
  width: calc(23%);
  @media screen and (max-width: 750px) {
    width: calc(94%);
    margin-top: 10px;
    ${(props) =>
      props.isRight &&
      `
    margin-left: 6%;
    
    `}
  }
`;

export const Title = styled.label`
  font-size: 39px;
  color: #000;
  font-weight: 500;
  margin: -10px 0px;
`;

export const SubTitle = styled.label`
  font-size: 14px;
  /* margin-top: -40px; */
  color: #000;
  font-weight: 500;
`;

export const ContainerIcon = styled.div`
  background-color: #f4f5f7;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  margin: 0;
  border-color: #006bf1;
  border-style: solid;
  border-width: 1px;
`;

export const ContainerLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  padding: 0;
`;

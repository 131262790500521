import { Button } from './styled'

const MainButton = (props) => {
    return(
        <Button style={props.style} type={props.type} onClick={props.handleClick} width={props.width} background={props.background} color={props.color} border={props.border} center={props.center} marginTop={props.marginTop}>
            {props.children}
        </Button>
    );
}

export default MainButton
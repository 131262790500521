import styled, { css } from "styled-components";

export const MasterWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 2rem;
  `}
`;
export const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    padding: 3rem 2rem;
    border-radius: 1rem;

    @media (max-width: 760px) {
      padding: 16px;
    }
  `}
`;

export const NotifyPreviewWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;

    @media (max-width: 960px) {
      flex-direction: column;
    }

    > div {
      flex: 1;
    }
  `}
`;

export const ColorPicker = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 8px;

    input[type="color"] {
      -webkit-appearance: none;
      border: none;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      overflow: hidden;
      cursor: pointer;
      border: 1px solid #e0e5ff;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
      border: none;
    }

    > label {
      margin: 0;
      font-size: 12px;
      font-weight: 300;
      cursor: pointer;
    }
  `}
`;

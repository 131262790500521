import styled from "styled-components";

export const BoxFixxed = styled.div`
    //background-color: red;
    display: flex;
    position: absolute !important;
    width: 800px;
    justify-content: center;
    align-items: center;
    left: calc(50vw - (400px - 144px));
    height: 80px;
    //top: 50px;
    z-index: 9999999;
`
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    border-top: 1px solid  #F3F4F6;
    padding-top: 20px;
    gap: 20px;
    //background-color: #fff;

    img{
        width: 64px;
        height: 48px;
        object-fit: cover;
        border-radius: 4px;
    }

    span{
        display: flex;
        flex: 1;
        flex-direction: column;
        //background-color: red;
        justify-content: center;

        p{
            padding: 0px;
            margin: 0px;
            font-weight: 500;
            font-size: 14px;
            color: #111827;
        }

        label{
            font-weight: 400;
            font-size: 14px;
            color: #374151;
        }
    }
`

export const Action = styled.label`
    display: flex;
    background-color: ${props => props.active ? "#DC2626" : "#F9FAFB"} ;
    height: 40px;
    border-radius: 4px;
    align-items: center;
    padding: 0px 20px;
    font-size: 14px;
    color:  ${props => props.active ? "#ffffff" : "#6B7280"} ; 
    font-weight: 600;
    cursor: pointer;
`
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import ReactModal from 'react-modal'
import { Actions, ButtomCustom, Container, Contents, Form, InputCustom, Title } from './styled';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        with: "500px"

    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
    }
};



const ModalAddTime = (props) => {

    const [qtd, setQtd] = useState("")
    const [typeAdd, setTypeAdd] = useState("days")

    return (
        <ReactModal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => props.setVisibleModal(false)}
        >
            <Container>
                <Title>
                    <label>{`${props.item.name} [${props.item.chave_empreender}]`}</label>
                </Title>
                <Form>
                    <InputCustom >
                        <input placeholder='Quantidade' type={"number"} value={qtd} onChange={(e) => { setQtd(e.target.value) }} />
                        <select onChange={(e) => { setTypeAdd(e.target.value) }} >
                            <option value={"days"} selected={typeAdd == "days" ? true : false} >Dias</option>
                            <option value={"months"} selected={typeAdd == "months" ? true : false} >Meses</option>
                        </select>
                    </InputCustom>
                </Form>
                <Actions>
                    <ButtomCustom onClick={() => { props.setVisibleModal(false) }} backgroundColor={"#F1416C"} >
                        <label>Cancelar</label>
                    </ButtomCustom>
                    <ButtomCustom onClick={() => { props.addTimeUser(qtd, typeAdd) }} backgroundColor={"#19B674"}>
                        <label>salvar</label>
                    </ButtomCustom>
                </Actions>
            </Container>
        </ReactModal>
    )
}

export default ModalAddTime
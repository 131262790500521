import styled from "styled-components";

export const ContainerBlock = styled.div`
  margin: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 40px;
  @media (max-width: 580px) {
    margin: 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 0px;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 0px 0px 0px 2px;
  /* margin: 60px 60px 40px 60px; */

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const ContainerMedias = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const BoxOptions = styled.div`
  display: flex;
  //background-color: red ;
  flex-direction: column;
`;

export const BoxContentStep = styled.div`
  display: flex;
  //background-color: purple ;
  flex: 1;
  padding: 20px;
  flex-direction: column;
  height: 450px;
`;

export const StepCircle = styled.span`
  display: flex;
  //background-color: #17238F;
  justify-content: left;
  label {
    display: flex;
    font-size: 14px;
    color: gray;
    width: 100%;
    height: 100%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
  }
`;

export const ItemOption = styled.div`
  display: flex;
  background-color: white;
  width: 300px;
  padding: 20px;
  height: 150px;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
  border-left: ${(props) => (props.active ? "5px solid #005AFF" : "none")};
  cursor: pointer;
  border-right: ${(props) => (!props.active ? "1px solid #E5E7EB" : "none")};

  @media (max-width: 1200px) {
    width: 100%;
  }

  :last-child {
    //background-color: red ;
    border-bottom: none;

    @media (max-width: 1100px) {
      border-bottom: 1px solid #e5e7eb;
    }
  }

  :hover {
    background-color: #f1f1f1;
  }

  label {
    //margin-left:10px ;
    cursor: pointer;
  }
`;

export const TitleBlock = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;

export const Description = styled.div`
  font-size: 14px;
  /* margin-top: 10px; */
`;

export const DescriptionBlock = styled.div`
  font-size: 16px;
  margin: 10px 0px;
`;

export const SectionCardsIntegration = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0px;
  flex-wrap: wrap;
`;
export const Btn = styled.label`
  display: flex;
  background: transparent;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 8px;
  width: 170px;
  //justify-content: center ;
  cursor: pointer;
  margin: 10px 0px;
  align-items: center;
  gap: 8px;
`;

export const TextHelp = styled.label`
  display: flex;
  font-weight: bold;
`;

export const TextSupport = styled.label`
  display: flex;

  strong {
    margin-left: 5px;
    cursor: pointer;
  }
`;
export const SectionContents = styled.label`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Line = styled.div`
  padding: 1px;
  width: 200px;
  height: 1px;
  background: blue;
  margin-top: 15px;
  @media (max-width: 1510px) {
    width: 100px;
  }
  @media (max-width: 1315px) {
    width: 50px;
  }
  @media (max-width: 1210px) {
    display: none;
  }
`;

export const Legend = styled.div`
  font-size: 14px;
  width: 100%;
  text-align: center;
  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

export const ImagePersonalize = styled.img`
  width: 350px;
  height: 180px;
  @media (max-width: 1450px) {
    width: 300px;
  }
  @media (max-width: 1320px) {
    width: 250px;
  }
  @media (max-width: 1200px) {
    width: 350px;
  }
  @media (max-width: 550px) {
    width: 300px;
  }
  @media (max-width: 490px) {
    width: 250px;
  }
`;

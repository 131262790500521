import { Alert } from "react-bootstrap";
import { put, call } from "redux-saga/effects";
import { POST, GET, PUT, DELETE } from "../../../../services/api";

export function* listSettings(action) {
  try {
    yield put({ type: "SETTINGS_REQUEST" });
    const result = yield call(GET, {
      endpoint: `/settings`,
    });
    yield put({ type: "SETTINGS_SUCCESS", payload: result.data });
  } catch (err) {
    alert(err);
  }
}

export function* deleteUser(action) {
  try {
    yield call(DELETE, {
      endpoint: `/delete_user`,
    });
    // yield put({ type: "SETTINGS_SUCCESS", payload: result.data });
  } catch (err) {
    alert(err);
  }
}

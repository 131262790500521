
const INITIAL_STATE = true

const ControlSaveCheckout = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONFIG_CONTROL_SAVE_CHECKOUT_SET':
            return action.payload
        default:
            return state;
    }
}

export default ControlSaveCheckout
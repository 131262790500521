import { put, call, delay } from "redux-saga/effects";
import { POST, GET, DELETE, POST_ADMIN_CUSTOM } from "../../../../services/api";
import { toast } from "react-toastify";
import { translations } from "../../../../utils/language";
import { propTypes } from "react-bootstrap/esm/Image";

export function* platforms() {
  yield put({ type: "GET_PLATFORMS_REQUEST" });
  //yield delay(2000)
  const result = yield call(GET, { endpoint: "/integrations" });

  if (result === false) {
    yield put({ type: "GET_PLATFORMS_ERROR" });
  } else {
    yield put({ type: "GET_PLATFORMS_SUCCESS", payload: result.data.data });
  }
  //yield put({ type: "GET_PLATFORMS_RESET" })
}

export function* integrate(action) {

  console.log("integrate action", action)
  alert("teste")

  let dataIntegrate = action.payload.type == "Sak" ?
    {
      webhookSak: action.payload.webhookSak,
      tokenSak: action.payload.tokenSak
    } :
    {
      code: action.payload?.code,
      typeIntegration: action.payload.type,
      token: action.payload.token,
      store: action.payload.store,
      user: action.payload.user,
      basic: action.payload.basic,
      webhookSak: action.payload.webhookSak,
      tokenSak: action.payload.tokenSak
    }



  try {
    const response = yield call(POST, {
      endpoint: "/integrations",
      data: dataIntegrate,
    });

    let language;
    const storageLanguage = localStorage.getItem("language");
    if (storageLanguage) {
      language = storageLanguage;
    } else {
      language = "pt";
    }

    if (response === false) {
      action.payload.closeModal && action.payload.closeModal();
      toast.error("Erro ao efetuar integração");
    } else {
      if (
        response.data.description === "Loja já integrada com outro usuário!"
      ) {
        toast.error(response.data.description);
        action.payload.closeModal && action.payload.closeModal();
      } else {
        action.payload.closeModal && action.payload.closeModal();
        toast.success(
          translations["Integração efetuada com sucesso!"][language]
        );
        localStorage.removeItem("userId");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("scope");
      }
    }

    if (action.payload.type !== "Yampi") {
      yield call(platforms);
      yield put({ type: "GET_PLATFORMS_REQUEST" });
      const result = yield call(GET, { endpoint: "/integrations" });

      if (result === false) {
        yield put({ type: "GET_PLATFORMS_ERROR" });
        toast.error("Erro ao efetuar integração");
      } else {
        yield put({ type: "GET_PLATFORMS_SUCCESS", payload: result.data.data });
      }
    }
  } catch {
    //toast.error("Erro ao efetuar integração");
    action.payload.closeModal && action.payload.closeModal();
  }
}

export function* getUserId(action) {
  try {
    const response = yield call(GET, {
      endpoint: "/integration_id",
    });
    yield put({ type: "INTEGRATION_USER", payload: response.data.data });
  } catch {
    toast.error("Erro ao bucar usuário");
  }
}

export function* deleteIntegration(action) {
  try {
    yield call(DELETE, {
      endpoint: `/delete_integrations/${action.payload.platform}`,
    });
    yield call(platforms);
    let language;
    const storageLanguage = localStorage.getItem("language");
    if (storageLanguage) {
      language = storageLanguage;
    } else {
      language = "pt";
    }
    toast.success(translations["Integração deletada com sucesso!"][language]);
  } catch (err) {
    toast.error(err);
  }

  yield call(platforms);
  yield put({ type: "INTEGRATION_DELETE_RESET" });
}

export function* deleteIntegrationNew(action) {
  yield put({ type: "INTEGRATION_DELETE_REQUEST", })
  const result = yield call(DELETE, { endpoint: `/delete_integrations/${action.payload.platform}`, })
  if (result === false) {
    yield put({ type: "INTEGRATION_DELETE_ERROR" })
  } else {
    yield put({ type: "INTEGRATION_DELETE_SUCCESS", payload: result.data })

    if (action.payload.platform != "sak") {
      let newLocal = JSON.parse(localStorage.getItem("depoimentos@login"))
      delete newLocal.integration
      localStorage.setItem('depoimentos@login', JSON.stringify(newLocal))
    } else if (action.payload.platform == "sak") {
      let newLocal = JSON.parse(localStorage.getItem("depoimentos@login"))
      delete newLocal.integrationSak
      localStorage.setItem('depoimentos@login', JSON.stringify(newLocal))
    }


    yield call(platforms)
  }
  yield put({ type: "INTEGRATION_DELETE_RESET" })
}

export function* deleteIntegrationAndUser(action) {
  try {
    yield call(DELETE, { endpoint: `/delete_integrations/${action.payload.platform}`, });
    yield call(DELETE, { endpoint: `/delete_user`, });
  } catch (err) {
    toast.error(err);
  }
}




export function* integrateNew(action) {


  console.log("integrateNew action teste", action)

  yield put({ type: "CONTROL_INTEGRATION_CHANGE", payload: { status: "loading", integration: action.payload.type } })



  const response = yield call(POST, {
    endpoint: "/integrations",
    data: { webhook: action.payload.webhookSak, code: action.payload.code, typeIntegration: action.payload.type, token: action.payload.type == "sak" ? action.payload.tokenSak : action.payload.token, store: action.payload.store, user: action.payload.user, basic: action.payload.basic, },
  });

  let language;
  const storageLanguage = localStorage.getItem("language");
  if (storageLanguage) { language = storageLanguage; }
  else { language = "pt"; }

  if (response === false) {
    toast.error("Erro ao efetuar integração");
    action.payload.closeModal && action.payload.closeModal();

    yield put({ type: "CONTROL_INTEGRATION_CHANGE", payload: { status: "error", integration: action.payload.type } })

    yield delay(1000)

    yield put({ type: "CONTROL_INTEGRATION_CHANGE", payload: { status: null, integration: action.payload.type } })
  } else {

    if (response.data.description === "Loja já integrada com outro usuário!") {
      toast.error(response.data.description);
      action.payload.closeModal && action.payload.closeModal();
      //action.payload.closeModal();
      yield put({ type: "CONTROL_INTEGRATION_CHANGE", payload: { status: "error", integration: action.payload.type } })
      yield delay(1000)
      yield put({ type: "CONTROL_INTEGRATION_CHANGE", payload: { status: null, integration: action.payload.type } })

    } else {
      action.payload.closeModal && action.payload.closeModal();
      toast.success(translations["Integração efetuada com sucesso!"][language]);
      localStorage.removeItem("userId");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("scope");

      if (action.payload.type != "sak") {
        let local = JSON.parse(localStorage.getItem("depoimentos@login"))
        local.integration = response.data.data
        localStorage.setItem('depoimentos@login', JSON.stringify(local))
      } else if (action.payload.type == "sak") {
        let local = JSON.parse(localStorage.getItem("depoimentos@login"))
        local.integrationSak = response.data.data
        localStorage.setItem('depoimentos@login', JSON.stringify(local))
      }

      yield put({ type: "CONTROL_INTEGRATION_CHANGE", payload: { status: "success", integration: action.payload.type } })
      yield delay(1000)
      yield put({ type: "CONTROL_INTEGRATION_CHANGE", payload: { status: null, integration: action.payload.type } })

      yield call(platforms);
    }
  }

  // if (action.payload.type !== "Yampi") {
  //   yield call(platforms);
  //   yield put({ type: "GET_PLATFORMS_REQUEST" });
  //   const result = yield call(GET, { endpoint: "/integrations" });
  //   if (result === false) {
  //     yield put({ type: "GET_PLATFORMS_ERROR" });
  //     toast.error("Erro ao efetuar integração");
  //   } else {
  //     yield put({ type: "GET_PLATFORMS_SUCCESS", payload: result.data.data });
  //   }
  // }

}

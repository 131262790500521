import React, { useEffect, useState } from 'react'
import { Action, Container } from './styled'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import ModalConfirmation from '../../../../components/Modal-Confirmation'
import ModalInfor from "../Modal-Integration";
import { useHistory } from "react-router-dom";


const CardNew = (props) => {

    const [visibleModalInfor, setVisibleModalInfor] = useState(false);
    const statusDeleteIntegration = useSelector(state => state.reducerDeleteIntegration)
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const [visibleModalConfirmationRastreio, setVisibleModalConfirmationRastreio] = useState(false)
    const [rastreioModal, setrastreioModal] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const [install, setInstall] = useState(false)

    const language = useSelector((state) => state.reducerLanguage);

    const deleteIntegration = () => {
        console.log("props", props)
        dispatch({ type: "SAGA_DELETE_NEW_INTEGRATION", payload: { platform: props.item.title.toLowerCase(), }, });
    };

    const statusPlatforms = useSelector(state => state.reducerGetPlatforms)


    const getInfor = (platform) => {

        switch (platform) {
            case "yampi":
                return `${"Loja"}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationDropi?.user_token}`
            case "Shopify":
                return `${"Loja"}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Nuvemshop":
                return `${"Loja"}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Sak":
                return props.install ? `Token: ${statusPlatforms?.data[1]?.access_token?.substr(0, 5)} *************** ${statusPlatforms?.data[1]?.access_token?.substr(statusPlatforms?.data[1]?.access_token?.length - 5, statusPlatforms?.data[1]?.access_token?.length - 1)}` : null
            default:
                return `${"Loja"}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
        }
    }


    const validate = () => {

        if (props.item.title == "Sak") {
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
                !props.install ? setVisibleModalInfor(true) : setVisibleModalConfirmation(true)
            } else if (props.install) {
                setVisibleModalConfirmation(true)
            } else {
                toast.info("Integre uma pataforma de ecommerce primeiro.", { autoClose: false, })
            }
        } else {
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
                if (props.item.title.toLowerCase().replace(" ", "") === JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform) {
                    setVisibleModalConfirmation(true)
                } else {
                    toast.info("Já existe uma integração com ecommerce.", { autoClose: false, })
                }
            } else {
                setVisibleModalInfor(true)
            }
        }
    }


    useEffect(() => {
        if (!statusDeleteIntegration.loading && !statusDeleteIntegration.error && statusDeleteIntegration.data.length === 0) {
        } else {
          const id = "statusDeleteIntegration";
          if (statusDeleteIntegration.loading) {
            toast.loading("Aguarde...", { toastId: id });
          } else {
            if (statusDeleteIntegration.error) {
              toast.update(id, { render: "Erro ao tentar excluir.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true, });
            } else {
              toast.update(id, { render: "Excluído com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true, });
              setVisibleModalConfirmation(false);
            }
          }
        }
      }, [statusDeleteIntegration]);



    return (
        <Container>

            <ModalInfor
                platform={props.item}
                visible={visibleModalInfor}
                setVisibleModal={setVisibleModalInfor}
                item={props.item}
            />

            <ModalConfirmation
                textButtom={"Excluir integração"}
                confirmation={deleteIntegration}
                visible={visibleModalConfirmation}
                setVisibleModal={setVisibleModalConfirmation}
                title={`${"Deseja excluir essa integração?"}`}
                infor={getInfor(props.item.title)}
            />

            {/* <ModalConfirmation
                textButtom={translation.integrations.acessarRastreio}
                confirmation={() => { window.open("https://app.rastreio.net/configuracoes#lily") }}
                visible={rastreioModal}
                setVisibleModal={setrastreioModal}
                title={translation.integrations.integracaoRastreio}
            /> */}

            <img src={props.item.image} />
            <span>
                <p>
                    {props.item?.title}

                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationSak != null && props.item?.title == "Sak" ?
                        <strong style={{ marginLeft: "10px", backgroundColor: "#f0fdf4", color: "#15803d" }} className="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">
                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationSak?.access_token.substr(0, 4)}
                            ****
                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationSak?.access_token.substr(-4)}
                        </strong> : null}

                    {props.empreender &&
                        <strong style={{ marginLeft: "10px" }} className="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">
                            EMPREENDER
                        </strong>}
                </p>
                <label>{props.item.message}</label>
            </span>
            <Action active={props.install} onClick={() => { validate() }}>
                {props.item.title == "Shopee" ? "Conectado" : props.install ? "Desconectar" : "Conectar"}
            </Action>
        </Container>
    )
}

export default CardNew
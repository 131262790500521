import React from 'react'
import { BarDivider, Container } from './styled'
import { IoIosHelpCircleOutline } from "react-icons/io";
import { GoLinkExternal } from "react-icons/go";
import { useSelector } from 'react-redux';
import { translations } from '../../utils/language';

const HelpClick = (props) => {

    const language = useSelector((state) => state.reducerLanguage);

    return (
        <Container>
            <IoIosHelpCircleOutline size={24} color='#292D32' />
            <label style={{ color: "#101828" }} >{translations["alert-duvida"][language.data]}</label>
            <BarDivider />
            <label style={{ color: "#006DA3" }}>{props.text}</label>
            <GoLinkExternal size={20} color='#292D32' />
        </Container>
    )
}

export default HelpClick
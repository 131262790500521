
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosClose } from "react-icons/io";

import { Container, Close, BoxImage, BoxInfor, ItemMenu, BoxMenu } from "./styled";
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { translations } from '../../../../utils/language';
import { ArrowDownOnSquareIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, ArrowTopRightOnSquareIcon, CloudArrowDownIcon, CloudArrowUpIcon, EnvelopeIcon, PaperClipIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useHistory } from 'react-router-dom';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalMenuMedia = (props) => {



    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <Container>
                <Close onClick={() => { props.setVisibleModal(false) }}><IoIosClose size={25} color={"gray"} /></Close>
                <BoxMenu>
                    <Item
                        image={<ArrowTopRightOnSquareIcon style={{ width: "32px" }} />}
                        action={() => { props.setVisibleModalConectar(true); 
                            props.setVisibleModal(false) 
                            //console.log("props", props)
                        }}
                        title={"Cadastrar URL"}
                        desc={"Cadastrar uma URL ou link de acesso."} />

                    <Item
                        image={<CloudArrowUpIcon style={{ width: "32px" }} />}
                        action={() => { props.setVisibleModalUploadFiles(true); props.setVisibleModal(false) }}
                        title={"Cadastrar Arquivo"}
                        desc={"Fazer upload de arquivos."} />
                </BoxMenu>
            </Container>
        </Modal>
    )
}

export default ModalMenuMedia


const Item = (props) => {

    const dispatch = useDispatch()

    useEffect(() => {
        //console.log("props_", props)
    }, [props])


    const [hover, setHover] = useState(false)


    return (
        <ItemMenu onClick={() => { props.action() }} hover={hover} onMouseLeave={() => { setHover(false) }} onMouseEnter={() => { setHover(true) }}>
            <BoxImage hover={hover}>
                {props.image}
            </BoxImage>
            <BoxInfor>
                <label>{props.title}</label>
                <p>{props.desc}</p>
            </BoxInfor>
        </ItemMenu>
    )
}


import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    
    flex-direction: column;
    width: 500px;
    min-height: 150px;
    padding: 10px;
    border-radius: 5px;

`;

export const Actions = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;

    button{
        width: 100px;
        height: 30px;
        margin-left: 10px;
        border-radius: 5px;
        outline: none;
        border: none;
        background-color: #19B674;
        cursor: pointer;
        color: white;

        :hover{
            opacity: 0.8;
        }
        
    }
    button:nth-child(1){
        background-color: transparent !important;
        border: 2px solid #19B674;
        color: black;
        //font-weight: bold;
    }
`;
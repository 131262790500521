const INITIAL_STATE = {
  error: null,
  loading: null,
  data: [],
};

const Sales = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SALES_REQUEST":
      return { loading: true, error: false, data: [] };
    case "SALES_ERROR":
      return { loading: false, error: true, data: [] };
    case "SALES_SUCCESS":
      return { loading: false, error: false, data: action.payload };
    case "SALES_RESET":
      return { loading: false, error: false, data: [] };
    default:
      return state;
  }
};

export default Sales;

import { ArrowDownTrayIcon, ArrowPathIcon, CodeBracketIcon, EllipsisVerticalIcon, EnvelopeIcon, PhotoIcon, ShoppingBagIcon, StarIcon } from "@heroicons/react/24/outline"
// import { BoxIcon, Submenu } from "../../../../cupons/components/tailwind/item-table/styled"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { FaWhatsapp } from "react-icons/fa"
//import { BoxImage, Message } from "../../../../reviews/components/tailwind/item-table/styled"
// import { getTranslation } from "../../../../../translations"
// import ModalListReviews from "../../modal-list-reviews"
// import ModalListQuestions from "../../modal-list-questions"
// import ModalAddReview from "../../modal-add-review";
// import { TextQtd } from "./styled"

//import ModalUpdate from "../../modal-edit";
//import ModalCode from "../../modal-code";
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { Message, TextQtd } from "./styled"
import ModalMenuItem from "../../modal-menu-item"

const ItemTable = (props) => {

    // const [visibleModalListReviews, setVisibleModalListReviews] = useState(false)
    // const [visibleModalListQuestions, setVisibleModalListQuestions] = useState(false)
    // const [visibleModalAddReview, setVisibleModalAddReview] = useState(false)


    const [visibleModalMenu, setvisibleModalMenu] = useState(false)
    // const [visibleModal, setVisibleModal] = useState(false)
    const [fullMessage, setfullMessage] = useState(false)
    // const dispatch = useDispatch()
    const history = useHistory()

    // const [visibleModalUpdate, setVisibleModalUpdate] = useState(false)
    // const [visibleModalCode, setVisibleModalCode] = useState(false)


    //const closeMenu = () => { setvisibleMenu(false) }
    useEffect(() => {
        // if (visibleMenu) {
        //     window.addEventListener('mouseup', closeMenu);
        //     return () => { window.removeEventListener('mouseup', closeMenu); };
        // }


    }, [
        //visibleMenu
    ])

    const sincProduct = () => {
        //dispatch({ type: "SAGA_UPDATE_DATA_PRODUCT", payload: { id: props.item.id } })
    }

    const deleteProduct = () => {
        // dispatch({
        //     type: "SAGA_DELETE_PRODUCT",
        //     payload: {
        //         id: props.item.id
        //     }
        // })
    }


    useEffect(() => {
        //console.log("props", props)
    }, [props])


    //return null

    return (
        <>
            {/* <ModalUpdate
                visible={visibleModalUpdate}
                setVisibleModal={setVisibleModalUpdate}
                item={props.item} />
            <ModalCode
                visible={visibleModalCode}
                setVisibleModal={setVisibleModalCode}
                item={props.item} />
            <ModalAddReview
                review={props.item}
                goSearch={props.goSearch}
                visible={visibleModalAddReview} setVisibleModal={setVisibleModalAddReview} />
            <ModalListReviews
                setControlUpdate={props.setControlUpdate}
                search={props.item.name} id={props.item.id}
                visible={visibleModalListReviews} setVisibleModal={setVisibleModalListReviews} />
            <ModalListQuestions
                setControlUpdate={props.setControlUpdate}
                search={props.item.name} id={props.item.id}
                visible={visibleModalListQuestions} setVisibleModal={setVisibleModalListQuestions} /> */}

            <ModalMenuItem
                //setVisibleModalEdit={setVisibleModal}
                item={props.item}
                visible={visibleModalMenu}
                setVisibleModal={setvisibleModalMenu}
            />


            <tr key={"person.email"} className={props.selectedProducts.includes(props.item) ? 'bg-gray-50 even:bg-gray-50' : "even:bg-gray-50"}>
                <td className="relative px-7 sm:w-12 sm:px-6">
                    {props.selectedProducts.includes(props.item) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-green-600" />
                    )}
                    <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={props.item.email}
                        checked={props.selectedProducts.includes(props.item)}
                        onChange={(e) =>
                            props.setSelectedProducts(
                                e.target.checked
                                    ? [...props.selectedProducts, props.item]
                                    : props.selectedProducts.filter((p) => p !== props.item),
                            )
                        }
                    />
                </td>
                <td
                    className={props.classNames(
                        'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                        props.selectedProducts.includes(props.item) ? 'text-gray-900' : 'text-gray-900',
                    )}
                >
                    {new Date(props.item.products.created_at).toLocaleDateString()}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                        {props.item.products.image_url ?
                            <img onClick={() => { window.open(props.item.products.canonical_url, '_blank') }} style={{ cursor: "pointer", width: "32px", height: "32px", objectFit: "cover", borderRadius: "4px" }} src={props.item.products.image_url} /> :
                            <ShoppingBagIcon onClick={() => { window.open(props.item.products.canonical_url, '_blank') }} style={{ height: "32px" }} />}
                        <Message full={fullMessage} onClick={() => { setfullMessage(!fullMessage) }}>
                            <label onClick={() => { window.open(props.item.products.canonical_url, '_blank') }}>
                                {props.item.products.name}
                            </label>
                        </Message>
                    </span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <TextQtd onClick={() => {
                        //props.item.total_ask > 0 && setVisibleModalListQuestions(true)
                        history.push(`/produtos/${props.item.products.product_id}/variacoes`)
                    }} style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        {props?.item?.variants > 0 ? props?.item?.variants : 1}
                    </TextQtd>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <TextQtd onClick={() => {
                        //props.item.total_review_all > 0 && setVisibleModalListReviews(true)
                        //history.push(`/produtos/${props.item.products.id}/midias`)
                    }} style={{ display: "flex", width: "100%", justifyContent: "center", cursor: "initial", textDecoration: "initial" }}>
                        {props?.item?.media}
                    </TextQtd>
                </td>
                <td style={{ display: "flex", justifyContent: "flex-end" }} className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <span style={{ position: "relative" }}>
                        <EllipsisVerticalIcon onClick={() => {
                            setvisibleModalMenu(true)
                        }} style={{ height: "32px", cursor: "pointer" }} />

                    </span>
                </td>
            </tr>
        </>

    )
}

export default ItemTable

export const isVisibleScript = (value) => {


    if (value) {
        if (document.getElementById('wa__btn_popup_chat') != null) {
            document.getElementById('wa__btn_popup_chat').style.display = "flex";
        }
        if (document.getElementById('productstashSelector') != null) {
            document.getElementById('productstashSelector').style.display = "flex";
        }
    } else {
        if (document.getElementById('wa__btn_popup_chat') != null) {
            document.getElementById('wa__btn_popup_chat').style.display = "none";
        }
        if (document.getElementById('productstashSelector') != null) {
            document.getElementById('productstashSelector').style.display = "none";
        }
    }

}


import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background-color: #ffffff;
    //min-height: 100vh;
    width: 100%;
    flex: 1;
    //background-color: red;
    height: max-content;
`

export const BoxOptions = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100vh;
`

export const BoxPreview = styled.div`
    display: flex;
    padding: 30px;
    flex: 1;
    justify-content: center;
    height: max-content;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props => props.img});
`
export const BarNav = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
`

export const BarColors = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 20px;
`

export const BarConfig = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 20px;
`

export const MenuOption = styled.div`
    display: flex;
    margin-bottom: 10px;
    gap: 10px;

`

export const ItemOption = styled.label`
    font-size: 14px;
    display: flex;
    flex: 1;
    justify-content: center;
    border-bottom: ${props => props.active ? "3px solid #1b76ff" : "none"};
    padding-bottom: 5px;
    color: ${props => props.active ? "#050505" : "inicial"};
    cursor: pointer;
    font-weight: ${props => props.active ? "500" : "inicial"};
    height: ${props => props.active ? "30px" : "33px"};
`
export const Title = styled.div`
    display: flex ;
    align-items: center ;

    label{
        display: flex ;
        flex: 1 ;
        justify-content: center ;
        font-size: 18px;
        color: #1E1E1E ;
        margin-left: 5px;
        margin: 20px 0px 10px 0px;
        font-weight: 400;
        font-family:"poppins";
    }
`

export const Section = styled.div`
    flex-direction: column ;
    display: flex ;
    gap: 5px;
    border: 1px solid #D3DAE3;
    padding: 10px ;
    border-radius: 4px;
    width: 100%;

    label{
        font-size: 10px ;
    }
`

export const ItemColor = styled.div`
    height: 30px ;
    display: flex ;
    align-items: center ;
    gap: 20px;
    //padding: 10px ;
    justify-content: space-between ;
    //background-color: red ;

    label{
        font-size: 12px;
        color: #000 ;
    }

    span{
        display: flex ;
        //background-color: yellow ;
        min-width: 100px !important;
        width: 120px;
        height: 30px ;
        display: flex ;
        align-items: center ;
        border: 1px solid #D3DAE3;
        border-radius: 6px;
        padding: 0px 10px ;
        gap: 10px;

        label{
            text-transform: uppercase;
        }

        div{
            width: 20px ;
            height: 20px ;
            background-color: black ;
            border-radius: 4px ;
        }

        input[type=color] {
            width: 20px;
            height: 20px; 
            border-radius: 5px;
            overflow: hidden;
            padding: 0px;
            margin: 0px;
            border: none;
            cursor: pointer;
            font-size: 11px ;   
            border: 1px solid #f1f1f144;
        }

        input[type=color]::-webkit-color-swatch {
            border: none;
            border-radius: 5px;
            padding: 0;
            cursor: pointer;
            font-size: 11px ;
        }

        input[type=color]::-webkit-color-swatch-wrapper {
            border: none;
            border-radius: 5px;
            padding: 0;
            cursor: pointer;
            font-size: 11px ;
        }
    }
`

export const SelectCustom = styled.select`
    display: flex;
    background-color: #F5F3F0;
    height: 34px;
    width: 100% !important;
    font-weight: 600;
    color: #353535;
    border-width: 1px 1px 1px 0px;
    border-color: #EFF2F5;
    border-style: solid;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 10px 0px 5px;
    outline: none;
    font-size: 12px;
    cursor: pointer;

    @media(min-width: 480px) {
        width: 280px;
    }
`
export const ItemTextArea = styled.div`
    //background-color:  yellow;
    display: flex ;
    flex-direction: column ;
    gap: 5px;

    label{
        font-size: 10px ;
    }

    textarea{
        display: flex;
        flex: 1;
        outline: none;
        border: none;
        background-color: #F5F3F0 !important;
        border-radius: 5px;
        padding: 10px;
        resize: none;
    
        font-size: 12px ;
        font-weight: 400;
        color: #353535;
        //width: 100%;
    }
`

export const ItemText = styled.div`

    display: flex ;
    flex-direction: column ;
    justify-content: center ;
    gap: 5px;

    input{
        display: flex;
        flex: 1;
        outline: none;
        border: none;
        background-color: #F5F3F0;
        border-radius: 5px;
        padding: 0px 60px 0px 10px;
        min-height: 34px;
        font-size: 12px ;
        color: #353535;
        font-weight: 600;
    }


`

export const ButtonHelp = styled.div`
    //background-color: red ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 48px ;
    border-radius: 4px ;
    justify-content: center ;
    //border: 1px dashed #0D6EFD;
    cursor: pointer;
    background-color: #121212 ;

    label{
        font-size: 12px ;
        cursor: pointer;
        color: #fff ;
    }

    img{
        width: 44px ;
        height: 44px ;
        cursor: pointer;
    }
`

export const BoxButtonsItem = styled.div`
    background-color: ${props => props.color ? "#00803B" : "transparent"}   ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 36px ;
    border-radius: 4px ;
    justify-content: center ;
    font-size: 12px ;
    color: ${props => props.color ? "#fff" : "#000"}  ;
    cursor: pointer;
    flex: 1;
    border:  ${props => props.color ? "none" : "1px solid #D3DAE3"} ;
`


export const BoxButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`
export const Body = styled.div`
   
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: center;
   //margin-top: 10px;
   border: 1px solid #f1f1f1;
   max-width: 300px ;
   //max-height: 400px;
   padding: 20px 20px ;
   //background-color: white;
   margin: 40px 0px;
   border-radius: 5px ;
   background-color: ${props => props.background} ;
   color: ${props => props.color};
   //background-color: yellow ;
    height: max-content;

   /* @media screen and (max-width: 950px) {
        flex-direction: column ;
    } */

    //background-color: red;

`

export const BoxImage = styled.div`
   
   
   display: flex;
   justify-content: center;
   border: ${props => props.active ? "none" : "1px dashed #D3DAE3"} ;
   min-height: 50px ;
   //background-color: ${props => props.active ? "transparente" : "yellow"} ;
   margin-bottom: 20px ;
   
   img{
       max-width: 260px;
       margin: 0px 0px;
       border-radius: 5px;
   }

   label{
        display: ${props => props.active ? "none" : "flex"} ;
        color: rgba(0, 0, 0, 0.15);
        align-items: center ;
        font-weight: 500;
        font-size: 48px;
        line-height: 72px;
        font-family: 'Poppins';
   }
`

export const BoxContent = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px ;
    font-family: ${props => props.font} ; 
    //background-color: red ;
    color: ${props => props.color}; 

    label{
        text-align: justify;
        margin: 0px 0px 10px 0px;
        width: 100%;
        font-family: ${props => props.font} ; 
        //background-color: yellow;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: ${props => props.color}

        pre {
            white-space: pre-wrap;       /* Since CSS 2.1 */
            white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
            white-space: -pre-wrap;      /* Opera 4-6 */
            white-space: -o-pre-wrap;    /* Opera 7 */
            word-wrap: break-word;       /* Internet Explorer 5.5+ */
            font-family: ${props => props.font} ; 
            color: ${props => props.color}
        }
    }

    button{
        min-width: 100px;
        border-radius: 17.5px;
        height: 35px;
        background-color: ${props => props.background};
        color: ${props => props.color};
        outline: none;
        border: none;
        cursor: pointer;
        padding: 0px 7.5px;
        font-family: ${props => props.font} ; 
    }
`
export const Item = styled.div`
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;

    button{
        color: ${props => props.colorButton};
        background-color: ${props => props.backgroundColorButton};
    }

    label{
        color: ${props => props.color};
    }
`


export const CounterItemsCircle = styled.div`
    display: flex;
    position: absolute ;
    width: 20px;
    height: 20px ;
    align-items: center ;
    justify-content: center ;
    //background-color: red ;
    border-radius: 50% ;
    right: 10px ;
    bottom: 10px;
    //border: 1px solid ${props => props.colorBorder};
    //background-color: #F5F3F0;
    background: conic-gradient(#F13F6C 0% ${props => props.porc}, #ffffff 0% 100%);

    label{
        display: flex ;
        font-size: 8px !important;
        height: calc(100% - 2px) ;
        width: 100%;
        background-color: white;
        justify-content: center ;
        align-items: center;
        border-radius: 50%;
        margin: 1px;
        
    }
`

export const InputFileCustom = styled.div`
    background-color: #F5F3F0;
    display: flex ;
    height: 34px;
    width: 100% !important;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 10px 0px 0px;
    position: relative ;
    align-items: center ;

    input{
        display: flex;
        background-color: transparent ;
        outline: none ;
        border: none ;
        padding-right: 20px !important;
        width: 100% ;
        font-size: 12px ;
        font-weight: 600;
        color: #353535;
        padding-left: 10px ;
    }

    label{
        display: flex ;
        position: absolute ;
        right: 10px;

        svg{
            cursor: pointer;
        }

    }
`

export const ItemUpload = styled.div`
    display: flex ;
    flex-direction: column ;
    gap: 5px;
`

export const BoxLoadingCustom = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    //background-color: violet;
    div{
        animation: spin 1s linear infinite;
    }

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
`;


export const InputFileCustomTeste = styled.div`
    background-color: #F5F3F0;
    display: flex ;
    height: 34px;
    width: 100% !important;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 10px 0px 0px;
    position: relative ;
    align-items: center ;

    input{
        display: flex;
        background-color: transparent ;
        outline: none ;
        border: none ;
        padding-right: 20px !important;
        width: 100% ;
        font-size: 12px ;
        font-weight: 600;
        color: #353535;
        padding-left: 10px ;
    }

    label{
        display: flex ;
        position: absolute ;
        right: 10px;

        svg{
            cursor: pointer;
        }

    }
`

export const SendEmail = styled.div`
    display: flex ;
    flex-direction: column ;
`

export const InputTextCustom = styled.div`
    display: flex ;
    position: relative;
    flex-direction: column ;
    justify-content: center ;

    input{
        display: flex;
        flex: 1;
        outline: none;
        border: none;
        background-color: #F5F3F0;
        border-radius: 5px;
        padding: 0px 60px 0px 10px;
        min-height: 34px;
        font-size: 12px ;
        color: #353535;
        font-weight: 600;
    }

    label{
        display: flex ;
        position: absolute;
        right: 5px;
        background-color: #FFCC00;
        border-radius: 5px;
        //padding: 0px 5px;
        color: #000000 ;
        font-size:11px ;
        width: 50px ;
        height: 24px;
        justify-content: center ;
        align-items: center ;
    }
    
`
export const InputTextColor = styled.input`
    display: flex;
    //background-color: red;
    width: 50px;
    border: none;
    outline: none;
    font-size: 10px;
    text-transform: uppercase;
`
const INITIAL_STATE = {
  userId: "",
};

const Integration = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "INTEGRATION_USER":
      return { userId: action.payload };
    default:
      return state;
  }
};

export default Integration;

const INITIAL_STATE = {
  error: false,
  loading: false,
};

const Expire = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADMIN_EXPIRE_REQUEST":
      return { loading: true, error: false };
    case "ADMIN_EXPIRE_ERROR":
      return { loading: false, error: true };
    case "ADMIN_EXPIRE_SUCCESS":
      return { loading: false, error: false };
    case "ADMIN_EXPIRE_RESET":
      return { loading: false, error: false };
    default:
      return state;
  }
};

export default Expire;

import styled from "styled-components";

export const BoxFilesListItem= styled.div`
    display: flex;
    //background-color: red;
    gap: 10px;
    align-items: start;
`
export const BoxFilesTitle= styled.div`
    display: flex;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
    //background-color: red;
`
export const UploadFIle= styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    //background-color: red;
`
export const BarUpload= styled.div`
    display: flex;
    min-height: 4px;
    background-color: #dcdcdc;
    min-width: 100%;
    position: relative;

    div{
        background-color: green;
        position: absolute;
        min-width: ${props => `${props.progress}%`};
        min-height: 4px;
    }
`
export const StatusFile= styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    //background-color: red;
    font-size: 11px;
`

export const UploadInfor= styled.div`
    display: flex;
    justify-content: space-between;
    //background-color: red;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    span{
        margin-left: 20px;
    }
`
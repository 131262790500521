
const INITIAL_STATE = {
    error: null,
    loading: null,
    data: [],
};

const changeStatusDownload = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "CHANGE_STATUS_DOWNLOAD_REQUEST":
            return { loading: true };
        case "CHANGE_STATUS_DOWNLOAD_SUCCESS":
            return { loading: false, error: false, data: action.payload };
        case "CHANGE_STATUS_DOWNLOAD_ERROR":
            return { loading: false, error: true, data: action.payload };
        case "CHANGE_STATUS_DOWNLOAD_RESET":
            return { loading: null, error: null, data: [] };
        default:
            return state;
    }
}

export default changeStatusDownload
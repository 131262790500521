import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    //background-color: purple;
    //height: 40px;
    margin-top: 1px;
    padding: 10px 0px;
    border-bottom: 1px solid #f1f1f1;
    align-items: center;

    :first-child{
        border-top: 1px solid #f1f1f1;
    }
`
export const Icon = styled.div`
    display: flex;

`

export const Infor = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0px 10px;
    //flex-wrap: wrap;


    label{
        font-size: 9px;
        flex-wrap: wrap;
        flex: 1;
        height: 100%;
    }

    div{
        min-height: 4px;
        background-color: #f1f1f1;
        margin: 5px 0px;
        border-radius: 3px;

        span{
            display: flex;
            //background-color: #19B674;
            background-color: #1B76FF99;
            min-height: 2px;
            width: ${props => props.completed ? "100%" : "10%"} ;
            margin-left: ${props => props.completed ? "0px" : "calc(100% - 10%)"} ;
            animation: ${props => props.completed ? "initial" : "lineColor 0.5s alternate infinite;"} ;
            //animation: lineColor 0.5s alternate infinite;

            @keyframes lineColor {
                0% { margin-left: calc(0% - 0%); }
                100% { margin-left: calc(100% - 10%); }
            }
        }


    }

    p{
        font-weight: 500;
        margin: 0px;
        font-size: 12px;
    }
`

export const Actions = styled.div`
    display: flex;   
`
export const ItemAction = styled.span`
    display: flex;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    background-color: ${props => props.type == "delete" ? "#f1416c22" : "#19B67422"};

    :hover{
        background-color: ${props => props.type == "delete" ? "#f1416c" : "#19B67422"};

        svg{
            color: ${props => props.type == "delete" ? "#ffffff" : "#19B674"};
        }
        
    }

    svg{
        color: ${props => props.type == "delete" ? "#f1416c" : "#19B674"};
    }
`

export const Name = styled.span`
    display: flex;
    gap: 10px;
    //background-color: red;
    align-items: center;
    width: 500px;
    overflow: hidden;
`
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import store from "./store";
import { Provider } from 'react-redux';
import { BurgerMenuProvider } from './hooks/useBurgerMenu'
import "../src/assets/style.css"
import "../src/assets/styleteste.css"
import "./index.css"
import BarPlus from './components/bar-plu';
//novo

ReactDOM.render(
  <React.StrictMode>
    <BarPlus />
    <Provider store={store}>
      <BurgerMenuProvider>
        <App />
      </BurgerMenuProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    
`

export const Search = styled.div`
    display: flex;
    background-color: gray;
    margin: 20px 20px;
    height: 80px;
`

export const List = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
    
`


export const ListItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;  
    margin-top: 20px;
`

export const Title = styled.label`
    display: flex;
    font-weight: 600;
    color: #111827;
`

export const Infor = styled.label`
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #6b7280;
`



import React, { useState, useEffect } from "react";
import { ConatainerLoader, ContainerNew } from "../../../dashboard/pages/home-new/styled";
import LayoutNew from "../../../../components/tailwind/layout-new";
import TableMedias from "../../components/tailwind/table-medias";
import { useParams } from "react-router-dom";
import TableProducts from "../../components/tailwind/table-products";


const products = () => {

  //const { id } = useParams();

  return (
    <LayoutNew>
      <ContainerNew>
        <TableProducts
        
        />
      </ContainerNew>
    </LayoutNew>
  
  );
};

export default products;

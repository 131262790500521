import styled from "styled-components";

export const ContainerLoading = styled.div`
  display: flex;
  //background-color: gray;
  min-height: 100px;
  min-width: 100px;
  position: relative;

  align-items: center ;
  justify-content: center ;

  span{
      display: flex;
      position: absolute;
      background-color: #F2F2FD;
      min-width: 90px ;
      min-height: 90px;
      border-radius: 50%;
    }

  img{
    width: 60px ;
    height: 60px ;
    position: absolute ;
    z-index: 9;
  }
`

export const Loader01 = styled.div`
  display: flex;
  background-color: green;
  min-width: 95px ;
  min-height: 95px ;
  border-radius: 50%;
  background: conic-gradient(#005AFF 0% 15%, transparent 15% 50%, #005AFF 50% 65%, transparent 0% 100%);
  position: absolute;
  animation: spin 1s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

`


import { put, call } from "redux-saga/effects";
import {
  POST,
  API_LOGAR,
  API_CONCLUIR_LOGIN_PLUS,
  API_REGISTER,
  API_PASSWORD,
} from "../../../../services/api";
import { getCookieFromString } from "../../../../utils/cookies";

export function* logar(action) {
  yield put({ type: "LOGIN_REQUEST" });
  const result = yield call(API_LOGAR, action.payload);
  if (result === false) {
    yield put({ type: "LOGIN_ERROR" });
  } else {
    

    if (result?.redirect?.to) {
      // CASO DO CADASTRO COM EMPREENDER PLUS
      window.location.href = result["redirect"]["to"];
      return;
    } else {
      if (result.data.user.isAdmin == 1) {
        yield localStorage.setItem(
          "depoimentosAdmin@login",
          JSON.stringify(result.data)
        );
      } else {
        yield localStorage.setItem(
          "depoimentos@login",
          JSON.stringify(result.data)
        );
      }
    }
    yield put({ type: "LOGIN_SUCCESS", payload: result });
  }
  yield put({ type: "LOGIN_RESET" });
}

export function* concluirLogarPlus(action) {
  yield put({ type: "LOGIN_REQUEST" });
  const result = yield call(API_CONCLUIR_LOGIN_PLUS, action.payload);
  if (result === false) {
    yield put({ type: "LOGIN_ERROR" });
  } else {
    

    if (result?.data?.user) {
      if (result.data.user.isAdmin == 1) {
        yield localStorage.setItem(
          "depoimentosAdmin@login",
          JSON.stringify(result.data)
        );
      } else {
        yield localStorage.setItem(
          "depoimentos@login",
          JSON.stringify(result.data)
        );
      }
      yield put({ type: "LOGIN_SUCCESS", payload: result });
    } else {
      yield put({ type: "LOGIN_ERROR" });
    }
  }
  yield put({ type: "LOGIN_RESET" });
}

export function* register(action) {
  
  if (localStorage.getItem("depoimentos@affiliate")) {
    action.payload.affiliate = yield localStorage.getItem("depoimentos@affiliate");
  }

  action.payload.utm_source = getCookieFromString('utm_source');
  action.payload.utm_campaign = getCookieFromString('utm_campaign');
  action.payload.utm_medium = getCookieFromString('utm_medium');

  
  yield put({ type: "REGISTER_REQUEST" });
  const result = yield call(API_REGISTER, action.payload);


  //alert("result", JSON.stringify(result))
  if (result.error === true) {
   
    yield put({ type: "REGISTER_ERROR", payload: result.data });
  } else {
    if (result?.data?.redirect?.to) {
      // CASO DO CADASTRO COM EMPREENDER PLUS
      // 
      // const userCountry = localStorage.getItem("country");
      // if (userCountry === "BR") {
      //   window.location.href = "https://app.empreender.plus/selecionar-app?app=DIJITAL";
      // } else if (userCountry === "MX") {
      //   window.location.href = "https://mx.empreender.plus/selecionar-app?app=DIJITAL";
      // } else if (userCountry === "AR") {
      //   window.location.href = "https://ar.empreender.plus/selecionar-app?app=DIJITAL";
      // } else {
      //   window.location.href = "https://app.empreender.plus/selecionar-app?app=DIJITAL";
      // }

      window.location.href = result['data']['redirect']['to'];
      return;
    } else {
      localStorage.setItem("depoimentos@login", JSON.stringify(result.data.data));
      yield put({ type: "REGISTER_SUCCESS", payload: result });
    }
  }
  yield put({ type: "REGISTER_RESET" });
}

export function* password(action) {
  yield put({ type: "PASSWORD_REQUEST" });
  const result = yield call(API_PASSWORD, action.payload);
  if (result === false) {
    yield put({ type: "PASSWORD_ERROR" });
  } else {
    yield put({ type: "PASSWORD_SUCCESS", payload: result });
  }
  yield put({ type: "PASSWORD_RESET" });
}

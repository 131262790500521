import React, { useEffect, useRef, useState } from 'react'
import { BarUpload, BoxFilesListItem, StatusFile, UploadFIle, UploadInfor } from './styled'
import { IconFile } from '../../pages/home-new/styled'
import { GrFormClose } from 'react-icons/gr'
import { useDispatch } from 'react-redux'
import axios from 'axios';
import { sizeFiles } from '../../../../utils/convert'


const CardUploadMedia = (props) => {

  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch()

  const getUrlUpload = async () => {

    const token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;
    const url = `${process.env.REACT_APP_API_URL}/getSignedUrl?extFile=${props.item.name?.split(".")[props.item.type?.split()?.length]}&mimeType=${props.item.type}&sizeFile=${props.item.size}`;
    const headers = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });

    const result = await fetch(url, {
      method: 'GET',
      headers: headers
    })
      .then((response, data) => {
        console.log("res", response, data);
        return response.json()
      })
      .catch(error => {
        console.error('Erro na busca:', error);
      });

    console.log("result", result)
    return result.data
  };

  const setUrlUpload = async (data, url) => {
    const token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;
    const urlRequest = `${process.env.REACT_APP_API_URL}/upload_media`;
    const headers = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });

    const dataRequest = {
      limit: 0,
      message: "",
      media_name: props.item.name,
      media_url: url,
      media_extname: props.item.name?.split(".")[props.item.type?.split()?.length],
      media_size: props.item.size
    };

    if (props.product_id) {
      dataRequest.product_id = props.product_id
      dataRequest.variant_id = props?.variant_id
    }

    const result = await fetch(urlRequest, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(dataRequest)
    })
      .then((response, data) => {
        console.log("res", response, data);
        return response.json()
      })
      .catch(error => {
        console.error('Erro na busca:', error);
      });

    console.log("result", result)
    return result.data
  };

  const uploadFile = async (data) => {
    const url = data.url + "/" + data.fields.key
    const formData = new FormData();

    Object.keys(data.fields).forEach(key => {
      formData.append(key, data.fields[key]);
    });
    formData.append("file", props.item);


    const options = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
      },

    };

    const result = await axios.post(data.url, formData, options).then(response => {
      console.log('Arquivo enviado com sucesso:', response);
      setUrlUpload(data, url)
    }).catch(error => {
      if (axios.isCancel(error)) {
        console.log('Upload cancelado', props.position);

      } else {
        console.log('Erro no upload:', error);
      }
    });
    return result
  }

  const uploadFileS3 = async () => {
    const data = await getUrlUpload()
    if (data) {
      const result = await uploadFile(data)
    } else {
      console.log("erro uploadFileS3")
    }

  }

  useEffect(() => {
    //console.log("useEffect card props", props)
    if (props?.item) {
      uploadFileS3()
    }
  }, [])



  return (
    <BoxFilesListItem  >

      {/* <ModalConfirmation
        confirmation={cancel}
        setVisibleModal={setVisibleModalCancel}
        visible={visibleModalCancel}
        title={`Deseja cancelar o upload ${props.item?.name} ?`}
      /> */}

      {props.getIconFile(props.item.name?.split(".")[props.item.type?.split()?.length], props.item.name)}
      <UploadFIle>
        <UploadInfor>
          <label>{props.item.name} <span>{sizeFiles(parseInt(props.item.size))}</span> </label>
          {/* <GrFormClose onClick={() => { cancel() }} style={{ cursor: "pointer" }} size={18} color="#12479B" /> */}
        </UploadInfor>
        <BarUpload progress={progress}>
          <div />
        </BarUpload>
        <StatusFile>
          <label>{progress}% completo</label>
        </StatusFile>
      </UploadFIle>
    </BoxFilesListItem>
  )
}

export default CardUploadMedia
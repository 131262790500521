import { Text, Number, TextDecoration } from "./styles";

const Title = ({ children, number, marginBottom }) => {
  return number ? (
    <div className="d-flex">
      <Number>{number} - </Number>
      <Text>{children}</Text>
    </div>
  ) : (
    <>
      <Text marginBottom={marginBottom}>{children}</Text>
      <TextDecoration />
    </>
  );
};

export default Title;

import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  background: #00000080;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBlock = styled.div`
  background: #fff;
  height: 300px;
  width: 500px;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 25px;
  margin: 0;
`;

export const Legend = styled.p`
  font-size: 20px;
  margin: 0;
`;

export const Input = styled.input`
  width: 200px;
  margin-left: 10px;
  height: 40px;
  padding: 6px;
  border-radius: 8px;
  border-width: 0.5px;
`;

export const Button = styled.div`
  height: 50px;
  width: 400px;
  background: blue;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const ButtonTitle = styled.p`
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

export const ContainerInput = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

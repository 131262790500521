
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE } from "../../../../services/api";



export function* updateAccount(action) {
    yield put({ type: "ACCOUNT_UPDATE_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `/users`, data: action.payload })
    if (result === false) {
        yield put({ type: "ACCOUNT_UPDATE_ERROR" })
    } else {
        yield put({ type: "ACCOUNT_UPDATE_SUCCESS", payload: result.data })
        localStorage.setItem('depoimentos@login', JSON.stringify(result.data.data))
    }
}

export function* deleteAccount(action) {

    yield put({ type: "ACCOUNT_DELETE_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `/users`, data: action.payload })
    if (result === false) {
        yield put({ type: "ACCOUNT_DELETE_ERROR" })
    } else {
        yield put({ type: "ACCOUNT_DELETE_SUCCESS", payload: result.data })
        localStorage.removeItem('depoimentos@login')
    }
    yield put({ type: "ACCOUNT_DELETE_RESET", })
}
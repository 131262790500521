import styled from 'styled-components'

export const CloseIcon = styled.button`
    position: absolute; 
    top: 26px; 
    font-size: 30px;
    right: 26px; 
    background: none; 
    border: none;
    cursor: pointer;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    input {
        width: 530px; 
        height: 47px;
        border-radius: 5px;
        border: 1px solid #eee;
        margin-top: 10px;
        padding: 0 15px;
    }

    a {
        height: 50px; 
        width: 100%; 
        outline: none; 
        border: none; 
        background-color: #20d489; 
        border-radius: 5px; 
        color: #fff; 
        cursor: pointer; 
        margin-top: 16px !important;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        transition: 200ms ease;
        
        &:hover {
            filter: brightness(0.9);
        }
    }
`

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    strong {
        margin-bottom: 10px;
    }
    small {
        font-weight: 500;
    }
`
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex: 1;
  min-height: 100%;
  overflow: hidden;
  padding: 30px;
  width: 100%;
  //background-color: red ;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: 1600px) {
    justify-content: space-evenly;
  }
`;

export const BoxLine = styled.div`
  /* background-color: yellow; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    /* margin: 0px 20px ;
        width: calc(100% - 40px) ; */
  }
  :last-child {
    margin-bottom: 0px;
  }
`;

export const BoxColumn = styled.div`
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  height: 686px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
  }
  :last-child {
    margin-bottom: 0px;
  }
  @media screen and (max-width: 750px) {
    height: 1000px;
  }
`;

export const BoxLoading = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  //height: 100% ;
  //background-color: yellow ;
  margin-top: 50px;

  span {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const NavInfor = styled.strong`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const SectionLeft = styled.div`
  width: calc(40% - 17.5px);
  //height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  //background-color: purple ;

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 30px;
  }
`;

export const Section = styled.div`
  width: 100%;
  /* height: 250px; */
  /* display: grid;
  grid-template-columns: 25% 25% 25% 25%; */
  display: flex;
  justify-content: space-between;
  /* background-color: purple; */

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-left: 0px;
    /* margin-top: 30px; */
  }

  @media screen and (max-width: 750px) {
    display: grid;
    grid-template-columns: 50% 50%;
    /* height: 500px; */
  }
`;

export const ContainerLoading = styled.div`
  display: flex ;
  background-color: green;
  flex-grow: 1;
  flex-basis: auto;
`
import styled from "styled-components";


export const ContainerNew = styled.div`
    display: flex;
    //background-color: red;
    justify-content: center;
    //width: 100%;
    height: 100vh;

`
export const ContaineControlSizeNew = styled.div`
    display: flex;
    //max-width: 1440px;
    //width: 100%;
    //background-color: yellow;
    justify-content: center;
`
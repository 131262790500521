import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    //min-height: 50px;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #f1f1f1;
    gap: 10px;
    flex: 1;
    position: relative;
    min-height: 130px;
`

export const SectionIcon = styled.div`
    display: flex;
`

export const SectionInfor = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
   
    label{
        display: flex;
        font-size: 12px;
        color: gray;
    }

    
    span{
        //display: flex;
        flex-direction: column;
        flex: 1;

        label{
            display: flex;
            font-size: 14px;
            font-weight: 600;
            width: max-content;
            align-items: center;

            span{
                display: flex;
                background-color: #1b76ff88;
                margin-left: 5px;
                padding: 4px 8px;
                border-radius: 4px;
                font-size: 11px;
                align-items: center;
                justify-content: center;
                color: #ffffff;
            }
        }

        p{
            display: flex;
            font-size: 11px;
            margin: 0px;
            padding: 0px;
            color: gray;
        }
    }
`

export const SectionActions = styled.div`
    display: flex;
    //background-color: violet;
    gap: 14px;
    margin-left: 5px;
    align-items: center;
`

export const ItemAction = styled.div`
    
    display: flex;
    width: 40px;
    height: 40px;
    //background-color: #f1f1f1;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    //border: 1px solid  ${props => props.color}33;
    border: 1px solid  #f1f1f1;
    position: relative;
    cursor: pointer;

    svg{
        //color: ${props => props.color};
        color: gray;
    }

    :hover{
        background-color: ${props => props.color};
        //background-color: #1B76FF;

        svg{
            color: #ffffff;
        }
        label{
            display: flex;
        }
    }


    label{
        display: none;
        position: absolute;
        font-size: 12px;
        background-color: #000000;
        width: max-content;
        right: 0px;
        top: -50px;
        padding: 10px 20px;
        border-radius: 4px;
        z-index: 999999;
        color: #ffffff;

        :after {
            content: "";
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid #000000;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
    
`

export const CheckCustom = styled.div`
    display: flex;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    border: 1px solid #dcdcdc;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg{
        cursor: pointer;
        color: #1B76FF;
    }    
`

export const SectionDate = styled.p`
    display: flex;
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    color: gray;
    align-items: center;
`

export const BoxData = styled.div`
    display: flex;
    flex-direction: column;
    //background-color: yellow;

    label{
        display: flex;
        font-size: 14px;
        font-weight: 600;
        width: max-content;
        align-items: center;
        color: #000000bb;

        span{
            display: flex;
            background-color: #1b76ff88;
            margin-left: 5px;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 11px;
            align-items: center;
            justify-content: center;
            color: #ffffff;
        }
    }

    p{
        margin: 0px;
        padding: 0px;
        font-size: 12px;
        color: gray;
    }
`

export const BoxPedidos = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    span{
        display: flex;
        position: relative;
        //background-color: red;
        max-width: 44px;
        max-height: 44px;
        

        :hover {

            label{
                display: flex;
            }
        }

        img{
            border-radius: 4px;
            //border: 1px solid #f1f1f1;
        }
        
        label{
            display: none;
            position: absolute;
            font-size: 12px;
            background-color: #000000;
            width: max-content;
            left: 0px;
            top: -50px;
            padding: 10px 20px;
            border-radius: 4px;
            z-index: 999999;
            color: #ffffff;

            :after {
                content: "";
                position: absolute;
                top: 100%;
                left: 10px;
                border-top: 8px solid #000000;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
    }

    
`

export const Badget = styled.div`
    background-color: ${props => props.color.background};
    display: flex ;
    
    border-radius: 12.5px ;
    height: 25px;
    align-items: center ;
    justify-content: center;
    padding: 5px 10px;
    position: absolute;
    top: 10px;
    right: 10px;

    label{
        font-size: 9px ;
        margin-left: 5px;
        //background-color: purple ;
        color: ${props => props.color.color} ;
    }
`
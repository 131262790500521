import styled from 'styled-components'

export const Container =  styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    label {
        margin-top: 1rem;
        margin-bottom: 8px;
    }

    input {
        padding: 8px 16px;
        border-radius: 5px; 
        border: 1px solid #d8dadd;
    }
`
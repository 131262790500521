

// export const ImageFileToBase64 = file => new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
// });


const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export async function ImageFileToBase64(data) {
    //const file = data
    //console.log(await toBase64(file));
    return await toBase64(data)
}


export const sizeFiles = (value) => {
    //console.log("sizefile", value)

    try {
        if (value < 1024) return value + " bytes"
        if (value / 1024 < 1024) return Math.trunc(value / 1024) + " KB"
        if (value / 1024 / 1024 < 1024) return Math.trunc(value / 1024 / 1024) + " MB"
    } catch (error) {
        return ""
    }


}
import { BoxLeft, BoxRight, Button, Container, SubTitle, Title, } from "./styled";
import AnimationLily from "../../../../assets/animation/animation-empreender-lily.webm";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import bossDijital from "../../../../assets/animation/dijitalAnimation.webm";
import bossDijitalNew from "../../../../assets/animation/dijitalNew.webm";
import { translations } from "../../../../utils/language";

const Animation = () => {
  const dashboard = useSelector((state) => state.reducerDashboard);
  const language = useSelector((state) => state.reducerLanguage);


  useEffect(() => {
    console.log("language", language)
  }, [language])



  return (
    <Container>
      <BoxLeft>
        <Title>
          {translations["Olá"][language.data]},{" "}
          {JSON.parse(localStorage.getItem("depoimentos@login"))
            ?.user?.name.charAt(0)
            .toUpperCase() +
            JSON.parse(
              localStorage.getItem("depoimentos@login")
            )?.user?.name.slice(1)}
        </Title>
        <SubTitle>
          {
            translations[
            "Use o Dijital para adicionar mídias e links na sua loja virtual."
            ][language.data]
          }
        </SubTitle>
        <Button
          onClick={() => {
            window.open("https://api.whatsapp.com/send?phone=558181928574");
          }}
        >
          {translations["Suporte"][language.data]} online
        </Button>
      </BoxLeft>

      {window.innerWidth > 650 &&
        <video
          width={350}
          muted="true"
          autoplay="true"
          loop="true"
          src={bossDijitalNew}
        />}


    </Container>
  );
};

export default Animation;

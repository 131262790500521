import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    padding: 3rem 2rem;
    border-radius: 1rem;
    background-color: #fff;
    padding: 3rem 2rem;
    border-radius: 10px;
    display: flex;
    flex: 1;
    min-width: 100% !important;
  `}

  @media (max-width: 760px) {
    padding: 16px;
  }
`;

export const EmailPreviewWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 10px;
    @media (max-width: 960px) {
      flex-direction: column;
    }

    > div {
      flex: 1;
    }
  `}
`;

export const ColorPicker = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 8px;

    input[type="color"] {
      -webkit-appearance: none;
      border: none;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      overflow: hidden;
      cursor: pointer;
      border: 1px solid #e0e5ff;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
      border: none;
    }

    > label {
      margin: 0;
      font-size: 12px;
      font-weight: 300;
      cursor: pointer;
    }
  `}
`;

export const Input = styled.input`
  margin: 8px 0;
`;

export const TextArea = styled.textarea`
  margin: 8px 0;
`;

export const BoxInput = styled.div`
  margin: 24px 0;
`;

export const BoxIcon = styled.span`
  display: flex;
  background-color: #eaebed;
  width: 35px;
  height: 40px;
  align-items: center;
  /* justify-content: center; */
  span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :hover div {
    visibility: visible;
    opacity: 0.9;
    top: 30px;
    z-index: 999;
  }

  div {
    display: flex;
    position: absolute;
    background: black;
    visibility: hidden;
    border-radius: 5px;
    max-width: 300px !important;
    width: max-content;
    padding: 5px 10px;

    label {
      font-size: 10px;
      color: white;
    }

    :after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 10px;
      border-bottom: 8px solid black;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }
`;

export const Tooltips = styled.div`
  position: relative;
  /* background-color: #eaebed; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    //background-color: purple;
    //height: 40px;
    margin-top: 1px;
    padding: 10px 0px;
    border-bottom: 1px solid #f1f1f1;
    align-items: center;
    gap: 20px;

    :first-child{
        border-top: 1px solid #f1f1f1;
    }
`
export const Icon = styled.div`
    display: flex;

`

export const Infor = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0px 0px;
    //background-color: yellow;
    

    label{
        font-size: 9px;
        flex-wrap: wrap;
        flex: 1;
        height: 100%;
    }


    p{
        font-weight: 500;
        margin: 0px;
        font-size: 12px;
    }
`

export const CheckCustom = styled.div`
    display: flex;
    height: 14px;
    width: 14px;
    border-radius: 2px;
    border: 1px solid #1B76FF;
    justify-content: center;
    align-items: center;

    svg{
        cursor: pointer;
        color: #1B76FF;
    }
    //background-color: red;
    
`
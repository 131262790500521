import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ItemTable from '../item-table'
import Pagination from "../pagination";
import LoadingLogo from '../../../../../components/Loading-logo';
import ItemTableProduct from '../item-table-product';
import Navbar from '../navbar';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableProducts(props) {
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const dispatch = useDispatch()
    const [medias, setMedias] = useState(true)
    const [noMedias, setNoMedias] = useState(true)
    const [dataProducts, setDataProducts] = useState([])
    const products = useSelector(state => state.reducerProducts)

    useEffect(() => {
        dispatch({ type: "SAGA_LIST_PRODUCTS", payload: { search: "", perPage: 10, page: 1, }, });
    }, [])

    useEffect(() => {
        console.log("products medias", products)
    }, [products])



    return (

        products.loading ?
            <div style={{ display: "flex", height: "calc(100vh - 140px)", justifyContent: "center", alignItems: "center" }}>
                <LoadingLogo />
            </div> :
            <>

                <div className="px-4 sm:px-6 lg:px-8">
                    <Navbar />
                    <div style={{ marginBottom: "28px" }} className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{"Produtos"}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {"Vincule arquivos aos produtos disponíveis na sua loja."}
                            </p>
                        </div>
                    </div >

                    <div className="mt-0 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    {false > 0 && (
                                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">

                                        </div>
                                    )}
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>

                                                <th style={{ paddingLeft: "20px" }} scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                                    {"Data"}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {"Produto"}
                                                </th>

                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                        {"Variação"}
                                                    </label>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {products?.data?.data?.data?.map((item) => (
                                                <ItemTableProduct
                                                    classNames={classNames}
                                                    item={item} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
    )
}

import { useEffect } from 'react';
import { Container } from './styled'
import { useSelector } from 'react-redux';

const Card = (props) => {

    const language = useSelector((state) => state.reducerLanguage);

    // useEffect(() => {
    //     console.log("language", language.data)
    //     if (language.data == "es" || language.data == "pt") modifyLanguage()
    // }, [language])

    const modifyLanguage = () => {
        const script2 = document.getElementById("script-support");
        if (script2) {
            script2.parentNode?.removeChild(script2);
        }
        let script = document.createElement('script');
        script.src = language.data == "es" ? "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/637276231309d.js" : "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/63441eb639af4.js"
        script.id = "script-support";
        script.async = true;
        document.body.appendChild(script);

        // return () => {
        //     document.body.removeChild(script);
        // }

    }

    useEffect(() => {
        modifyLanguage()
    })


    return (
        <Container background={props.background} color={props.color}>
            <span>{props.headerText}</span>
            <strong>{props.mainText}</strong>
            <a onMouseEnter={() => { modifyLanguage() }} href={props.btnAction} target={props.headerText !== 'Atendimento'} rel="noreferrer">{props.btnText}</a>
        </Container>
    );
}

export default Card
import { Tooltip, BoxInfor, BoxCode, BoxMessage, Status, BoxDescription, BoxIntegration, Container, BoxContents, BoxOptions, ButtonAnswered, ButtonHidden, ButtonDelete } from "./styled"
import { AiFillEdit, AiOutlineClose, AiFillStar, AiFillClockCircle, AiOutlineCheck, AiFillEyeInvisible, AiOutlineQuestion, AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ModalDelete from "../modal-delete";
import ModalStatus from "../modal-status";
//import ModalUpdate from "../modal-update";
import ModalUpdate from "../modal-edit";
import { useState } from "react";

import ConfirmationDelete from "../../../../components/Modal-Confirmation";
import ConfirmationStatus from "../../../../components/Modal-Confirmation";


const Card = (props) => {

    const dispatch = useDispatch()
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [visibleModalStatus, setVisibleModalStatus] = useState(false)
    const [visibleModalUpdate, setVisibleModalUpdate] = useState(false)

    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const [visibleModalConfirmationStatus, setVisibleModalConfirmationStatus] = useState(false)

    const statusDeleteCupom = useSelector(state => state.reducerDeleteCupom)

    useEffect(() => {
        if (!(statusDeleteCupom.loading == false && !statusDeleteCupom.error == false && statusDeleteCupom.data.length == 0)) {
            const id = "statusDeleteCupom"
            if (statusDeleteCupom.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusDeleteCupom.error) toast.update(id, { render: "Erro ao tentar excluir.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: "Excluído com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    setVisibleModalConfirmation(false)
                }
            }
        }
    }, [statusDeleteCupom])

    const deleteCupom = () => {
        dispatch({
            type: "SAGA_DELETE_CUPOM",
            payload: {
                id: props.item.id
            }
        })
    }
    const updateStatusCupom = () => {
        dispatch({
            type: "SAGA_UPDATE_CUPOM",
            payload: {
                id: props.item.id,
                active: props.item.active == 1 ? false : true
            }
        })
    }

    return (
        <Container>
            {/* <ModalDelete visible={visibleModalDelete} setVisibleModal={setVisibleModalDelete} item={props.item} /> */}
            <ConfirmationDelete
                confirmation={deleteCupom}
                visible={visibleModalConfirmation}
                setVisibleModal={setVisibleModalConfirmation}
                title={"Deseja excluir esse cupom?"}
                infor={"Obs: Ao excluir esse cupom não será possivel recuperar o mesmo."} />
            <ConfirmationStatus
                confirmation={updateStatusCupom}
                visible={visibleModalConfirmationStatus}
                setVisibleModal={setVisibleModalConfirmationStatus}
                title={`Deseja ${props.item.active == 1 ? "desativar" : "ativar"} esse cupom?`}
            //infor={"Obs: Ao excluir esse cupom não será possivel recuperar o mesmo."} 
            />
            {/* <ModalStatus visible={visibleModalStatus} setVisibleModal={setVisibleModalStatus} item={props.item} /> */}
            <ModalUpdate visible={visibleModalUpdate} setVisibleModal={setVisibleModalUpdate} item={props.item} />

            {/* <Status color={props.item.active == 0 ? "#F1BC00" : "#20D489"}            >
                {props.item.active ? <AiOutlineCheck size={15} color={"white"} /> : <AiOutlineClose size={15} color={"white"} />}
            </Status> */}

            <Status color={props.item.active == 0 ? "#F1BC00" : "#20D489"}  >
                {props.item.active ? <AiOutlineCheck size={15} color={"white"} /> : <AiOutlineClose size={15} color={"white"} />}
                <Tooltip>
                    <label>{props.item.active ? "Esse cupom está ativo em sua loja." : "Esse cupom está inativo em sua loja."}</label>
                </Tooltip>
            </Status>

            <BoxContents color={props.item.active == 0 ? "#F1BC00" : "#20D489"}>
                <BoxCode active={props.item.active == 0 ? false : true}>
                    <p>{props.item.code}</p>
                </BoxCode>
                <BoxInfor>
                    <label><strong style={{ color: "gray" }}>Expira em:</strong>  {new Date(props.item.expiration_date).toLocaleDateString()}</label>
                    <BoxMessage>
                        <strong style={{ color: "gray" }}>Mensagem:</strong>
                        {props.item.message}
                    </BoxMessage>
                    <BoxDescription>
                        <strong style={{ color: "gray" }}>Descrição do cupom:</strong>
                        {props.item.description}
                    </BoxDescription>
                </BoxInfor>
                {/* <BoxIntegration>
                    NuvemShop
                </BoxIntegration> */}
            </BoxContents>
            <BoxOptions>
                <ButtonAnswered onClick={() => { setVisibleModalConfirmationStatus(true) }}
                    active={props.item.active == 1 ? false : true}
                //disabled={props.item.status == "answered" ? true : false}
                >
                    {props.item.active == 1 ? "Desativar" : "Ativar"}
                    <span>
                        {props.item.active == 0 ? <AiOutlineCheck size={15} color={"white"} /> : <AiOutlineClose size={15} color={"#19B674"} />}
                    </span>
                </ButtonAnswered>
                <ButtonHidden onClick={() => { setVisibleModalUpdate(true) }}
                    active={true}
                //disabled={props.item.status == "hidden" ? true : false}
                >
                    {/* {props.item.status != "hidden" ? "Esconder" : "Mostrar"} */}
                    Editar
                    <span>
                        <AiFillEdit size={15} color={"white"} />
                    </span>
                </ButtonHidden>
                <ButtonDelete onClick={() => {
                    //setVisibleModalDelete(true)
                    setVisibleModalConfirmation(true)
                }}
                >
                    Excluir
                    <span>
                        <AiOutlineDelete size={15} color={"white"} />
                    </span>
                </ButtonDelete>
                {/* <button>Verificar</button>  */}
            </BoxOptions>
        </Container >
    )
}

export default Card

import styled from 'styled-components'
import { lighten, darken } from 'polished'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center; 
    padding: 27px 45px; 
    min-height: 240px;
    border-radius: 10px;
    background: ${props => props.background};
    
    @media(max-width: 1100px) {
        flex: 1;
    }

    span {
        padding: 4px 8px; 
        font-size: 0.9rem; 
        font-weight: 500; 
        border-left: 3px solid; 
        line-height: 10px;
        color: ${props => props.color};
    }

    strong {
        font-size: 1.5rem; 
        font-weight: bold; 
        padding-top: 12px;
        color: ${props => props.color};
    }

    a {
        margin-top: 12px; 
        padding: 10px 25px; 
        font-weight: 500; 
        font-size: 1rem; 
        border-radius: 5px;
        color: ${props => props.color};
        background: ${props => lighten(0.1,props.background)};
        text-decoration: none;
        transition: 200ms ease;

        &:hover {
            background: ${props => darken(0.1,props.background)};
        }
    }

`
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    padding: 3rem 2rem;
    border-radius: 1rem;
    background-color: #fff;
    @media (max-width: 760px) {
      padding: 16px;
    }
  `}
`;

export const InnerWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  > div:first-of-type {
    flex: 1;
  }
`;

export const Preview = styled.div`
  ${({ theme }) => css`
    background: #eaebed;
    background-image: linear-gradient(to right, #0000, #0000),
      url("img/xadrez-pattern.png");
    background-size: 60%;
    border-radius: 8px;
    padding: 32px;
    flex: 1;
    /* background-color: #eaebed; */

    @media (max-width: 760px) {
      padding: 8px;
    }

    > section:first-of-type {
      position: relative;
      height: 250px;
      /* width: 400px; */
      background-image: url("../../../../assets/imgs/whatsapp-background.png");
      /* background-image: url("paper.gif"); */
      /* background-size: cover; */
      background-color: black;

      > div:first-of-type {
        position: absolute;
        padding: 16px;
        background: #202c33;
        word-break: break-all;
        max-height: 186px;
        overflow-y: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-width: 420px;

        > strong {
          color: #0ecf9c;
          font-weight: 600;
        }

        > p {
          font-weight: 300;
          font-size: 14px;
          margin: 0;
          color: #fff;
        }
      }
    }
  `}
`;

export const TextArea = styled.textarea`
  margin: 8px 0;
`;

import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ItemTable from '../item-table'
import Pagination from "../pagination";
//import { BarFilters, BarFiltersIcon, BarFiltersLeft, Search } from '../../../../reviews/components/tailwind/table/styled';
import { CodeBracketIcon, MagnifyingGlassIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
//import ButtonSinc from '../../button-sinc';
import { toast } from 'react-toastify';
//import Navbar from '../navbar';
import { BarFilters, BarFiltersLeft, Search } from './styled';
//import FiltersProducts from '../filters-products';
import { ContainerLoading } from '../../../../../components/Loading-logo/styled';
import LoadingLogo from '../../../../../components/Loading-logo';
import ItemTableVariant from '../../../../products/components/tailwind/item-table-variant';
import ItemTableProduct from '../item-table-product';
import Navbar from '../navbar';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableProducts(props) {

    useEffect(() => {
        console.log("props.variants", props.products)
    }, [props.products])

    const order = useSelector((state) => state.reducerGetOrder);

    useEffect(() => {
     console.log("order", order)
    }, [order])
    


    return (
        order.loading ?
            <div style={{ display: "flex", height: "calc(100vh - 140px)", justifyContent: "center", alignItems: "center" }}>
                <LoadingLogo />
            </div> :
            <>


                <div className="px-4 sm:px-6 lg:px-8">
                    <Navbar />
                    <div style={{ marginBottom: "28px" }} className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{"Produtos"}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {"Vincule arquivos aos produtos disponíveis na sua loja."}
                            </p>
                        </div>
                    </div>

                    {/* <BarFilters style={{ paddingRight: "20px" }}>
                        <span style={{ display: "flex", justifyContent: "space-between" }}>
                            <BarFiltersLeft style={{ marginRight: "20px" }}>
                                <Search>
                                    <span>
                                        <MagnifyingGlassIcon style={{ width: "20px" }} />
                                    </span>
                                    <input onKeyPress={(e) => { e.key == "Enter" && props.goSearch() }} type='text' placeholder={"Pesquisar por nome"} value={props.search} onChange={(e) => { props.setSearch(e.target.value) }} />
                                </Search>
                            </BarFiltersLeft>

                            <FiltersProducts
                                medias={medias} setMedias={setMedias}
                                noMedias={noMedias} setNoMedias={setNoMedias}
                            />
                        </span>
                    </BarFilters> */}



                    <div className="mt-0 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    {/* {selectedProducts.length > 0 && (
                                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ? <></> :
                                                <button
                                                    onClick={() => {
                                                        //sincMulti()
                                                    }}
                                                    type="button"
                                                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                                >
                                                    {"Sincronizar"}
                                                </button>}
                                        </div>
                                    )} */}
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                        {"Imagem"}
                                                    </label>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Nome
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {props.products?.map((item) => (
                                                <ItemTableProduct
                                                    classNames={classNames}
                                                    item={item} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Pagination setPage={changePage} /> */}
            </>
    )
}

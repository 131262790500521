import Modal from "react-modal";
import { BoxActions, BoxForm, Close, Container, InputCustom, TitleModal } from "./styled";
import Title from "../../../../components/Title";
import { CloseCircle } from "iconsax-react";
import Loading from "../../../../components/Loading";
import { useState } from "react";
import { useEffect } from "react";
import { translations } from "../../../../utils/language";
import { useSelector } from "react-redux";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "700px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    },
};



const ModalRenomearMidia = (props) => {

    const [nameMidia, setNameMidia] = useState(props.item.type == "url" ? props.item.media : props?.item?.media_name?.replace(/\.[^/.]+$/, ""))
    const language = useSelector((state) => state.reducerLanguage);

    const [url, seturl] = useState(props.item.media)
    const [nameUrl, setnameUrl] = useState(props.item.media_name)

    const rename = () => {
        if (props.item.type == "url") {
            props.renameMedia(nameUrl, url)
        } else {
            props.renameMedia(props.item.type == "url" ? nameMidia : nameMidia + "." + props?.item?.media_name?.split(".")[props?.item?.media_name?.split(".").length - 1], "")
        }
    }
    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { }}
        >
            <Container>
                <Close onClick={() => { props.setVisible(false) }}>
                    <CloseCircle color="gray" size={30} />
                </Close>
                <TitleModal>
                    <Title marginBottom="16px">
                        {translations["medias-renomear-midia"][language.data]}
                    </Title>
                </TitleModal>

                <BoxForm>
                    {props.item.type == "url" ?
                        <InputCustom>
                            <input style={{ marginRight: "20px" }} type="text" value={nameUrl} placeholder="nome da url" onChange={(e) => { setnameUrl(e.target.value) }} />
                            <input type="text" value={url} placeholder="url" onChange={(e) => { seturl(e.target.value) }} />
                            <span>
                                <label>URL</label>
                            </span>
                        </InputCustom> :

                        <InputCustom>
                            <input type="text" value={nameMidia} placeholder="mídia" onChange={(e) => { setNameMidia(e.target.value) }} />
                            <span>
                                {props.item.type == "url" ?
                                    <label>URL</label> :
                                    <label>.{props?.item?.media_name?.split(".")[props?.item?.media_name?.split(".").length - 1]}</label>}
                            </span>
                        </InputCustom>}
                </BoxForm>


                <BoxActions>
                    {/* {controlMedias.loading ?
                        <label>
                            <Loading size={18} color={"#ffffff"} /> Salvando
                        </label> : */}
                    <label onClick={() => { rename() }}>
                        {translations["medias-salvar"][language.data]}
                    </label>
                    {/* } */}

                </BoxActions>
            </Container>

        </Modal >
    )
}

export default ModalRenomearMidia
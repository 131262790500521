import styled from "styled-components";

export const Message = styled.div`
    //width: 500px;
    display: flex;
    white-space: normal;
    width: 100%;
    //max-width: 500px;
    //background-color: red;

    label{
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* Definimos quantas linhas queremos */
        display: ${props => props.full ? "flex" : "-webkit-box"} ;
        overflow: hidden;
        cursor: pointer;
        //width: 300px;
        //background-color: yellow;
        
    }
`



export const Submenu = styled.div`
    display: ${props => props.isVisible ? "flex" : "none"};
    background-color: #fff;
    position: absolute;
    right: 0px;
    padding: 10px;
    border-radius: 4px;
    z-index: 999999999;
    flex-direction: column;
    box-shadow: 0 0 2em #DCDBDA;


    div{
        display: flex;
        gap: 10px;
        height: 60px;
        padding: 10px;
        cursor: pointer;
        align-items: center;
        border-radius: 4px;

        :hover{

            span:nth-child(1){
                background-color: #fff;
            }
        }


        span{
            display: flex;
            flex-direction: column;
            height: 40px;
            //background-color: red;
            align-items: start;
            justify-content: space-between;

            label{
                cursor: pointer;
                font-size: 12px;
                //background-color: purple;
            }

            p{
                margin: 0px;
                padding: 0px;
                font-size: 14px;
                color: #111827;
                //background-color: yellow;
            }
        }


        :hover{
            background-color: #F9FAFB;
        }
    }
`

export const BoxIcon = styled.span`
    display: flex;
    flex-direction: column;
    background-color: #F9FAFB;
    //background-color: red;
    align-items: center !important;
    justify-content: center !important;
    width: 40px;
    height: 40px;
    border-radius: 4px;

`

export const BoxCode = styled.div`
    
    display: flex;
    align-items: center;
    //background-color: red ;
    cursor: pointer;
    position: relative ;

    :hover{
        label{
            visibility: visible ;
        }
    }
    

    p{
        height: 34px;
        min-width: 120px;
        border-radius: 5px;
        //border: 1px dashed #138062;
        border: ${props => props.active ? "1px dashed #21D487" : "1px dashed gray"};
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: #ECFAE5; #f0ad4e
        //background-color: ${props => props.active ? "#E4FFF4" : "#f2b8a055"} ; 
        background-color: ${props => props.active ? "#E4FFF4" : "#f1f1f1"} ; 
        color: ${props => props.active ? "#21D487" : "gray"} ;
        padding: 0px 4px;
        text-transform: uppercase;
        margin: 0px ;
        font-size: 14px;
    }

    label{
        display: flex;
        position: absolute;
        background: #292929cc;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important;
        width: max-content;
        padding: 10px;
        color: white ;
        font-size: 11px !important;
        margin-top: -100px;
        margin-left: 0px;

        :after{
            content: '';
            position: absolute;
            top: 100%;
            left: 10px;
            border-top: 8px solid #292929cc;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }

    svg{

        position: absolute ;
        top: -8px ;
        left: -8px ;
    }
`;

export const TextQtd = styled.label`

cursor: pointer;

    :hover{
        text-decoration: underline;
        color: gray;
    }
`
import styled, { css } from "styled-components";

export const Text = styled.h2`
  ${({ theme }) => css`
    font-weight: bold;
    color: #1e1e1e;
    margin-bottom: ${(props) => props.marginBottom ?? 2.5}rem;
    font-size: 1.2rem;
    position: relative;

    &:after {
      content: "";
      height: 5px;
      width: 36px;
      background: ${theme.colors.primary};
      border-radius: 500px;
      position: absolute;
      bottom: -7px;
      left: 0;
    }
  `}
`;

export const TextDecoration = styled.div`
  height: 5px;
  width: 36px;
  background: #1b76ff;
  border-radius: 500px;
  left: 0;
  margin: -8px 0 5px 0;
`;

export const Number = styled.h2`
  ${({ theme }) => css`
    font-weight: bold;
    color: #1e1e1e;
    margin-bottom: 2.5rem;
    font-size: 1.5rem;
    margin-right: 4px;
  `}
`;

import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 700px;
    //height: 300px;
    padding: 20px;
    flex-direction: column;
    gap: 20px;
`

export const Title = styled.div`
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    //background-color: red;

    label{
        color: #0f172a;
    }

`

export const Body = styled.div`
     display: grid;
     grid-template-columns: 1fr 1fr;
     gap: 20px;

`

export const Card = styled.div`
    padding: 24px;
    width: 320px;
    height: 180px;
    border-radius: 12px;
    box-sizing: border-box;
    border: 2px  solid #e5e7eb;
    cursor: pointer;
    overflow: hidden;
    gap: 10px;
    display: flex;
    flex-direction: column;

    :hover{
        border: 2px  solid #0ea5e9; //sky-500
        background-color: #F0F9FF; //sky-50
    }

    p{
        font-size: 16px;
        color: #0f172a; //slate-900
        margin: 0px;
        padding: 0px;
        cursor: pointer;

    } 

    label{
        font-size: 12px;
        color: #334155; //slate-700
        cursor: pointer;
    } 
    /* display: flex;
    width: 100%;
    height: 180px;
    //background-color: yellow;
    flex-direction: column;
    padding: 8px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;

    span{
        display: flex;
        
        align-items: center;
        gap: 4px;
        //background-color: red;

        label{
            font: 14px;
        }
    }

    label{
        font-size: 12px;
    } */

`
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  //width: 50%;
  align-items: center;
  justify-content: flex-end;
  /* margin-bottom: 20px; */
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const ContainerInput = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    width: 100%;
  }

  button {
    width: 40px;
    height: 40px;
    background-color: #1b76ff; //#FBF9F6 ;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  input {
    display: flex;
    width: 308px;
    flex: 1;
    background-color: #f5f6fb;
    border: none;
    outline: none;
    padding: 0px 5px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    @media (max-width: 1250px) {
      width: 258px;
    }
    @media (max-width: 900px) {
      width: 100%;
    }
    /* @media (max-width: 525px) {
      width: 80px;
    } */
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 40px;
  background-color: #f5f6fb;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 525px) {
    width: 80px;
  }
  p {
    font-size: 16px;
    color: #b8b8b8;
    margin-bottom: 0;
    margin-left: 5px;
  }
`;

export const ContainerAdd = styled.div`
  padding: 8px;
  background-color: #f4f5fe;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
`;

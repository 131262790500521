import React from 'react'
import { Container, ControlButton } from './styled'
import { Bar, BarInfor, Trial } from '../../../../components/Header/styled'
import { translations } from '../../../../utils/language'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { intervalToDuration } from 'date-fns'

const SpaceInfor = () => {

    const settings = useSelector((state) => state.reducerSettings);
    const history = useHistory()
    

    const calcBar = () => {
        let porc = ((settings?.data?.data?.storage / settings?.data?.data?.limit) * 100).toFixed(1)
        let result = (porc * 200 / 100)
        return result < 10 ? "10px" : result + "px"
    }

    const getColor = () => {
        let porc = ((settings?.data?.data?.storage / settings?.data?.data?.limit) * 100).toFixed(0)
        if (porc <= 60) { return "#21D487" }
        else if (porc < 90 && porc > 60) { return "#FFC400" }
        else { return "#F13F6C" }
    }

    const getSizeStorage = (value) => {
        if (value == "10000000000") { return "10GB" }
        else if (value == "20000000000") { return "20GB" }
        else if (value == "1000000000") { return "1GB" }
        else { return "150MB" }
    }



    return (
        <Container>
            <BarInfor>
                <label style={{ fontSize: "16px" }}> {translations["header-armazenamento"]["pt"]}</label>
                <label style={{ fontSize: "16px" }}>{`${(settings?.data?.data?.storage / 1000000).toFixed(1)}MB / ${getSizeStorage(settings?.data?.data?.limit)}`}</label>
            </BarInfor>
            <Bar color={getColor()} size={calcBar()}>
                <div />
                <label >{((settings?.data?.data?.storage / settings?.data?.data?.limit) * 100).toFixed(1)}%</label>
            </Bar>

            <ControlButton>
                <label onClick={() => { history.push("/pagamentos") }}>
                    {translations["header-comprar"]["pt"]}
                </label>
            </ControlButton>
        </Container>
    )
}

export default SpaceInfor
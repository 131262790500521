import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.layout.primary};
  transition: 300ms ease;
  min-width: ${(props) => (!props.isOpen ? "72px" : "200px")};
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 99999;
  background-color: #fff;
  //overflow-x: hidden;
  border-right: 1px solid #D3DAE3;

  //background-color: RED;

  @media screen and (max-width: 900px) {
    ${(props) =>
    !props.isOpen ? "display: none" : "display: flex, width: 200px"};
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 99999;
  }
`;

export const SectionLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: yellow;
  padding: 0px;
  margin-top: 5px;
  height: ${props => props.menuOpen ? "130px" : "80px"}; //50px;;
  align-items: center ;
  /* height: 300px;
  width: 300px; */
  //margin-top: -20px;

  /* @media screen and (max-width: 768px) {
        display: none;
    } */

    img {
    width:  ${(props) => !props.isOpen ? "60px" : "80px"};
    //height:  ${(props) => !props.isOpen ? "56px" : "112px"};
    }
  span {
    /* height: 200%;
    width: 200%; */
    display: flex;
    justify-content: center;

    img {
    width:  ${(props) => !props.isOpen ? "75px" : "150px"};
    }

    video {
      clip-path: inset(0.1px 0.1px);
      ${(props) => !props.isOpen ? `           width: 75px;` : `          width: 120px;`}
    }
  }
`;

export const SectionMenu = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 0px 20px; */
  /* padding-left: 2.5rem; */
  margin: 10px 0px;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  gap: 10px;
  //background-color: yellow;
`;

export const ItemMenu = styled.div`
  //background-color: violet;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-width: 0 0 0 3px;
  border-style: solid;
  border-color: #fff;
  align-items: center;
  ${(props) => props.selectedMenu && `  border-width: 0 0 0 3px;  border-style: solid;  border-color: #1b76ff;`}
  background-color: ${(props) => props.selectedMenu ? "rgb(27, 118, 255, 0.1)" : "none"};
  padding: 5px 0px;
  border-radius: 0px 4px 4px 0px;
  position: relative;

  align-items: center;
  justify-content: center;
 

  img{
    height: 24px ;
    width: 24px ;
    margin-left: ${(props) => props?.isOpen ? "10px" : "-4px"};
    //background-color: yellow;
  }

  :hover {
    color: white;
    border-width: 0 0 0 4px;
    border-style: solid;
    border-color: #1b76ff99;
    
  }

  span {
    label {
      display: flex;
      background-color: #1b76ff;
      //padding: 0px 5px ;
      font-size: 10px;
      /* align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%; */
      color: white;
      right: 0;
    }
  }

  label {
    display: flex;
    flex: 1;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);

    /* @media screen and (max-width: 768px) {
            display: none;
        } */
  }

  :hover{

    div{
      display: ${(props) => (props.isOpen ? "none" : "flex")};
    }
  }

  div{
    display: none;
  }
`;

export const ItemMenuOptions = styled.div`
  //background-color: yellowgreen;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 19px;
`;
export const BoxMenu = styled.div`
  //background-color: red;
  display: flex;
  width: 100%;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
  }

  label {
    margin-left: 19px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
`;
export const BoxSubMenu = styled.div`
  //background-color: purple;
  display: ${(props) => (props.visible ? "flex" : "none")};
  flex-direction: column;
  padding-left: 45px;
  width: 100%;
  //margin-bottom: -14px;
  //margin-top: 5px;

  /* transition: opacity .5s ease-in-out ;
    opacity: ${(props) => (props.visible ? "1" : "0")};
    height: ${(props) => (props.visible ? "auto" : "19px")};
    overflow: hidden; */
`;

export const BoxSubMenulabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin: 19px 19px 0px 0px;
  cursor: pointer;
`;

export const TitleMenu = styled.div`
  display: flex;
  margin: 10px 0px;
  font-size: 14px;
  color: #fff;
  transition: 300ms ease;

  /* @media screen and (max-width: 768px) {
        display: none;
    } */
`;

export const ItemMenuChaveEmpreender = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  //cursor: pointer;
  flex-direction: column;

  span {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;

    label {
      margin-left: 5px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  span:nth-child(2) {
    cursor: pointer;
    label {
      font-size: 14px;
    }
  }

  span:nth-child(1) {
    cursor: pointer;
    label {
      font-size: 14px;
    }
  }
`;

export const Logo = styled.img`
  margin-bottom: 20px;
  transition: 300ms ease;
  width: 10px;
  ${(props) => !props.isOpen ? `  height: 20px;` : `  height: 80px; `};
`


export const TooltipItem = styled.p`
    background-color: #1E1E1E ;
    color: white ;
    padding: 5px 10px ;
    //display: none ;
    position: absolute ;
    z-index: 1 ;
    font-size: 9px ;
    top: ${props => props.menuOpen ? "0px" : "5px"}  ; ;
    left:  ${props => props.menuOpen ? "10px" : "55px"}  ; 
    border-radius:  8px;
    align-items: center ;
    width: max-content ;
    justify-content: center ;
    height: max-content;
    align-items: center ;


    :after{
        content: '';
        position: absolute;
        //left: 100%;
        left: -8px;
        border-top: 8px solid #1E1E1E ;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        transform: rotate(90deg);
    }
`

export const Tooltip = styled.div`
  background-color: #1E1E1E ;
  color: white ;
  padding: 5px 10px ;
  position: absolute;
  left: calc(100% + 15px);
  border-radius:  8px;
  align-items: center ;
  width: max-content ;
  justify-content: center ;
  height: max-content;
  align-items: center ;
  font-size: 11px;

  :after{
      content: '';
      position: absolute;
      //left: 100%;
      left: -4px;
      border-top: 16px solid #1E1E1E ;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      transform: rotate(90deg);
  }
`
import { ArrowTopRightOnSquareIcon, CheckCircleIcon, CloudArrowDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import Pagination from "../components/pagination";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BarFilters, BarFiltersIcon, BarFiltersLeft, DivProduct, Search } from "./styled";
import FiltersOrder from "../components/filters-order";
import { sizeFiles } from "../../../../utils/convert";
import TableDownload from "../components/table-download";
import { ContaineControlSizeNew, ContainerNew } from "./styled-new";
import Loading from "../../../../components/Loading";
const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
const activityItems = [
    {
        user: {
            name: 'Michael Foster',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        commit: '2d89f0c8',
        branch: 'main',
        status: 'Completed',
        duration: '25s',
        date: '45 minutes ago',
        dateTime: '2023-01-23T11:00',
    },
    {
        user: {
            name: 'Lindsay Walton',
            imageUrl:
                'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        commit: '249df660',
        branch: 'main',
        status: 'Completed',
        duration: '1m 32s',
        date: '3 hours ago',
        dateTime: '2023-01-23T09:00',
    },
    {
        user: {
            name: 'Courtney Henry',
            imageUrl:
                'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        commit: '11464223',
        branch: 'main',
        status: 'Error',
        duration: '1m 4s',
        date: '12 hours ago',
        dateTime: '2023-01-23T00:00',
    },
    {
        user: {
            name: 'Courtney Henry',
            imageUrl:
                'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        commit: 'dad28e95',
        branch: 'main',
        status: 'Completed',
        duration: '2m 15s',
        date: '2 days ago',
        dateTime: '2023-01-21T13:00',
    },
    {
        user: {
            name: 'Michael Foster',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        commit: '624bc94c',
        branch: 'main',
        status: 'Completed',
        duration: '1m 12s',
        date: '5 days ago',
        dateTime: '2023-01-18T12:34',
    },
    {
        user: {
            name: 'Courtney Henry',
            imageUrl:
                'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        commit: 'e111f80e',
        branch: 'main',
        status: 'Completed',
        duration: '1m 56s',
        date: '1 week ago',
        dateTime: '2023-01-16T15:54',
    },
    {
        user: {
            name: 'Michael Foster',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        commit: '5e136005',
        branch: 'main',
        status: 'Completed',
        duration: '3m 45s',
        date: '1 week ago',
        dateTime: '2023-01-16T11:31',
    },
    {
        user: {
            name: 'Whitney Francis',
            imageUrl:
                'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        commit: '5c1fd07f',
        branch: 'main',
        status: 'Completed',
        duration: '37s',
        date: '2 weeks ago',
        dateTime: '2023-01-09T08:45',
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
    const params = useParams();
    const dispatch = useDispatch();
    const [page, setpage] = useState(1)

    const [search, setsearch] = useState("")
    const products = useSelector((state) => state.reducerYourMedias);
    const [config, setConfig] = useState("")
    const [filterProducts, setfilterProducts] = useState([])

    const getColorButton = () => {
        switch (config.checkout_color) {
            case "gray":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-gray-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            case "red":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            case "yellow":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-yellow-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
            case "green":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            case "blue":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            case "purple":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-purple-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
            case "pink":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-pink-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
            default:
                return "inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        }
    }

    const getColorType = () => {
        switch (config.checkout_color) {
            case "gray":
                return "rounded bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm hover:bg-gray-100"
            case "red":
                return "rounded bg-red-50 px-2 py-1 text-xs font-semibold text-red-600 shadow-sm hover:bg-red-100"
            case "yellow":
                return "rounded bg-yellow-50 px-2 py-1 text-xs font-semibold text-yellow-600 shadow-sm hover:bg-yellow-100"
            case "green":
                return "rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100"
            case "blue":
                return "rounded bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
            case "purple":
                return "rounded bg-purple-50 px-2 py-1 text-xs font-semibold text-purple-600 shadow-sm hover:bg-purple-100"
            case "pink":
                return "rounded bg-pink-50 px-2 py-1 text-xs font-semibold text-pink-600 shadow-sm hover:bg-pink-100"
            default:
                return "rounded bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
        }
    }

    const getColorMedia = () => {
        switch (config.checkout_color) {
            case "gray":
                return "rounded bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm hover:bg-gray-100"
            case "red":
                return "rounded bg-red-50 px-2 py-1 text-xs font-semibold text-red-600 shadow-sm hover:bg-red-100"
            case "yellow":
                return "rounded bg-yellow-50 px-2 py-1 text-xs font-semibold text-yellow-600 shadow-sm hover:bg-yellow-100"
            case "green":
                return "rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100"
            case "blue":
                return "rounded bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
            case "purple":
                return "rounded bg-purple-50 px-2 py-1 text-xs font-semibold text-purple-600 shadow-sm hover:bg-purple-100"
            case "pink":
                return "rounded bg-pink-50 px-2 py-1 text-xs font-semibold text-pink-600 shadow-sm hover:bg-pink-100"
            default:
                return "rounded bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
        }
    }

    useEffect(() => {
        console.log("product", products)
        try {
            setfilterProducts(getDownloads())
            setConfig(products?.data?.template[0])
        } catch (error) {

        }

    }, [products])

    useEffect(() => {
        console.log("filterProducts", filterProducts)
    }, [filterProducts])

    useEffect(() => {
        console.log("config", config)
    }, [config])


    const getDownloads = () => {
        let data = []
        products.data?.data.forEach(element => {
            element.forEach(item => {
                data.push(item)
            });
        });


        return data
    }

    useEffect(() => {
        console.log("params", params)
        dispatch({ type: "GET_ORDER_DATA", payload: { user_id: params.user_id, order_id: params.order_id, store_id: params.store_id, }, });
    }, [params]);

    const download = (item) => {
        if (item.type == "url") {
            window.open(`${process.env.REACT_APP_API_URL}/download/${item.id}/${params.order_id}/${params.user_id}/link/${item.media_name ? encodeURIComponent(item.media_name) : "linkproduct"}`, '_blank')
        } else {
            window.open(`${process.env.REACT_APP_API_URL}/download/${item.id}/${params.order_id}/${params.user_id}/download/${encodeURIComponent(item.media_name)}`, '_blank')
        }
    }


    useEffect(() => {

        console.log("search", search)
        try {
            if (search == "") {
                setfilterProducts(getDownloads())
            } else {
                let data = filterProducts
                setfilterProducts(getDownloads().filter(item => item.product_name.toLowerCase().includes(search.toLowerCase()) || item.media_name.toLowerCase().includes(search.toLowerCase())))
            }
        } catch (error) {
            console.log("error search", error)
        }


    }, [search])

    useEffect(() => {
        document.querySelectorAll('.insert-toolbar-plus').forEach(element => {
            element.parentElement.remove();
            //element.style.display = 'none';
        });

        document.body.style.backgroundColor = "#fff"
    }, [])


    return (
        // <ContainerNew>
        //     <ContaineControlSizeNew>
        products.loading ?
            <div style={{display: "flex", width: "100%", height: "100vh", alignContent: "center", justifyContent: "center"}}>
                <Loading />
            </div> :
            <TableDownload
                config={config}
                filterProducts={filterProducts}
                getColorMedia={getColorMedia}
                sizeFiles={sizeFiles}
                getColorType={getColorType}
                getColorButton={getColorButton}
                search={search}
                setsearch={setsearch}
                download={download}
            />
        //     </ContaineControlSizeNew>
        // </ContainerNew>

    )

    return (
        <div style={{ width: "100vh", display: "flex", justifyContent: "center", padding: "10px 0px", backgroundColor: "white" }}>
            <div style={{ width: "1440px", }} className="bg-white py-10">
                <div style={{ margin: "0px 20px" }}>
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        {/* Compra Concluída com Sucesso! */}
                        {config.checkout_message_title}
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {config.checkout_message}
                        {/* Confira abaixo os seus arquivos para download. Agradecemos a sua compra e esperamos que aproveite! */}
                    </p>
                </div>
                <BarFilters>
                    <span style={{ display: "flex", justifyContent: "space-between" }}>
                        <BarFiltersLeft>
                            {/* <Search>
                                <span>
                                    <MagnifyingGlassIcon style={{ width: "20px" }} />
                                </span>
                                <input onKeyPress={(e) => { e.key == "Enter" && alert("teste") }} type='text' placeholder='Pesquisar por produto...' value={search} onChange={(e) => { setsearch(e.target.value) }} />
                            </Search> */}
                        </BarFiltersLeft>
                        {/* <BarFiltersIcon>
                            <div style={{ display: "flex" }}>
                                <FiltersOrder

                                />
                            </div>
                        </BarFiltersIcon> */}
                    </span>
                </BarFilters>
                <table className="mt-6  whitespace-nowrap text-left">
                    <colgroup>
                        <col className="sm:w-4/12" />
                        <col className="lg:w-4/12" />
                        <col className="lg:w-1/12" />
                        <col className="lg:w-1/12" />
                    </colgroup>
                    <thead className="border-b border-gray/10 text-sm leading-6 text-gray-700">
                        <tr>
                            <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                                Produto
                            </th>
                            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                                Mídia
                            </th>
                            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                                Tipo
                            </th>
                            <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                                Download
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray/5">
                        {filterProducts.map((item) => (
                            <tr key={"item.commit"}>
                                <td className="py-2 pl-4 pr-8 sm:pl-6 lg:pl-8">
                                    <div className="flex items-center gap-x-4">
                                        <img alt="" src={item.product_image} className="h-8 w-8 rounded bg-gray-800" />
                                        <DivProduct style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} className="truncate text-sm font-medium leading-6 text-gray">{item?.product_name}</DivProduct>
                                    </div>
                                </td>
                                <td className="hidden py-2 pl-0 pr-4 sm:table-cell sm:pr-8">
                                    <div className="flex gap-x-3">
                                        <div className="font-mono text-sm leading-6 text-gray-700">{item?.media_name}</div>
                                        {item.size != null && <div className={getColorMedia()}>
                                            {sizeFiles(item.size)}
                                        </div>}
                                    </div>
                                </td>
                                <td className="hidden py-2 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                                    <div className="flex gap-x-3">
                                        <div className={getColorType()}>
                                            {item?.type.toUpperCase()}
                                        </div>
                                    </div>
                                    {/* <div
                                    
                                    className="rounded bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                >
                                    {item.type.toUpperCase()}
                                </div> */}
                                </td>
                                <td className="py-4 pl-0 pr-0 text-sm leading-6 sm:pr-0 lg:pr-0"
                                >
                                    <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }}>
                                        <button
                                            onClick={() => { download(item) }}
                                            type="button"
                                            className={getColorButton()}
                                        >
                                            {item.type != "url" ?
                                                < CloudArrowDownIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" /> :
                                                < ArrowTopRightOnSquareIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                            }
                                            {item.type != "url" ? products?.data?.template[0]?.checkout_button : products?.data?.template[0]?.checkout_button_link}
                                            {console.log("testy ", products?.data?.template[0]?.checkout_button)}
                                        </button>
                                    </div>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Pagination setPage={setpage} items={filterProducts} />
            </div>
        </div>
    )
}

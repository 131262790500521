import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, ContainerLine } from "./styled";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Title from "../../../../components/Title";
import { useDispatch, useSelector } from "react-redux";
import { translations } from "../../../../utils/language";

const Chart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.reducerDashboard);
  const chart = useSelector((state) => state.reducerChart);
  const data = chart?.data?.data?.slice(0).reverse();
  const language = useSelector((state) => state.reducerLanguage);
  //const language = {data: "pt"}


  return (
    <Container>
      <ContainerLine>
        <Title marginBottom="16px">
          {translations["Últimos 7 dias"][language.data]}
        </Title>
      </ContainerLine>
      <ResponsiveContainer width="95%" height="70%">
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="0 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="link"
            stroke="#EE9702"
            name={translations["Cliques no link"][language.data]}
            // activeDot={{ r: 8 }}
            strokeWidth={4}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="download"
            name="Downloads"
            stroke="#006BEC"
            strokeWidth={4}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default Chart;

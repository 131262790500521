import React from 'react'
import { BarColors, BarConfig, BarNav, BoxButtons, BoxButtonsItem, BoxOptions, BoxPreview, ButtonHelp, CounterItemsCircle, InputTextColor, ItemColor, ItemOption, ItemText, ItemTextArea, MenuOption, Section, SelectCustom, Title } from '../BoxEmail/styled'
import { AlertSave, BoxActive, BoxPreviewContent, Container, ItemActive, PreviewBody, PreviewCard, PreviewHeader, PreviewProducts, PreviewTitle, Steps, TitleSteps } from './styled'

import imgBackground from "../../../../assets/imgs/preview-background.png";
import IconBossVideo from "../../../../assets/imgs/boss-video.svg";
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import IconSlider from "../../../../assets/imgs/icon-slider-home.svg";

import IconBossSave from '../../../../assets/imgs/boss-save.svg';
import { useEffect } from 'react';
import { MdContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';
import { translations } from '../../../../utils/language';

const BoxPageCheckout = () => {

    const [status, setStatus] = useState(JSON.parse(localStorage.getItem("depoimentos@login")).integration?.is_script_download == 1 ? true : false)
    const language = useSelector((state) => state.reducerLanguage);
    const dispatch = useDispatch()

    const copyCode = () => {
        navigator.clipboard.writeText('<div id="dijital-downloads" style="width: 100vw;"></div>')
        toast.success("Código copiado.")
    }

    // const changeStatusActive = () => {
    //     console.log("changeStatusActive")
    //     let copy = JSON.parse(localStorage.getItem("depoimentos@login"))
    //     if (copy.integration?.is_script_download == 1) {
    //         copy["integration"].is_script_download = false
    //         setStatus(false)
    //     } else {
    //         copy["integration"].is_script_download = true
    //         setStatus(true)
    //     }

    //     localStorage.setItem("depoimentos@login", JSON.stringify(copy))

    //     save()
    // }
    const reducerChangeStatusDownload = useSelector(state => state.reducerChangeStatusDownload)

    useEffect(() => {
        if (reducerChangeStatusDownload.loading == null && reducerChangeStatusDownload.error == null && reducerChangeStatusDownload.data.length == 0) {

        } else {
            const id = "statusEmailTeste"
            if (reducerChangeStatusDownload.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (reducerChangeStatusDownload.error) toast.update(id, { render: "Erro ao tentar enviar.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: "Atualizado com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    let copy = JSON.parse(localStorage.getItem("depoimentos@login"))
                    copy.integration = reducerChangeStatusDownload.data.data
                    localStorage.setItem("depoimentos@login", JSON.stringify(copy))
                    setStatus(reducerChangeStatusDownload.data.data.is_script_download == 1 ? true : false)
                }
            }
        }
    }, [reducerChangeStatusDownload])

    const save = () => {
        dispatch({
            type: "SAGA_CHANGE_STATUS_DOWNLOAD",
            payload: { status: !status, platform: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform },
        })
    }

    return (
        <Container>
            <BoxOptions>
                <BarNav>
                    <Title>
                        <label>
                            Página downloads
                        </label>
                    </Title>
                </BarNav>

                <BarConfig>

                    <Section>
                        <BoxActive>
                            <span>
                                <div>
                                    <img width={20} src={IconSlider} />
                                    <label>{translations["thema-checkout-loja"][language.data]}</label>
                                </div>
                                <ItemActive onClick={() => { save() }}
                                    active={status}>
                                    <span />
                                </ItemActive>
                            </span>
                            <label>{translations["thema-necessario-pagina"][language.data]}</label>
                        </BoxActive>
                    </Section>

                    <Section>
                        <ItemTextArea style={{ position: "relative", }}>
                            <label>Código:</label>
                            <textarea
                                value={'<div id="dijital-downloads" style="width: 100vw;"></div>'}
                                rows={4}
                                maxLength="255"
                                placeholder={""}
                            //onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { checkout_message_header: e.target.value } }) }}
                            />
                            <CounterItemsCircle style={{ cursor: "pointer" }}>
                                <MdContentCopy onClick={() => { copyCode() }} color='gray' />
                            </CounterItemsCircle>
                        </ItemTextArea>
                    </Section>

                    {/* <Section>
                        <ItemTextArea style={{ position: "relative", }}>
                            <label>iframe:</label>
                            <textarea
                                value={'<div id="dijital-downloads" style="width: 100vw;"></div>'}
                                rows={4}
                                maxLength="255"
                                placeholder={""}
                            //onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { checkout_message_header: e.target.value } }) }}
                            />
                            <CounterItemsCircle style={{ cursor: "pointer" }}>
                                <MdContentCopy onClick={() => { copyCode() }} color='gray' />
                            </CounterItemsCircle>
                        </ItemTextArea>
                    </Section> */}


                    <ButtonHelp>
                        <img src={IconBossVideo} />
                        <label> {translations["Precisa de ajuda?"][language.data]} </label>
                        <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                    </ButtonHelp>

                    {/* <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} /> */}

                    {/* <BoxButtons>
                        <BoxButtonsItem onClick={() => {
                            //getDefaultConfig()
                        }}>
                            Restaurar
                        </BoxButtonsItem>
                        <BoxButtonsItem color={true} onClick={() => {
                            //saveConfig()
                        }}>
                            Salvar
                        </BoxButtonsItem>
                    </BoxButtons> */}
                </BarConfig>
            </BoxOptions>

            <BoxPreview style={{ position: "relative", backgroundColor: "gray !important" }}>
                <BoxPreviewContent>
                    <iframe
                        width={window.innerWidth - 1200}
                        height={(window.innerWidth - 1200) / 2}
                        //width={600}
                        //height={400}
                        src={"https://www.youtube.com/embed/wzGsyxEtHp0"}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="false" />
                    <span>
                        <TitleSteps>
                            <p>{translations["thema-criar-pagina"][language.data]}</p>
                            <label>{translations["thema-vamos-criar-pagina"][language.data]}</label>
                        </TitleSteps>
                        <Steps>
                            <label>
                                1 - {translations["thema-na-sua-plataforma"][language.data]}
                            </label>
                            <label>
                                2 - {translations["thema-no-titulo-pagina"][language.data]}
                            </label>
                            <label>
                                3 - {translations["thema-no-form-escreva"][language.data]}<br></br>
                                <span style={{ borderRadius: "4px", color: "#54B4DA", display: "flex", width: "100%", backgroundColor: "#151718", margin: "10px 0px", padding: "20px" }}>
                                    {'<div id="dijital-downloads" style="width: 100vw;"></div>'}
                                </span>
                            </label>
                            <label>
                                4 - {translations["thema-na-plataforma"][language.data]}
                            </label>
                            <label>
                                5 - {translations["thema-para-salvar"][language.data]}
                            </label>
                            <label style={{ fontWeight: "600" }}>
                                {translations["thema-obs"][language.data]}
                            </label>
                        </Steps>
                    </span>
                </BoxPreviewContent>
            </BoxPreview>
        </Container>
    )
}

export default BoxPageCheckout
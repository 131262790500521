import {
  Container,
  ButtonCustom,
  Close,
  Title,
  Item,
  ItemTitle,
  InputCustomShopify,
  Code,
  Input,
  Badge,
  BoxScript,
  ContainerCard,
  BlockInfo,
  BlockPermissions,
} from "./styled";
import Modal from "react-modal";
import { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import Alert from "../../../../components/Alert";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { BiCopy, BiLinkExternal } from "react-icons/bi";
import { GrShare } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { MdOutlineContentCopy } from "react-icons/md";
import { useEffect } from "react";
import { translations } from "../../../../utils/language";
import { useSelector } from "react-redux";
import Loading from "../../../../components/Loading";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "0px",
    margin: "0px",
    borderRadius: "10px",
    overflow: "auto",
    maxHeight: "90vh",
  },
  overlay: {
    backgroundColor: "rgba(10,23,55,0.5)",
    zIndex: "99999999"
  },
};

const ModalIntegration = (props) => {
  const [inputStore, setInputStore] = useState("");
  const [inputUrlSite, setInputUrlSite] = useState("");
  const [inputClientId, setInputClientId] = useState("");
  const [inputClientSecret, setInputClientSecret] = useState("");
  const [inputBasic, setInputBasic] = useState("");
  const [tokenMontink, setTokenMontink] = useState("");
  const [storeName, setStoreName] = useState("");
  const [token, setToken] = useState("");
  const [key, setKey] = useState("");
  const [stepYampi, setStepYampi] = useState(0);
  const [loading, setLoading] = useState(false);
  const [secretKey, setSecretKey] = useState();

  const [webhookSak, setWebhookSak] = useState("")
  const [tokenSak, setTokenSak] = useState("")

  const language = useSelector((state) => state.reducerLanguage);

  const dispatch = useDispatch();

  const history = useHistory();

  const webhook = `${process.env.PORT}/webhook/hotmart?store=${JSON.parse(localStorage.getItem("depoimentos@login")).user.chave_empreender
    }`;

  useEffect(() => {
    setStepYampi(0);
  }, [props]);

  function integrate() {
    dispatch({
      type: "SAGA_INTEGRATE_NEW_INTEGRATION",
      payload: {
        code: key,
        type: "Yampi",
        token: token,
        store: storeName,
        closeModal: () => {
          props.setVisibleModal(false);
          setLoading((prevState) => !prevState);
        },
      },
    });
  }

  function integrateSak() {
    dispatch({
      type: "SAGA_INTEGRATE_NEW_INTEGRATION",
      payload: {
        type: "sak",
        tokenSak: tokenSak,
        webhookSak: webhookSak,
        closeModal: () => {
          props.setVisibleModal(false);
          setLoading((prevState) => !prevState);
        },
      },
    });
  }

  function integrateShopify() {
    dispatch({
      type: "SAGA_INTEGRATE_NEW_INTEGRATION",
      payload: {
        code: "empy",
        type: "Shopify",
        token: token,
        basic: "empy",
        store: storeName.replace("https://", ""),
        closeModal: () => {
          props.setVisibleModal(false);
          setLoading((prevState) => !prevState);
        },
      },
    });
  }

  const getBodyPlatform = (platform) => {


    switch (platform.title) {
      case "Nuvemshop":
        return (
          <>
            <Title>
              <strong>
                {language.data == "es" ? "Tiendanube" : "Nuvemshop"}
              </strong>
            </Title>
            <ContainerCard>
              <iframe
                width="420"
                height="315"
                src={`https://www.youtube.com/embed/${language.data == "es" ? "lHm8jvzQJto" : "tO_qG87876g"}`}
              ></iframe>
              <BlockInfo>
                <ItemTitle>
                  {translations[platform.body.title][language.data]}
                </ItemTitle>
                {platform.body.steps.map((item) => (
                  <Item key={Math.random()}>
                    {translations[item][language.data]}
                  </Item>
                ))}
                <Alert marginBottom="0px" padding="0px 10px" background="red">
                  <small style={{ fontSize: "11px" }}>
                    {
                      translations[
                      "Não deslogue do Dijital durante o processo de integração."
                      ][language.data]
                    }
                  </small>
                </Alert>
                <ButtonCustom
                  onClick={() => {
                    language.data === "pt"
                      ? (window.location = platform.action)
                      : (window.location = platform.action.replace(
                        "https://www.nuvemshop.com.br",
                        "https://www.tiendanube.com"
                      ));
                  }}
                >
                  Conectar
                </ButtonCustom>
              </BlockInfo>
            </ContainerCard>
          </>
        );
      case "Yampi":
        return (
          <>
            <Title>
              <strong>Configuração {platform.title}</strong>
            </Title>
            <ContainerCard>
              <iframe
                width="420"
                height="315"
                src="https://www.youtube.com/embed/U7FMJj_E4QQ"
              ></iframe>
              <BlockInfo>
                {stepYampi === 0 && (
                  <>
                    <Alert
                      marginBottom="0px"
                      padding="0px 10px"
                      background="red"
                    >
                      <small style={{ fontSize: "11px" }}>
                        Não deslogue do Dijital durante o processo de
                        integração.
                      </small>
                    </Alert>
                    <Badge>
                      <p>
                        Você encontrará os dados no menu credenciais de API
                        dentro do painel da yampi
                      </p>
                    </Badge>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Input
                        placeholder="Alias(apelido) da loja"
                        onChange={(e) => setStoreName(e.target.value)}
                      />
                      <Input
                        placeholder="Token Yampi"
                        onChange={(e) => setToken(e.target.value)}
                      />
                      <Input
                        placeholder="Chave secreta Yampi"
                        onChange={(e) => setKey(e.target.value)}
                      />
                    </div>
                    <ButtonCustom
                      onClick={() => {
                        integrate();
                        setLoading(true);
                      }}
                    >
                      {loading ? <Loading /> : "Conectar"}
                    </ButtonCustom>
                  </>
                )}
                {/* {stepYampi === 1 && (
                  <>
                    <p style={{ marginBottom: 0, marginTop: 10 }}>
                      Adicione esse script ao seu checkout:
                    </p>
                    <BoxScript>
                      <p>
                        {`
                <script> 
                var yampiThankYouScript = document.createElement('script'); 
                yampiThankYouScript.setAttribute('src', 'https://api.dijital.com.br/scripts/script-checkout-yampi.js'); 
                yampiThankYouScript.setAttribute('type', 'text/javascript'); 
               
               document.getElementsByTagName('head')[0].appendChild(yampiThankYouScript); 
                </script>
                `}
                      </p>
                    </BoxScript>
                    <ButtonCustom
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `<script>
                    var yampiThankYouScript = document.createElement('script');
                    yampiThankYouScript.setAttribute('src',
                    'https://api.dijital.com.br/scripts/script-checkout-yampi.js');
                    yampiThankYouScript.setAttribute('type', 'text/javascript');
                    document.getElementsByTagName('head')[0].appendChild(yampiThankYouScript);
                  </script>`
                        );
                        toast.success("Script copiado com sucesso!");
                      }}
                    >
                      <MdOutlineContentCopy
                        size={20}
                        color="#fff"
                        style={{ marginRight: 10 }}
                      />
                      Copiar
                    </ButtonCustom>
                  </>
                )} */}
              </BlockInfo>
            </ContainerCard>
          </>
        );
      case "Woocommerce":
        return (
          <>
            <Title>
              <strong>Configuração {platform.title}</strong>
            </Title>
            <ContainerCard>
              <iframe
                width="420"
                height="315"
                src="https://www.youtube.com/embed/-IejJdxm6zQ"
              ></iframe>
              <BlockInfo>
                <>
                  <Alert marginBottom="0px" padding="0px 10px" background="red">
                    <small style={{ fontSize: "11px" }}>
                      Não deslogue do Dijital durante o processo de integração.
                    </small>
                  </Alert>
                  <Input
                    placeholder="URL do Woocommerce"
                    onChange={(e) => setStoreName(e.target.value)}
                  />
                  <ButtonCustom
                    onClick={() => {
                      if (storeName.length > 0) {
                        if (
                          storeName.includes("https://") ||
                          storeName.includes("http://")
                        ) {
                          window.location = `${storeName}/wc-auth/v1/authorize?app_name=Dijital&user_id=18&return_url=https://app.dijital.com.br/integracoes/woocommerce&callback_url=https://api.dijital.com.br/integration_woo&scope=read_write`;
                          // window.location = `${storeName}/wc-auth/v1/authorize?app_name=Dijital&user_id=18&return_url=http://localhost:3000/integracoes/woocommerce&callback_url=https://api.dijital.com.br/integration_woo&scope=read_write`;
                        } else {
                          window.location = `http://${storeName}/wc-auth/v1/authorize?app_name=Dijital&user_id=18&return_url=https://app.dijital.com.br/integracoes/woocommerce&callback_url=https://api.dijital.com.br/integration_woo&scope=read_write`;
                          // window.location = `http://${storeName}/wc-auth/v1/authorize?app_name=Dijital&user_id=18&return_url=http://localhost:3000/integracoes/woocommerce&callback_url=https://api.dijital.com.br/integration_woo&scope=read_write`;
                        }
                      } else {
                        toast.error("Adicione a url da sua loja Woocommerce");
                      }
                    }}
                  //"https://lojawoo.freelas.com.br/wc-auth/v1/authorize?app_name=Dijital&user_id=123&return_url=https%3A%2F%2Fapp.rastreio.net%2FIntegracao%2FretornoLogin%2Fwoocommerce%3Fshop%3Dlojawoo.freelas.com.br&callback_url=https%3A%2F%2Fapp.rastreio.net%2FIntegracao%2FretornoLogin%2Fwoocommerce%3Fshop%3Dlojawoo.freelas.com.br&scope=read_write";
                  //"https://lojawoo.freelas.com.br/wc-auth/v1/authorize?app_name=Dijital&user_id=123&return_url=https%3A%2F%2Fapp.rastreio.net%2FIntegracao%2FretornoLogin%2Fwoocommerce&callback_url=https%3A%2F%2Fapp.rastreio.net%2FIntegracao%2FretornoLogin%2Fwoocommerce&scope=read_write";
                  >
                    Conectar
                  </ButtonCustom>
                </>
              </BlockInfo>
            </ContainerCard>
          </>
        );
      case "Shopify":
        return (
          <>
            <Title>
              <strong>{translations["config-shopify"][language.data]}</strong>
            </Title>
            <ContainerCard>
              <iframe
                width="420"
                height="315"
                src={`https://www.youtube.com/embed/${language.data == "es" ? "TXy9a2chYfs" : "RrF7hW1Hf1o"}`}
              ></iframe>
              <BlockInfo>
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ marginTop: 10, marginBottom: 10 }}>
                      {translations["crie-app"][language.data]}
                      <a
                        style={{ cursor: "pointer" }}
                        target="_blank"
                        href="https://shopify.com/admin/settings/apps/development"
                      >
                        <GrShare />
                      </a>
                    </p>
                    <p style={{ marginTop: 10, marginBottom: 0 }}>
                      {translations["permissoes"][language.data]}
                    </p>

                    <BlockPermissions>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            background: "#fff",
                            padding: 6,
                            marginRight: 12,
                            borderBottom: 8,
                          }}
                        >
                          {translations["produtos"][language.data]}
                        </div>{" "}
                        <div
                          style={{
                            display: "flex",
                            background: "#fff",
                            padding: 6,
                            marginRight: 12,
                            borderBottom: 8,
                          }}
                        >
                          {translations["pedidos"][language.data]}
                        </div>{" "}
                        <div
                          style={{
                            display: "flex",
                            background: "#fff",
                            padding: 6,
                            marginRight: 12,
                            borderBottom: 8,
                          }}
                        >
                          {translations["lista-produtos"][language.data]}
                        </div>{" "}
                      </div>
                    </BlockPermissions>
                    <p style={{ marginTop: 10, marginBottom: 0 }}>
                      {translations["digite-infor"][language.data]}
                    </p>
                    <Input
                      placeholder={translations["endereco-loja"][language.data]}
                      onChange={(e) => setStoreName(e.target.value)}
                    />

                    <Input
                      placeholder={translations["token"][language.data]}
                      onChange={(e) => setToken(e.target.value)}
                    />

                    <Alert marginBottom="0px" padding="0px 10px" background="red">
                      <small style={{ fontSize: "11px" }}>
                        {translations["nao-deslogue"][language.data]}
                      </small>
                    </Alert>
                  </div>
                  <ButtonCustom
                    onClick={() => {
                      integrateShopify();
                      setLoading(true);
                    }}
                  >
                    {loading ? <Loading /> : "Conectar"}
                  </ButtonCustom>
                </>
              </BlockInfo>
            </ContainerCard>
          </>
        );

      case "Sak":
        return (
          <>
            <Title>
              <strong>{translations["config-sak"][language.data]}</strong>
            </Title>
            <ContainerCard>
              <iframe
                width="420"
                height="315"
                src="https://www.youtube.com/embed/RrF7hW1Hf1o"
              ></iframe>
              <BlockInfo>
                <>

                  <p style={{ marginTop: 10, marginBottom: 0 }}>
                    {translations["digite-webhook"][language.data]} <BiLinkExternal style={{ cursor: "pointer" }} onClick={() => { window.open('https://app.sak.com.br/integracoes#dijital', '_blank'); }} />
                  </p>
                  <Input
                    value={webhookSak}
                    placeholder="Webhook"
                    onChange={(e) => setWebhookSak(e.target.value)}
                  />
                  <p style={{ marginTop: 10, marginBottom: 0 }}>
                    {translations["digite-token"][language.data]}
                  </p>
                  <Input
                    value={tokenSak}
                    placeholder="Token"
                    onChange={(e) => setTokenSak(e.target.value)}
                  />

                  <div style={{ display: "flex", visibility: "hidden", minHeight: "20px" }} />

                  <Alert style={{ marginTop: "20px" }} marginBottom="0px" padding="0px 10px" background="red">
                    <small style={{ fontSize: "11px" }}>
                      {translations["nao-deslogue"][language.data]}
                    </small>
                  </Alert>

                  <ButtonCustom
                    onClick={() => {
                      tokenSak == "" || webhookSak == "" ?
                        toast.warning("Preencha os dados.", { toastId: 0 }) :
                        integrateSak()
                    }}
                  >
                    {loading ? <Loading /> : "Conectar"}
                  </ButtonCustom>
                </>
              </BlockInfo>
            </ContainerCard>
          </>
        );



      case "Rastreio":
        return (
          <>
            <Title>
              <strong>
                Configuração{" "}
                {platform.title}
              </strong>
            </Title>
            <ContainerCard>
              <iframe
                width="420"
                height="315"
                src="https://www.youtube.com/embed/RrF7hW1Hf1o"
              ></iframe>
              <BlockInfo>
                <ItemTitle>{platform.body.title}</ItemTitle>
                <Item>{platform.body.steps[0]}</Item>
                <Item>{platform.body.steps[1]}</Item>
                <Item>{platform.body.steps[2]}</Item>
                <Alert margin="20px 0px 0px 0px" padding="10px" background="red" >
                  <small style={{ fontSize: "11px" }}>
                    {translations["nao-deslogue"][language.data]}
                  </small>
                </Alert>
                <ButtonCustom onClick={() => {
                  window.open('https://app.rastreio.net/configuracoes', '_blank')
                }}> {"Conectar"}</ButtonCustom>
              </BlockInfo>
            </ContainerCard>


          </>
        )

      default:
        return null;
    }
  };

  const copy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Código copiado.");
  };
  return (
    <Modal
      isOpen={props.visible}
      onRequestClose={() => props.setVisibleModal(false)}
      style={customStyles}
      ariaHideApp={false}
    >
      <Container>
        <Close onClick={() => props.setVisibleModal(false)}>
          <GrFormClose size={25} color={"#5e6278"} />
        </Close>
        {getBodyPlatform(props.platform)}
      </Container>
    </Modal>
  );
};

export default ModalIntegration;

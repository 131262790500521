import { ShoppingBagIcon, StarIcon } from "@heroicons/react/24/outline"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { Message, TextQtd } from "./styled"
import ModalMenuItem from "../../modal-menu-item"

const ItemTableProduct = (props) => {

    const [visibleModalMenu, setvisibleModalMenu] = useState(false)
    const [fullMessage, setfullMessage] = useState(false)
    const history = useHistory()

    console.log("props produto", props)

    return (
        <>
            <tr  key={"person.email"} className={"even:bg-gray-50"}>
                
                <td style={{paddingLeft: "20px"}}
                    className={props.classNames(
                        'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                         'text-gray-900',
                    )}
                >
                    {new Date(props.item.products.created_at).toLocaleDateString()}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                        {props.item.products.image_url ?
                            <img onClick={() => { window.open(props.item.products.canonical_url, '_blank') }} style={{ cursor: "pointer", width: "32px", height: "32px", objectFit: "cover", borderRadius: "4px" }} src={props.item.products.image_url} /> :
                            <ShoppingBagIcon onClick={() => { window.open(props.item.products.canonical_url, '_blank') }} style={{ height: "32px" }} />}
                        <Message full={fullMessage} onClick={() => { setfullMessage(!fullMessage) }}>
                            <label onClick={() => { window.open(props.item.products.canonical_url, '_blank') }}>
                                {props.item.products.name}
                            </label>
                        </Message>
                    </span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <TextQtd onClick={() => {
                        //history.push(`/produtos/${props.item.products.id}/midias`)
                    }} style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                         {props.item.variants.length > 0 ? props.item.variants[0].variant.name : ""}
                    </TextQtd>
                </td>

                <td style={{ display: "flex", justifyContent: "flex-end" }} className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <span style={{ position: "relative" }}>

                    </span>
                </td>
            </tr>
        </>

    )
}

export default ItemTableProduct
import styled from "styled-components";

export const InnerWrapper = styled.div`
  /* background: #eaebed; */
  background-image: linear-gradient(to right, #0000, #0000),
    url("img/xadrez-pattern.png");
  background-size: 60%;
  border-radius: 8px;
  padding: 32px;
  background-color: #eaebed;

  @media (max-width: 760px) {
    padding: 8px;
  }

  img {
    height: 200px;
    width: 400px;
    /* @media (max-width) */
  }

  > .email--preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    max-width: 500px;
    padding: 32px;
    border-radius: 4px;
    margin: 0 auto;
    /* pointer-events: none; */
    position: relative;
    text-align: center;

    @media (max-width: 760px) {
      padding: 8px;
    }

    > .btnSuccess {
      background: #19b674;
      border: 1px solid #19b674;
      color: #fff;
      border-radius: 4px;
      height: 38px;
      padding: 6px 12px;
      cursor: pointer;
    }

    > .titleConfirm {
      font-weight: 700;
      font-size: 20px;
      margin: 0;
      text-align: center;
      margin-bottom: 16px;
      word-break: break-word;
      color: ${(props) => props.titleColor ?? "#19b674"};
    }

    > .email--description {
      font-size: 14px;
      word-break: break-all;
    }

    > .email--product-info {
      font-size: 14px;

      > img {
        width: 100%;
      }
    }

    > .cta--preview-btn {
      margin-top: 24px;
      border-radius: 500px;
      height: 48px;
      background: ${(props) => props.backgroundColorCTA ?? "#F7C006"};
      border-color: ${(props) => props.backgroundColorCTA ?? "#F7C006"};
      color: ${(props) => props.backgroundTextColorCTA ?? "#121121"};
    }
  }
`;

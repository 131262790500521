const INITIAL_STATE = {
  error: false,
  loading: false,
  data: [],
  dataIntegration: [],
};

const List = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ADMIN_LIST_REQUEST":
      return { loading: true, error: false, data: [], dataIntegration: [] };
    case "ADMIN_LIST_ERROR":
      return { loading: false, error: true, data: [], dataIntegration: [] };
    case "ADMIN_LIST_SUCCESS":
      return {
        loading: false,
        error: false,
        data: action.payload,
        dataIntegration: [],
      };
    case "ADMIN_LIST_RESET":
      return { loading: false, error: false, data: [], dataIntegration: [] };
    case "ADMIN_LIST_REQUEST_INTEGRATION":
      return { loading: true, error: false, data: [], dataIntegration: [] };
    case "ADMIN_LIST_ERROR_INTEGRATION":
      return { loading: false, error: true, data: [], dataIntegration: [] };
    case "ADMIN_LIST_SUCCESS_INTEGRATION":
      return {
        loading: false,
        error: false,
        dataIntegration: action.payload,
      };
    default:
      return state;
  }
};

export default List;

import React, { useEffect } from "react";
import SideBar from "../../../components/SideBar";
import Body from "../../../components/Body";
import Title from "../../../components/Title";
import Contents from "../../../components/Contents";
import Header from "../../../components/Header";
import { useTranslation } from "react-i18next";
import {
  BlockMedias,
  SubTitle,
  ContainerStorage,
  Legend,
  ContainerLegend,
  ContainerItems,
  CustomSwitch,
  Input,
  ProgressBar,
  Button,
} from "./styled";
import { useDispatch, useSelector } from "react-redux";
import ModalDelete from "../../../components/ModalDelete";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../../components/Loading";
import { translations } from "../../../utils/language";

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const settings = useSelector((state) => state.reducerSettings);
  const language = useSelector((state) => state.reducerLanguage);
  const [visibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    dispatch({
      type: "SAGA_LIST_SETTINGS",
    });
  }, [dispatch, history]);

  return (
    <Body>
      <SideBar />
      <div
        style={{
          margin: "0px",
          padding: "0px",
          display: "flex",
          flexDirection: "column",
          flex: "1",
          width: "100%",
        }}
      >
        <Header />
        <Contents>
          <ModalDelete
            visible={visibleModal}
            title={translations["Deseja excluir sua conta?"][language.data]}
            setVisibleModal={setVisibleModal}
          />

          <BlockMedias>
            <ContainerItems>
              <Title marginBottom="16px">
                {translations["Configurações"][language.data]}
              </Title>
              {/* <SubTitle>Confira os seus produtos listados</SubTitle> */}
            </ContainerItems>
            <ContainerItems>
              {/* <CustomSwitch
                type="switch"
                id="custom-switch"
                label="Entregar conteúdo quando o status da compra for pendente ou aguardando pagamento"
                checked={false}
                style={{
                  color: "#13113C",
                  fontWeight: 500,
                }}
                // onChange={(e) => {
                //   if (switchTitleSubjectEnabled === false) {
                //     setEmailTitle(emailSubject);
                //   }
                //   setSwitchTitleSubjectEnabled((prevState) => !prevState);
                // }}
              /> */}
              {/* <CustomSwitch
                type="switch"
                id="custom-switch"
                label="Limite de Downloads"
                checked={true}
                style={{ color: "#13113C", fontWeight: 500 }}
                // onChange={(e) => {
                //   if (switchTitleSubjectEnabled === false) {
                //     setEmailTitle(emailSubject);
                //   }
                //   setSwitchTitleSubjectEnabled((prevState) => !prevState);
                // }}
              /> */}
              {/* <Input value={32} max={100} /> */}
            </ContainerItems>
            <ContainerItems></ContainerItems>
            <ContainerItems>
              <ContainerStorage>
                <ContainerLegend>
                  <Legend>
                    {translations["Armazenamento"][language.data]}
                  </Legend>
                  {settings.loading ? (
                    <Loading />
                  ) : (
                    <Legend>
                      {Number(settings?.data?.data?.storage) / 1000000} mb /{" "}
                      {Number(settings?.data?.data?.limit) / 1000000}
                      mb
                    </Legend>
                  )}
                </ContainerLegend>
                <ProgressBar
                  value={Number(settings?.data?.data?.storage) / 1000000}
                  max={Number(settings?.data?.data?.limit) / 1000000}
                />
              </ContainerStorage>
            </ContainerItems>
            {/* <Button>Salvar alterações</Button> */}
          </BlockMedias>
          <BlockMedias>
            <ContainerItems>
              <Title marginBottom="16px">
                {translations["Deletar conta"][language.data]}
              </Title>
              <SubTitle>
                {translations["Gostaria de deletar sua conta?"][language.data]}
              </SubTitle>
            </ContainerItems>
            <Button onClick={() => setVisibleModal(true)}>
              {translations["Deletar conta"][language.data]}
            </Button>
          </BlockMedias>
        </Contents>
      </div>
    </Body>
  );
};

export default Home;

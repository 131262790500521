import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    //background-color: red;
`

export const Header = styled.div`
    display: flex;
    height: 80px;
    background-color: ${props => props.backgroundColor};
    color:  ${props => props.color};;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
`

export const Infor = styled.div`
    display: flex;
    flex-direction: column;
    //background-color: red;

    p{
        display: flex;
        margin: 0px;
        padding: 0px;
        //background-color: red;
        text-align: center;
        justify-content: center;
        font-size: 32px;
        padding: 30px 0px 0px 0px;
        color: ${props => props.titleColor};
    }

    label{
        display: flex;
        //background-color: yellow;
        text-align: center;
        justify-content: center;
        font-size: 18px;
        color: ${props => props.messageColor};
        opacity: 0.5;
    }
`

export const SectionData = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    //background-color: red;
`

export const SearchCustom = styled.div`
    display: flex;


    input{
        display: flex;
        border: none;
        outline: none;
        height: 40px;
        width: 300px;
        padding: 0px 20px;
        border-radius: 4px;
    }
`

export const BarFilters = styled.div`
    display: flex;
    //background-color: red;
    margin-top: 20px;
    justify-content: space-between;
    //border-bottom: 1px solid #f2f2f2;
    padding: 10px 0px;
    gap: 20px;
    flex-direction: column;

    //margin-left: 20px;

    //background-color: greenyellow;
`

export const DivProduct = styled.div`
    width: 500px;
    //background-color: red;

    @media screen and (min-width: 500px) {
        //background-color: yellow;
        //width: 140px;
    }
    @media screen and (min-width: 1024px) {
        //background-color: yellow;
        //width: 400px;
    }
`

export const BarFiltersLeft = styled.div`
    display: flex;
    //background-color: red;
    align-items: center;
    width: 100%;
`

export const BarFiltersIcon = styled.div`
    display: flex;
    gap: 20px;


    span{
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        cursor: pointer;
    }
`

export const Search = styled.div`
    display: flex;
    //ackground-color: yellow;
    align-items: center;
    width: 100%;

    span{
        background-color: #F9FAFB;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    input{
        display: flex;
        background-color: #F9FAFB;
        height: 36px;
        outline: none;
        border: none;
        width: 100%;
    }
`
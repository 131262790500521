import styled from 'styled-components'

export const Container = styled.div`
    display: grid; 
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); 
    gap: 1.5rem;
    
`

export const Card = styled.div`
    background: white;
    width: fit-content;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 30px;
    border: 2px solid #f1f1f1;

    @media(max-width: 450px) {
        width: 100%;
    }

    h3 {
        font-weight: 700;
        font-size: 2rem;
        text-align: center;
    }

    ul {
        list-style: none;
        li {
            display: flex; 
            align-items: center; 
            padding: 10px;
            gap: .5rem;
        }
    }

`


export const Price = styled.div`
    text-align: center;
    padding: 10px;
    span {
        font-size: 26px;
    }
`
import Modal from "react-modal";
import { BarSearch, BoxActions, BoxUpload, Close, Container, ItemOption, Options, Product, SectionConectar, SectionMedias, SectionUpload, SectionUploadFile, SectionUploadList, SectionVincular, TitleModal } from "./styled";
import { useEffect } from "react";
import Title from "../../../../components/Title";
import { Name, SectionAnexos, SectionImage, SectionInfor } from "../card-product/styled";
import { useState } from "react";

import { AiOutlineCloudUpload } from "react-icons/ai";
import CardUpload from "../card-upload";
import CardCheck from "../card-check";

import { CloseCircle } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/Loading";
import { Paginate, PaginateInfor, PaginateNav } from "../../pages/home-new/styled";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { VscSearch } from "react-icons/vsc";
import { translations } from "../../../../utils/language";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "700px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    },
};



const ModalVincularAnexo = (props) => {

    const [files, setFiles] = useState([])
    const dispatch = useDispatch()
    const medias = useSelector((state) => state.reducerMedias);
    const [selectedMedias, setSelectedMedias] = useState([])
    const [vincularSelect, setVincularSelect] = useState(true)
    const controlMedias = useSelector((state) => state.reducerControlMedias);
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")

    const language = useSelector((state) => state.reducerLanguage);

    useEffect(() => {
        if (controlMedias.loading == false && controlMedias.data.length > 0) {
            closeModal()
        }
    }, [controlMedias])

    const closeModal = () => {
        props.setVisible(false)
        setSelectedMedias([])
        dispatch({ type: "PRODUCT_CONTROL_MEDIA_RESET" })
        // dispatch({
        //     type: "SAGA_LIST_PRODUCTS",
        //     payload: {
        //         search: props.search,
        //         perPage: 20,
        //         page: 1,
        //         log: "01"
        //     },
        // });
        // dispatch({
        //     type: "SAGA_LIST_MEDIA",
        //     payload: {
        //         perPage: 100,
        //         page: 1,
        //         cod: "02"
        //     },
        // });
    }


    const addFiles = (values) => {

        setFiles([...files, ...values])
    }

    const addSelectedMedias = (value) => {

        setSelectedMedias([...selectedMedias, value])
    }

    const removeSelectMedias = (item) => {
        setSelectedMedias(files.filter((value) => {
            return value.name != item.name
        }))
    }

    const removeFiles = (item) => {
        setFiles(files.filter((value) => {
            return value.id != item.id
        }))
    }




    const save = () => {

        setVincularSelect(false)
        setFiles(selectedMedias)
        dispatch({
            type: "SAGA_PRODUCT_LINK_MEDIA",
            payload: {
                medias: selectedMedias,
                product_id: props.item.variants.product_id,
                variant_id: props.item.variants.variant_id,
                //variant_id: props.selectedVariante == "" ? props.selectedVariante : props.selectedVariante.variant.variant_id,
            },
        });
    }


    const countItens = () => {
        let result = 0
        if (page == medias.data?.meta?.last_page) result = medias.data?.meta?.total
        else result = page * 100
        return result
    }


    useEffect(() => {
        if (props.visible) {
            dispatch({ type: "SAGA_LIST_MEDIA", payload: { search: search, perPage: 10, page: 1, log: "teste" }, });
        }
    }, [props])

    useEffect(() => {
        if (props.visible) {
            dispatch({ type: "SAGA_LIST_MEDIA", payload: { search: search, perPage: 10, page: page, }, });
        }
    }, [page])

    const goSearch = () => {
        dispatch({ type: "SAGA_LIST_MEDIA", payload: { search: search, perPage: 10, page: 1, }, });
    }


    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { }}
        >
            <Container>
                <Close onClick={() => { props.setVisible(false) }}>
                    <CloseCircle color="gray" size={30} />
                </Close>
                <TitleModal>
                    <Title marginBottom="16px">
                        {translations[`products-add-anexo`][language?.data]}
                    </Title>
                </TitleModal>
                {/* <Product>
                    <SectionImage>
                        <img src={props.item.image_url} />
                    </SectionImage>
                    <SectionInfor>
                        <Name>{props.item.name} {props.selectedVariante != "" && `(variante: ${props.selectedVariante.variant.name})`}</Name>
                        <SectionAnexos>
                           
                            {props.medias?.map((item) => {
                                return (
                                    item.type == "url" ?
                                        props.getIconFile("url", item.media_name) :
                                        props.getIconFile(item.media_name.split(".")[item.media_name.split(".").length - 1], item.media_name)
                                )
                            })}
                        </SectionAnexos>
                    </SectionInfor>
                </Product> */}



                <label style={{ fontSize: "14px", color: "gray", textAlign: "center" }}>
                    {controlMedias.loading ?
                        "Mídias selecionadas" :
                        medias.data?.data?.length > 0 ? translations[`products-selecione-abaixo-midias`][language?.data] : "Nenhuma mídia encontrada."
                    }

                </label>

                <BarSearch>
                    <input
                        value={search}
                        onChange={(e) => { setSearch(e.target.value) }}
                        placeholder="Buscar..."
                    />
                    <div onClick={() => { goSearch() }}>
                        <VscSearch size={20} color={"#ffffff"} />
                    </div>
                </BarSearch>

                <BoxUpload>

                    {vincularSelect ?
                        <SectionMedias>
                            <SectionVincular>
                                {vincularSelect &&
                                    medias.data?.data?.map((item) => {
                                        return <CardCheck add={addSelectedMedias} remove={removeSelectMedias} item={item} getIconFile={props.getIconFile} />
                                    })
                                }
                            </SectionVincular>
                        </SectionMedias> :
                        <SectionUploadList>
                            {selectedMedias.map((item) => {
                                return <CardUpload removeFiles={removeFiles} loading={false} item={item} getIconFile={props.getIconFile} />
                            })}
                        </SectionUploadList>}

                </BoxUpload>

                <Paginate>
                    <PaginateNav>
                        <span onClick={() => { if (page != 1) setPage(page - 1) }}                        >
                            <MdKeyboardArrowLeft size={35} color={medias.data.meta?.current_page == 1 ? "gray" : "#19B674"} />
                        </span>
                        <label>{medias.data?.meta?.current_page}</label>
                        <span onClick={() => { if (page < medias.data?.meta?.last_page) setPage(page + 1) }}>
                            <MdKeyboardArrowRight size={35} color={medias.data.meta?.last_page == page ? "gray" : "#19B674"} />
                        </span>
                    </PaginateNav>
                    <PaginateInfor>
                        <label>{"Total"} {countItens()} - {medias.data?.meta?.total}</label>
                    </PaginateInfor>
                </Paginate>
                <BoxActions>
                    {controlMedias.loading ?
                        <label>
                            <Loading size={18} color={"#ffffff"} /> Salvando
                        </label> :
                        <label onClick={() => save()}>
                            {translations[`Salvar`][language?.data]}
                        </label>}
                </BoxActions>
            </Container>

        </Modal >
    )
}

export default ModalVincularAnexo
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
`

export const DateFilter = styled.div`
  display: flex;
  gap: 20px;

`
export const SearchPlatform = styled.div`
  display: flex;
  padding: 20px;
  background-color:  #1B76FF;;
  height: 80px ;
  align-items: center;
  margin-top: 20px;
  border-radius: 4px;
  justify-content: center;

  label{
    color:   #fff;
    text-align: center;

    strong{
      cursor: pointer;

      :hover{
        text-decoration: underline;
      }
    }

  }

  p{
    margin: 0px;
    padding: 0px 20px;
    background-color: #1B76FF;
    color: #fff;
    border-radius: 4px;
    height: 40px;
    align-items: center;
    display: flex;
  }


`

export const ItemDateFilter = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  label{
    font-size: 10px;
    position: absolute;
    top: -14px;
    left: 0px;
  }

  input{
    display: flex;
    padding: 0px 10px;
    height: 40px;

    border: none;
    outline: none;
    font-size: 14px;
    border-radius: 4px;
  }
`

export const OptionsUpdate = styled.div`
  display: flex;
  background-color: #fff;
  position: relative;
  align-items: center;
  padding: 0px 20px;
  border-radius: 4px;
  gap: 5px;
  cursor: pointer;

  label{
    cursor: pointer;
    font-size: 14px;
    color: #222;
  }
`

export const MenuOption = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  background-color: #fff;
  position: absolute;
  flex-direction: column;
  top: 42px;
  right: 0px;
  z-index: 999;
  width: 192px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  //gap: 10px;
  //padding: 20px;

  label{
    color: gray;
    font-size: 14px;
    padding: 10px 20px;
    display: flex;
    cursor: pointer;
    gap: 5px;

    img{
      width: 16px;
      height: 16px;
      //background-color: red;
      margin-top: 2px;
      margin-right: 4px;
    }

    &:hover{
      background-color: #F0F6FF;
    }
    //background-color: red;
    
  }
  
`
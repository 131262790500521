import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    min-height: 80px;
    width: 100%;
    background-color: #dcdcdc;
    border-radius: 4px;

    animation: Skeleton 1s ease infinite alternate;

    @keyframes Skeleton{
        to{
            opacity: 0.4;
        }
    }
`
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    //padding: 40px;
    overflow: auto;
    max-height: 90vh;
    //gap: 20px;
    background-color: #fff;

    ::-webkit-scrollbar {
        width: 8px; /* largura da barra de rolagem */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #c1c1c188; /* cor da barra de rolagem */
        border-radius: 1px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1; /* cor do track */
    }
`
export const Title = styled.div`
    display: flex;
    background-color: #005AFF;
    flex-direction: column;
    padding: 20px;
    color: #fff;

    div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;

        label{
            font-size: 14px;
        }
    }

    label{
        font-size: 12px;
    }
`
export const Contents = styled.div`
    display: flex;
    background-color: #fff;
    padding: 20px;
    flex-direction: column;
    gap: 20px;

`

export const BoxForm = styled.div`
    display: flex;
    gap: 12px;
    align-items: end;
`

export const InputName = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    input{
        display: flex;
        outline: none;
        border: none;
        background-color: #f2f2f2;
        height: 36px;
        font-size: 14px;
        padding: 0px 8px;
        border-radius: 4px;
    }

    label{
        font-size: 12px;
    }
`

export const InputLink = styled(InputName)`
    display: flex;
`
export const Save = styled.label`
    display: flex;
    font-size: 14px;
    //background-color: red;
    align-items: center;
    height: 36px;
    border-radius: 4px;
    padding: 0px 10px;
    color: #fff;
    cursor: pointer;

`

export const ListLinks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    label{
        font-size: 12px;
        color: #64748b;
    }
`

export const ItemLink = styled.div`
    display: flex;
    height: 58px;
    border: 1px solid #f2f2f2;
    padding: 8px;
    border-radius: 4px;
    align-items: center;
    gap: 8px;
`

export const BoxText = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;

    p{
        display: flex;
        padding: 0px;
        margin: 0px;
        font-size: 12px;
        background-color: #005AFF22;
        color: #005AFF;
        padding: 4px 8px;
        border-radius: 4px;
    }

    label{
        font-size: 12px;
    }
`
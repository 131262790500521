import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ItemTable from '../item-table'
import Pagination from "../pagination";
//import { BarFilters, BarFiltersIcon, BarFiltersLeft, Search } from '../../../../reviews/components/tailwind/table/styled';
import { CodeBracketIcon, MagnifyingGlassIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
//import ButtonSinc from '../../button-sinc';
import { toast } from 'react-toastify';
import Navbar from '../navbar';
import { BarFilters, BarFiltersLeft, Search } from './styled';
import FiltersProducts from '../filters-products';
import { ContainerLoading } from '../../../../../components/Loading-logo/styled';
import LoadingLogo from '../../../../../components/Loading-logo';
import ModalFeedSinc from '../modal-feed-sinc';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableProducts(props) {
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const dispatch = useDispatch()

    const [medias, setMedias] = useState(true)
    const [noMedias, setNoMedias] = useState(true)

    const [visibleModalFeedSinc, setVisibleModalFeedSinc] = useState(false)


    const [dataProducts, setDataProducts] = useState([])
    const statusDeleteSelectProducts = useSelector(state => state.reducerDeleteSelectProducts)
    const products = useSelector(state => state.reducerProducts)
    const statusUpdateProduct = useSelector(state => state.reducerUpdateProduct)
    const statusDeleteProduct = useSelector(state => state.reducerDeleteProduct)
    const reducerSincSelectedProduct = useSelector(state => state.reducerSincSelectedProduct)

    useEffect(() => {
        console.log("products", products)
        products?.data?.data?.data?.length > 0 && setDataProducts(products?.data?.data?.data)
        products?.data?.data?.data == undefined && setDataProducts([])
    }, [products])

    useLayoutEffect(() => {
        const isIndeterminate = selectedProducts.length > 0 && selectedProducts.length < dataProducts.length
        setChecked(dataProducts.length == 0 ? false : selectedProducts.length === dataProducts.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedProducts])

    const handleClick = () => {
        if (checkbox.current) {
            checkbox.current.click();
        }
    };

    function toggleAll() {
        setSelectedProducts(checked || indeterminate ? [] : dataProducts)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }


    const sincMulti = () => {
        //console.log("selectedProducts", selectedProducts)
        const dataId = selectedProducts.map((item) => (item.products.id))
        dispatch({
            type: "SAGA_UPDATE_PRODUCT_MULTI", payload: {
                data: selectedProducts,
                //platform: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform
            }
        })
        setTimeout(() => { handleClick() }, 100);
    }

    const deleteAll = () => {
        // console.log("selectedProducts", selectedProducts)
        // dispatch({
        //     type: "SAGA_DELETE_SELECT_PRODUCTS",
        //     payload: {
        //         itens: selectedProducts.map((item) => (item.id))
        //     }
        // })
        // setTimeout(() => { handleClick() }, 100);

    }

    useEffect(() => {
        // if (!statusDeleteSelectProducts.loading && !statusDeleteSelectProducts.error && statusDeleteSelectProducts.data.length == 0) {
        // } else {
        //     const id = "statusDeleteSelectReviews"
        //     if (statusDeleteSelectProducts.loading) {
        //         toast.loading(translation.products.aguarde, { toastId: id })
        //     } else {
        //         if (statusDeleteSelectProducts.error) {
        //             toast.update(id, { render: translation.products.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        //         } else {
        //             toast.update(id, { render: translation.products.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
        //             props.goSearch()
        //         }
        //     }
        // }
    }, [statusDeleteSelectProducts])

    useEffect(() => {
        // if (statusUpdateProduct.loading == false && statusUpdateProduct.error == false && statusUpdateProduct.data.length == 0) {
        // } else {
        //     const id = "statusUpdateProduct"
        //     if (statusUpdateProduct.loading) {
        //         toast.loading(translation.products.card.aguarde, { toastId: id })
        //     } else {
        //         if (statusUpdateProduct.error) toast.update(id, { render: translation.products.card.errorAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        //         else toast.update(id, { render: translation.products.card.atualizadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
        //     }
        // }
    }, [statusUpdateProduct])

    useEffect(() => {
        // if (statusDeleteProduct.loading == false && statusDeleteProduct.error == false && statusDeleteProduct.data.length == 0) {
        // } else {
        //     const id = "statusDeleteProduct"
        //     if (statusDeleteProduct.loading) {
        //         toast.loading(translation.products.card.aguarde, { toastId: id })
        //     } else {
        //         if (statusDeleteProduct.error) toast.update(id, { render: translation.products.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        //         else toast.update(id, { render: translation.products.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
        //     }
        // }
    }, [statusDeleteProduct])

    useEffect(() => {
        // if (reducerSincSelectedProduct.loading == false && reducerSincSelectedProduct.error == false && reducerSincSelectedProduct.data.length == 0) {
        // } else {
        //     const id = "reducerSincSelectedProduct"
        //     if (reducerSincSelectedProduct.loading) {
        //         toast.loading(translation.products.card.aguarde, { toastId: id })
        //     } else {
        //         if (reducerSincSelectedProduct.error) toast.update(id, { render: translation.products.card.errorAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        //         else {
        //             toast.update(id, { render: translation.products.card.atualizadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
        //             props.goSearch()

        //         }
        //     }
        // }
    }, [reducerSincSelectedProduct])

    useEffect(() => {
        //console.log("selectedProducts", selectedProducts)
    }, [selectedProducts])

    const changePage = (page) => {
        console.log("changePage: ", page)
        props.setPage(page)
    }

    useEffect(() => {
        if (medias == true && noMedias == true) {
            props.setMediasFilter("all")
        } else if (medias == true && noMedias == false) {
            props.setMediasFilter("only")
        } else if (medias == false && noMedias == true) {
            props.setMediasFilter("not")
        }
    }, [medias, noMedias])

    const [dataModalSinc, setDataModalSinc] = useState([])
    const [dataModalSincCompleted, setDataModalSincCompleted] = useState([])
    const [productLoadingId, setProductLoadingId] = useState(null)

    useEffect(() => {
        console.log("selectedProducts", selectedProducts)
    }, [selectedProducts])

    useEffect(() => {
        console.log("dataModalSincCompleted", dataModalSincCompleted)
    }, [dataModalSincCompleted])


    const requestItems = async () => {
        for (const item of selectedProducts) {
            try {
                setProductLoadingId(item.products.id)
                const token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;
                const url = `${process.env.REACT_APP_API_URL}/products/update`;
                const headers = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });
                const response = await fetch(url, { method: 'PUT', headers: headers, body: JSON.stringify({ product: item.products, integration: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration }) });
                const result = await response.json();
                console.log(`result:`, result);
                setDataModalSincCompleted(prevData => [...prevData, item.products.id]);
                setProductLoadingId(null)
            } catch (error) {
                console.error(`Erro na requisição do item ${item.name}:`, error);
            }
        }
    }

    const sincSelected = async () => {
        requestItems()
    }


    return (
        products.loading ?
            <div style={{ display: "flex", height: "calc(100vh - 140px)", justifyContent: "center", alignItems: "center" }}>
                <LoadingLogo />
            </div> :
            <>

                <ModalFeedSinc
                    setVisible={setVisibleModalFeedSinc}
                    visible={visibleModalFeedSinc}
                    selectedProducts={selectedProducts}
                    dataModalSincCompleted={dataModalSincCompleted}
                    setDataModalSincCompleted={setDataModalSincCompleted}
                    productLoadingId={productLoadingId}
                    goSearch={props.goSearch}
                    handleClick={handleClick}
                />


                <div className="px-4 sm:px-6 lg:px-8">

                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{"Produtos"}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {"Vincule arquivos aos produtos disponíveis na sua loja."}
                            </p>
                        </div>

                        {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button
                                onClick={() => {
                                    //setvisibleModalMenu(true) 
                                }}
                                type="button"
                                className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <PlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                {"Sincronizar"}
                            </button>
                        </div> */}
                    </div>

                    <BarFilters style={{ paddingRight: "20px" }}>
                        <span style={{ display: "flex", justifyContent: "space-between" }}>
                            <BarFiltersLeft style={{ marginRight: "20px" }}>
                                <Search>
                                    <span>
                                        <MagnifyingGlassIcon style={{ width: "20px" }} />
                                    </span>
                                    <input onKeyPress={(e) => { e.key == "Enter" && props.goSearch() }} type='text' placeholder={"Pesquisar por nome"} value={props.search} onChange={(e) => { props.setSearch(e.target.value) }} />
                                </Search>
                            </BarFiltersLeft>

                            <FiltersProducts
                                medias={medias} setMedias={setMedias}
                                noMedias={noMedias} setNoMedias={setNoMedias}
                            />
                        </span>
                    </BarFilters>



                    <div className="mt-0 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    {selectedProducts.length > 0 && (
                                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ? <></> :
                                                <button
                                                    onClick={() => {
                                                        //sincMulti()
                                                        setVisibleModalFeedSinc(true)
                                                        sincSelected()
                                                        
                                                    }}
                                                    type="button"
                                                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                                >
                                                    {"Sincronizar"}
                                                </button>}
                                        </div>
                                    )}
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        ref={checkbox}
                                                        checked={checked}
                                                        onChange={toggleAll}
                                                    />
                                                </th>
                                                <th scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                                    {"Data"}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {"Produto"}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                        {"Variações"}
                                                    </label>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                        {"Arquivos"}
                                                    </label>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {dataProducts?.map((item) => (
                                                <ItemTable
                                                    visibleModalImport={props.visibleModalImport}
                                                    setVisibleModalImport={props.setVisibleModalImport}
                                                    setImportShopeeProductSelected={props.setImportShopeeProductSelected}
                                                    setControlUpdate={props.setControlUpdate}
                                                    goSearch={props.goSearch}
                                                    classNames={classNames}
                                                    setSelectedProducts={setSelectedProducts}
                                                    selectedProducts={selectedProducts}
                                                    item={item} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination setPage={changePage} />
            </>
    )
}

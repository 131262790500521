const INITIAL_STATE = {
  error: null,
  loading: null,
  data:[]
};

const Emails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "EMAIL_REQUEST":
      return { loading: true, error: false };
    case "EMAIL_ERROR":
      return { loading: false, error: true };
    case "EMAIL_SUCCESS":
      return { loading: false, error: false };
    default:
      return state;
  }
};

export default Emails;

import React from 'react'
import { Actions, Container, Icon, Infor, ItemAction, Name } from './styled'

import { AiFillDelete } from "react-icons/ai";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { BsCheckCircleFill } from "react-icons/bs";


import { Trash, TickCircle } from "iconsax-react";

const CardUpload = (props) => {

    const statusFile = useSelector((state) => state.reducerAddMedias);
    const statusMedia = useSelector((state) => state.reducerLinkMedias);
    const statusUrl = useSelector((state) => state.reducerUrlMedias);
    const [loading, setLoading] = useState(false)
    const [completed, setCompleted] = useState(null)
    const controlMedias = useSelector((state) => state.reducerControlMedias);

    useEffect(() => {
        if (loading) {
            setCompleted(false)
        } else if (completed == false) {
            setCompleted(true)
        }
    }, [loading])



    useEffect(() => {
        
        if (statusFile.loading && statusFile.data.file.name == props.item.name) {
            setLoading(true)

        } else {
            setLoading(false)
        }
    }, [statusFile])

    useEffect(() => {
     
        if (statusUrl.loading && statusUrl.data.media == props.item) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [statusUrl])

    useEffect(() => {
        
        if (statusMedia.loading && statusMedia.data.media.id == props.item.id) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [statusMedia])



    return (
        <Container>
            <Icon>
                {props.getIconFile(props.item?.name?.split(".")[props.item?.name?.split(".").length - 1], "")}
            </Icon>
            <Infor completed={completed}>

                {
                    props.type == "conectar" ?
                        <>
                            <Name>
                                <p><span style={{backgroundColor: "#F0F6FF", color: "#1b76ff",padding: "4px 8px", marginRight: "4px", borderRadius: "4px"}}>{props.item.urlName}</span>{props.item.url}</p>
                            </Name>
                            {loading &&
                                <div loading={props.loading}>
                                    <span />
                                </div>}
                        </> :
                        props.item.type == "url" ?
                            <>
                                <Name>
                                    <p>{props.item.media}</p>
                                </Name>
                                {loading || completed &&
                                    <div loading={props.loading}>
                                        <span />
                                    </div>}
                            </> :
                            <>
                                <Name>
                                    <p>{typeof props.item.name != "undefined" ? props.item.name : props.item.media_name}</p>

                                </Name>

                                {loading || completed ?
                                    <div>
                                        <span />
                                    </div> : null}
                                {/* <label>{sizeFiles(props?.item?.size)}</label> */}
                            </>
                }
                {/* {props.type == "conectar" ?
                    <>
                        <p>{props.item}</p>
                        {loading &&
                            <div loading={props.loading}>
                                <span />
                            </div>}
                    </> :
                    <>
                        <p>{typeof props.item.name != "undefined" ? props.item.name : props.item.media_name}</p>
                        {loading &&
                            <div loading={props.loading}>
                                <span />
                            </div>}
                        <label>{props.item.size}</label>
                    </>
                } */}

            </Infor>
            <Actions>
                {
                    controlMedias.loading != true ?
                        <ItemAction type={"delete"} color={"#f1416c"}>
                            <Trash size={16} onClick={() => { props.removeFiles(props.item) }} />
                        </ItemAction> :
                        completed &&
                        <ItemAction color={"#19B674"}>
                            <TickCircle size={16} />
                        </ItemAction>
                }
            </Actions>
        </Container>
    )
}

export default CardUpload
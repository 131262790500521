
const INITIAL_STATE = ""

const SearchReviewProduct = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PRODUCT_ADD_SEARCH_REVIEWS':
            return action.payload
        default:
            return state;
    }
}

export default SearchReviewProduct
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  //word-wrapbackground-color: ${props => props.theme.colors.layout.primary};
  height: 45px;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #DCDBDA;
  background-color: #F7F7F7;

  input{
    display: flex;
    outline: none;
    border: 0;
    height: 100%;
    //font-sizebackground-color: ${props => props.theme.colors.layout.primary};
    flex: 1;
    border-radius: 0px 20px 20px 0px;
    background-color: #F7F7F7;
  }
  span{
    display: flex;
    width: 45px;
    height: 45px;
    justify-content: center !important;
    align-items: center !important;
    background-color: transparent !important;
  }

`;
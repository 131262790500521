import styled from "styled-components";

export const FormRegister = styled.form`
    display: flex;
    height: 100%;
    flex-direction: column;
`;

export const Actions = styled.div`
    display: flex;
    align-items: center;

    label{
        flex: 1;
        text-align: right;
        color: gray;
        cursor: pointer;
        :hover{
            text-decoration-line: underline;
        }
    }
`;

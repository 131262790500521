import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ItemTable from '../item-table'
import ItemTableVariant from '../item-table-variant'
import Pagination from "../pagination";
//import { BarFilters, BarFiltersIcon, BarFiltersLeft, Search } from '../../../../reviews/components/tailwind/table/styled';
import { CodeBracketIcon, MagnifyingGlassIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
//import ButtonSinc from '../../button-sinc';
import { toast } from 'react-toastify';
import Navbar from '../navbar';
import { BarFilters, BarFiltersLeft, Search } from './styled';
import FiltersProducts from '../filters-products';
import { ContainerLoading } from '../../../../../components/Loading-logo/styled';
import LoadingLogo from '../../../../../components/Loading-logo';
import ModalUploadAnexoNew from '../../modal-upload-anexo-new';
import ModalCadLinkNew from '../../modal-cad-link-new';
import ModalAnexarGaleria from '../../modal-anexar-galeria-new';



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableVariants(props) {
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedVariants, setSelectedVariants] = useState([])
    const dispatch = useDispatch()

    const [visibeModalAnexoNew, setVisibeModalAnexoNew] = useState(false)
    const [visbleModalCadLinkNew, setVisbleModalCadLinkNew] = useState(false)
    const [visbleModalAnexarGaleriaNew, setVisbleModalAnexarGaleriaNew] = useState(false)

    const [dataVariants, setDataVariants] = useState([])

    const [pageFiles, setPageFiles] = useState(1)

    const variants = useSelector((state) => state.reducerVariants);
    // const products = useSelector(state => state.reducerProducts)

    useEffect(() => {
        console.log("dataVariants", dataVariants)
    }, [dataVariants])

    useEffect(() => {
        console.log("selectedVariants", selectedVariants)
    }, [selectedVariants])


    useEffect(() => {
        console.log("products", variants)
        variants?.data?.length > 0 && setDataVariants(variants?.data)
        variants?.data == undefined && setDataVariants([])
    }, [variants])

    useLayoutEffect(() => {
        const isIndeterminate = selectedVariants.length > 0 && selectedVariants.length < dataVariants.length
        setChecked(dataVariants.length == 0 ? false : selectedVariants.length === dataVariants.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedVariants])

    const handleClick = () => {
        if (checkbox.current) {
            checkbox.current.click();
        }
    };

    function toggleAll() {
        console.log("toggleAll")
        setSelectedVariants(checked || indeterminate ? [] : dataVariants)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)

    }


    const changePage = (page) => {
        console.log("changePage: ", page)
        props.setPage(page)
    }


    useEffect(() => {
        console.log("variants", variants)
    }, [variants])



    const [searchFiles, setSearchFiles] = useState("")

    useEffect(() => { goSearchFiles() }, [pageFiles])

    const goSearchFiles = () => {
        dispatch({ type: "SAGA_LIST_MEDIA", payload: { search: searchFiles, perPage: "10", page: pageFiles }, });
    }

    return (
        variants.loading ?
            <div style={{ display: "flex", height: "calc(100vh - 140px)", justifyContent: "center", alignItems: "center" }}>
                <LoadingLogo />
            </div> :
            <>

                <ModalUploadAnexoNew
                    setVisible={setVisibeModalAnexoNew}
                    visible={visibeModalAnexoNew}
                //getIconFile={getIconFile}
                //item={props.item}
                />

                <ModalCadLinkNew
                    setVisible={setVisbleModalCadLinkNew}
                    visible={visbleModalCadLinkNew}
                //getIconFile={getIconFile}
                //item={props.item}
                />

                <ModalAnexarGaleria
                    setVisible={setVisbleModalAnexarGaleriaNew}
                    visible={visbleModalAnexarGaleriaNew}
                    searchFiles={searchFiles}
                    setSearchFiles={setSearchFiles}
                    goSearchFiles={goSearchFiles}
                    pageFiles={pageFiles}
                    setPageFiles={setPageFiles}
                //getIconFile={getIconFile}
                //item={props.item}
                />

                <div className="px-4 sm:px-6 lg:px-8">
                    <Navbar />
                    <div className="sm:flex sm:items-center">
                        <div style={{ marginBottom: "28px" }} className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{"Variações"}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {"Vincule arquivos aos produtos disponíveis na sua loja."}
                            </p>
                        </div>
                    </div>

                    {/* <BarFilters style={{ paddingRight: "20px" }}>
                        <span style={{ display: "flex", justifyContent: "space-between" }}>
                            <BarFiltersLeft style={{ marginRight: "20px" }}>
                                <Search>
                                    <span>
                                        <MagnifyingGlassIcon style={{ width: "20px" }} />
                                    </span>
                                    <input onKeyPress={(e) => { e.key == "Enter" && props.goSearch() }} type='text' placeholder={"Pesquisar por nome"} value={props.search} onChange={(e) => { props.setSearch(e.target.value) }} />
                                </Search>
                            </BarFiltersLeft>

                            <FiltersProducts
                                medias={medias} setMedias={setMedias}
                                noMedias={noMedias} setNoMedias={setNoMedias}
                            />
                        </span>
                    </BarFilters> */}



                    <div className="mt-0 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    {selectedVariants.length > 0 && (
                                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                            <button
                                                onClick={() => {
                                                    setVisibeModalAnexoNew(true);
                                                    dispatch({ type: "VARIANTS_CONTROL_ADD", payload: selectedVariants });
                                                    setTimeout(() => { handleClick() }, 100);
                                                }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {"Anexar arquivo"}
                                            </button>

                                            <button
                                                onClick={() => {
                                                    setVisbleModalCadLinkNew(true)
                                                    dispatch({ type: "VARIANTS_CONTROL_ADD", payload: selectedVariants });
                                                    setTimeout(() => { handleClick() }, 100);
                                                }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {"Anexar link"}
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setVisbleModalAnexarGaleriaNew(true)
                                                    dispatch({ type: "VARIANTS_CONTROL_ADD", payload: selectedVariants });
                                                    setTimeout(() => { handleClick() }, 100);
                                                    goSearchFiles()
                                                }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {"Anexar da galeria"}
                                            </button>
                                        </div>
                                    )}
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        ref={checkbox}
                                                        checked={checked}
                                                        onChange={toggleAll}
                                                    />
                                                </th>
                                                <th style={{ paddingLeft: "20px" }} scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                                    {"Data"}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {"Name"}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {/* <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                        {"Mídias"}
                                                    </label> */}
                                                    Mídias
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {/* {Array(2).fill(
                                                <ItemTableVariant
                                                    //goSearch={props.goSearch}
                                                    classNames={classNames}
                                                    setSelectedProducts={setSelectedProducts}
                                                    selectedProducts={selectedProducts}
                                                    item={dataProducts[0]} />
                                            )} */}
                                            {props?.variants?.data?.map((item) => (
                                                <ItemTableVariant
                                                    classNames={classNames}
                                                    setSelectedVariants={setSelectedVariants}
                                                    selectedVariants={selectedVariants}
                                                    setVisibeModalAnexoNew={setVisibeModalAnexoNew}
                                                    visibeModalAnexoNew={visibeModalAnexoNew}

                                                    setVisbleModalCadLinkNew={setVisbleModalCadLinkNew}
                                                    visbleModalCadLinkNew={visbleModalCadLinkNew}

                                                    visbleModalAnexarGaleriaNew={visbleModalAnexarGaleriaNew}
                                                    setVisbleModalAnexarGaleriaNew={setVisbleModalAnexarGaleriaNew}

                                                    item={item} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Pagination setPage={changePage} /> */}
            </>
    )
}

import styled from "styled-components";
import { Form } from "react-bootstrap";

export const Contents = styled.div``;

export const BlockMedias = styled.div`
  width: calc(100% - 60px);
  /* height: 400px; */
  border-radius: 8px;
  background-color: white;
  padding: 40px;
  margin: 30px;
`;

export const SubTitle = styled.p`
  color: #858585;
  size: 16px;
  display: flex;
`;

export const ContainerStorage = styled.div`
  width: 400px;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  padding: 10px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ContainerLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* @media (max-width: 1200px) {
    flex-direction: column;
  } */
`;

export const Legend = styled.p`
  font-size: 16px;
`;

export const ProgressBar = styled.progress`
  background-color: red;
  border-radius: 10px;
  width: 100%;
  height: 20px;
  &::-webkit-progress-bar {
    background-color: #d9d9d9;
    border-radius: 2px;
  }
  &::-webkit-progress-value {
    background-color: #1b76ff;
    border-radius: 2px;
  }
`;

export const ContainerItems = styled.div`
  margin: 20px 0px;
`;

export const CustomSwitch = styled(Form.Switch)`
  label::before {
    background-color: #28a745;
  }
  label::after {
    background-color: #28a745;
  }
  &::before {
    background-color: #28a745 !important;
  }
`;

export const Input = styled.input`
  width: 40px;
  padding: 2px;
`;

export const Button = styled.div`
  background-color: #1b76ff;
  width: 204px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
`;

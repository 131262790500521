import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    //background-color: red;
    //height: 40px;
    //width: 300px;
    gap: 20px;
    align-items: center;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`

export const ItemData = styled.div`
    display: flex;
    //background-color: yellow;
    height: 40px;
    //width: 100px;

    input{
        background-color: #F9FAFB;
        padding: 0px 20px;
        border-radius: 4px;
        width: 220px;
    }
` 
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { isVisibleScript } from "../../../../utils/displayScript";
import { toast } from "react-toastify";
import { translations } from "../../../../utils/language";

const LoginPlus = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const statuslogin = useSelector((state) => state.reducerLogin);
  const language = useSelector((state) => state.reducerLanguage);

  useEffect(() => {
    if (
      !(
        statuslogin.loading === false &&
        statuslogin.error === false &&
        statuslogin.data.length === 0
      )
    ) {
      if (statuslogin.loading === true) {
        toast.loading("Aguarde...", { toastId: 0 });
      } else {
        if (statuslogin.error === true) {
          toast.update(0, {
            render:
              translations["Erro ao tentar efetuar login."][language.data],
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
          history.push("/login");
        } else if (statuslogin.error === false) {
          toast.update(0, {
            render: translations["Login efetuado com sucesso."][language.data],
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });

          if (statuslogin.data.data.user.isAdmin == 1) {
            history.push("/admin");
          } else {
            history.push("/");
          }
        }
      }
    }
  }, [statuslogin]);

  useEffect(() => {
    const searchParams = Object.fromEntries(
      new URLSearchParams(location.search)
    );

    if (searchParams?.code) {
      dispatch({ type: "SAGA_LOGAR_PLUS_CONCLUIR", payload: searchParams });
    } else {
      history.push("/login");
    }
  }, [history, dispatch, location]);

  return <>{isVisibleScript(false)}</>;
};

export default LoginPlus;

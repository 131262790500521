import { BoxBarApps, UserDropdownSubMenuInfor, UserDropdownSubMenu, QtdNotify, SubMenuNotify, Notify, Container, SectionOptions, UserDropdown, TooltipMenu, Trial, BarStorage, Storage, BarInfor, Bar, DetailStorage, CircleInfor, InforDetail, ItemDetail, TitleDetail, } from "./styled";
import { AiOutlineLogout, AiOutlineCopy } from "react-icons/ai";
import { FiHelpCircle, FiSettings } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { useBurgerMenu } from "../../hooks/useBurgerMenu";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import iconMenuDropi from "../../assets/imgs/userIcon.svg";
import iconNotifyDropi from "../../assets/imgs/teste.svg";
import { translations } from "../../utils/language";
import Select from "react-select";
import { intervalToDuration } from "date-fns";

import IconImage from "../../assets/imgs/icons/image.svg";

const Header = () => {
  const { burgerMenu, setBurgerMenu } = useBurgerMenu();

  const [countImage, setcountImage] = useState(0)
  const [countPDF, setCountPDF] = useState(0)
  const [countVideo, setcountVideo] = useState(0)
  const [countOthers, setcountOthers] = useState(0)

  const options = [
    {
      value: "BR",
      label: (
        <div>
          <img
            src="https://www.gov.br/planalto/pt-br/conheca-a-presidencia/acervo/simbolos-nacionais/bandeira/bandeiragrande.jpg"
            height="30px"
            width="40px"
          />
        </div>
      ),
      language: "pt",
    },
    {
      value: "MX",
      label: (
        <div>
          <img
            src="https://s4.static.brasilescola.uol.com.br/be/2022/10/bandeira-do-mexico.jpg"
            height="30px"
            width="40px"
          />
        </div>
      ),
      language: "es",
    },
    {
      value: "AR",
      label: (
        <div>
          <img
            src="https://static.todamateria.com.br/upload/ba/nd/bandeira_argentina_bb.jpg"
            height="30px"
            width="40px"
          />
        </div>
      ),
      language: "es",
    },
  ];

  const icon = { size: 20, color: "gray" };
  const selectedMenu = useLocation();
  const history = useHistory();
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visibleMenuNotify, setVisibleMenuNotify] = useState(false);
  const [language, setLanguage] = useState("");
  const [countryValue, setCountryValue] = useState();

  const settings = useSelector((state) => state.reducerSettings);

  const dispatch = useDispatch();

  const controlConfig = useSelector((state) => state.reducerControlConfig);
  const config = useSelector((state) => state.reducerTemplate);

  const listNotify = useSelector((state) => state.reducerListNotify);

  const closeMenu = (event) => { setVisibleMenu(false); };
  const closeMenuNotify = (event) => { setVisibleMenuNotify(false); };

  useEffect(() => {

    if (visibleMenu) {
      window.addEventListener("mouseup", closeMenu);
      return () => {
        window.removeEventListener("mouseup", closeMenu);
      };
    }
  }, [visibleMenu]);

  useEffect(() => {
    const storageLanguage = localStorage.getItem("country");
    if (storageLanguage === "BR") {
      setCountryValue({
        value: "BR",
        label: (
          <div>
            <img
              src="https://www.gov.br/planalto/pt-br/conheca-a-presidencia/acervo/simbolos-nacionais/bandeira/bandeiragrande.jpg"
              height="30px"
              width="40px"
            />
          </div>
        ),
        language: "pt",
      });
    } else if (storageLanguage === "MX") {
      setCountryValue({
        value: "MX",
        label: (
          <div>
            <img
              src="https://s4.static.brasilescola.uol.com.br/be/2022/10/bandeira-do-mexico.jpg"
              height="30px"
              width="40px"
            />
          </div>
        ),
        language: "es",
      });
    } else if (storageLanguage === "AR") {
      setCountryValue({
        value: "ar",
        label: (
          <div>
            <img
              src="https://static.todamateria.com.br/upload/ba/nd/bandeira_argentina_bb.jpg"
              height="30px"
              width="40px"
            />
          </div>
        ),
        language: "es",
      });
    } else {
      setCountryValue({
        value: "BR",
        label: (
          <div>
            <img
              src="https://www.gov.br/planalto/pt-br/conheca-a-presidencia/acervo/simbolos-nacionais/bandeira/bandeiragrande.jpg"
              height="30px"
              width="40px"
            />
          </div>
        ),
        language: "pt",
      });
    }
  }, []);

  useEffect(() => {

    if (visibleMenu) {
      window.addEventListener("mouseup", closeMenu);
      // cleanup this component
      return () => {
        window.removeEventListener("mouseup", closeMenu);
      };
    }
  }, [visibleMenu]);

  useEffect(() => {
    if (visibleMenuNotify) {
      window.addEventListener("mouseup", closeMenuNotify);
      // cleanup this component
      return () => {
        window.removeEventListener("mouseup", closeMenuNotify);
      };
    }
  }, [visibleMenuNotify]);

  const copy = () => {
    navigator.clipboard.writeText(
      JSON.parse(localStorage.getItem("depoimentos@login")).user
        .chave_empreender
    );
    //setCopyKey(text)
    toast.success(translations["Chave empreender copiada."][language]);
  };

  function handleBurgerMenuClick() {
    burgerMenu ? setBurgerMenu(false) : setBurgerMenu(true);
  }

  useEffect(() => {
    if (localStorage.getItem("depoimentos@login")) {
    } else {
      history.push("/login");
    }

    // dispatch({ type: "SAGA_LIST_NOTIFY" });
  }, []);

  useEffect(() => {
    const storageLanguage = localStorage.getItem("language");
    if (storageLanguage) {
      setLanguage(storageLanguage);
      dispatch({
        type: "SAGA_LIST_LANGUAGE",
        payload: {
          language: storageLanguage,
        },
      });
    } else {
      setLanguage("pt");
      dispatch({
        type: "SAGA_LIST_LANGUAGE",
        payload: {
          language: "pt",
        },
      });
    }
  }, [localStorage, dispatch]);


  let userInformations = localStorage.getItem('depoimentos@login');

  // useEffect(function () {
  //   try {
  //     let toolbarTimeout = setInterval(() => {
  //       let token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;

  //       let tokenType = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.type;

  //       if (token) {
  //         window.empreenderPlusApiInstancia({
  //           caminhoParaRepassarRequestAoPlus: process.env.REACT_APP_API_URL + "/eplus/hub", //OBRIGATORIO
  //           afiliado: "", //OPCIONAL
  //           insert: "insert-toolbar-plus", //classe para inserir script se não passar insere no body - OPCIOANL
  //           app: "DIJITAL", //code do app - OBRIGATORIO            // headers - OPCIONAL
  //           headers: {
  //             Authorization: tokenType + " " + token,
  //             "Content-Type": "application/json",
  //           },
  //           upperBannerContainer: "upperBannerContainer"
  //         });

  //         clearInterval(toolbarTimeout);
  //       }
  //     }, 250);
  //   } catch (e) {
  //     console.error("empreenderPlusApiInstancia - error", e);
  //   }
  // }, [selectedMenu, userInformations]);

  useEffect(function () {
    try {
      let infosParsed = JSON.parse(userInformations);
      if (infosParsed?.user?.id_user_plus) {
        window.empreenderPlusRegistrarConversao(infosParsed.user.id_user_plus); // passar o id user plus
      }
    } catch (err) { }
  }, [userInformations]);



  const getMessageReviews = (qtd) => {
    if (qtd === 1) {
      return "1 nova avaliação";
    } else {
      return qtd + " novas avaliações";
    }
  };
  const getMessageQuestions = (qtd) => {
    if (qtd === 1) {
      return "1 nova pergunta";
    } else {
      return qtd + " novas perguntas";
    }
  };

  // useEffect(() => {
  //     const script = document.createElement("script")
  //     script.src = "https://empreender.nyc3.cdn.digitaloceanspaces.com/static/OUTROS/top-menu-produtos-empreeder?app=LILY&insert=container__script_top_menu_lily"
  //     script.async = true
  //     document.body.appendChild(script)
  // }, [])


  const isSave = () => {
    if (selectedMenu.pathname == "/personalizar" || selectedMenu.pathname == "/personalizar/email" || selectedMenu.pathname == "/personalizar/checkout") {
      if (
        config.data[0].color_checkout_background_header == controlConfig.color_checkout_background_header &&
        config.data[0].color_checkout_text_header == controlConfig.color_checkout_text_header &&
        config.data[0].color_checkout_background == controlConfig.color_checkout_background &&
        config.data[0].color_checkout_text_title == controlConfig.color_checkout_text_title &&
        config.data[0].color_checkout_background_product == controlConfig.color_checkout_background_product &&
        config.data[0].color_checkout_text_product == controlConfig.color_checkout_text_product &&
        config.data[0].color_button_checkout == controlConfig.color_button_checkout &&
        config.data[0].color_message_button_checkout == controlConfig.color_message_button_checkout &&

        config.data[0].checkout_message_header == controlConfig.checkout_message_header &&
        config.data[0].checkout_message_title == controlConfig.checkout_message_title &&
        config.data[0].checkout_message == controlConfig.checkout_message &&
        config.data[0].checkout_button == controlConfig.checkout_button &&
        config.data[0].checkout_button_link == controlConfig.checkout_button_link &&

        config?.data[0]?.color_email_background == controlConfig.color_email_background &&
        config?.data[0]?.color_title_email == controlConfig.color_title_email &&
        config?.data[0]?.color_button == controlConfig.color_button &&
        config?.data[0]?.color_message_button == controlConfig.color_message_button &&

        config?.data[0]?.subject_email == controlConfig.subject_email &&
        config?.data[0]?.message_email == controlConfig.message_email &&
        config?.data[0]?.message_button == controlConfig.message_button
      ) {
        dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_SET", payload: true })
        dispatch({ type: "CONFIG_CONTROL_SAVE_CHECKOUT_SET", payload: true })
        return true
      } else {
        dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_SET", payload: false })
        dispatch({ type: "CONFIG_CONTROL_SAVE_CHECKOUT_SET", payload: false })
        return false
      }
    } else {
      return true
    }


  }

  const getTrial = () => {
    let result = "";
    let dates = intervalToDuration({
      start: new Date(),
      end: new Date(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).setDate(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).getDate() + (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.affiliate == null ? 3 : 10))),
    });

    if (new Date() > new Date(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).setDate(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).getDate() + (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.affiliate == null ? 3 : 10)))) {
      return `Fim do período de testes.`
    }
    if (dates.days == 0 && dates.hours > 0) {
      return `Restam ${dates.hours} horas grátis.`
    } else if (dates.days == 0 && dates.hours == 0) {
      return translations["header-fim-teste"][language]
    } else if (dates.days > 0 && dates.hours == 0) {
      return `Restam ${dates.days} dias grátis.`
    } else {
      return `Restam ${dates.days} dias e ${dates.hours} horas grátis.`
    }

    //return dates.hours > 0 ? `Restam ${dates.days} dias e ${dates.hours} horas grátis.` : `Restam ${dates.days} dias grátis.`
  }

  useEffect(() => {    dispatch({ type: "SAGA_LIST_SETTINGS", });  }, []);

  useEffect(() => {    calcBar()  }, [settings])

  const calcBar = () => {
    let porc = ((settings?.data?.data?.storage / settings?.data?.data?.limit) * 100).toFixed(1)

    let result = (porc * 200 / 100)
    return result < 10 ? "10px" : result + "px"
  }

  const getPorcent = (value) => {
    return ((value / (countImage + countOthers + countPDF + countVideo)) * 100).toFixed(0)
  }

  const getColor = () => {
    let porc = ((settings?.data?.data?.storage / settings?.data?.data?.limit) * 100).toFixed(0)
    //let result = (porc * 200 / 100)
    //porc = parseInt(porc) + parseInt(60)


    if (porc <= 60) {
      return "#21D487"
    } else if (porc < 90 && porc > 60) {
      return "#FFC400"
    } else {
      return "#F13F6C"
    }
  }

  const getCountMidia = () => {
    // if (settings?.data?.data) {
    //   setcountImage(settings?.data?.data?.mediaCount?.filter(midia => midia.type == "webp" || midia.type == "png" || midia.type == "jpeg" || midia.type == "jpg"))
    //   setCountPDF(settings?.data?.data?.mediaCount?.filter(midia => midia.type == "pdf"))
    //   setcountVideo(settings?.data?.data?.mediaCount?.filter(midia => midia.type == "mp4" || midia.type == "mkv" || midia.type == "avi"))
    //   setcountOthers(settings?.data?.data?.mediaCount?.filter(midia => midia.type != "webp" || midia.type != "png" || midia.type != "jpeg" || midia.type != "jpg" || midia.type != "mp4" || midia.type != "mkv" || midia.type != "avi" || midia.type != "pdf"))
    // }

    if (settings?.data?.data?.mediaCount) {
      setcountImage(0)
      setCountPDF(0)
      setcountVideo(0)
      setcountOthers(0)
      settings?.data?.data?.mediaCount?.forEach(element => {
        if (element.type == "webp" || element.type == "png" || element.type == "jpeg" || element.type == "jpg") {
          setcountImage(countImage + element.total)
        } else if (element.type == "pdf") {
          setCountPDF(countPDF + element.total)
        } else if (element.type == "mp4" || element.type == "mkv" || element.type == "avi") {
          setcountVideo(countVideo + element.total)
        } else if (element.type != "mp4" || element.type != "mkv" || element.type != "avi" || element.type != "pdf" || element.type != "webp" || element.type != "png" || element.type != "jpeg" || element.type != "jpg") {
          setcountOthers(countOthers + element.total)
        }
      });
    }
  }

  useEffect(() => {
    getCountImage()
    getCountVideo()
    getCountPDF()
    getCountOthers()
  }, [settings])

  const getCountImage = () => {
    let data = []
    let countImage = 0
    if (settings?.data?.data?.mediaCount) {
      data = settings?.data?.data?.mediaCount.filter(midia => midia.type == "webp" || midia.type == "png" || midia.type == "jpeg" || midia.type == "jpg")
    }
    data.forEach(element => { countImage += element.total });
    setcountImage(countImage)
  }

  const getCountVideo = () => {
    let data = []
    let countVideo = 0
    if (settings?.data?.data?.mediaCount) {
      data = settings?.data?.data?.mediaCount.filter(midia => midia.type == "mp4" || midia.type == "mkv" || midia.type == "avi")
    }
    data.forEach(element => { countVideo += element.total });
    setcountVideo(countVideo)
  }

  const getCountPDF = () => {
    let data = []
    let countPDF = 0
    if (settings?.data?.data?.mediaCount) {
      data = settings?.data?.data?.mediaCount.filter(midia => midia.type == "pdf")
    }
    data.forEach(element => { countPDF += element.total });
    setCountPDF(countPDF)
  }

  const getCountOthers = () => {
    let data = []
    let countOthers = 0
    if (settings?.data?.data?.mediaCount) {
      data = settings?.data?.data?.mediaCount.filter(midia => midia.type != "pdf" || midia.type != "webp" || midia.type != "png" || midia.type != "jpeg" || midia.type != "jpg" || midia.type != "mp4" || midia.type != "mkv" || midia.type != "avi")
    }
    data.forEach(element => { countOthers += element.total });
    setcountOthers(countOthers)
  }


  const getSizeStorage = (value) => {
    if (value == "10000000000") {
      return "10GB"
    } else if (value == "20000000000") {
      return "20GB"
    } else if (value == "1000000000") {
      return "1GB"
    } else {
      return "150MB"
    }
  }


  return (
    <>

      {/* <div style={{ backgroundColor: "red", display: "flex", width: "calc(100vw - 90px)", height: "100px", position: "absolute" }}>
        <div class="upperBannerContainer"></div>
        <div className="insert-toolbar-plus" style={{ position: "relative", minHeight: "62px" }}></div>
      </div> */}

      <Container>
        <GiHamburgerMenu
          id="burger__id"
          className="hideBugerMenu"
          color={"gray"}
          size={24}
          onClick={handleBurgerMenuClick}
          style={{ cursor: "pointer", marginLeft: "16px" }}
        />

        <SectionOptions>
          {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.cred_expire == 0 &&
            <Trial onClick={() => { isSave() && history.push("/pagamentos"); }}>
              {getTrial()}
              <span>  {translations["header-assinar"][language]}</span>
            </Trial>}

          {settings?.data?.data && window.location.pathname == "/" ?
            <BarStorage>
              <Storage>
                <BarInfor>
                  <label> {translations["header-armazenamento"][language]}</label>
                  <label>{`${(settings?.data?.data?.storage / 1000000).toFixed(1)}MB / ${getSizeStorage(settings?.data?.data?.limit)}`}</label>
                </BarInfor>
                <Bar color={getColor()} size={calcBar()}>
                  <div />
                  <label>{((settings?.data?.data?.storage / settings?.data?.data?.limit) * 100).toFixed(1)}%</label>
                </Bar>
                <DetailStorage>
                  <TitleDetail>
                    {translations["header-detalhes"][language]}
                  </TitleDetail>

                  <CircleInfor
                    imageStart={"0%"} imageEnd={getPorcent(countImage) + "%"} imageColor={"#F13F6C"}
                    pdfStart={getPorcent(countImage) + "%"} pdfEnd={getPorcent(countImage + countPDF) + "%"} pdfColor={"#0BACFF"}
                    videoStart={getPorcent(countImage + countPDF) + "%"} videoEnd={getPorcent(countImage + countPDF + countVideo) + "%"} videoColor={"#21D487"}
                    othersStart={getPorcent(countImage + countPDF + countVideo) + "%"} othersEnd={getPorcent(countImage + countPDF + countVideo + countOthers) + "%"} othersColor={"#f0ad4e"}
                  >
                    <div>
                      <label>
                        <strong>{getSizeStorage(settings?.data?.data?.limit).replace(/GB/i, "").replace(/MB/i, "")}</strong>
                        {getSizeStorage(settings?.data?.data?.limit).slice(getSizeStorage(settings?.data?.data?.limit).length - 2, getSizeStorage(settings?.data?.data?.limit).length)}
                      </label>
                    </div>
                  </CircleInfor>

                  <InforDetail>
                    <ItemDetail color={"#F13F6C"}>
                      <span />
                      <div>
                        <p>{translations["header-imagem"][language]}</p>
                        <label>{countImage}</label>
                      </div>
                    </ItemDetail>

                    <ItemDetail color={"#0BACFF"}>
                      <span>
                        <div />
                      </span>
                      <div>
                        <p>PDF</p>
                        <label>{countPDF}</label>
                      </div>
                    </ItemDetail>

                    <ItemDetail color={"#21D487"}>
                      <span />
                      <div>
                        <p>Vídeo</p>
                        <label>{countVideo}</label>
                      </div>
                    </ItemDetail>

                    <ItemDetail color={"#f0ad4e"}>
                      <span />
                      <div>
                        <p>{translations["header-outros"][language]}</p>
                        <label>{countOthers}</label>
                      </div>
                    </ItemDetail>


                  </InforDetail>
                </DetailStorage>

              </Storage>
              <p onClick={() => { history.push("/pagamentos") }}> {translations["header-comprar"][language]}</p>
            </BarStorage> : null}

          <Select
            options={options}
            styles={{ marginLeft: 80 }}
            value={countryValue}
            onChange={(a) => {
              setCountryValue(a);
              setLanguage(a.language);
              localStorage.setItem("country", a.value);
              localStorage.setItem("language", a.language);
              dispatch({
                type: "SAGA_LIST_LANGUAGE",
                payload: { language: a.language, },
              });
              window.location.reload()
            }}
          />


          <Notify onClick={() => { setVisibleMenuNotify(true); }}>
            <img
              style={{ color: "#718eb8" }}
              onClick={() => { setVisibleMenuNotify(true); }}
              width={30}
              src={iconNotifyDropi}
            />

            {listNotify.data.data?.newReview > 0 || listNotify.data.data?.newAsk > 0 ?
              <QtdNotify>
                <label>
                  {listNotify.data.data?.newReview +
                    listNotify.data.data?.newAsk}
                </label>
              </QtdNotify> :
              <></>}

            {/* <SubMenuNotify visible={visibleMenuNotify}>
              {listNotify.data.data?.newReview == 0 && listNotify.data.data?.newAsk == 0 ?
                <span>
                  <label>0 notificações</label>
                </span> :
                <>
                  {listNotify.data.data?.newReview > 0 &&
                    <span style={{ marginBottom: "5px" }} onClick={() => { history.push("/avaliacoes"); }}>
                      <label>
                        {getMessageReviews(listNotify.data.data?.newReview)}
                      </label>
                    </span>}

                  {listNotify.data.data?.newAsk > 0 &&
                    <span onClick={() => { history.push("/perguntas"); }}>
                      <label>
                        {getMessageQuestions(listNotify.data.data?.newAsk)}
                      </label>
                    </span>}
                </>}
            </SubMenuNotify> */}
          </Notify>

          <UserDropdown onClick={() => { setVisibleMenu(true); }}>
            <img width={40} src={iconMenuDropi} />
            <UserDropdownSubMenu visible={visibleMenu}>
              <UserDropdownSubMenuInfor>
                <label>
                  {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.name}
                </label>
                <label>
                  {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.email}
                </label>

                {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url != null &&
                  <label>
                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}
                  </label>}

              </UserDropdownSubMenuInfor>
              <hr />
              <span onClick={() => { copy(); }} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", }} >
                <p
                  style={{ display: "flex", width: "100%", alignItems: "center", marginBottom: "0px", marginLeft: "-10px", }}                >
                  <label style={{ display: "flex", alignItems: "center", marginLeft: "5px", color: "#20D489", }}>
                    {translations["Chave Empreender"][language]}:
                    <TooltipMenu>
                      <FiHelpCircle size={17} color={"#20D489"} />
                      <label>
                        {translations["Esse é o código de indentificação desse aplicativo."][language]}
                      </label>
                    </TooltipMenu>
                  </label>
                </p>
              </span>
              <span onClick={() => { copy(); }}>
                <label style={{ width: "100%", display: "flex", alignItems: "center", }}                >
                  <AiOutlineCopy size={19} color={"gray"} />
                  <label style={{ textTransform: "uppercase", marginLeft: "7px" }}                  >
                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}
                  </label>
                </label>
              </span>

              {process.env.REACT_APP_HABILITAR_PLUS ?
                <span
                  onClick={() => { if (isSave()) { window.location.href = process.env.REACT_APP_EPLUS_PAGINA_APPS } }}                >
                  <img
                    alt="Empreender Plus"
                    width="16"
                    src="https://empreender.nyc3.digitaloceanspaces.com/landingpage/landingpage/ap8n31063713.svg"
                  />
                  <label style={{ marginLeft: "8px" }}>Empreender Plus</label>
                </span> :
                ""}

              <span onClick={() => { isSave() && history.push("/configuracoes"); }}>
                <FiSettings size={20} color={"gray"} />
                <label style={{ marginLeft: "7px" }}>
                  {translations["Configurações"][language]}
                </label>
              </span>

              <span style={{ marginBottom: "0px" }} onClick={() => {
                if (isSave()) {
                  localStorage.removeItem("depoimentos@login")
                  history.push("/login")
                }

              }}>
                <AiOutlineLogout size={20} color={"gray"} />
                <label style={{ marginLeft: "7px" }}>
                  {translations["Sair"][language]}
                </label>
              </span>
            </UserDropdownSubMenu>
          </UserDropdown>
        </SectionOptions>
      </Container >
    </>
  );
};

export default Header;

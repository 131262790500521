
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosClose } from "react-icons/io";

import { Container, Close, BoxImage, BoxInfor, ItemMenu, BoxMenu } from "./styled";
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { translations } from '../../../../utils/language';
import { ArrowDownOnSquareIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, ArrowTopRightOnSquareIcon, CloudArrowDownIcon, EnvelopeIcon, PaperClipIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useHistory } from 'react-router-dom';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalMenuItem = (props) => {

    const dispatch = useDispatch()
    const language = useSelector((state) => state.reducerLanguage);
    const history = useHistory()
    const [visibleModalRenomear, setVisibleModalRenomear] = useState(false)


    const downloadMedia = () => {
        window.open(`${process.env.REACT_APP_API_URL}/download/${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id}/${props.item.id}`, '_blank')
    }

    const sincProduct = () => {
        dispatch({
            type: "SAGA_UPDATE_DATA_PRODUCT",
            payload: {
                integration: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration,
                product: props.item.products,

            }
        })
    }

    const deleteMedia = () => {
        dispatch({ type: "SAGA_DELETE_MULTI_MEDIA", payload: { id: props.item.id, } })
    }

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <Container>
                <Close onClick={() => { props.setVisibleModal(false) }}><IoIosClose size={25} color={"gray"} /></Close>
                <BoxMenu>

                    {props.item.type == "url" ?
                        <Item
                            image={<ArrowTopRightOnSquareIcon style={{ width: "32px" }} />}
                            action={() => { window.open(props.item.media, '_blank') }}
                            item={props.item}
                            title={"Acessar"}
                            desc={"Acessar essa URL."} /> :
                        <Item
                            image={<CloudArrowDownIcon style={{ width: "32px" }} />}
                            action={() => { downloadMedia() }}
                            item={props.item}
                            title={"Baixar"}
                            desc={"Baixar esse arquivo."} />}

                    <Item
                        image={<TrashIcon style={{ width: "32px" }} />}
                        action={() => { deleteMedia() }}
                        item={props.item}
                        title={"Excluír"}
                        desc={"Excluír essa arquivo."} />

                    <Item
                        image={<PencilSquareIcon style={{ width: "32px" }} />}
                        action={() => { props.setVisibleModalRenomear(true); props.setVisibleModal(false) }}
                        item={props.item}
                        title={"Renomear"}
                        desc={"Renomear essa arquivo."} />

                </BoxMenu>
            </Container>
        </Modal>
    )
}

export default ModalMenuItem


const Item = (props) => {

    const dispatch = useDispatch()

    useEffect(() => {
        //console.log("props_", props)
    }, [props])


    const [hover, setHover] = useState(false)


    return (
        <ItemMenu onClick={() => { props.action() }} hover={hover} onMouseLeave={() => { setHover(false) }} onMouseEnter={() => { setHover(true) }}>
            <BoxImage hover={hover}>
                {props.image}
            </BoxImage>
            <BoxInfor>
                <label>{props.title}</label>
                <p>{props.desc}</p>
            </BoxInfor>
        </ItemMenu>
    )
}


import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  background-color: #efefef;
  height: 50px;
  display: flex;
  padding: 0px 25px;
`;

export const Body = styled.div`
  //background-color: purple;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Filters = styled.div`
  //background-color: tomato;
  display: flex;
  //height: 100px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DataInfor = styled.table`
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 12px;
  font-family: sans-serif;
  //min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin: 0px 25px 25px 25px;

  thead {
    height: 50px;
    tr {
      background-color: #006bec;
      color: #ffffff;
      text-align: center;
      th,
      td {
        padding: 12px 15px;
      }
    }
  }

  tBody {
    tr {
      border-bottom: 1px solid #dddddd;
      height: 40px;

      th {
        a {
          text-decoration: none;
          :hover {
            text-decoration: underline;
          }
        }
      }
    }
    tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }
    tr:last-of-type {
      border-bottom: 2px solid #009879;
    }
    tr.active-row {
      font-weight: bold;
      color: #009879;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;
  label {
    display: flex;
    margin: 0px 10px;
    cursor: pointer;
    :hover {
      text-decoration: underline;
      text-decoration-color: #c1c1c1;
    }
  }
`;

export const Options = styled.div`
  //background-color: yellow ;
  display: flex;
  //height: 50px;
  width: calc(100% - 50px);
  margin-bottom: 20px;
  align-items: center;
`;

export const OptionFilter = styled.label`
  display: flex;
  background-color: ${(props) => (props.active ? "#EFEFEF" : "transparent")};
  height: 35px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 0px 15px;
  border-radius: 17px;
  cursor: pointer;
  font-size: 13px;
  border: ${(props) => (props.active ? "none" : "1px solid #EFEFEF")}; ;
`;

export const Search = styled.div`
  background-color: #efefef;
  display: flex;
  height: 50px;
  border-radius: 25px;
  padding: 10px;
  align-items: center;
  margin: 20px 0px;

  span {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    display: flex;
    height: 100%;
    width: 500px;
    outline: none;
    border: none;
    background-color: transparent;
    padding-left: 10px;
  }
`;

export const Paginate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 25px;
  //background-color: grey;
`;

export const PaginateNav = styled.div`
  display: flex;
  height: 40px;
  margin-bottom: 5px;
  align-items: center;

  span {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  label {
    margin: 0px 10px;
  }
`;

export const PaginateInfor = styled.div`
  display: flex;
  //background-color: saddlebrown;
`;

export const BoxLoading = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  span {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

import React from 'react'
import { Container, Trial } from './styled'
import { translations } from '../../../../utils/language';
import { useSelector } from 'react-redux';
import { intervalToDuration } from 'date-fns';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const BoxTrial = () => {

    const language = useSelector((state) => state.reducerLanguage);
    const history = useHistory()

    const getTrial = () => {
        let result = "";
        let dates = intervalToDuration({
            start: new Date(),
            end: new Date(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).setDate(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).getDate() + (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.affiliate == null ? 3 : 10))),
        });

        if (new Date() > new Date(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).setDate(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).getDate() + (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.affiliate == null ? 3 : 10)))) {
            return `Fim do período de testes.`
        }
        if (dates.days == 0 && dates.hours > 0) { return `Restam ${dates.hours} horas grátis.` }
        else if (dates.days == 0 && dates.hours == 0) { return translations["header-fim-teste"][language.data] }
        else if (dates.days > 0 && dates.hours == 0) { return `Restam ${dates.days} dias grátis.` }
        else { return `Restam ${dates.days} dias e ${dates.hours} horas grátis.` }

        //return dates.hours > 0 ? `Restam ${dates.days} dias e ${dates.hours} horas grátis.` : `Restam ${dates.days} dias grátis.`
    }

    return (
        <Container>
            <Trial>
                <label>
                    {getTrial()}
                </label>
                <span onClick={() => { history.push("/pagamentos"); }}>
                    {translations["header-assinar"][language.data]}
                </span>
            </Trial>
        </Container>
    )
}

export default BoxTrial
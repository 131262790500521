import { ThemeProvider } from "styled-components";
import Routes from "./routers";
import GlobalStyle from "./styles/global";
import { ThemeDefault } from "./styles/themes/default";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

function App() {
  const dispatch = useDispatch();
  let code =
    window.location.pathname === "/integracoes"
      ? window.location.href.split("?code=")[1]
      : null;

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("depoimentos@login") === null && code) {
        const response = await axios.post(
          "https://produtodigital-back.herokuapp.com/integrations_nuvem",
          {
            code: code,
          }
        );
        localStorage.setItem("country", response.data.store.country);

        if (response.data.store.country === "BR") {
          localStorage.setItem("language", "pt");
          dispatch({
            type: "SAGA_LIST_LANGUAGE",
            payload: {
              language: "pt",
            },
          });
        } else {
          localStorage.setItem("language", "es");
          dispatch({
            type: "SAGA_LIST_LANGUAGE",
            payload: {
              language: "es",
            },
          });
        }
        localStorage.setItem("userId", response.data.data.user_id);
        localStorage.setItem("accessToken", response.data.data.access_token);
        localStorage.setItem("scope", response.data.data.scope);
      }
    })();
  }, [code]);
  window.Buffer = window.Buffer || require('buffer').Buffer;
  return (

    <ThemeProvider theme={ThemeDefault}>
      <ToastContainer position="top-center" style={{ zIndex: "999999999999999999999999" }} />
      <GlobalStyle />
      {/* <span style={{position: "absolute", minWidth: "100%",  top: "10px"}}>
        <span style={{position: "relative"}}>
          <div class="upperBannerContainer"></div>
          <div className="insert-toolbar-plus" style={{ position: "relative", minHeight: "62px" }}></div>
        </span>
      </span> */}
      <Routes />
    </ThemeProvider>
  );
}
export default App;

import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    overflow: auto;
    max-height: 90vh;
    gap: 20px;
`

export const Close = styled.div`
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`

export const TitleModal = styled.div`
    display: flex;
    //height: 50px;
    //background-color: yellow;
    flex-direction: column;
    justify-content: center;
`

export const Product = styled.div`
    display: flex;
    //height: 100px;
    //background-color: #F2F2FD;
    border-radius: 8px;
    padding: 20px;
    gap: 20px;
    border: 1px solid #f1f1f1;
    
`

export const BoxUpload = styled.div`
    display: flex;
    //height: 200px;
    //background-color: yellow;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`

export const Options = styled.div`
    display: flex;
    //height: 40px;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    margin: 10px 0px;
`

export const ItemOption = styled.label`
    display: flex;
    flex: 1;
    justify-content: center;
    border-bottom: 4px solid ${props => props.selected ? "#1b76ff" : "#f1f1f1"};
    cursor: pointer;
    padding-bottom: 14px;
    font-size: 18px;
    font-weight: 500;
`

export const SectionMedias = styled.div`
    display: flex;
    //background-color: gray;
    gap: 20px;
    flex-direction: column;
    width: 100%;
`

export const SectionConectar = styled.div`
    display: flex;
    width: 400px;
    flex-direction: column;
    gap: 10px;
    align-items: end;
    border-right: 1px solid #f1f1f1;
    padding-right: 20px;
    

    input{
        display: flex;
        height: 40px;
        background-color: #f1f1f1;
        outline: none;
        border: none;
        padding: 0px 10px;
        border-radius: 4px;
        width: 100%;
    }

    label{
        display: flex;
        padding: 6px 18px;
        background-color: #19B674;
        width: max-content;
        border-radius: 4px;
        cursor: pointer;
        color: #ffffff;
    }
`

export const SectionVincular = styled.div`
    display: flex;
    //background-color: violet;
    border-right: 1px solid #f1f1f1;
    padding-right: 20px;
    flex-direction: column;

    label{
        font-size: 12px;
        margin: 0px;
        padding: 0px;
        margin-bottom: 5px
    }
`

export const SectionUploadFile = styled.div`
    display: flex;
    //background-color: red;
    //min-width: 100%;
    flex-direction: column;
    align-items: center;
    //border-right: 1px solid #f1f1f1;
    gap: 20px;
    //padding-right: 20px;

    label{
        display: flex;
        //background-color: green;
        width: 100%;
        height: 150px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 2px dashed #dcdcdc;
        gap: 10px;
        border-radius: 8px;
        background-color: transparent !important;

        label{
            display: flex;
            background-color: transparent;
            text-align: center;
            font-size: 14px;
            color: gray;
            border: none;
            width: max-content;
            height: max-content;
        }
    }

    label{
        display: flex;
        background-color: #1b76ff;
        font-size: 12px;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        color: #ffffff;
    }
`

export const SectionUploadList = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    //background-color: red;
    width: 100%;
`

export const BoxActions = styled.div`
    display: flex;
    //background-color: red;
    justify-content: end;


    label{
        display: flex;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        background-color:  #19B674;
        color: #ffffff;
        gap: 10px;
    }
`

const INITIAL_STATE = {
    error: false,
    loading: false,
    data: {
        completed: null,
        media: null
    }
}

const UrlMedias = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PRODUCT_URL_MEDIA_REQUEST':
            return { loading: true, error: false, data: { completed: false, media: action.payload } }
        case 'PRODUCT_URL_MEDIA_ERROR':
            return { loading: false, error: true, data: { completed: "error", media: action.payload } };
        case 'PRODUCT_URL_MEDIA_SUCCESS':
            return { loading: false, error: false, data: { completed: true, media: action.payload } };
        case 'PRODUCT_URL_MEDIA_RESET':
            return { loading: false, error: false, data: [] };
        default:
            return state;
    }
}

export default UrlMedias
import { PaginateNav, PaginateInfor, Container, Paginate, Filters, BoxLoading, Search, Check, DropDown, Infor, ListCard, ButtonAdd, BoxAlert } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { AiOutlineSearch, AiOutlineLoading } from "react-icons/ai";
import { IoIosAdd, IoIosAddCircle } from "react-icons/io";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { RiCheckFill } from "react-icons/ri";
import { useState } from "react";
import Card from "../../components/card";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalAdd from "../../components/modal-add";
import { isVisibleScript } from "../../../../utils/displayScript";
import AlertInfor from "../../../../components/Alert";

const Home = () => {

    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const cupons = useSelector(state => state.reducerCupons)
    const [visibleModalAdd, setVisibleModalAdd] = useState(false)
    const dispatch = useDispatch()

    const statusUpdateCupom = useSelector(state => state.reducerUpdateCupom)
    const statusDeleteCupom = useSelector(state => state.reducerDeleteCupom)
    const statusAddCupom = useSelector(state => state.reducerAddCupom)

    useEffect(() => {
        if (!statusUpdateCupom.loading && !statusUpdateCupom.error && statusUpdateCupom.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_CUPONS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusUpdateCupom])

    useEffect(() => {
        if (!statusDeleteCupom.loading && !statusDeleteCupom.error && statusDeleteCupom.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_CUPONS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusDeleteCupom])

    useEffect(() => {
        if (!statusAddCupom.loading && !statusAddCupom.error && statusAddCupom.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_CUPONS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusAddCupom])

    useEffect(() => {
        if (typeof search !== "undefined" || typeof statusQuestion !== "undefined") {
            dispatch({
                type: "SAGA_LIST_CUPONS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [])

    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_CUPONS", payload: {
                search: search,
                perPage: perPage,
                page: page
            }
        })
    }

    useEffect(() => {
        goSearch()
    }, [page])

    const countItens = () => {
        let result = 0
        if (page == cupons.data?.meta?.last_page) result = cupons.data?.meta?.total
        else result = page * perPage
        return result
    }

    return (
        <Body>
            <ModalAdd visible={visibleModalAdd} setVisibleModal={setVisibleModalAdd} />
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >

                <Header />
                <BoxAlert>
                    <AlertInfor>
                        <p>Para que os cupons criados no lily funcionem corretamente é necessário que o mesmo cupom exista na sua loja. (configure na sua plataforma, ex.: Nuvemshop, Shopify, Yampi...)</p>
                    </AlertInfor>
                </BoxAlert>
                <Contents>
                    <Container>
                        <Filters>
                            <Search >
                                <input
                                    type={"text"}
                                    placeholder={"Pesquisar..."}
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value) }} />
                                <span onClick={() => { setPage(1); goSearch() }}>
                                    <AiOutlineSearch size={25} color={"gray"} />
                                </span>
                            </Search>
                            <ButtonAdd onClick={() => { setVisibleModalAdd(true) }}>
                                <label>
                                    Adicionar
                                </label>
                                {/* <span>
                                    <IoIosAddCircle size={20} color={"white"} />
                                </span> */}
                            </ButtonAdd>
                            {/* <select value={statusQuestion} onChange={(e) => { setStatusQuestion(e.target.value) }} >
                                <option value="all" >Todos</option>
                                <option value="" >Respondidos e Pendentes</option>
                                <option value="answered" >Respondidos</option>
                                <option value="pending" >Pendentes</option>
                                <option value="hidden" >Escondidos</option>
                            </select> */}
                            {/* <button onClick={() => { goSearch() }}>Filtrar</button> */}
                        </Filters>
                        {
                            cupons.loading ?
                                <BoxLoading >
                                    <span>
                                        <AiOutlineLoading size={30} color={"#20d489"} />
                                    </span>
                                </BoxLoading> :
                                <>
                                    <Infor style={{ display: cupons.data?.length <= 0 ? "flex" : "none" }}>
                                        {
                                            cupons.data?.length <= 0 && <label>Nenhum cupom encontrado, <strong onClick={() => { setVisibleModalAdd(true) }}>clique aqui</strong>, para criar um novo cupom.</label>
                                        }

                                        {/* <button onClick={() => { }}>Mais opções
                                            {
                                                false &&
                                                <div>
                                                    <label>Aprovar todos os reviews</label>
                                                    <label>Esconder todos os reviews</label>
                                                    <label>Excluir todos os reviews</label>
                                                </div>
                                            }
                                        </button> */}


                                    </Infor>
                                    <ListCard>

                                        {
                                            cupons.data.data?.map((item) => {
                                                return <Card key={item.id} item={item} />
                                            })
                                        }
                                        {
                                            cupons.data.data?.length > 0 &&
                                            <Paginate>
                                                <PaginateNav>
                                                    <span onClick={() => {
                                                        if (page != 1) setPage(page - 1)
                                                    }}>
                                                        <MdKeyboardArrowLeft size={35} color={cupons.data.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                                    </span>
                                                    <label>{cupons.data?.meta?.current_page}</label>
                                                    <span onClick={() => {
                                                        if (page < cupons.data?.meta?.last_page) setPage(page + 1)
                                                    }}>
                                                        <MdKeyboardArrowRight size={35} color={cupons.data.meta?.last_page == page ? "gray" : "#19B674"} />
                                                    </span>
                                                </PaginateNav>
                                                <PaginateInfor>
                                                    <label>Total {countItens()} - {cupons.data?.meta?.total}</label>
                                                </PaginateInfor>
                                            </Paginate>
                                        }

                                    </ListCard>
                                </>
                        }
                    </Container>
                </Contents>
            </div>
            {isVisibleScript(false)}
        </Body >
    )
}

export default Home

import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    position: relative;
    min-width: 350px;
`;

export const Close = styled.div`
    display: flex;
    position: absolute;
    right: 0;
    margin-right: 5px;
    cursor: pointer;
    top: 5px;
`;

export const Title = styled.label`
    display: flex;
    color: #5e6278;
    //background-color: red;
`;
export const Infor = styled.label`
    display: flex;
    font-size: 12px;
    margin: 10px 0px 10px 0px;
`;

export const ButtonCustom = styled.button`
    display: flex;
    background-color: #F1416C ;
    outline: none;
    border: none;
    border-radius: 5px;
    margin-top: 25px;
    height: 35px;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    cursor: pointer;
    width: 100px;
    :hover{
        opacity: 0.7;
    }
`;
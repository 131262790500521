import { addDays } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { GrFormClose } from "react-icons/gr";
import { AiFillTag } from "react-icons/ai";

import { CheckCustom, Container, Error, Title, FormCustom, Close, ButtonCustom, InputCustomText, InputCustomDate, BoxInput, TextAreaCustom } from "./styled";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
    }
};



const ModalEdit = (props) => {

    const statusUpdateCupom = useSelector(state => state.reducerUpdateCupom)
    const dispatch = useDispatch()
    const [activeCupom, setActiveCupom] = useState(props.item.active == 0 ? false : true)

    useEffect(() => {
        if (!(statusUpdateCupom.loading == false && !statusUpdateCupom.error == false && statusUpdateCupom.data.length == 0)) {
            const id = "statusAddCupom"
            if (statusUpdateCupom.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusUpdateCupom.error) toast.update(id, { render: "Erro ao tentar salvar cupom.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: "Salvo com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    props.setVisibleModal(false)
                    formik.resetForm();
                }
            }
        }
    }, [statusUpdateCupom])


    const formik = useFormik({
        initialValues: { code: props.item.code, message: props.item.message, description: props.item.description, expiration_date: new Date(props.item.expiration_date).toISOString().slice(0, 10) },
        validationSchema: Yup.object({
            code: Yup.string().required("Preencher o campo cupom.").max(20, "Máximo de 10caracteres.").min(3, "Minimo de 3 caracteres."),
            message: Yup.string().required("Preencher o campo mensagem.").max(100, "Máximo de 100 caracteres.").min(3, "Minimo de 3 caracteres."),
            description: Yup.string().required("Preencher o campo descrição.").min(3, "Minimo de 3 caracteres.").max(100, "Máximo de 100 caracteres."),
            expiration_date: Yup.string().required("Preencher o campo data de validade."),
        }),
        onSubmit: (values) => {
            dispatch({ type: "SAGA_UPDATE_CUPOM", payload: { ...values, active: activeCupom, id: props.item.id } })
        },
    });

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { formik.resetForm(); props.setVisibleModal(false) }}
        >
            <Container onSubmit={formik.handleSubmit}>
                <Close onClick={() => { props.setVisibleModal(false) }}><GrFormClose size={25} color={"#5e6278"} /></Close>
                <Title><strong>Atualizar cupom</strong></Title>
                <FormCustom>
                    <BoxInput>
                        <label>Cupom:</label>
                        <InputCustomText>
                            <span>
                                <AiFillTag size={20} color={"gray"} />
                            </span>
                            <input type={"text"} placeholder={"Ex: OFF10"} name={"code"} value={formik.values.code} onChange={formik.handleChange} />
                        </InputCustomText>
                        {formik.errors.code && formik.touched.code && <Error>{formik.errors.code}</Error>}
                    </BoxInput>
                    <BoxInput>
                        <label>Expiram em:</label>
                        <InputCustomDate type={"date"} name={"expiration_date"} value={formik.values.expiration_date} onChange={formik.handleChange} />
                        {formik.errors.expiration_date && formik.touched.expiration_date && <Error>{formik.errors.expiration_date}</Error>}
                    </BoxInput>
                    <BoxInput>
                        <label>Descrição do cupom:</label>
                        <TextAreaCustom rows={3} placeholder={"Ex: 10% de desconto"} name={"description"} value={formik.values.description} onChange={formik.handleChange} />
                        {formik.errors.description && formik.touched.description && <Error>{formik.errors.description}</Error>}
                    </BoxInput>
                    <BoxInput>
                        <label>Mensagem:</label>
                        <TextAreaCustom rows={3} name={"message"} value={formik.values.message} onChange={formik.handleChange} />
                        {formik.errors.message && formik.touched.message && <Error>{formik.errors.message}</Error>}
                    </BoxInput>
                    <CheckCustom>
                        <input checked={activeCupom ? true : false} type="checkbox" id="ativo" name="status" value={activeCupom} onChange={(e) => { setActiveCupom(!activeCupom) }} />
                        <label for="ativo">Ativo</label>
                    </CheckCustom>
                </FormCustom>
                <ButtonCustom type="submit">Salvar</ButtonCustom>
            </Container>
        </Modal>
    )
}

export default ModalEdit

import InputMask from "react-input-mask";
import {useEffect} from 'react'
import { Container } from './styled'

const Input = ({isCnpj, value, label, placeholder, name, handleChange, mask}) => {

    useEffect(() => {
        if(isCnpj) {
            isCnpj(value.length < 15? false : true)
        }
    }, [value, isCnpj])

    const mascara = mask === 'doc' ? (value.length < 15 ? "999.999.999-999" : "99.999.999/9999-99"): mask

    return(
        <Container className={label == 'CEP' ? 'cep-input' : ''}>
            <label>{label}</label>
            <InputMask value={value} onChange={handleChange} 
            name={name}
            placeholder={placeholder} 
            mask={mascara}
            maskChar=""
            />
        </Container>
    );
}

export default Input
import styled from "styled-components";

export const Container = styled.label`
  display: flex;
  height: 20px;
  width: 100%;
  color: purple;
  margin-top: -20px;
  font-size: 12px;
  color: red;
  margin-left: 10px;
`;
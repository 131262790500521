import { FormCustom, InputCustom, ButtonSave, ErrorMessage, ButtonDelete } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import * as Yup from 'yup';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { displayScript, isVisibleScript } from "../../../../utils/displayScript";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Confirmation from "../../../../components/Modal-Confirmation";
import { useState } from "react";

const Whatsapp = (props) => (<InputMask {...props} mask="(99) 9.9999-9999" maskChar="" />)

const Home = () => {

    const dispatch = useDispatch()
    const statusUpdateAccount = useSelector(state => state.reducerUpdateAccount)

    const statusDeleteAccount = useSelector(state => state.reducerDeleteAccount)
    const history = useHistory()
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)

    useEffect(() => {
        const id = "statusUpdateAccount"
        if (!(statusUpdateAccount.loading == false && !statusUpdateAccount.error == false && statusUpdateAccount.data.length == 0)) {
            if (statusUpdateAccount.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusUpdateAccount.error) toast.update(id, { render: "Erro ao tentar atualizar os dados.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: "Salvo com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    formik.resetForm();
                }
            }
        } else if (statusUpdateAccount.error) toast.update(id, { render: "Erro ao tentar atualizar os dados.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    }, [statusUpdateAccount])

    useEffect(() => {
        if (!statusDeleteAccount.loading && !statusDeleteAccount.error && statusDeleteAccount.data.length == 0) {
        } else {
            const id = "statusDeleteAccount"
            if (statusDeleteAccount.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusDeleteAccount.error) toast.update(id, { render: "Erro ao tentar excluir os dados.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: "Excluído com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    history.push("/login")
                }
            }
        }
    }, [statusDeleteAccount])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { name: JSON.parse(localStorage.getItem("depoimentos@login"))?.user.name, email: JSON.parse(localStorage.getItem("depoimentos@login"))?.user.email, whatsapp: JSON.parse(localStorage.getItem("depoimentos@login"))?.user.whatsapp, password: '', confirmationPassword: '' },
        validationSchema: Yup.object({
            name: Yup.string().required("Preencher o campo nome.").max(200, "Máximo de 200 caracteres.").min(3, "Minimo de 8 caracteres."),
            email: Yup.string().required("Preencher o campo E-mail.").email("Preencha um e-mail valido.").max(200, "Máximo de 200 caracteres.").min(3, "Minimo de 8 caracteres."),
            whatsapp: Yup.string().required("Preencher o campo whatsapp.").max(16, "Whatsapp invalido2."),
            password: Yup.string().min(8, "Minimo de 8 caracteres.").max(200, "Maximo de 200 caracteres."),
            confirmationPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas não correspondem.'),
        }),
        onSubmit: (values) => {
            delete values.confirmationPassword
            if (values.password === "") delete values.password
            dispatch({ type: "SAGA_UPDATE_ACCOUNT", payload: { ...values, whatsapp: values.whatsapp.replace(/[^\d]+/g, '') } })
        },
    });

    const deleteAccount = () => {
        dispatch({
            type: "SAGA_DELETE_ACCOUNT",
            payload: {
                active: false
            }
        })
    }

    return (
        <Body>
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >
                <Header />
                <Contents>
                    <Confirmation
                        confirmation={deleteAccount}
                        visible={visibleModalConfirmation}
                        setVisibleModal={setVisibleModalConfirmation}
                        title={"Deseja escluir essa conta?"}
                        infor={"Obs: Ao excluir essa conta não será possivel recuperar o mesmo."}
                    />
                    <FormCustom onSubmit={formik.handleSubmit}>
                        <InputCustom>
                            <label>Nome</label>
                            <input type={"text"} name={"name"} value={formik.values.name} onChange={formik.handleChange} />
                        </InputCustom>
                        {formik.errors.name && <ErrorMessage>{formik.errors.name}</ErrorMessage>}

                        <InputCustom>
                            <label>Email</label>
                            <input type={"text"} name={"email"} value={formik.values.email} onChange={formik.handleChange} />
                        </InputCustom>
                        {formik.errors.email && <ErrorMessage>{formik.errors.email}</ErrorMessage>}

                        <InputCustom>
                            <label>Whatsapp</label>
                            <Whatsapp type={"text"} placeholder={"(00) 0.0000-0000"} name={"whatsapp"} value={formik.values.whatsapp} onChange={formik.handleChange} />
                        </InputCustom>
                        {formik.errors.whatsapp && <ErrorMessage>{formik.errors.whatsapp}</ErrorMessage>}

                        <InputCustom>
                            <label>Nova Senha</label>
                            <input type={"password"} placeholder={"Digite aqui sua nova senha."} name={"password"} value={formik.values.password} onChange={formik.handleChange} />
                        </InputCustom>
                        {formik.errors.password && formik.touched.password && <ErrorMessage>{formik.errors.password}</ErrorMessage>}

                        <InputCustom>
                            <label>Confirmar Nova Senha</label>
                            <input type={"password"} placeholder={"Confirme sua nova senha."} name={"confirmationPassword"} value={formik.values.confirmationPassword} onChange={formik.handleChange} />
                        </InputCustom>
                        {formik.errors.confirmationPassword && formik.touched.confirmationPassword && <ErrorMessage>{formik.errors.confirmationPassword}</ErrorMessage>}

                        <ButtonSave type="submit">Salvar</ButtonSave>

                        <label style={{ marginTop: "50px", marginBottom: "10px" }}><strong>Excluir conta:</strong> Antes de realizar essa operação, lembre-se que essa operação é irreversível e você perderá todos os dados de sua conta.</label>
                        <ButtonDelete type="button" onClick={() => setVisibleModalConfirmation(true)} >Excluir Conta</ButtonDelete>
                    </FormCustom>
                </Contents>
            </div>
            {isVisibleScript(false)}
        </Body >
    )
}

export default Home

import styled from 'styled-components'

export const Container = styled.div`
    padding: 30px;
    padding-top: 0;
    background: white;
    margin-top: 30px;
    border-radius: 10px;
    /* min-height: 150px;
    min-height: -webkit-fill-available; */
    overflow-x: auto;
    table {
        background: white;
        width: 100%;
        border-collapse: collapse;
    }

    th {
        font-weight: 500;
    }

    td, th {
        padding: 12px;
        text-align: left;
        font-size: 14px;
        color: ${props => props.theme.colors.font.default};
    }

    tr {
        border-bottom: 1px solid #eee;
    }

    h3 {
        padding: 30px 0 18px 0;
    }
`
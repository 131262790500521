import { Container, ItemMenu, SectionMenu, Logo, SectionLogo, TooltipItem, Tooltip } from "./styled";
import { RiChatPollFill, RiDashboardFill } from "react-icons/ri";
import { AiOutlineShoppingCart } from "react-icons/ai";
import {
  RiQuestionnaireFill,
  RiDashboard2Line,
  RiAccountBoxFill,
  RiKeyFill,
} from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { TbPuzzle } from "react-icons/tb";
import { CgBox } from "react-icons/cg";
import { BiBox } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useBurgerMenu } from "../../hooks/useBurgerMenu";
import { TiFolder } from "react-icons/ti";
import { IoStorefrontSharp, IoFootstepsOutline } from "react-icons/io5";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { useSelector } from "react-redux";
import { BsPiggyBank } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import logo from "../../assets/imgs/logo01.png";
import animation from "../../assets/animation/dijital.webm";
import { translations } from "../../utils/language";
import { useDispatch } from "react-redux";

import IconDashboard from "../../assets/imgs/icon-dashboard.svg";
import IconProdutos from "../../assets/imgs/icon-produtos.svg";
import IconMedias from "../../assets/imgs/icon-medias.svg";
import IconPedidos from "../../assets/imgs/icon-pedidos.svg";
import IconPersonalizar from "../../assets/imgs/icon-personalizar.svg";
import IconIntegracoes from "../../assets/imgs/icon-integracoes.svg";
import IconAssinatura from "../../assets/imgs/assinatura-inativo.svg";
import IconPasso from "../../assets/imgs/icon-passo.svg";
import IconAjuda from "../../assets/imgs/ajuda.svg";




const SideBar = () => {
  const { burgerMenu, setBurgerMenu } = useBurgerMenu(false);
  const [isPersonOpen, setIsPersonOpen] = useState(false);
  const language = useSelector((state) => state.reducerLanguage);
  const icon = { size: 30, color: "rgba(0, 0, 0, 0.5)" };
  const listNotify = useSelector((state) => state.reducerListNotify);
  const selectedMenu = useLocation();
  const history = useHistory();

  const dispatch = useDispatch()

  const controlConfig = useSelector((state) => state.reducerControlConfig);
  const config = useSelector((state) => state.reducerTemplate);




  // useEffect(() => {
  //   function handleResize() {
  //     window.innerWidth <= 900 ? setBurgerMenu(true) : setBurgerMenu(false);
  //   }

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };

  // }, []);

  function handleClick(path) {
    history.push(path);
  }

  // useEffect(() => {
  //   function handleClickOutside(e) {
  //     if (window.innerWidth > 900) return;
  //     let id = e.target?.id;
  //     if (id === "") {
  //       id = e.target?.parentElement?.id;
  //     }

  //     if (
  //       id !== "burger__id" &&
  //       !document.getElementById("container__sidemenu")?.contains(e.target)
  //     ) {
  //       setBurgerMenu(true);
  //     }
  //   }

  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [setBurgerMenu]);


  // useEffect(() => {
  //   const animation = document.getElementById("animation");
  //   animation.addEventListener(
  //     "mouseenter",
  //     function (e) {
  //       this.play();
  //     },
  //     false
  //   );


  //   function handleResize() {
  //     window.innerWidth <= 768 ? setBurgerMenu(true) : setBurgerMenu(false);
  //   }

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };

  // }, []);


  const isSave = () => {

    if (selectedMenu.pathname == "/personalizar" || selectedMenu.pathname == "/personalizar/email" || selectedMenu.pathname == "/personalizar/checkout") {
      if (
        config.data[0].color_checkout_background_header == controlConfig.color_checkout_background_header &&
        config.data[0].color_checkout_text_header == controlConfig.color_checkout_text_header &&
        config.data[0].color_checkout_background == controlConfig.color_checkout_background &&
        config.data[0].color_checkout_text_title == controlConfig.color_checkout_text_title &&
        config.data[0].color_checkout_background_product == controlConfig.color_checkout_background_product &&
        config.data[0].color_checkout_text_product == controlConfig.color_checkout_text_product &&
        config.data[0].color_button_checkout == controlConfig.color_button_checkout &&
        config.data[0].color_message_button_checkout == controlConfig.color_message_button_checkout &&

        config.data[0].checkout_message_header == controlConfig.checkout_message_header &&
        config.data[0].checkout_message_title == controlConfig.checkout_message_title &&
        config.data[0].checkout_message == controlConfig.checkout_message &&
        config.data[0].checkout_button == controlConfig.checkout_button &&
        config.data[0].checkout_button_link == controlConfig.checkout_button_link &&

        config?.data[0]?.color_email_background == controlConfig.color_email_background &&
        config?.data[0]?.color_title_email == controlConfig.color_title_email &&
        config?.data[0]?.color_button == controlConfig.color_button &&
        config?.data[0]?.color_message_button == controlConfig.color_message_button &&

        config?.data[0]?.subject_email == controlConfig.subject_email &&
        config?.data[0]?.message_email == controlConfig.message_email &&
        config?.data[0]?.message_button == controlConfig.message_button
      ) {
        dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_SET", payload: true })
        dispatch({ type: "CONFIG_CONTROL_SAVE_CHECKOUT_SET", payload: true })
        return true
      } else {
        dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_SET", payload: false })
        dispatch({ type: "CONFIG_CONTROL_SAVE_CHECKOUT_SET", payload: false })
        return false
      }
    } else {
      return true
    }
  }


 const sidebarRef = useRef(null);

  useEffect(() => {

  

    const pageClickEvent = (e) => {

      if (sidebarRef.current !== null && !sidebarRef.current.contains(e.target)) {
     
        if (window.innerWidth < 900) {
          setBurgerMenu(false);
        }

      }
    };
    if (burgerMenu) {
      window.addEventListener('mouseup', pageClickEvent);
    }
    return () => {
      window.removeEventListener('mouseup', pageClickEvent);
    }
  }, [burgerMenu]);


  

  return (
    <Container ref={sidebarRef} isOpen={burgerMenu}>
      {/* <label onClick={() => { Teste() }}>teste</label> */}
      <SectionLogo isOpen={burgerMenu}>
        <img style={{ cursor: "pointer" }} src={logo} onClick={() => { isSave() && history.push("/") }} />
        {/* <span id="box-animation">
          <video
            style={{ cursor: "pointer" }}
            id="animation"
            width="75"
            muted
            onClick={() => handleClick("/")}
          >
            <source src={animation} type="video/webm" />
          </video>
        </span> */}
      </SectionLogo>
      <SectionMenu>
        <ItemMenu isOpen={burgerMenu}
          onClick={() => { isSave() && handleClick("/"); }}
          selectedMenu={selectedMenu.pathname === "/" ? true : false}
        //style={burgerMenu ? { justifyContent: "center" } : { justifyContent: "left" }}  
        >
          {/* <RiDashboard2Line size={icon.size} color={icon.color} style={{ marginLeft: " 10 " }} /> */}
          <img src={IconDashboard} />
          <label style={!burgerMenu ? { display: "none" } : { display: "flex" }}>
            Dashboard
          </label>

          <Tooltip>
            Dashboard
          </Tooltip>
        </ItemMenu>

        <ItemMenu isOpen={burgerMenu}
          onClick={() => {
            isSave() && handleClick("/produtos");
          }}
          selectedMenu={selectedMenu.pathname === "/produtos" ? true : false}

        >
          {/* <BiBox
            size={icon.size}
            color={icon.color}
            style={{ marginLeft: " 10 " }}
          /> */}
          <img src={IconProdutos} />
          <label style={!burgerMenu ? { display: "none" } : { display: "flex" }}>
            {translations["Produtos"][language.data]}
          </label>
          <Tooltip>
            {translations["Produtos"][language.data]}
          </Tooltip>
        </ItemMenu>

        <ItemMenu isOpen={burgerMenu}
          onClick={() => {
            isSave() && handleClick("/midias");
          }}
          selectedMenu={selectedMenu.pathname === "/midias" ? true : false}

        >
          {/* <TiFolder
            size={icon.size}
            color={icon.color}
            style={{ marginLeft: " 10 " }}
          /> */}
          <img src={IconMedias} />
          <label style={!burgerMenu ? { display: "none" } : { display: "flex" }}>
            {translations["Mídias"][language.data]}
          </label>
          <Tooltip>
            {translations["Mídias"][language.data]}
          </Tooltip>
        </ItemMenu>

        <ItemMenu isOpen={burgerMenu}
          onClick={() => {
            isSave() && handleClick("/pedidos");
          }}
          selectedMenu={selectedMenu.pathname === "/pedidos" ? true : false}

        >
          <img src={IconPedidos} />
          <label style={!burgerMenu ? { display: "none" } : { display: "flex" }}>
            {translations["Pedidos"][language.data]}
          </label>
          <Tooltip>
            {translations["Pedidos"][language.data]}
          </Tooltip>
        </ItemMenu>

        <ItemMenu isOpen={burgerMenu} onClick={() => { isSave() && handleClick("/personalizar"); }}
          selectedMenu={selectedMenu.pathname === "/personalizar" || selectedMenu.pathname === "/personalizar/email" || selectedMenu.pathname === "/personalizar/checkout" ? true : false}
        >
          <img src={IconPersonalizar} />
          <label style={!burgerMenu ? { display: "none" } : { display: "flex" }}>
            {translations["Personalizar"][language.data]}
          </label>

          <Tooltip>
            {translations["Personalizar"][language.data]}
          </Tooltip>
        </ItemMenu>
        {/* <ItemMenu
          onClick={() => {
            setIsPersonOpen((prevState) => !prevState);
            // handleClick("/personalizar");
          }}
          selectedMenu={
            selectedMenu.pathname === "/personalizar" ? true : false
          }
          style={
            burgerMenu
              ? { justifyContent: "center" }
              : { justifyContent: "left" }
          }
        >
          <HiOutlinePencilAlt
            size={icon.size}
            color={icon.color}
            style={{ marginLeft: " 10 " }}
          />
          <label
            style={
              !burgerMenu
                ? { display: "none" }
                : { display: "flex", alignItems: "center" }
            }
          >
            {translations["Personalizar"][language.data]}
            {isPersonOpen ? (
              <SlArrowUp
                size={12}
                color={icon.color}
                style={{ marginLeft: 10 }}
              />
            ) : (
              <SlArrowDown
                size={12}
                color={icon.color}
                style={{ marginLeft: 10 }}
              />
            )}
          </label>
        </ItemMenu> */}
        {/* {isPersonOpen && (
          <>
            <ItemMenu
              onClick={() => {
                handleClick("/personalizar/email");
              }}
              selectedMenu={
                selectedMenu.pathname === "/personalizar/email" ? true : false
              }
              style={
                burgerMenu
                  ? { justifyContent: "center" }
                  : { justifyContent: "left" }
              }
            >
              <HiOutlinePencilAlt
                size={icon.size}
                color={"#fff"}
                style={!burgerMenu && { marginLeft: 10 }}
              />
              <label
                style={
                  !burgerMenu
                    ? { display: "none" }
                    : {
                      display: "flex",
                      color:
                        selectedMenu.pathname === "/personalizar/email"
                          ? "#1b76ff"
                          : "black",
                    }
                }
              >
                Email
              </label>
            </ItemMenu>
            <ItemMenu
              onClick={() => {
                handleClick("/personalizar/checkout");
              }}
              selectedMenu={
                selectedMenu.pathname === "/personalizar/checkout"
                  ? true
                  : false
              }
              style={
                burgerMenu
                  ? { justifyContent: "center" }
                  : { justifyContent: "left" }
              }
            >
              <HiOutlinePencilAlt
                size={icon.size}
                color={"#fff"}
                style={!burgerMenu && { marginLeft: 10 }}
              />
              <label
                style={!burgerMenu ? { display: "none" } : { display: "flex", color: selectedMenu.pathname === "/personalizar/checkout" ? "#1b76ff" : "black", }
                }
              >
                Checkout
              </label>
            </ItemMenu>
          </>
        )} */}
        <ItemMenu isOpen={burgerMenu}
          onClick={() => {
            isSave() && handleClick("/integracoes");
          }}
          selectedMenu={selectedMenu.pathname === "/integracoes" ? true : false}

        >
          <img src={IconIntegracoes} />
          <label style={!burgerMenu ? { display: "none" } : { display: "flex" }}>
            {translations["Integrações"][language.data]}
          </label>

          <Tooltip>
            {translations["Integrações"][language.data]}
          </Tooltip>
        </ItemMenu>
        <ItemMenu isOpen={burgerMenu}
          onClick={() => {
            isSave() && handleClick("/pagamentos");
          }}
          selectedMenu={selectedMenu.pathname === "/pagamentos" ? true : false}

        >
          <img src={IconAssinatura} />
          <label style={!burgerMenu ? { display: "none" } : { display: "flex" }}>
            {translations["Pagamentos"][language.data]}
          </label>

          <Tooltip>
            {translations["Pagamentos"][language.data]}
          </Tooltip>
        </ItemMenu>
        <ItemMenu isOpen={burgerMenu}
          onClick={() => {
            isSave() && handleClick("/passo-a-passo");
          }}
          selectedMenu={
            selectedMenu.pathname === "/passo-a-passo" ? true : false
          }

        >
          <img src={IconPasso} />
          <label style={!burgerMenu ? { display: "none" } : { display: "flex" }}>
            {translations["Passo a passo"][language.data]}
          </label>

          <Tooltip>
            {translations["Passo a passo"][language.data]}
          </Tooltip>
        </ItemMenu>

        {/* {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" &&
                    <ItemMenu onClick={() => { handleClick("/produtos") }} selectedMenu={selectedMenu.pathname === "/produtos" ? true : false}>
                        <FaBoxOpen size={icon.size} color={icon.color} />
                        <label>Produtos</label>
                    </ItemMenu>} */}

        {/* <ItemMenu
          onClick={() => {
            handleClick("/configuracoes");
          }}
          selectedMenu={
            selectedMenu.pathname === "/configuracoes" ? true : false
          }
          style={
            burgerMenu
              ? { justifyContent: "center" }
              : { justifyContent: "left" }
          }
        >
          <FiSettings
            size={icon.size}
            color={icon.color}
            style={{ marginLeft: " 10 " }}
          />
          <label style={!burgerMenu ? { display: "none" } : { display: "flex" }}>
            {translations["Configurações"][language.data]}
          </label>
        </ItemMenu> */}

        <ItemMenu isOpen={burgerMenu}
          onClick={() => {
            isSave() && handleClick("/ajuda");
          }}

          selectedMenu={selectedMenu.pathname === "/ajuda" ? true : false}
        >
          <img src={IconAjuda} />
          <label style={!burgerMenu ? { display: "none" } : { display: "flex" }}>
            {translations["Ajuda"][language.data]}
          </label>

          <Tooltip>
            {translations["Ajuda"][language.data]}
          </Tooltip>
        </ItemMenu>

        {/* {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration
          ?.url != null && (
          <ItemMenu
            onClick={() => {
              if (
                JSON.parse(localStorage.getItem("depoimentos@login"))
                  .integration?.url != null
              )
                window.open(
                  `https://${
                    JSON.parse(localStorage.getItem("depoimentos@login"))
                      .integration.url
                  }`
                );
            }}
            style={
              burgerMenu
                ? { justifyContent: "center" }
                : { justifyContent: "left" }
            }
          >
            <IoStorefrontSharp
              size={icon.size}
              color={icon.color}
              style={!burgerMenu && { marginLeft: 10 }}
            />
            <abbr
              style={
                burgerMenu
                  ? { display: "none" }
                  : { display: "flex", textDecoration: "none" }
              }
              title="Ir para sua loja"
            >
              <label>Minha Loja</label>
            </abbr>
          </ItemMenu>
        )} */}
      </SectionMenu>
      <SectionMenu>
        {/* <TitleMenu>CONFIGURAÇÕES</TitleMenu>
        <ItemMenu
          onClick={() => {
            handleClick("/conta");
          }}
          selectedMenu={selectedMenu.pathname === "/conta" ? true : false}
        >
          <RiAccountBoxFill size={icon.size} color={icon.color} />
          <label>Minha Conta</label>
        </ItemMenu>

        <ItemMenu
          onClick={() => {
            handleClick("/integracoes");
          }}
          selectedMenu={selectedMenu.pathname === "/integracoes" ? true : false}
        >
          <FaLayerGroup size={icon.size} color={icon.color} />
          <label>Integrações</label>
        </ItemMenu> */}
        {/* <ItemMenu onClick={() => { handleClick("/personalizar") }} selectedMenu={selectedMenu.pathname === "/personalizar" ? true : false} >
                     <FcDataConfiguration size={icon.size} color={icon.color} /> 
                    <BsFillGearFill size={icon.size} color={icon.color} />
                    <label>Personalizar</label>
                </ItemMenu> */}
        {/* <ItemMenu
          onClick={() => {
            handleClick("/pagamentos");
          }}
          selectedMenu={selectedMenu.pathname === "/pagamentos" ? true : false}
        >
          <FaPiggyBank size={icon.size} color={icon.color} />
          <label>Assinatura</label>
        </ItemMenu> */}
        {/* <ItemMenu
          onClick={() => {
            localStorage.removeItem("depoimentos@login");
            handleClick("/login");
          }}
        >
          <AiOutlineLogout size={icon.size} color={icon.color} />
          <label>Sair</label>
        </ItemMenu> */}
      </SectionMenu>
    </Container >
  );
};

export default SideBar;

import Page from "../../components/Page";
import { AiFillMail } from "react-icons/ai";
import InputCustom from "../../components/InputCustom";
import { FormRegister, Actions } from "./styled";
import { FaLock, FaUserAlt } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import Btn from "../../components/Btn";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../components/ErrorInput";
import InputMask from "react-input-mask";
import { useEffect } from "react";
import { useState } from "react";
import {
  displayScript,
  isVisibleScript,
} from "../../../../utils/displayScript";
import { translations } from "../../../../utils/language";

const Whatsapp = (props) => (
  <InputMask {...props} mask="(99) 9.9999-9999" maskChar="" />
);

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.reducerLanguage);

  const statusRegister = useSelector((state) => state.reducerRegister);

  const [id, setId] = useState(null);
  const [country, setCountry] = useState();

  useEffect(() => {
    if (
      !(
        statusRegister.loading === false &&
        statusRegister.error === false &&
        statusRegister.data.length === 0
      )
    ) {
      if (statusRegister.loading === true) {
        setId(toast.loading("Aguarde...", { toastId: 0 }));
      } else {
        if (statusRegister.error === true) {

          toast.update(id, {
            render: statusRegister.data.description,
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        } else if (statusRegister.error === false) {
          toast.update(id, {
            render: "Sua conta foi criado com sucesso.",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
          formik.resetForm();
          history.push("/");
        }
      }
    }
  }, [statusRegister]);

  useEffect(() => {
    if (localStorage.getItem("depoimentos@login")) {
      history.push("/");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      whatsapp: "",
      password: "",
      confirmationPassword: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Preencher o campo nome.")
        .max(200, "Máximo de 200 caracteres.")
        .min(3, "Minimo de 8 caracteres."),
      email: Yup.string()
        .required("Preencher o campo E-mail.")
        .email("Preencha um e-mail valido.")
        .max(200, "Máximo de 200 caracteres.")
        .min(3, "Minimo de 8 caracteres."),
      whatsapp: Yup.string()
        .required("Preencher o campo whatsapp.")
        .min(16, "Whatsapp invalido1.")
        .max(16, "Whatsapp invalido2."),
      password: Yup.string()
        .required("Preencher o campo Senha.")
        .min(8, "Minimo de 8 caracteres.")
        .max(200, "Maximo de 200 caracteres."),
      confirmationPassword: Yup.string()
        .required("Confirmar a senha.")
        .oneOf([Yup.ref("password"), null], "Senhas não correspondem."),
      // country: country ? country : "BR",
    }),
    onSubmit: async (values) => {
      values.country = localStorage.getItem("country")
      dispatch({
        type: "SAGA_REGISTER_LOGIN",
        payload: {
          ...values,
          whatsapp: values.whatsapp.replace(/[^\d]+/g, ""),
        },
      });
    },
  });

  return (
    <Page>
      <FormRegister onSubmit={formik.handleSubmit}>
        <InputCustom>
          <span>
            <FaUserAlt size={20} color={"gray"} />
          </span>
          <input
            name={"name"}
            value={formik.values.name}
            onChange={formik.handleChange}
            type={"text"}
            placeholder={translations["Nome"][language.data]}
          />
        </InputCustom>
        {formik.errors.name && formik.touched.name && (
          <ErrorMessage>{formik.errors.name}</ErrorMessage>
        )}

        <InputCustom>
          <span>
            <AiFillMail size={20} color={"gray"} />
          </span>
          <input
            name={"email"}
            value={formik.values.email}
            onChange={formik.handleChange}
            type={"text"}
            placeholder={"E-mail"}
          />
        </InputCustom>
        {formik.errors.email && formik.touched.email && (
          <ErrorMessage>{formik.errors.email}</ErrorMessage>
        )}

        <InputCustom>
          <span>
            <RiWhatsappFill size={20} color={"gray"} />
          </span>
          <Whatsapp
            key="editor1"
            name="whatsapp"
            value={formik.values.whatsapp}
            onChange={formik.handleChange}
            id="whatsapp"
            type="text"
            placeholder="Whatsapp"
          />
        </InputCustom>
        {formik.errors.whatsapp && formik.touched.whatsapp && (
          <ErrorMessage>{formik.errors.whatsapp}</ErrorMessage>
        )}

        <InputCustom>
          <span>
            <FaLock size={20} color={"gray"} />
          </span>
          <input
            name={"password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            type={"password"}
            placeholder={translations["Senha"][language.data]}
          />
        </InputCustom>
        {formik.errors.password && formik.touched.password && (
          <ErrorMessage>{formik.errors.password}</ErrorMessage>
        )}

        <InputCustom>
          <span>
            <FaLock size={20} color={"gray"} />
          </span>
          <input
            name={"confirmationPassword"}
            value={formik.values.confirmationPassword}
            onChange={formik.handleChange}
            type={"password"}
            placeholder={translations["Confirmar senha"][language.data]}
          />
        </InputCustom>
        {formik.errors.confirmationPassword &&
          formik.touched.confirmationPassword && (
            <ErrorMessage>{formik.errors.confirmationPassword}</ErrorMessage>
          )}

        <Actions>
          <Btn
            type="submit"
            label={translations["Experimente grátis"][language.data]}
            onClick={() => {
              if (formik.dirty === false)
                toast.warning("Preencha os dados.", { toastId: 0 });
            }}
          />
          <label
            onClick={() => {
              history.push("/login");
            }}
          >
            {translations["Já possui uma conta?"][language.data]}
          </label>
        </Actions>
      </FormRegister>
      {isVisibleScript(false)}
    </Page>
  );
};

export default Register;

const INITIAL_STATE = {
    error: false,
    loading: false,
    data: []
}

const Cupons = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CUPONS_REQUEST':
            return { loading: true, error: false, data: [] }
        case 'CUPONS_ERROR':
            return { loading: false, error: true, data: [] };
        case 'CUPONS_SUCCESS':
            return { loading: false, error: false, data: action.payload };
        case 'CUPONS_RESET':
            return { loading: false, error: false, data: [] }; 
        default:
            return state;
    }
}

export default Cupons
import { Container, Search, SubTitle, Title } from "./styled";
import { VscSearch } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { translations } from "../../../../utils/language";

const Help = () => {
  const [queryHelp, setQueryHelp] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.reducerLanguage);

  //const language = {data: "pt"}

  const actionHelp = () => {
    dispatch({ type: "SET_QUERY_HELP", payload: queryHelp });
    history.push("/ajuda");
  };

  return (
    <Container large={!JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.cred_expire == 0}>
      <Title>
        {translations["Pesquisar Dúvidas Frequentes"][language.data]}
      </Title>
      <SubTitle>
        {translations["Está procurando por algo na plataforma, use nosso campo de busca"][language.data]}
      </SubTitle>
      <Search>
        <input
          placeholder={`${translations["Pesquisar"][language.data]} ...`}
          type={"text"}
          value={queryHelp}
          onChange={(e) => { setQueryHelp(e.target.value); }}
        />
        <button onClick={() => { actionHelp(); }}>
          <VscSearch size={15} color={"#fff"} />
        </button>
      </Search>
      {/* <Title>Outros canais</Title> */}
      <div></div>
    </Container>
  );
};

export default Help;

const INITIAL_STATE = {
  error: null,
  loading: null,
  requestCompleted: null
};

const SearchPlatformAllItemsProducts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_REQUEST":
      return { loading: true, error: false, requestCompleted: null };
    case "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_UPDATE":
      return { loading: true, error: false, requestCompleted: action.payload };
    case "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_ERROR":
      return { loading: false, error: true };
    case "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_SUCCESS":
      return { loading: false, error: false, requestCompleted: action.payload };
    case "SEARCH_PLATFORM_ALL_ITEMS_PRODUCTS_RESET":
      return { loading: null, error: null, requestCompleted: null };
    default:
      return state;
  }
};

export default SearchPlatformAllItemsProducts;

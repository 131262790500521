import styled from 'styled-components'

export const Button = styled.button`
    height: 50px; 
    width: ${props => props.width}; 
    outline: none; 
    border: none; 
    background-color: ${props => props.background}; 
    border-radius: 5px; 
    color: ${props => props.color}; 
    cursor: pointer; 
    margin-top: ${props => props.marginTop} !important;
    margin: ${props => props.center};
    font-weight: 600;
    padding: 10px;
    transition: 200ms ease;
    border: ${props => props.border && `1px solid ${props.border}`};
    cursor: pointer;
    
    &:hover {
        filter: brightness(0.9);
    }

    @media(max-width: 450px) {
        width: 100%;
    }
`
import {
  Container,
  SubTitle,
  Title,
  ContainerIcon,
  ContainerLine,
  Block,
} from "./styled";
import { BiBox } from "react-icons/bi";
import { TiFolder } from "react-icons/ti";
import { GoFileMedia } from "react-icons/go";
import { FiDownload } from "react-icons/fi";
import { BsTrophy, BsPersonPlus, BsThreeDotsVertical } from "react-icons/bs";
import { HiCursorClick } from "react-icons/hi";
import { useSelector } from "react-redux";
import { translations } from "../../../../utils/language";

const CardStatistic = ({ type, data }) => {
  const language = useSelector((state) => state.reducerLanguage);
  //const language = {data: "pt"}

  return (
    // <Container first={type === "total"} last={type === "space"}>
    <Block isRight={type === "space" || type === "products"}>
      <ContainerLine>
        <ContainerIcon>
          {type === "total" && <FiDownload size={32} color="#000" />}
          {type === "products" && <HiCursorClick size={32} color="#000" />}
          {type === "record" && <TiFolder size={32} color="#000" />}
          {type === "space" && <BiBox size={32} color="#000" />}
        </ContainerIcon>
      </ContainerLine>
      <ContainerLine>
        <Title>{data}</Title>
      </ContainerLine>
      <ContainerLine>
        {type === "total" && (
          <SubTitle>
            {translations["Total de"][language.data]} <br />
            {translations["downloads"][language.data]}
          </SubTitle>
        )}
        {type === "products" && (
          <SubTitle>
            {translations["Total de"][language.data]} <br />
            {translations["cliques"][language.data]}
          </SubTitle>
        )}
        {type === "record" && (
          <SubTitle>
            {translations["Mídias"][language.data]} <br />
            {translations["cadastradas"][language.data]}
          </SubTitle>
        )}
        {type === "space" && (
          <SubTitle>
            {translations["Produtos"][language.data]} <br />
            {translations["cadastrados"][language.data]}
          </SubTitle>
        )}
      </ContainerLine>
    </Block>
    // </Container>
  );
};

export default CardStatistic;

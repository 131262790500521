//prettier-ignore
export const translations = {

  "alert-botao": {
    pt: "Ver como funciona",
    es: "Ver cómo funciona",
  },

  "alert-duvida": {
    pt: "Tem dúvidas?",
    es: "¿Tienes dudas?",
  },

  "alert-ajuda": {
    pt: "Veja como funciona o menu ajuda.",
    es: "Vea cómo funciona el menú ayuda",
  },

  "alert-dashboard": {
    pt: "Entenda como o Dijital pode trazer valor aos seus produtos.",
    es: "Mira cómo Dijital puede agregar valor a tus productos.",
  },
  "alert-produtos": {
    pt: "Vincule arquivos aos produtos disponíveis na sua loja.",
    es: "Vincule archivos a los productos disponibles en su tienda.",
  },
  "alert-midias": {
    pt: "Saiba quais arquivos estão disponíveis no seu Dijital e o que você pode fazer com eles.",
    es: "Descubre qué archivos están disponibles en tu Dijital y qué puedes hacer con ellos.",
  },
  "alert-pedidos": {
    pt: "Reenvie e-mails e gerencie seus pedidos.",
    es: "Gestiona tus pedidos y reenvia emails.",
  },
  "alert-personalizar": {
    pt: "Saiba como deixar o Dijital com a cara da sua marca.",
    es: "Descubre cómo personalizar Dijital y dejarlo con la imagen de tu marca.",
  },
  "alert-integracoes": {
    pt: "Veja como integrar o Dijital na sua plataforma.",
    es: "Descubre cómo integrar Dijital en tu plataforma.",
  },



  "Use o Dijital para adicionar mídias e links na sua loja virtual.": {
    pt: "Use o Dijital para adicionar arquivos e links na sua loja virtual.",
    es: "Use Dijital para agregar archivos digitales y enlaces en su tienda en línea.",
  },
  "Olá": {
    pt: "Olá",
    es: "Hola",
  },
  "Adicionar novo arquivo": {
    pt: "Adicionar novo arquivo",
    es: "Agregar nuevo archivos"
  },
  "Pesquisar Dúvidas Frequentes": {
    pt: "Pesquisar Dúvidas Frequentes",
    es: "Buscar Preguntas Frecuentes",
  },
  "Está procurando por algo na plataforma, use nosso campo de busca": {
    pt: "Está procurando por algo na plataforma, use nosso campo de busca",
    es: "¿Estás buscando algo en la plataforma?, utiliza nuestro campo de búsqueda",
  },
  "Total de": {
    pt: "Total de",
    es: "Total de",
  },
  "Total": {
    pt: "Total",
    es: "Total",
  },
  "downloads": {
    pt: "downloads",
    es: "downloads",
  },
  "cliques": {
    pt: "cliques",
    es: "clics",
  },
  "Mídias": {
    pt: "Mídias",
    es: "Archivos",
  },
  "Galeria": {
    pt: "Galeria",
    es: "Galeria",
  },
  "cadastradas": {
    pt: "cadastradas",
    es: "registrados",
  },
  "Produtos": {
    pt: "Produtos",
    es: "Productos",
  },
  "cadastrados": {
    pt: "cadastrados",
    es: "registrados",
  },
  "Registrar": {
    pt: "Registrar",
    es: "Registro",
  },
  "Últimos 7 dias": {
    pt: "Últimos 7 dias",
    es: "Últimos 7 días",
  },
  "Nenhuma mídia possui cilque ou download": {
    pt: "Nenhuma mídia possui cilque ou download",
    es: "Ningún archivo posee clics o downloads",
  },
  "Cliques no link": {
    pt: "Cliques no link",
    es: "Clics en el link",
  },
  "Buscar": {
    pt: "Buscar",
    es: "Buscar",
  },
  "Pesquisar": {
    pt: "Pesquisar",
    es: "Buscar",
  },
  "Selecione produtos e vincule arquivos digitais para começar a vender": {
    pt: "Selecione produtos e vincule arquivos digitais para começar a vender",
    es: "Selecciona productos y vincula archivos digitales para empezar a vender",
  },
  "Anexar arquivos": {
    pt: "Anexar arquivos",
    es: "Adjuntar archivos",
  },
  "Ver variantes": {
    pt: "Ver variantes",
    es: "Ver variantes",
  },
  "Ver tudo": {
    pt: "Ver tudo",
    es: "Ver todo",
  },
  "Personalizar": {
    pt: "Personalizar",
    es: "Personalizar",
  },
  "Personalizar checkout": {
    pt: "Personalizar checkout",
    es: "Personalizar checkout",
  },
  "Minhas mídias": {
    pt: "Meus arquivos",
    es: "Mis archivos",
  },
  "Confira suas mídias e anexos": {
    pt: "Confira suas mídias e anexos",
    es: "Verifique sus archivos multimedia y archivos adjuntos",
  },
  "Anexos": {
    pt: "Anexos",
    es: "Adjuntos",
  },
  "Extensão": {
    pt: "Extensão",
    es: "Extensión",
  },
  "Data": {
    pt: "Data",
    es: "Fecha",
  },
  "Ações": {
    pt: "Ações",
    es: "Acciones",
  },
  "Ver produtos": {
    pt: "Ver produtos",
    es: "Ver productos",
  },
  "Personalizar e-mail": {
    pt: "Personalizar e-mail",
    es: "Personalizar e-mail",
  },
  "Personalize o e-mail que será enviado ao seu cliente": {
    pt: "Personalize o e-mail que será enviado ao seu cliente",
    es: "Personalize el email que se enviará a su cliente",
  },
  "Assunto do e-mail": {
    pt: "Assunto do e-mail",
    es: "Asunto del email",
  },
  "Texto central": {
    pt: "Texto central",
    es: "Texto central",
  },
  "Texto do botão": {
    pt: "Texto do botão",
    es: "Texto del botón",
  },
  "Botão": {
    pt: "Botão",
    es: "Botón",
  },
  "Salvar alterações": {
    pt: "Salvar alterações",
    es: "Guardar cambios",
  },
  "Salvar": {
    pt: "Salvar",
    es: "Guardar",
  },
  "Fundo do botão": {
    pt: "Fundo do botão",
    es: "Fondo del botón",
  },
  "Fundo": {
    pt: "Fundo",
    es: "Fondo",
  },
  "Personalize sua tela de checkout": {
    pt: "Personalize sua tela de checkout",
    es: "Personalize la página del checkout",
  },
  "Todos": {
    pt: "Todos",
    es: "Todos",
  },
  "Plataformas": {
    pt: "Plataformas",
    es: "Plataformas",
  },
  "Pagamentos são feitos através do Empreender Plus": {
    pt: "Pagamentos são feitos através do Empreender Plus",
    es: "Los pagos se realizan a través de Empreender Plus",
  },
  "Empreender Plus é uma central de cadastros e pagamentos da Empreender, onde você receberá descontos por utilizar nossos aplicativos.": {
    pt: "Empreender Plus é uma central de cadastros e pagamentos da Empreender, onde você receberá descontos por utilizar nossos aplicativos.",
    es: "Empreender Plus es la central de suscripciones y pago de Empreender, donde recibirás descuentos por utilizar nuestras aplicaciones.",
  }, "Plano Contratado": {
    pt: "Plano Contratado",
    es: "Plan Contratado",
  },
  "Desconto": {
    pt: "Desconto",
    es: "Descuento",
  },
  "Validade": {
    pt: "Validade",
    es: "Vencimiento",
  },
  "Quantidade": {
    pt: "Quantidade",
    es: "Cantidad",
  },
  "Nota Fiscal": {
    pt: "Nota Fiscal",
    es: "Nota Fiscal",
  },
  "Status": {
    pt: "Status",
    es: "Status",
  }, "Bem vindo ao Dijital": {
    pt: "Bem vindo ao Dijital",
    es: "Bienvenido a Dijital",
  },
  "Siga o passa a passo e comece a vender.": {
    pt: "Siga o passa a passo e comece a vender.",
    es: "Sigue el paso a paso y comienza a vender.",
  },
  "Conecte sua loja": {
    pt: "Conecte sua loja",
    es: "Conecte su tienda virtual",
  },
  "Escolha a plataforma de sua preferência e conecte ou crie a sua loja para começar a usar o Dijital.": {
    pt: "Escolha a plataforma de sua preferência e conecte ou crie a sua loja para começar a usar o Dijital.",
    es: "Elige tu plataforma de preferencia y conéctate o crea tu tienda virtual para empezar a usar Dijital.",
  },
  "Precisa de ajuda?": {
    pt: "Precisa de ajuda?",
    es: "¿Tienes alguna duda?",
  },
  "Assistir vídeo": {
    pt: "Assistir vídeo",
    es: "Ver el vídeo",
  }, "Ir para o FAQ": {
    pt: "Ir para o FAQ",
    es: "Ir al FAQ",
  },
  "Precisa de mais ajuda? Falar com o suporte": {
    pt: "Precisa de mais ajuda? Falar com o suporte",
    es: "¿Necesitas de más ayuda? Contacta nuestro soporte",
  },
  "Integrações": {
    pt: "Integrações",
    es: "Integraciones",
  },
  "Ajuda": {
    pt: "Ajuda",
    es: "Ayuda",
  },
  "Suporte": {
    pt: "Suporte",
    es: "Soporte",
  },
  "Vincular arquivo": {
    pt: "Vincular arquivo",
    es: "Vincular archivo",
  },
  "Adicione uma mídia (anexo) ao produto.": {
    pt: "Adicione uma mídia (anexo) ao produto.",
    es: "Agregue un archivo (adjunto) al producto.",
  },
  "Vá em produtos": {
    pt: "Vá em produtos",
    es: "Vaya a productos",
  },
  "Clique no ícone": {
    pt: "Clique no ícone",
    es: "Haga clic en el ícono",
  },
  "Selecione a mídia que deseja vincular": {
    pt: "Selecione a mídia que deseja vincular",
    es: "Seleccione el archivo que desea vincular",
  }, "Personalize seu email e sua página de checkout.": {
    pt: "Personalize seu email e sua página de checkout.",
    es: "Personalize su email y su página del checkout.",
  },
  "Armazenamento": {
    pt: "Armazenamento",
    es: "Almacenamiento",
  }, "Configurações": {
    pt: "Configurações",
    es: "Configuraciones",
  },
  "Deletar conta": {
    pt: "Deletar conta",
    es: "Eliminar cuenta",
  }, "Gostaria de deletar sua conta?": {
    pt: "Gostaria de deletar sua conta?",
    es: "¿Desea eliminar su cuenta?",
  },
  "Deseja excluir sua conta?": {
    pt: "Deseja excluir sua conta?",
    es: "¿Deseas eliminar tu cuenta?",
  }, "Confirmar": {
    pt: "Confirmar",
    es: "Confirmar",
  },
  "Sem resultados": {
    pt: "Sem resultados",
    es: "No se encontraron resultados",
  }, "Você ainda não subiu nenhum arquivo": {
    pt: "Você ainda não subiu nenhum arquivo",
    es: "Aún no has subido ningún archivo.",
  },
  "Você ainda não integrou com nenhuma plataforma": {
    pt: "Use o Dijital para adicionar arquivos e links na sua loja virtual.",
    es: "Aún no has integrado con ninguna plataforma, haga clic aquí para integrar",
  },
  "Você ainda não integrou com nenhuma plataforma, clique aqui para integrar": {
    pt: "Use o Dijital para adicionar arquivos e links na sua loja virtual.",
    es: "Aún no has integrado con ninguna plataforma, haga clic aquí para integrar",
  },
  "Passo a passo": {
    pt: "Passo a passo",
    es: "Paso a Paso"
  },
  "Pagamentos": {
    pt: "Pagamentos",
    es: "Pagos"
  },
  "Renomear": {
    pt: "Renomear",
    es: "Cambiar el nombre",
  },
  "Copiar link": {
    pt: "Copiar link",
    es: "Copiar link",
  },
  "Baixar arquivo": {
    pt: "Baixar arquivo",
    es: "Descargar archivo",
  },
  "Botão com tema da loja": {
    pt: "Botão com tema da loja",
    es: "Botón con tema de la tienda",
  },
  "Arredondamento do botão": {
    pt: "Arredondamento do botão",
    es: "Arredondamiento del botón",
  },
  "Deletar": {
    pt: "Deletar",
    es: "Eliminar",
  },
  "Limitar cliques": {
    pt: "Limitar cliques",
    es: "Limitar clics",
  },
  "Limitar downloads": {
    pt: "Limitar downloads",
    es: "Limitar downloads",
  },
  "Não possui uma conta?": {
    pt: "Não possui uma conta?",
    es: "¿No tienes una cuenta?",
  },
  "Esqueceu sua senha?": {
    pt: "Esqueceu sua senha?",
    es: "¿Olvidó su contraseña?",
  },
  "Senha": {
    pt: "Senha",
    es: "Contraseña",
  },
  "Confirmar Senha": {
    pt: "Confirmar Senha",
    es: "Confirmar Contraseña",
  },
  "ou acesse com": {
    pt: "ou acesse com",
    es: "o ingrese con",
  },
  "Política de Privacidade": {
    pt: "Política de Privacidade",
    es: "Política de privacidad",
  },
  "Política de Cookie": {
    pt: "Política de Cookie",
    es: "Política de Cookie",
  },
  "Termos e condições": {
    pt: "Termos e condições",
    es: "Términos y condiciones",
  },
  "Confirmar senha": {
    pt: "Confirmar senha",
    es: "Confirmar contraseña",
  },
  "Experimente grátis": {
    pt: "Experimente grátis",
    es: "Pruébelo gratis",
  },
  "Já possui uma conta?": {
    pt: "Já possui uma conta?",
    es: "¿Usted ya tiene una cuenta?",
  },
  "Nome": {
    pt: "Nome",
    es: "Nombre",
  },
  "Chave Empreender": {
    pt: "Chave Empreender",
    es: "Llave Empreender",
  },
  "Erro ao tentar efetuar login.": {
    pt: "Erro ao tentar efetuar login.",
    es: "Error al intentar iniciar sesión",
  },
  "Login efetuado com sucesso.": {
    pt: "Login efetuado com sucesso.",
    es: "Inicio de sesión realizado con éxito.",
  },
  "Chave empreender copiada.": {
    pt: "Chave empreender copiada.",
    es: "Llave empreender copiada",
  },
  "Configuração Nuvemshop": {
    pt: "Configuração Nuvemshop",
    es: "Configuración Tiendanube",
  },
  "1. Faça o login em sua conta da nuvemshop que deseja integrar.": {
    pt: "1. Faça o login em sua conta da nuvemshop que deseja integrar.",
    es: "1. Inicie sesión en la cuenta Tiendanube que desea integrar.",
  },
  "2. Clique no botão Conectar. (você será redirecionado à nuvemshop para autorizar a integração e assim que terminar será redirecionado para o Dijital novamente.)": {
    pt: "2. Clique no botão Conectar. (você será redirecionado à nuvemshop para autorizar a integração e assim que terminar será redirecionado para o Dijital novamente.)",
    es: "2. Haga clic en el botón Conectar. (Usted será redirijido a Tiendanube para autorizar la integración y cuando finalize será redirijido de vuelta a la app Dijital).",
  },
  "3. Pronto sua loja já está integrada ao Dijital.": {
    pt: "3. Pronto sua loja já está integrada ao Dijital.",
    es: "3. ¡Listo! Su tienda ya está integrada a Dijital.",
  },
  "Não deslogue do Dijital durante o processo de integração.": {
    pt: "Não deslogue do Dijital durante o processo de integração.",
    es: "No cierre la sesión de Dijital durante el proceso de integración.",
  },
  "Personalize - opcional": {
    pt: "Personalize - opcional",
    es: "Personalizar - opcional",
  },
  "Personalize seu email": {
    pt: "Personalize seu email",
    es: "Personalize su email.",
  },
  "Personalize seu checkout": {
    pt: "Personalize seu checkout",
    es: "Personalize su checkout",
  },
  "Ir ao FAQ": {
    pt: "Ir ao FAQ",
    es: "Ir al FAQ",
  },
  "Precisa de mais ajuda?": {
    pt: "Precisa de mais ajuda?",
    es: "¿Necesitas de más ayuda?",
  },
  "Falar com o suporte": {
    pt: "Falar com o suporte",
    es: "Contacta nuestro soporte",
  },
  "Nuvemshop": {
    pt: "Nuvemshop",
    es: "Tiendanube",
  },
  "Gerenciar produto digital": {
    pt: "Gerenciar produto digital",
    es: "Gestionar productos digitales",
  },
  "[upload_limit] para upload": {
    pt: "150 MB para upload",
    es: "150 MB para upload",
  },
  "Plano atual": {
    pt: "Plano atual",
    es: "Plan actual",
  },
  "Deseja excluir essa integração com Nuvemshop ?": {
    pt: "Deseja excluir essa integração com Nuvemshop ?",
    es: "¿Deseas eliminar esta integración con Tiendanube?",
  },
  "Deseja excluir essa integração com Yampi ?": {
    pt: "Deseja excluir essa integração com Yampi ?",
    es: "¿Deseas eliminar esta integración con Yampi?",
  },
  "Deseja excluir essa integração com Woocommerce ?": {
    pt: "Deseja excluir essa integração com Woocommerce ?",
    es: "¿Deseas eliminar esta integración con Woocommerce?",
  },
  "Deseja excluir essa integração com Shopify ?": {
    pt: "Deseja excluir essa integração com Shopify ?",
    es: "¿Deseas eliminar esta integración con Shopify?",
  },
  "Deseja excluir essa integração com Rastreio ?": {
    pt: "Deseja excluir essa integração com Rastreio ?",
    es: "¿Deseas eliminar esta integración con Rastreio?",
  },
  "Botão que redireciona para tela de checkout": {
    pt: "Botão que redireciona para tela de checkout",
    es: "Botón que redirige a la página del checkout",
  },
  "[Nome do arquivo] / [Link]": {
    pt: "[Nome do arquivo] / [Link]",
    es: "[Nombre del archivo] / [Link]"
  },
  "[Nome do arquivo]": {
    pt: "[Nome do arquivo]",
    es: "[Nombre del archivo]"
  },
  "Sair": {
    pt: "Sair",
    es: "Salir"
  },
  "Esse é o código de indentificação desse aplicativo.": {
    pt: "Esse é o código de indentificação desse aplicativo.",
    es: "Este es el código de identificación de esta aplicación"
  },
  "Integre sua conta Nuvemshop e comece a adicionar produtos digitais na sua loja": {
    pt: "Integre sua conta Nuvemshop e comece a adicionar produtos digitais na sua loja",
    es: "Integre su cuenta Tiendanube y comience a incluir productos digitales en su tienda."
  },
  "Integre sua conta Yampi e comece a adicionar produtos digitais na sua loja": {
    pt: "Integre sua conta Yampi e comece a adicionar produtos digitais na sua loja",
    es: "Integre su cuenta Yampi y comience a incluir productos digitales en su tienda."
  },
  "Integre sua conta Woocommerce e comece a adicionar produtos digitais na sua loja": {
    pt: "Integre sua conta Woocommerce e comece a adicionar produtos digitais na sua loja",
    es: "Integre su cuenta Woocommerce y comience a incluir productos digitales en su tienda."
  },
  "Yampi": {
    pt: "Yampi",
    es: "Yampi"
  },
  "Woocommerce": {
    pt: "Woocommerce",
    es: "Woocommerce"
  },
  "Aguarde...": {
    pt: "Aguarde...",
    es: "Espere..."
  },
  "Integração efetuada com sucesso!": {
    pt: "Integração efetuada com sucesso!",
    es: "¡Integración realizada correctamente!"
  },
  "Integração deletada com sucesso!": {
    pt: "Integração deletada com sucesso!",
    es: "Integración eliminada con éxito."
  },
  "Integre sua conta Woocommerce e comece a criar avaliações e perguntas na sua loja": {
    pt: "Integre sua conta Woocommerce e comece a criar avaliações e perguntas na sua loja",
    es: "Integre su cuenta Woocommerce y comience a incluir productos digitales en su tienda"
  },
  "Adicionar novo anexo": {
    pt: "Adicionar novo anexo",
    es: "Añadir nuevo archivo"
  },
  "Fazer upload": {
    pt: "Fazer upload",
    es: "Subir archivo"
  },
  "Vincular mídia": {
    pt: "Vincular mídia",
    es: "Vincular archivo"
  },
  "Integre sua conta Shopify e comece a adicionar produtos digitais na sua loja": {
    pt: "Integre sua conta Shopify e comece a adicionar produtos digitais na sua loja",
    es: "Integre su cuenta Shopify y comience a incluir productos digitales en su tienda."
  },
  "Shopify": {
    pt: "Shopify",
    es: "Shopify"
  },
  "Pedidos": {
    pt: "Pedidos",
    es: "Pedidos"
  },
  "Pedido": {
    pt: "Pedido",
    es: "Orden"
  },
  "Você ainda não recebeu nenhum pedido depois que integrou sua loja com o Dijital": {
    pt: "Você ainda não recebeu nenhum pedido depois que integrou sua loja com o Dijital",
    es: "Todavía no has recibido ningún pedido después de integrar tu tienda con Dijital"
  },
  "Veja os pedidos feitos nas loja integradas": {
    pt: "Veja os pedidos feitos nas loja integradas",
    es: "Ver los pedidos realizados en las tiendas integradas"
  },

  //novas traduções
  "config-shopify": {
    pt: "Configuração Shopify",
    es: "Configuración Shopify"
  },
  "nao-deslogue": {
    pt: "Não deslogue do Dijital durante o processo de integração.",
    es: "No cierre sesión de la app Dijital durante el proceso de integración"
  },
  "crie-app": {
    pt: "Crie seu app privado na shopify",
    es: "Crea tu app privada en Shopify"
  },
  "permissoes": {
    pt: "Permissões necessárias: (Leitura e Escrita):",
    es: "Alcances de acceso requeridos (Lectura y Escritura)"
  },
  "produtos": {
    pt: "Produtos",
    es: "Productos"
  },
  "pedidos": {
    pt: "Pedidos",
    es: "Pedidos"
  },
  "lista-produtos": {
    pt: "Lista de produtos",
    es: "Publicaciones de productos"
  },
  "digite-infor": {
    pt: "Digite as informações da sua loja:",
    es: "Introduzca los datos de su tienda:"
  },
  "endereco-loja": {
    pt: "Endereço da loja",
    es: "URL de su tienda"
  },
  "token": {
    pt: "token",
    es: "Token de acceso"
  },

  "acao": {
    pt: "Ação",
    es: "Acción"
  },

  "integracao": {
    pt: "Integração",
    es: "Integración"
  },

  "progresso": {
    pt: "Progresso",
    es: "Progreso"
  },

  "feedback": {
    pt: "Feedback",
    es: "Feedback"
  },
  //

  "config-sak": {
    pt: "Configuração Sak",
    es: "Configuración SAK"
  },
  "digite-webhook": {
    pt: "Digite o webhook da conta SAK que deseja integrar:",
    es: "Introduzca el webhook de la cuenta SAK que desea integrar:"
  },
  "digite-token": {
    pt: "Digite o token da conta SAK que deseja integrar:",
    es: "Introduzca el token de la cuenta SAK que desea integrar:"
  },

  "existe-integracao": {
    pt: "já existe uma integração",
    es: "Ya existe una integración."
  },
  //
  "sales-nao-pedidos": {
    pt: "Você ainda não possuí pedidos.",
    es: "No tienes pedidos aún."
  },
  "sales-reenviar-emails": {
    pt: "Reenviar emails dos pedidos selecionados",
    es: "Reenviar emails de los pedidos seleccionados"
  },
  "sales-atualizar": {
    pt: "Atualizar",
    es: "Actualizar"
  },
  "sales-todos-pedidos": {
    pt: "Todos os pedidos",
    es: "Todos los pedidos"
  },
  "sales-ate": {
    pt: "Até",
    es: "Hasta"
  },
  "sales-pesquisar": {
    pt: "Pesquisar",
    es: "Buscar"
  },
  "sales-acessar": {
    pt: "Acessar",
    es: "Acceder"
  },
  "sales-salvar": {
    pt: "Salvar",
    es: "Guardar"
  },
  "sales-enviado-sucesso": {
    pt: "Enviado com sucesso.",
    es: "Enviado con éxito!"
  },
  "sales-atualizado-sucesso": {
    pt: "Atualizado com sucesso.",
    es: "Actualizado con éxito!"
  },

  "sales-atualizacao-pedidos": {
    pt: "Atualização de pedidos",
    es: "Actualización de pedidos"
  },
  "sales-acao": {
    pt: "Ação",
    es: "Acción"
  },
  "sales-progresso": {
    pt: "Progresso",
    es: "Progreso"
  },
  "sales-verificacoes": {
    pt: "Verificações",
    es: "Verificaciones"
  },
  "sales-atualizacoes": {
    pt: "Atualizações",
    es: "Atualizaciones"
  },

  //
  "thema-cores": {
    pt: "Cores",
    es: "Colores"
  },
  "thema-avancado": {
    pt: "Avançado",
    es: "Avanzado"
  },
  "thema-combinacao-cores": {
    pt: "Combinação de cores:",
    es: "Combinación de colores:"
  },
  "thema-vermelho": {
    pt: "Vermelho",
    es: "Rojo"
  },
  "thema-amarelo": {
    pt: "Amarelo",
    es: "Amarillo"
  },
  "thema-escuro": {
    pt: "Escuro",
    es: "Oscuro"
  },
  "thema-cabecalho": {
    pt: "Cabeçalho",
    es: "Encabezado"
  },
  "thema-texto-cabecalho": {
    pt: "Texto cabeçalho",
    es: "Texto encabezado"
  },
  "thema-fundo": {
    pt: "Fundo",
    es: "Fondo"
  },
  "thema-produto": {
    pt: "Produto",
    es: "Producto"
  },
  "thema-nome": {
    pt: "Nome",
    es: "Nombre"
  },
  "thema-botao": {
    pt: "Botão",
    es: "Botón"
  },
  "thema-texto-botao": {
    pt: "Texto botão",
    es: "Texto Botón"
  },
  "thema-ajuda": {
    pt: "Precisa de ajuda",
    es: "¿Necesitas ayuda?"
  },
  "thema-salvar": {
    pt: "Salvar",
    es: "Guardar"
  },
  "thema-cuidado-salvar": {
    pt: "Cuidado - Alterações não salvas",
    es: "Precaución: Cambios no guardados"
  },

  "thema-checkout-loja": {
    pt: "Checkout loja",
    es: "Checkout de la tienda"
  },
  "thema-necessario-pagina": {
    pt: "É necessário criar uma página em sua plataforma e colar nosso código.",
    es: "Es necesario crear una página en su plataforma y pegar nuestro código."
  },
  "thema-criar-pagina": {
    pt: "Criar página de downloads",
    es: "Crear página de Downloads"
  },
  "thema-vamos-criar-pagina": {
    pt: "Vamos criar uma página em sua loja, para seus clientes baixarem os arquivos digitais comprados.",
    es: "Vamos a crear una página en su tienda para que sus clientes descarguen los archivos digitales comprados."
  },
  "thema-na-sua-plataforma": {
    pt: "Na sua plataforma, vá até criar página.",
    es: 'En su plataforma, vaya hasta "crear página".'
  },
  "thema-no-titulo-pagina": {
    pt: 'No título da página digite "Downloads"',
    es: 'En el título de la página escriba "Downloads".'
  },
  "thema-no-form-escreva": {
    pt: 'No formulário "Escreva o conteúdo da sua página" clique na opção Código Fonte e cole o codigo HTML abaixo:',
    es: 'En el formulario "Escriba el contenido de su página", haga clic en la opción Código Fuente y pegue el código HTML abajo:'
  },
  "thema-na-plataforma": {
    pt: 'Na sua plataforma, desça até o final da página e digite "Downloads" em URL da página',
    es: 'En su plataforma, baje hasta el final de la página y escriba "Downloads" en URL de la página'
  },
  "thema-para-salvar": {
    pt: 'Para Salvar, basta clicar no botão "Criar Página"',
    es: 'Para guardar, simplemente haga clic en el botón "Crear Página"'
  },
  "thema-obs": {
    pt: 'Observação: Caso tenha alguma dúvida, veja o vídeo ao lado.',
    es: "Nota: Si tiene alguna duda, vea el video al lado."
  },
  "thema-botoes": {
    pt: 'Botôes',
    es: "Botones."
  },


  //
  "assinatura-assine": {
    pt: "Assine o",
    es: "Suscríbete a"
  },
  "assinatura-descontos-app": {
    pt: "para ter descontos nesse e em outros aplicativos!",
    es: "para obtener descuentos en esta y otras aplicaciones!"
  },
  "assinatura-acesse-ja": {
    pt: "Acesse já",
    es: "Ingresa ahora!"
  },
  "assinatura-plano-atual": {
    pt: "Plano Atual",
    es: "Plan Actual"
  },
  "assinatura-trocar-plano": {
    pt: "Trocar Plano",
    es: "Cambiar plan"
  },

  //
  "ajuda-atendimento": {
    pt: "Atendimento",
    es: "Atendimiento"
  },
  "ajuda-suporte-whats": {
    pt: "Suporte via Whatsapp",
    es: "Soporte vía Whatsapp"
  },
  "ajuda-ir-whats": {
    pt: "Ir para Whatsapp",
    es: "Ir al Whatsapp"
  },
  "ajuda-canais": {
    pt: "Canais",
    es: "Canales"
  },
  "ajuda-suporte-forum": {
    pt: "Suporte Fórum",
    es: "Soporte Foro"
  },
  "ajuda-ir-forum": {
    pt: "Ir para fórum",
    es: "Ir al Foro"
  },
  //
  "medias-em": {
    pt: "Em",
    es: "En"
  },
  "medias-produtos": {
    pt: "produtos",
    es: "productos"
  },
  "medias-deletar-selecionados": {
    pt: "Deletar arquivos selecionados",
    es: "Borrar archivos seleccionados"
  },
  "medias-pesquisar": {
    pt: "Pesquisar",
    es: "Buscar"
  },
  "medias-baixar-arquivo": {
    pt: "Baixar arquivo",
    es: "Descargar archivo"
  },
  "medias-renomear-arquivo": {
    pt: "Renomear arquivo",
    es: "Renombrar archivo"
  },
  "medias-deletar-arquivo": {
    pt: "Deletar arquivo",
    es: "Borrar archivo"
  },
  "medias-nao-midias": {
    pt: "Você ainda não possuí nenhuma mídia. ",
    es: "Aún no eres propietario de ningún medio."
  },
  "medias-excluido-sucesso": {
    pt: "Excluído com sucesso.",
    es: "Eliminado con éxito."
  },
  "medias-renomear-midia": {
    pt: "Renomear mídia",
    es: "Renombrar archivo"
  },
  "medias-salvar": {
    pt: "Salvar",
    es: "Guardar"
  },
  "medias-upload-arquivos": {
    pt: "Upload de arquivos",
    es: "Subir archivos"
  },
  "medias-arraste-arquivos": {
    pt: "Arrastre as mídias para upload",
    es: "Arrastre los archivos para upload"
  },
  "medias-escolher-midias": {
    pt: "Selecionar arquivo",
    es: "Seleccionar archivo"
  },
  "medias-nenhum-arquivo": {
    pt: "Nenhuma mídia",
    es: "Ningún archivo"
  },
  "medias-arquivo": {
    pt: "Arquivos",
    es: "Archivos"
  },
  "medias-deseja-deletar": {
    pt: "Deseja deletar a mídia ",
    es: "¿Deseas eliminar el archivo "
  },

  //
  "header-fim-teste": {
    pt: "Fim do período de testes",
    es: "Fin del periodo de prueba."
  },
  "header-assinar": {
    pt: " Assinar",
    es: " Suscríbete"
  },
  "header-armazenamento": {
    pt: "Armazenamento",
    es: "Almacenamiento"
  },
  "header-comprar": {
    pt: "Comprar mais espaço",
    es: "Comprar más espacio"
  },

  "header-detalhes": {
    pt: "Detalhes",
    es: "Detalles"
  },

  "header-imagem": {
    pt: "Imagem",
    es: "Imagen"
  },

  "header-outros": {
    pt: "Outros",
    es: "Otros"
  },

  //
  "products-sincronizar-dados": {
    pt: "Sincronizar dados desse produto com sua plataforma.",
    es: "Sincronizar datos de este producto con su plataforma"
  },
  "products-": {
    pt: "Produtos sincronizados com sucesso",
    es: "Producto sincronizadocon éxito"
  },
  "products-upload-arquivos": {
    pt: "Upload de arquivo",
    es: "Subir archivo"
  },
  "products-vincular-arquivo": {
    pt: "Vincular arquivo existente",
    es: "Vincular archivo existente"
  },
  "products-anexar": {
    pt: "anexar",
    es: "Adjuntar"
  },
  "products-anexar-arquivo": {
    pt: "Anexar arquivo",
    es: "Adjuntar archivo"
  },
  "products-sem-midias": {
    pt: "Sem mídias",
    es: "Sin medios"
  },
  "products-com-midias": {
    pt: "Com arquivos",
    es: "Con medios"
  },
  "products-fechar-variantes": {
    pt: "Fechar variantes",
    es: "Cerrar variantes"
  },
  "products-digite-url": {
    pt: "Digite a URL que deseja conectar, um nome para identificar a url, adicione a fila e depois click no botão salvar.",
    es: "Introduzca la URL que desea conectar, agreguela a la fila y despues haga clic en Guardar."
  },
  "products-url-add": {
    pt: "URLS adicionadas",
    es: "URLs agregados"
  },
  "products-nenhuma-url": {
    pt: "Nenhuma URL adicionada",
    es: "Ninguna URL agregada"
  },
  "products-add-anexo": {
    pt: "Adicionar anexo",
    es: " Adjuntar archivo"
  },
  "products-selecione-abaixo-midias": {
    pt: "Selecione abaixo os arquivos que deseja vincular ao produto.",
    es: "Seleccione abajo los archivos que desea vincular."
  },
  "products-atualizacao-produtos": {
    pt: "Atualização de produtos",
    es: "Actualización de productos"
  },
  "products-todos-produtos": {
    pt: "Todos os produtos",
    es: "todos los productos"
  },

  //
  "integrations-existe-integracao": {
    pt: "Já existe uma integração",
    es: "Ya posees una integración activa."
  },
  "integrations-integre-plataforma": {
    pt: "Por favor integrar uma plataforma primeiro",
    es: "Primero integre una plataforma"
  },

  
};

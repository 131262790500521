import { useSelector } from "react-redux";
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE } from "../../../../services/api";

export function* getInfor(action) {
  yield put({ type: "DASHBOARD_REQUEST" });
  yield call(GET, { endpoint: `/sales` });
  const result = yield call(GET, { endpoint: `/downloads` });
  yield put({ type: "DASHBOARD_SUCCESS", payload: result.data });
}

export function* getChart(action) {
  const result = yield call(GET, { endpoint: `/downloads_date` });
  yield put({ type: "CHART_SUCCESS", payload: result.data });
}

export function* getTop(action) {
  const result = yield call(GET, { endpoint: `/downloads_top` });
  yield put({ type: "TOP_SUCCESS", payload: result.data });
}


export function* getInforMedias(action) {
  yield put({ type: "DASHBOARD_INFOR_MEDIAS_REQUEST", })
  const result = yield call(GET, { endpoint: `/medias/count`, })
  if (result === false) {
    yield put({ type: "DASHBOARD_INFOR_MEDIAS_ERROR" })
  } else {
    yield put({ type: "DASHBOARD_INFOR_MEDIAS_SUCCESS", payload: result.data })
  }

}

export function* getInforProducts(action) {
  yield put({ type: "DASHBOARD_INFOR_PRODUCTS_REQUEST", })
  const result = yield call(GET, { endpoint: `/products/count`, })
  if (result === false) {
    yield put({ type: "DASHBOARD_INFOR_PRODUCTS_ERROR" })
  } else {
    yield put({ type: "DASHBOARD_INFOR_PRODUCTS_SUCCESS", payload: result.data })
  }
}
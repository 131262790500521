const INITIAL_STATE = {
  error: false,
  loading: false,
  data: [],
};

const Products = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PRODUCTS_REQUEST":
      return { loading: true, error: false, data: [] };
    case "PRODUCTS_ERROR":
      return { loading: false, error: true, data: [] };
    case "PRODUCTS_SUCCESS":
      return { loading: false, error: false, data: action.payload };
    case "PRODUCTS_RESET":
      return { loading: false, error: false, data: [] };
    default:
      return state;
  }
};

export default Products;

import InputCustom from "../../components/InputCustom";
import Page from "../../components/Page";
import Btn from "../../components/Btn";
import { FormPassword, Actions } from "./styled";
import { AiFillMail } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import ErrorMessage from "../../components/ErrorInput";
import { useState } from "react";
import { useEffect } from "react";
import { isVisibleScript } from "../../../../utils/displayScript";

const Password = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const statusPassword = useSelector((state) => state.reducerPassword);
  const [id, setId] = useState(null);

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Preencher o campo E-mail.")
        .email("Preencha um e-mail valido.")
        .max(200, "Máximo de 200 caracteres.")
        .min(3, "Minimo de 8 caracteres."),
    }),
    onSubmit: (values) => {
      dispatch({ type: "SAGA_PASSWORD_LOGIN", payload: values });
    },
  });

  useEffect(() => {
    if (
      !(
        statusPassword.loading === false &&
        statusPassword.error === false &&
        statusPassword.data.length === 0
      )
    ) {
      if (statusPassword.loading === true) {
        setId(toast.loading("Aguarde...", { toastId: 0 }));
      } else {
        if (statusPassword.error === true) {
          toast.update(id, {
            render: "Erro ao tentar recuperar acesso.",
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        } else if (statusPassword.error === false) {
          toast.update(id, {
            render: "Enviamos uma nova senha para seu e-mail.",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
          formik.resetForm();
          history.push("/");
        }
      }
    }
  }, [statusPassword]);

  useEffect(() => {
    if (localStorage.getItem("depoimentos@login")) {
      history.push("/");
    }
  }, []);

  return (
    <Page>
      <FormPassword onSubmit={formik.handleSubmit}>
        <InputCustom>
          <span>
            <AiFillMail size={20} color={"gray"} />
          </span>
          <input
            name={"email"}
            onChange={formik.handleChange}
            value={formik.values.email}
            type={"text"}
            placeholder={"E-mail"}
          />
        </InputCustom>
        {formik.errors.email && formik.touched.email && (
          <ErrorMessage>{formik.errors.email}</ErrorMessage>
        )}

        <Actions>
          <Btn
            type="submit"
            label={"Recuperar Senha"}
            onClick={() => {
              if (formik.dirty === false)
                toast.warning("Preencha os dados.", { toastId: 0 });
            }}
          />
          <label
            onClick={() => {
              history.push("/login");
            }}
          >
            Voltar
          </label>
        </Actions>
      </FormPassword>
      {isVisibleScript(false)}
    </Page>
  );
};

export default Password;

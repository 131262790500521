import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    //background-color: red;
`

export const Header = styled.div`
    display: flex;
    height: 80px;
    background-color: ${props => props.backgroundColor};
    color:  ${props => props.color};;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
`

export const Infor = styled.div`
    display: flex;
    flex-direction: column;
    //background-color: red;

    p{
        display: flex;
        margin: 0px;
        padding: 0px;
        //background-color: red;
        text-align: center;
        justify-content: center;
        font-size: 32px;
        padding: 30px 0px 0px 0px;
        color: ${props => props.titleColor};
    }

    label{
        display: flex;
        //background-color: yellow;
        text-align: center;
        justify-content: center;
        font-size: 18px;
        color: ${props => props.messageColor};
        opacity: 0.5;
    }
`

export const SectionData = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    //background-color: red;
`

export const SearchCustom = styled.div`
    display: flex;


    input{
        display: flex;
        border: none;
        outline: none;
        height: 40px;
        width: 300px;
        padding: 0px 20px;
        border-radius: 4px;
    }
`
import React from "react";
import { BoxLoading } from "./styled";
import { AiOutlineLoading } from "react-icons/ai";

const Loading = (props) => {
  return (
    <BoxLoading>
      <span>
        <AiOutlineLoading size={typeof props.size == "undefined" ? "30" : props.size} color={typeof props.color == "undefined" ? "#20d489" : props.color} />
      </span>
    </BoxLoading>
  );
};

export default Loading;

import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import ReactModal from 'react-modal'
import { Actions, ButtomCustom, Container, Contents, Form, InputCustom, Title } from './styled';
import LoadingLogo from '../../../../components/Loading-logo';
import { useSelector } from 'react-redux';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        with: "500px"

    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
    }
};



const ModalWebhook = (props) => {

    const statusWebhook = useSelector((state) => state.reducerAdminVerifyHebhookCard);

    return (
        <ReactModal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => props.setVisibleModal(false)}
        >
            <Container>
                <Title>
                    <label>WEBHOOKS</label>
                </Title>
                <Form>
                    {statusWebhook.loading ?
                        <LoadingLogo /> :
                        <label>
                            {JSON.stringify(statusWebhook)}
                        </label>}
                </Form>
                <Actions>
                    <ButtomCustom onClick={() => { props.setVisibleModal(false) }} backgroundColor={"#F1416C"} >
                        <label>Fechar</label>
                    </ButtomCustom>
                    {/* <ButtomCustom onClick={() => { props.addTimeUser(qtd, typeAdd) }} backgroundColor={"#19B674"}>
                        <label>salvar</label>
                    </ButtomCustom> */}
                </Actions>
            </Container>
        </ReactModal>
    )
}

export default ModalWebhook
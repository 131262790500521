import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    //height: 80px;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`

export const HeaderLoading = styled.div`
    display: flex;
    min-height: 80px;
    background-color: #dcdcdc;
    min-width: 100%;

    animation: Skeleton 1s ease infinite alternate;

    @keyframes Skeleton{
        to{
            opacity: 0.5;
        }
    }
`

export const InforLoading = styled.div`
    display: flex;
    min-height: 140px;
    background-color: #dcdcdc;
    margin: 20px 0px 0px 0px;
    border-radius: 4px;
    width: 50%;

    animation: Skeleton 1s ease infinite alternate;

    @keyframes Skeleton{
        to{
            opacity: 0.4;
        }
    }
`
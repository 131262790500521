import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Container,
  ContainerLine,
  ContainerNumber,
  Number,
  Name,
  ContainerProducts,
} from "./styled";
import Title from "../../../../components/Title";
import { AiOutlineFile, AiOutlineLink } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { translations } from "../../../../utils/language";
import { useCallback } from "react";

const TopSales = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const top = useSelector((state) => state.reducerTop);
  const history = useHistory();
  const language = useSelector((state) => state.reducerLanguage);






  // useEffect(() => {
  //   if (top?.data?.downloads?.length === 0) {
  //     history.push("/passo-a-passo");
  //   }
  // }, [top, history]);

  return (
    <Container>
      <Title marginBottom="16px">{t("Top mídias")}</Title>
      <ContainerProducts complete={top?.data?.downloads?.length === 8}>
        {top?.data?.downloads?.length === 0 ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Number>
              {
                translations["Nenhuma mídia possui cilque ou download"][
                language.data
                ]
              }
            </Number>
          </div>
        ) : (
          top?.data?.downloads?.map((item, index) => (
            <ContainerLine>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ContainerNumber index={index}>
                  <Number>{index + 1}</Number>
                </ContainerNumber>
                {/* <Image src={item.image} /> */}
                {item.type === "download" && (
                  <AiOutlineFile
                    size={24}
                    color="#000"
                    style={{ marginRight: 10, marginLeft: 10 }}
                  />
                )}
                {item.type === "link" && (
                  <AiOutlineLink
                    size={24}
                    color="#000"
                    style={{ marginRight: 10, marginLeft: 10 }}
                  />
                )}
                <Name>{item.media_name}</Name>
              </div>
              <Name
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  width: 100,
                  color: "#BABBBA",
                }}
              >
                {item.occurrence}
                <br />
                {item.type === "download" &&
                  (item.occurrence > 1 ? "downloads" : "download")}
                {item.type === "link" &&
                  (item.occurrence > 1 ? "cliques" : "clique")}
              </Name>
            </ContainerLine>
          ))
        )}
      </ContainerProducts>
    </Container>
  );
};

export default TopSales;

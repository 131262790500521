import styled from 'styled-components'

export const Container = styled.div`
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    background: white; 
    border-radius: 10px 10px 0 0; 
    padding: 30px;
    margin-top: 30px;
`
import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px;
  border-radius: 5px;
`;

export const Close = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 0;
  margin-right: 25px;
  background: red;
  /* padding: 5px; */
  height: 25px;
  width: 25px;
  cursor: pointer;
  z-index: 9999;
  color: #fff;
  border-radius: 50%;
`;

export const ContainerLegend = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 146px;
  padding: 25px;
  border-radius: 5px;
  background-color: #f4e6fb;
`;

export const ContainerIcon = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  padding: 25px;
  border-radius: 50%;
  background-color: #f4e6fb;

  @media screen and (max-width: 450px) {
    width: 300px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.div`
  display: flex;
  width: 120px;
  height: 40px;
  background-color: #1b76ff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

export const TitleButton = styled.p`
  color: #fff;
  font-weight: 500;
  margin: 0;
`;

export const ContainerProduct = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin: 10px 0;
  height: 60px;
  padding: 10px;
`;

export const Image = styled.img`
  height: 60px;
  width: 60px;
`;

export const TitleProduct = styled.p`
  font-weight: 500;
  margin: 0;
  font-size: 16px;
`;

export const ContainerTabs = styled.div`
  width: 100%;
  display: flex;
`;

export const Tab = styled.div`
  width: 20%;
  ${(props) =>
    props.isSelected &&
    ` text-decoration: underline solid #1B76FF;
  cursor: pointer;`}
`;

export const Checkbox = styled.input`
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #000;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  padding: 5px;
  cursor: pointer;
  &:checked {
    background-color: #1b76ff;
  }
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`;

export const TitleProducts = styled.div`
  font-size: ${(props) => props.size}px;
  font-weight: 500;
  margin: ${(props) => props.marginTB}px ${(props) => props.marginLR}px;
  cursor: pointer;
`;

export const Input = styled.input`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: #f9f9f9;
  border-color: #d3d3d3;
  border-style: solid;
  padding: 2px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const InputImage = styled.input`
  height: 50px;
  top: 150;
  font-family: calibri;
  width: 50%;
  padding: 10px;
  cursor: pointer;
  -webkit-border-radius: 20px;
  -moz-border-radius: 50px;
  border: 1px dashed #1b76ff;
  text-align: center;
  background-color: #f2f2fd;
  cursor: pointer;
  @media (max-width: 1042px) {
    width: 100%;
  }
`;

export const Select = styled.select`
  width: 90%;
  background-color: #eeeff7;
  border-color: #eeeff7;
  height: 50px;
  border-radius: 10px;
  color: #000;
  cursor: pointer;
`;

export const TagFile = styled.div`
  background: #f5f6fb;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  padding: 8px;
  border-radius: 8px;
  margin-top: 10px;
`;

export const MediaName = styled.span`
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  /* @media (max-width: 700px) {
    max-width: 70px;
  } */
`;

export const ContainerTag = styled.div`
  display: grid;
  width: 100%;
  /* background: red; */
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1250px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 985px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 605px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

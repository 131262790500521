import React, { useEffect } from 'react'
import { BarColors, BarConfig, BarNav, BoxButtons, BoxButtonsItem, BoxOptions, BoxPreview, ButtonHelp, CounterItemsCircle, InputTextColor, ItemColor, ItemOption, ItemText, ItemTextArea, MenuOption, Section, SelectCustom, Title } from '../BoxEmail/styled'
import { AlertSave, BoxActive, Container, ItemActive, PreviewBody, PreviewCard, PreviewHeader, PreviewProducts, PreviewTitle } from './styled'

import imgBackground from "../../../../assets/imgs/preview-background.png";
import IconBossVideo from "../../../../assets/imgs/boss-video.svg";
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import IconSlider from "../../../../assets/imgs/icon-slider-home.svg";

import IconBossSave from '../../../../assets/imgs/boss-save.svg';
import { translations } from '../../../../utils/language';
import PreviewCheckout from '../preview-checkout';

const BoxCheckout = () => {

    const [navOption, setNavOption] = useState("config")
    const [theme, setTheme] = useState("")
    const controlConfig = useSelector((state) => state.reducerControlConfig);
    const controlSaveCheckout = useSelector(state => state.reducerControlSaveCheckout)
    const config = useSelector((state) => state.reducerTemplate);
    const dispatch = useDispatch()
    const language = useSelector((state) => state.reducerLanguage);

    const [screen, setscreen] = useState(window.screen.height)

    useEffect(() => {
        console.log("screen", screen)
    }, [screen])


    // const [color_background_header, set_color_background_header] = useState("#1b76ff")
    // const [color_text_header, set_color_text_header] = useState("#ffffff")
    // const [color_background_body, set_color_background_body] = useState("#ffffff")
    // const [color_text_title, set_color_text_title] = useState("#000000")
    // const [color_background_product, set_color_background_product] = useState("#f1f1f1")
    // const [color_text_name, set_color_text_name] = useState("#000000")
    // const [color_background_button, set_color_background_button] = useState("#1b76ff")
    // const [color_text_button, set_color_text_button] = useState("#ffffff")

    // const [config_header, set_config_header] = useState("Compra concluída com sucesso 🎉")
    // const [config_title, set_config_title] = useState("Obrigado por sua compra")
    // const [config_subtitle, set_config_subtitle] = useState("Seus arquivos")
    // const [config_button_download, set_config_button_download] = useState("Baixar")
    // const [config_button_link, set_config_button_link] = useState("Acessar")



    const saveColors = () => {

        dispatch({
            type: "SAGA_UPDATE_TEMPLATE", payload: {
                id: controlConfig.id,
                color_checkout_background_header: controlConfig?.color_checkout_background_header,
                color_checkout_text_header: controlConfig?.color_checkout_text_header,
                color_checkout_background: controlConfig?.color_checkout_background,
                color_checkout_text_title: controlConfig?.color_checkout_text_title,
                color_checkout_background_product: controlConfig?.color_checkout_background_product,
                color_checkout_text_product: controlConfig?.color_checkout_text_product,
                color_button_checkout: controlConfig?.color_button_checkout,
                color_message_button_checkout: controlConfig?.color_message_button_checkout
            }
        })

    }

    const saveConfig = () => {

        dispatch({
            type: "SAGA_UPDATE_TEMPLATE", payload: {
                id: controlConfig?.id,
                checkout_message_header: controlConfig.checkout_message_header,
                checkout_message_title: controlConfig.checkout_message_title,
                checkout_message: controlConfig.checkout_message,
                checkout_button: controlConfig.checkout_button,
                checkout_button_link: controlConfig.checkout_button_link,
                checkout_color: controlConfig.checkout_color
            }
        })

    }

    const isSaveCheckout = () => {
        // if (config?.data[0]) {

        // }
        if (
            config.data[0].color_checkout_background_header == controlConfig.color_checkout_background_header &&
            config.data[0].color_checkout_text_header == controlConfig.color_checkout_text_header &&
            config.data[0].color_checkout_background == controlConfig.color_checkout_background &&
            config.data[0].color_checkout_text_title == controlConfig.color_checkout_text_title &&
            config.data[0].color_checkout_background_product == controlConfig.color_checkout_background_product &&
            config.data[0].color_checkout_text_product == controlConfig.color_checkout_text_product &&
            config.data[0].color_button_checkout == controlConfig.color_button_checkout &&
            config.data[0].color_message_button_checkout == controlConfig.color_message_button_checkout &&

            config.data[0].checkout_message_header == controlConfig.checkout_message_header &&
            config.data[0].checkout_message_title == controlConfig.checkout_message_title &&
            config.data[0].checkout_message == controlConfig.checkout_message &&
            config.data[0].checkout_button == controlConfig.checkout_button &&
            config.data[0].checkout_button_link == controlConfig.checkout_button_link
        ) {

            dispatch({ type: "CONFIG_CONTROL_SAVE_CHECKOUT_SET", payload: true })

            return true
        } else {

            dispatch({ type: "CONFIG_CONTROL_SAVE_CHECKOUT_SET", payload: false })

            return false
        }
    }

    const getDefaultColors = () => {
        const themaDefault = {
            id: config?.data[0]?.id,
            color_checkout_background_header: "#1b76ff",
            color_checkout_text_header: "#ffffff",
            color_checkout_background: "#ffffff",
            color_checkout_text_title: "#000000",
            color_checkout_background_product: "#f1f1f1",
            color_checkout_text_product: "#000000",
            color_button_checkout: "#1b76ff",
            color_message_button_checkout: "#ffffff"
        }
        dispatch({ type: "SAGA_UPDATE_TEMPLATE", payload: themaDefault })
    }

    const getDefaultConfig = () => {
        const themaDefault = {
            id: config.data[0].id,
            checkout_message_header: "Compra concluída com sucesso 🎉",
            checkout_message_title: "Obrigado por sua compra",
            checkout_message: "Seus arquivos",
            checkout_button: "Baixar",
            checkout_button_link: "Acessar"
        }
        dispatch({ type: "SAGA_UPDATE_TEMPLATE", payload: themaDefault })
    }

    useEffect(() => {
        switch (theme) {
            case "dark":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        color_checkout_background_header: "#1D1D1D",
                        color_checkout_text_header: "#ffffff",
                        color_checkout_background: "#292929",
                        color_checkout_text_title: "#ffffff",
                        color_checkout_background_product: "#2e2e2e",
                        color_checkout_text_product: "#ffffff",
                        color_button_checkout: "#1D1D1D",
                        color_message_button_checkout: "#ffffff"
                    }
                })
                break;
            case "light":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        color_checkout_background_header: "#f1f1f1",
                        color_checkout_text_header: "#000000",
                        color_checkout_background: "#ffffff",
                        color_checkout_text_title: "#000000",
                        color_checkout_background_product: "#fafafa",
                        color_checkout_text_product: "#000000",
                        color_button_checkout: "#f1f1f1",
                        color_message_button_checkout: "#000000"
                    }
                })
                break;
            case "green":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        color_checkout_background_header: "#008069",
                        color_checkout_text_header: "#ffffff",
                        color_checkout_background: "#ffffff",
                        color_checkout_text_title: "#000000",
                        color_checkout_background_product: "#f1f1f1",
                        color_checkout_text_product: "#000000",
                        color_button_checkout: "#008069",
                        color_message_button_checkout: "#ffffff"
                    }
                })
                break;
            case "red":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        color_checkout_background_header: "#c4302b",
                        color_checkout_text_header: "#ffffff",
                        color_checkout_background: "#ffffff",
                        color_checkout_text_title: "#000000",
                        color_checkout_background_product: "#f1f1f1",
                        color_checkout_text_product: "#000000",
                        color_button_checkout: "#c4302b",
                        color_message_button_checkout: "#ffffff"
                    }
                })
                break;
            case "yellow":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        color_checkout_background_header: "#FF7800",
                        color_checkout_text_header: "#ffffff",
                        color_checkout_background: "#ffffff",
                        color_checkout_text_title: "#000000",
                        color_checkout_background_product: "#f1f1f1",
                        color_checkout_text_product: "#000000",
                        color_button_checkout: "#FF7800",
                        color_message_button_checkout: "#ffffff"
                    }
                })
                break;
            case "blue":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        color_checkout_background_header: "#1b76ff",
                        color_checkout_text_header: "#ffffff",
                        color_checkout_background: "#ffffff",
                        color_checkout_text_title: "#000000",
                        color_checkout_background_product: "#f1f1f1",
                        color_checkout_text_product: "#000000",
                        color_button_checkout: "#1b76ff",
                        color_message_button_checkout: "#ffffff"
                    }
                })
                break;
            default:
                break;
        }
    }, [theme])

    useEffect(() => {
        if (
            // controlConfig.background_button == "#000000" &&
            // controlConfig.color_text_button == "#ffffff" &&
            // controlConfig.background_body == "#ffffff" &&
            // controlConfig.color_text == "#000000" &&
            // controlConfig.color_border == "#d3d3d3"

            controlConfig.color_checkout_background_header == "#f1f1f1" &&
            controlConfig.color_checkout_text_header == "#000000" &&
            controlConfig.color_checkout_background == "#ffffff" &&
            controlConfig.color_checkout_text_title == "#000000" &&
            controlConfig.color_checkout_background_product == "#fafafa" &&
            controlConfig.color_checkout_text_product == "#000000" &&
            controlConfig.color_button_checkout == "#f1f1f1" &&
            controlConfig.color_message_button_checkout == "#000000"
        ) {
            setTheme("light")
        }
        else if (
            controlConfig.color_checkout_background_header == "#1D1D1D" &&
            controlConfig.color_checkout_text_header == "#ffffff" &&
            controlConfig.color_checkout_background == "#292929" &&
            controlConfig.color_checkout_text_title == "#ffffff" &&
            controlConfig.color_checkout_background_product == "#2e2e2e" &&
            controlConfig.color_checkout_text_product == "#ffffff" &&
            controlConfig.color_button_checkout == "#1D1D1D" &&
            controlConfig.color_message_button_checkout == "#ffffff"

        ) {
            setTheme("dark")
        }
        else if (
            controlConfig.color_checkout_background_header == "#1b76ff" &&
            controlConfig.color_checkout_text_header == "#ffffff" &&
            controlConfig.color_checkout_background == "#ffffff" &&
            controlConfig.color_checkout_text_title == "#000000" &&
            controlConfig.color_checkout_background_product == "#f1f1f1" &&
            controlConfig.color_checkout_text_product == "#000000" &&
            controlConfig.color_button_checkout == "#1b76ff" &&
            controlConfig.color_message_button_checkout == "#ffffff"

        ) {
            setTheme("blue")
        }
        else if (
            controlConfig.color_checkout_background_header == "#008069" &&
            controlConfig.color_checkout_text_header == "#ffffff" &&
            controlConfig.color_checkout_background == "#ffffff" &&
            controlConfig.color_checkout_text_title == "#000000" &&
            controlConfig.color_checkout_background_product == "#f1f1f1" &&
            controlConfig.color_checkout_text_product == "#000000" &&
            controlConfig.color_button_checkout == "#008069" &&
            controlConfig.color_message_button_checkout == "#ffffff"

        ) {
            setTheme("green")
        }
        else if (
            controlConfig.color_checkout_background_header == "#c4302b" &&
            controlConfig.color_checkout_text_header == "#ffffff" &&
            controlConfig.color_checkout_background == "#ffffff" &&
            controlConfig.color_checkout_text_title == "#000000" &&
            controlConfig.color_checkout_background_product == "#f1f1f1" &&
            controlConfig.color_checkout_text_product == "#000000" &&
            controlConfig.color_button_checkout == "#c4302b" &&
            controlConfig.color_message_button_checkout == "#ffffff"

        ) {
            setTheme("red")
        }
        else if (
            controlConfig.color_checkout_background_header == "#FF7800" &&
            controlConfig.color_checkout_text_header == "#ffffff" &&
            controlConfig.color_checkout_background == "#ffffff" &&
            controlConfig.color_checkout_text_title == "#000000" &&
            controlConfig.color_checkout_background_product == "#f1f1f1" &&
            controlConfig.color_checkout_text_product == "#000000" &&
            controlConfig.color_button_checkout == "#FF7800" &&
            controlConfig.color_message_button_checkout == "#ffffff"
        ) {
            setTheme("yellow")
        }
        else {
            setTheme("custom")
        }
    }, [controlConfig])

    return (
        <Container>
            <BoxOptions>
                <BarNav>
                    <Title>
                        <label>
                            Checkout
                        </label>
                    </Title>
                    {/* <MenuOption>
                        <ItemOption active={navOption != "config" ? true : false} onClick={() => { isSaveCheckout() && setNavOption("color") }}>
                            {translations["thema-cores"][language.data]}
                        </ItemOption>
                        <ItemOption active={navOption == "config" ? true : false} onClick={() => { isSaveCheckout() && setNavOption("config") }}>
                            {translations["thema-avancado"][language.data]}
                        </ItemOption>
                    </MenuOption> */}
                </BarNav>
                {
                    navOption != "config" ?
                        <BarColors>
                            <Section>
                                <label> {translations["thema-combinacao-cores"][language.data]}</label>
                                <SelectCustom onChange={(event) => { setTheme(event.target.value) }}>
                                    <option value="red" selected={theme == "red" ? true : false}> {translations["thema-vermelho"][language.data]}</option>
                                    <option value="blue" selected={theme == "blue" ? true : false}>Azul</option>
                                    <option value="yellow" selected={theme == "yellow" ? true : false}> {translations["thema-amarelo"][language.data]}</option>
                                    <option value="green" selected={theme == "green" ? true : false}>Verde</option>
                                    <option value="light" selected={theme == "light" ? true : false}>Claro</option>
                                    <option value="dark" selected={theme == "dark" ? true : false}> {translations["thema-escuro"][language.data]}</option>
                                    <option disabled value="custom" selected={theme == "custom" ? true : false}>Personalizado</option>
                                </SelectCustom>
                            </Section>
                            <Section>
                                <label style={{ marginBottom: "10px" }}> {translations["thema-cores"][language.data]}:</label>
                                <ItemColor>
                                    <label> {translations["thema-cabecalho"][language.data]}</label>
                                    <span>
                                        <input type={"color"} value={controlConfig.color_checkout_background_header} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_background_header: e.target.value } }) }} />
                                        {/* <label>{controlConfig.color_checkout_background_header}</label> */}
                                        <InputTextColor type='text' value={controlConfig.color_checkout_background_header} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_background_header: e.target.value } }) }} />
                                    </span>
                                </ItemColor>

                                <ItemColor>
                                    <label> {translations["thema-texto-cabecalho"][language.data]}</label>
                                    <span>
                                        <input type={"color"} value={controlConfig.color_checkout_text_header} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_text_header: e.target.value } }) }} />
                                        {/* <label>{controlConfig.color_checkout_text_header}</label> */}
                                        <InputTextColor type='text' value={controlConfig.color_checkout_text_header} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_text_header: e.target.value } }) }} />
                                    </span>
                                </ItemColor>

                                <ItemColor>
                                    <label> {translations["thema-fundo"][language.data]}</label>
                                    <span>
                                        <input type={"color"} value={controlConfig.color_checkout_background} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_background: e.target.value } }) }} />
                                        {/* <label>{controlConfig.color_checkout_background}</label> */}
                                        <InputTextColor type='text' value={controlConfig.color_checkout_background} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_background: e.target.value } }) }} />
                                    </span>
                                </ItemColor>

                                <ItemColor>
                                    <label>Título </label>
                                    <span>
                                        <input type={"color"} value={controlConfig.color_checkout_text_title} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_text_title: e.target.value } }) }} />
                                        {/* <label>{controlConfig.color_checkout_text_title}</label> */}
                                        <InputTextColor type='text' value={controlConfig.color_checkout_text_title} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_text_title: e.target.value } }) }} />
                                    </span>
                                </ItemColor>

                                <ItemColor>
                                    <label> {translations["thema-produto"][language.data]} </label>
                                    <span>
                                        <input type={"color"} value={controlConfig.color_checkout_background_product} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_background_product: e.target.value } }) }} />
                                        {/* <label>{controlConfig.color_checkout_background_product}</label> */}
                                        <InputTextColor type='text' value={controlConfig.color_checkout_background_product} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_background_product: e.target.value } }) }} />
                                    </span>
                                </ItemColor>

                                <ItemColor>
                                    <label> {translations["thema-nome"][language.data]} </label>
                                    <span>
                                        <input type={"color"} value={controlConfig.color_checkout_text_product} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_text_product: e.target.value } }) }} />
                                        {/* <label>{controlConfig.color_checkout_text_product}</label> */}
                                        <InputTextColor type='text' value={controlConfig.color_checkout_text_product} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_checkout_text_product: e.target.value } }) }} />

                                    </span>
                                </ItemColor>

                                <ItemColor>
                                    <label> {translations["thema-botao"][language.data]} </label>
                                    <span>
                                        <input type={"color"} value={controlConfig.color_button_checkout} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_button_checkout: e.target.value } }) }} />
                                        {/* <label>{controlConfig.color_button_checkout}</label> */}
                                        <InputTextColor type='text' value={controlConfig.color_button_checkout} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_button_checkout: e.target.value } }) }} />
                                    </span>
                                </ItemColor>

                                <ItemColor>
                                    <label> {translations["thema-texto-botao"][language.data]} </label>
                                    <span>
                                        <input type={"color"} value={controlConfig.color_message_button_checkout} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_message_button_checkout: e.target.value } }) }} />
                                        {/* <label>{controlConfig.color_message_button_checkout}</label> */}
                                        <InputTextColor type='text' value={controlConfig.color_message_button_checkout} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_message_button_checkout: e.target.value } }) }} />
                                    </span>
                                </ItemColor>
                            </Section>

                            <ButtonHelp>
                                <img src={IconBossVideo} />
                                <label> {translations["thema-ajuda"][language.data]}</label>
                                <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                            </ButtonHelp>

                            <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                            <BoxButtons>
                                <BoxButtonsItem onClick={() => { getDefaultColors() }}>
                                    Restaurar
                                </BoxButtonsItem>
                                <BoxButtonsItem color={true} onClick={() => { saveColors() }}>
                                    {translations["thema-salvar"][language.data]}
                                </BoxButtonsItem>
                            </BoxButtons>
                        </BarColors> :
                        <BarConfig>
                            {/* <Section>
                                <ItemTextArea style={{ position: "relative", }}>
                                    <label>{translations["thema-cabecalho"][language.data]}:</label>
                                    <textarea
                                        value={controlConfig.checkout_message_header}
                                        rows={4}
                                        maxLength="255"
                                        placeholder={"Cabeçalho da sua página"}
                                        onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { checkout_message_header: e.target.value } }) }}
                                    />
                                    <CounterItemsCircle porc={`${Math.round(controlConfig.checkout_message_header?.length / 255 * 100)}%`} colorBorder={controlConfig.checkout_message_header?.length < 255 ? "green" : "red"}>
                                        <label>{255 - controlConfig.checkout_message_header?.length}</label>
                                    </CounterItemsCircle>
                                </ItemTextArea>
                            </Section> */}

                            <Section>
                                <label>Cor</label>
                                <SelectCustom onChange={(event) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { checkout_color: event.target.value } }) }}>
                                    <option value="gray" selected={controlConfig.checkout_color == "gray" ? true : false}> Gray</option>
                                    <option value="red" selected={controlConfig.checkout_color == "red" ? true : false}>Red</option>
                                    <option value="yellow" selected={controlConfig.checkout_color == "yellow" ? true : false}>Yellow </option>
                                    <option value="green" selected={controlConfig.checkout_color == "green" ? true : false}>Green</option>
                                    <option value="blue" selected={controlConfig.checkout_color == "blue" ? true : false}>Blue</option>
                                    <option value="purple" selected={controlConfig.checkout_color == "purple" ? true : false}>Purple </option>
                                    <option value="pink" selected={controlConfig.checkout_color == "pink" ? true : false}>Pink </option>
                                </SelectCustom>
                            </Section>

                            <Section>
                                <ItemTextArea style={{ position: "relative", }}>
                                    <label>Título:</label>
                                    <textarea
                                        value={controlConfig.checkout_message_title}
                                        rows={screen < 800 ? 1 : 3}
                                        maxLength="255"
                                        placeholder={"Título da sua página."}
                                        onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { checkout_message_title: e.target.value } }) }}
                                    />
                                    <CounterItemsCircle porc={`${Math.round(controlConfig.checkout_message_title?.length / 255 * 100)}%`} colorBorder={controlConfig.checkout_message_title?.length < 255 ? "green" : "red"}>
                                        <label>{255 - controlConfig.checkout_message_title?.length}</label>
                                    </CounterItemsCircle>
                                </ItemTextArea>
                            </Section>

                            <Section>
                                <ItemTextArea style={{ position: "relative", }}>
                                    <label>Subtítulo:</label>
                                    <textarea
                                        value={controlConfig.checkout_message}
                                        rows={screen < 800 ? 1 : 3}
                                        maxLength="255"
                                        placeholder={"Subtítulo de sua página."}
                                        onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { checkout_message: e.target.value } }) }}
                                    />
                                    <CounterItemsCircle porc={`${Math.round(controlConfig.checkout_message?.length / 255 * 100)}%`} colorBorder={controlConfig.checkout_message?.length < 255 ? "green" : "red"}>
                                        <label>{255 - controlConfig.checkout_message?.length}</label>
                                    </CounterItemsCircle>
                                </ItemTextArea>
                            </Section>
                            <Section>
                                <label style={{ marginBottom: "10px" }}> {translations["thema-botoes"][language.data]}: </label>
                                <ItemText style={{ marginBottom: "10px" }}>
                                    <label>Download</label>
                                    <input
                                        type={"text"}
                                        value={controlConfig.checkout_button}
                                        onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { checkout_button: e.target.value } }) }}
                                        placeholder={"Baixar"}
                                    />
                                </ItemText>

                                <ItemText>
                                    <label>Link</label>
                                    <input
                                        type={"text"}
                                        value={controlConfig.checkout_button_link}
                                        onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { checkout_button_link: e.target.value } }) }}
                                        placeholder={"Baixar"}
                                    />
                                </ItemText>
                            </Section>

                            {
                                window.screen.height > 800 &&
                                <>
                                    <ButtonHelp>
                                        <img src={IconBossVideo} />
                                        <label> {translations["thema-ajuda"][language.data]}</label>
                                        <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                                    </ButtonHelp>

                                    <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />
                                </>
                            }

                            <BoxButtons>
                                <BoxButtonsItem onClick={() => { getDefaultConfig() }}>
                                    Restaurar
                                </BoxButtonsItem>
                                <BoxButtonsItem color={true} onClick={() => { saveConfig() }}>
                                    {translations["thema-salvar"][language.data]}
                                </BoxButtonsItem>
                            </BoxButtons>
                        </BarConfig>
                }
            </BoxOptions>
            <BoxPreview img={imgBackground} style={{ position: "relative" }}>
                <PreviewCheckout />
                {/* <PreviewBody backgroundColor={controlConfig.color_checkout_background}>
                    <PreviewHeader backgroundColor={controlConfig.color_checkout_background_header} color={controlConfig.color_checkout_text_header}>
                        <label>{controlConfig.checkout_message_header}</label>
                    </PreviewHeader>
                    <PreviewTitle color={controlConfig.color_checkout_text_title}>
                        <p>{controlConfig.checkout_message_title}</p>
                        <label>{controlConfig.checkout_message}</label>
                    </PreviewTitle>
                    <PreviewProducts>
                        <PreviewCard color={controlConfig.color_checkout_text_product} colorButton={controlConfig.color_message_button_checkout} backgroundColor={controlConfig.color_checkout_background_product} backgroundColorButton={controlConfig.color_button_checkout}>
                            <label>
                                ebook-curso-ganhar-dinheiro-facil-na-internet-promoção.pdf.
                            </label>
                            <span>
                                <label>{controlConfig.checkout_button}</label>
                            </span>
                        </PreviewCard>

                        <PreviewCard color={controlConfig.color_checkout_text_product} colorButton={controlConfig.color_message_button_checkout} backgroundColor={controlConfig.color_checkout_background_product} backgroundColorButton={controlConfig.color_button_checkout}>
                            <label>
                                curso-ganhar-dinheiro-facil-na-internet-promoção
                            </label>
                            <span>
                                <label>{controlConfig.checkout_button_link}</label>
                            </span>
                        </PreviewCard>

                        <PreviewCard color={controlConfig.color_checkout_text_product} colorButton={controlConfig.color_message_button_checkout} backgroundColor={controlConfig.color_checkout_background_product} backgroundColorButton={controlConfig.color_button_checkout}>
                            <label>
                                imagem-alta-resolucao-promoção.svg
                            </label>
                            <span>
                                <label>{controlConfig.checkout_button}</label>
                            </span>
                        </PreviewCard>

                        <PreviewCard color={controlConfig.color_checkout_text_product} colorButton={controlConfig.color_message_button_checkout} backgroundColor={controlConfig.color_checkout_background_product} backgroundColorButton={controlConfig.color_button_checkout}>
                            <label>
                                produto-digital-especial-promoção
                            </label>
                            <span>
                                <label>{controlConfig.checkout_button_link}</label>
                            </span>
                        </PreviewCard>
                    </PreviewProducts>
                </PreviewBody> */}

                {
                    !controlSaveCheckout &&
                    <span style={{ position: "absolute", left: "50%", marginLeft: "-350px" }}>
                        <AlertSave>
                            <img src={IconBossSave} />
                            <span>
                                <label> {translations["thema-cuidado-salvar"][language.data]}</label>
                                <div>
                                    <label onClick={() => { dispatch({ type: "SAGA_GET_TEMPLATES" }) }} >
                                        DESCARTAR
                                    </label>
                                    <span onClick={() => { navOption != "config" ? saveColors() : saveConfig() }} >
                                        {/* {translation.thema.alertSalvar} */}
                                        {translations["thema-salvar"][language.data]}
                                    </span>
                                </div>
                            </span>
                        </AlertSave>
                    </span>
                }


            </BoxPreview>
        </Container>
    )
}

export default BoxCheckout
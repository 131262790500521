import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: 100%;
  flex-direction: column;
  width: 100%;
  
`;

export const ContainerLine = styled.div`
  display: flex;
  //align-items: center;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
  height: max-content;

  @media (max-width: 525px) {
    padding: 10px 20px 10px 20px;
  }
`;

export const BlockProducts = styled.div`
  width: 100%;
  /* height: 400px; */
  border-radius: 8px;
  background-color: white;
  padding: 40px;
  
  @media (max-width: 525px) {
    padding: 20px;
  }
`;


export const SubTitle = styled.p`
  color: #b8b8b8;
  font-weight: 500;
  size: 12px;
  display: flex;
  margin-bottom: 4px;
  @media (max-width: 460px) {
    margin-bottom: 24px;
    text-align: center;
  }
`;


export const Line = styled.tr`
  height: 50px;
  background: #fff;
  border-width: 2px 0px 0px 0px;
  border-color: #f5f6fb;
  width: 100%;
`;

export const TextLine = styled.th`
  padding: 10px;
  font-weight: 500;
  text-align: center;
  margin-top: 40px;
  display: flex;
  align-items: center;
  //background-color: red;
`;

export const ButtonSeeAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b76ff;
  width: 120px;
  height: 35px;
  border-radius: 4px;
  cursor: pointer;
`;

export const TitleButton = styled.p`
  font-size: 15px;
  color: #fff;
  margin: 0;
`;



export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;



export const Paginate = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    //background-color: red ;
    margin-top: 20px ;
`;

export const PaginateNav = styled.div`
    display: flex;
    height: 40px;
    margin-bottom: 5px;
    align-items: center;

    span{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    label{
        margin: 0px 10px;
    }
`;

export const PaginateInfor = styled.div`
    display: flex;
`;

export const ItemFilter = styled.label`
    display: flex;
    background-color: ${props => props.active ? "#1b76ff" : "transparent"};
    border: ${props => props.active ? "none" : "1px solid #dcdcdc"};
    padding: 7px 15px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    color: ${props => props.active ? "#ffffff" : "gray"};

    :hover{
      background-color: #1b76ff;
      color: #ffffff;
    }
`;


export const OptionsUpdate = styled.div`
  display: flex;
  background-color: #fff;
  position: relative;
  align-items: center;
  padding: 0px 20px;
  border-radius: 4px;
  gap: 5px;
  cursor: pointer;
  height: 40px;

  label{
    cursor: pointer;
    font-size: 14px;
    color: #222;
  }
`

export const MenuOption = styled.div`
  display: ${props => props.visible ? "flex" : "none"};
  background-color: #fff;
  position: absolute;
  flex-direction: column;
  top: 42px;
  right: 0px;
  z-index: 999;
  width: 200px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  //gap: 10px;
  //padding: 20px;

  label{
    color: gray;
    font-size: 14px;
    padding: 10px 20px;
    display: flex;
    cursor: pointer;
    gap: 5px;

    img{
      width: 16px;
      height: 16px;
      //background-color: red;
      margin-top: 2px;
      margin-right: 4px;
    }

    &:hover{
      background-color: #F0F6FF;
    }
    //background-color: red;
    
  }
  
`
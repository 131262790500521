import { Container, BoxLoading, FilterOption, Filters, BoxCards, TitleCard, ContentsCards, Line, SubTitle, } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import Card from "../../components/Card";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import { isVisibleScript } from "../../../../utils/displayScript";
import { platforms } from "./dataCards";
import { AiOutlineLoading } from "react-icons/ai";
import { AvaliacaoPopUpProvider } from "../../../../hooks/UseAvaliacaoPopUp";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { translations } from "../../../../utils/language";
import Title from "../../../../components/Title";
import LoadingLogo from "../../../../components/Loading-logo";
import ModalNewComponent from "../../components/modal-new";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import ModalInforVideo from "../../../../components/modal-infor-video";
import HelpClick from "../../../../components/HelpClick";
import LayoutNew from "../../../../components/tailwind/layout-new";
import { ContainerNew } from "../../../dashboard/pages/home-new/styled";
import ListIntegrations from "../../components/list-integrations";

const HomeNew = () => {
  const [statusNuvemshop, setStatusNuvemshop] = useState(false);
  const [statusShopify, setStatusShopify] = useState(false);
  const [statusDropi, setStatusDropi] = useState(false);
  const [statusCustom, setStatusCustom] = useState(false);
  const [statusHotmart, setStatusHotmart] = useState(false);
  const [statusMontink, setStatusMontink] = useState(false);
  const [statusRastreio, setStatusRastreio] = useState(false);
  const [statusLandPage, setStatusLandPage] = useState(false);
  const [statusYampi, setStatusYampi] = useState(false);
  const [statusWoocommerce, setStatusWoocommerce] = useState(false);
  const [integrationFinish, setIntegrationFinish] = useState(false);
  const statusPlatforms = useSelector((state) => state.reducerGetPlatforms);
  const dispatch = useDispatch();
  const location = useLocation();
  const [filter, setFilter] = useState("");
  const history = useHistory();
  const language = useSelector((state) => state.reducerLanguage);
  const [visibleModalInfor, setVisibleModalInfor] = useState(false)
  // const userId = useSelector((state) => state.reducerIntegrationUser.userId);

  const [statusSak, setStatusSak] = useState(false);

  useEffect(() => {

    if (statusPlatforms.data.length > 0) {
      for (let item of statusPlatforms.data) {
        switch (item.platform) {
          case "nuvemshop":
            setStatusNuvemshop(true);
            break;
          case "shopify":
            setStatusShopify(true);
            break;
          case "dropi":
            setStatusDropi(true);
            break;
          case "custom":
            setStatusCustom(true);
            break;
          case "hotmart":
            setStatusHotmart(true);
            break;
          case "montink":
            setStatusMontink(true);
            break;
          case "rastreio":
            setStatusRastreio(true);
            break;
          case "landpage":
            setStatusLandPage(true);
            break;
          case "sak":
            setStatusSak(true);
            break;
          default:
            break;
        }
      }
    } else {
      setStatusNuvemshop(false);
      setStatusShopify(false);
      setStatusDropi(false);
      setStatusCustom(false);
      setStatusHotmart(false);
      setStatusMontink(false);
      setStatusRastreio(false);
      setStatusLandPage(false);
      setStatusSak(false);
    }
  }, [statusPlatforms, location, integrationFinish]);

  useEffect(() => {
    dispatch({ type: "SAGA_GET_PLATFORMS_INTEGRATION" });
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch({ type: "SAGA_GET_USER_ID" });
  // }, [dispatch]);

  useEffect(() => {
    statusPlatforms?.data.map((item) => {
      if (item.platform === "Nuvemshop") {
        setStatusNuvemshop(true);
      }
      if (item.platform === "Yampi") {
        setStatusYampi(true);
      }
      if (item.platform === "Woocommerce") {
        setStatusWoocommerce(true);
      }
      if (item.platform === "Shopify") {
        setStatusShopify(true);
      }
      if (item.platform === "Sak") {
        setStatusSak(true);
      }
    });
  }, [statusPlatforms]);

  useEffect(() => {

    if (location.search.replace("?code=", "")) {
      // alert(location.search.replace("?code=", ""));

      dispatch({
        type: "SAGA_INTEGRATE_NEW_INTEGRATION",
        payload: {
          code: location.search.replace("?code=", ""),
          type: "Nuvemshop",
        },
      });
      history.push("/integracoes");
      // alert("aqui");
      setStatusNuvemshop(true);
    }
    // alert(statusNuvemshop);
  }, [dispatch, statusNuvemshop, location.search, history]);

  const getCards = () => {
    switch (filter) {
      case "":
        return (
          <>
            <Line>
              <BoxCards>
                <Card platform={platforms.nuvemshop} install={statusNuvemshop} />
                {language.data === "pt" && <Card platform={platforms.yampi} install={statusYampi} />}
                <Card platform={platforms.shopify} install={statusShopify} />
                <Card platform={platforms.sak} install={statusSak} />
              </BoxCards>
            </Line>
          </>
        );

      case "ecommerce":
        return (
          <Line>
            <TitleCard>Plataformas</TitleCard>
            <BoxCards>
              <Card platform={platforms.nuvemshop} install={statusNuvemshop} />
              {language.data === "pt" && (<Card platform={platforms.yampi} install={statusYampi} />)}
              <Card platform={platforms.woocommerce} install={statusWoocommerce} />
              <Card platform={platforms.shopify} install={statusShopify} />
            </BoxCards>
          </Line>
        );
      default:
        return (
          <>
            <Line>
              <TitleCard>Empreender</TitleCard>
              <BoxCards>
                <Card platform={platforms.dropi} install={statusDropi} />
                <Card platform={platforms.landpage} install={statusLandPage} />
                <Card platform={platforms.montink} install={statusMontink} />
              </BoxCards>
            </Line>
            <Line>
              <TitleCard>Plataformas</TitleCard>
              <BoxCards>
                <Card platform={platforms.nuvemshop} install={statusNuvemshop} />
                <Card platform={platforms.shopify} install={statusShopify} />
                <Card platform={platforms.hotmart} install={statusHotmart} />
              </BoxCards>
            </Line>
            <Line>
              <TitleCard>Outros</TitleCard>
              <BoxCards>
                <Card platform={platforms.custom} install={statusCustom} />
              </BoxCards>
            </Line>
          </>
        );
    }
  };


  return (
    <LayoutNew>
      <ListIntegrations />
    </LayoutNew>
    // <LayoutNew>
    //   <ContainerNew>
    //     <ModalInforVideo visible={visibleModalInfor} setVisibleModal={setVisibleModalInfor} video={`https://www.youtube.com/embed/${language.data == "es" ? "KJQxWXLFcJA" : "_EMJ2cSI4O8"}`} />
    //     <ModalNewComponent />
    //     <Modal />
    //     {
    //       statusPlatforms.loading ?
    //         <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
    //           <LoadingLogo />
    //         </div> :
    //         <AvaliacaoPopUpProvider>

    //           <Contents>
    //             {statusPlatforms.loading ? (
    //               <BoxLoading>
    //                 <span>
    //                   <AiOutlineLoading size={30} color={"#20d489"} />
    //                 </span>
    //               </BoxLoading>
    //             ) : (
    //               <>
    //                 <Container >
    //                   <Title marginBottom="16px">
    //                     Integrações
    //                   </Title>
    //                   <SubTitle>
    //                     Integre sua plataforma e comece a vender.
    //                   </SubTitle>
    //                   {getCards()}
    //                 </Container>
    //               </>
    //             )}
    //             {
    //               !statusPlatforms.loading &&
    //               <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
    //                 <div onClick={() => { setVisibleModalInfor(true) }}>
    //                   <HelpClick text={translations["alert-integracoes"][language.data]} />
    //                 </div>
    //               </div>
    //             }
    //           </Contents>
    //         </AvaliacaoPopUpProvider>

    //     }


    //     {isVisibleScript(false)}
    //   </ContainerNew>
    // </LayoutNew>
  );
};

export default HomeNew;

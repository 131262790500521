
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosClose } from "react-icons/io";

import { Container, Close, BoxImage, BoxInfor, ItemMenu, BoxMenu } from "./styled";
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { translations } from '../../../../utils/language';
import { ArrowDownOnSquareIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, EnvelopeIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import ModalSearchPlatform from '../modal-search-platform';
import ModalSearchAllPlatform from '../Modal-Search-All-Platform';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalMenuSinc = (props) => {

    const dispatch = useDispatch()
    const language = useSelector((state) => state.reducerLanguage);

    const sendEmail = () => {
        // dispatch({
        //     type: "SAGA_RESEND_EMAIL",
        //     payload: { order: props.item },
        // });
    }

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <Container>
                <Close onClick={() => { props.setVisibleModal(false) }}><IoIosClose size={25} color={"gray"} /></Close>
                <BoxMenu>
                    <Item
                        image={< MagnifyingGlassIcon style={{ width: "32px" }} />}
                        action={() => { props.setVisibleModalSearchPlatform(true); props.setVisibleModal(false) }}
                        item={props.item}
                        title={"Pedido"}
                        desc={"Buscar pedido que ainda não sincronizou com o Dijital"} />

                    <Item
                        image={<ArrowPathIcon style={{ width: "32px" }} />}
                        action={() => { dispatch({ type: "SAGA_SEARCH_PLATFORM_ALL", payload: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform }); props.setVisibleModal(false) }}
                        item={props.item}
                        title={"Todos os pedidos"}
                        desc={"Sincronizar pedidos com o Dijital"} />

                </BoxMenu>
            </Container>
        </Modal>
    )
}

export default ModalMenuSinc


const Item = (props) => {

    const dispatch = useDispatch()

    useEffect(() => {
        //console.log("props_", props)
    }, [props])


    const [hover, setHover] = useState(false)


    return (
        <ItemMenu onClick={() => { props.action() }} hover={hover} onMouseLeave={() => { setHover(false) }} onMouseEnter={() => { setHover(true) }}>
            <BoxImage hover={hover}>
                {props.image}
            </BoxImage>
            <BoxInfor>
                <label>{props.title}</label>
                <p>{props.desc}</p>
            </BoxInfor>
        </ItemMenu>
    )
}


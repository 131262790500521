import styled from "styled-components";

export const ContainerNew = styled.div`
    display: flex;
    min-height: calc(100vh - 130px);
    flex-direction: column;
    gap: 30px;
    //background-color: red;

    @media screen and (max-width: 1024px) {
        min-height: calc(100vh - 200px);
        //background-color: red;
    }
`
export const ConatainerLoader = styled.div`
    display: flex;
    //background-color: red;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 130px);
`

export const Control = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 20px;
    
    @media screen and (max-width: 1200px) {
        //min-height: calc(100vh - 200px);
        //background-color: yellow;
        margin-left: 0px;
        margin-top: 30px;
    }
`
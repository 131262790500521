import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    min-height: 100%;
    flex-direction: column;
    //padding-bottom: 200px;
    margin: 30px;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;

`;

export const Cupom = styled.div`
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    background: white; 
    border-radius: 10px; 
    padding: 30px;
`




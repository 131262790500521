export const ThemeDefault = {
  colors: {
    layout: {
      primary: "#FFFFFF",
      secondary: "#F2F2FD",
    },
    font: {
      // default: "#5e627",
      // default: "#5e5268",
      default: "#000",
      active: "#20d489",
    },
    buttons: {
      primary: "#FFFFFF",
      secondary: "#FBF9F6",
      border: "#d3d3d3",
    },
  },
};

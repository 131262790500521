import { put, call, delay } from "redux-saga/effects";
import {
  POST,
  GET,
  PUT,
  DELETE,
  GET_ADMIN,
  POST_ADMIN,
  POST_ADMIN_CUSTOM,
  GET_ADMIN_CUSTOM1,
  PUT_ADMIN,
} from "../../../../services/api";

export function* getList(action) {
  yield put({ type: "ADMIN_LIST_REQUEST" });
  //yield delay(2000)
  const result = yield call(GET_ADMIN, {
    endpoint: `/users/index?search=${action.payload.search}&perPage=${action.payload.perPage}&page=${action.payload.page}&isIntegrated=${action.payload.isIntegrated}&isDesactived=${action.payload.isDesactived}&platforms=${action.payload.platforms}&isTrial=${action.payload.isTrial}&isExpired=${action.payload.isExpired}`,
  });
  if (result === false) {

    yield put({ type: "ADMIN_LIST_ERROR" });
  } else {
    yield put({ type: "ADMIN_LIST_SUCCESS", payload: result.data.data });
  }
}

export function* deleteCard(action) {
  yield put({ type: "ADMIN_DELETE_CARD_REQUEST" });
  const result = yield call(GET_ADMIN, {
    endpoint: `/admin/removecard/${action.payload.id}`,
  });
  if (result === false) {
    yield put({ type: "ADMIN_DELETE_CARD_ERROR" });
  } else {
    yield put({ type: "ADMIN_DELETE_CARD_SUCCESS", payload: result.data });
  }
}

export function* debug(action) {
  yield put({ type: "ADMIN_DEBUG_REQUEST" });
  const result = yield call(POST_ADMIN, {
    endpoint: `/admin/debug`,
    data: action.payload,
  });
  if (result === false) {
    yield put({ type: "ADMIN_DEBUG_ERROR" });
  } else {

    yield localStorage.setItem(
      "depoimentos@login",
      JSON.stringify(result.data.data)
    );
    yield put({ type: "ADMIN_DEBUG_SUCCESS", payload: result.data.data });
  }
  yield put({ type: "ADMIN_DEBUG_RESET" });
}

export function* updateExpire(action) {
  // yield put({ type: "ADMIN_DEBUG_REQUEST" });
  yield put({ type: "ADMIN_EXPIRE_REQUEST" });
  const result = yield call(PUT_ADMIN, {
    endpoint: `/admin/update_expire`,
    data: action.payload,
  });
  if (result === false) {
    yield put({ type: "ADMIN_EXPIRE_ERROR" });
  } else {
    yield put({ type: "ADMIN_EXPIRE_SUCCESS" });
    action.payload.closeModal();
  }
}

export function* getIntegrations(action) {
  // yield put({ type: "ADMIN_DEBUG_REQUEST" });
  yield put({ type: "ADMIN_EXPIRE_REQUEST_INTEGRATION" });
  const result = yield call(GET_ADMIN, {
    endpoint: `/admin/get_integrations?perPage=10&page=${action.payload.page}`,
  });
  if (result === false) {
    yield put({ type: "ADMIN_LIST_ERROR_INTEGRATION" });
  } else {
    yield put({
      type: "ADMIN_LIST_SUCCESS_INTEGRATION",
      payload: result.data,
    });
  }

}

export function* getIntegrationsNuvem(action) {
  // yield put({ type: "ADMIN_DEBUG_REQUEST" });
  yield put({ type: "ADMIN_EXPIRE_REQUEST_INTEGRATION" });
  const result = yield call(GET_ADMIN, {
    endpoint: `/admin/get_by_platform?filter=${action.payload.filter}`,
  });

  if (result === false) {
    yield put({ type: "ADMIN_LIST_ERROR_INTEGRATION" });
  } else {
    yield put({
      type: "ADMIN_LIST_SUCCESS_INTEGRATION",
      payload: result.data,
    });
  }
}

export function* testIntegrations(action) {
  // yield put({ type: "ADMIN_DEBUG_REQUEST" });
  const result = yield call(POST_ADMIN, {
    endpoint: `/admin/test_integrations`,
    data: action.payload,
  });
  if (result === false) {
    alert("INTEGRAÇÃO INVÁLIDA");
  } else {
    alert("INTEGRAÇÃO VÁLIDA");
  }
}

export function* dropi(action) {

  yield put({ type: "ADMIN_DROPI_REQUEST", payload: action.payload.id });
  const result = yield call(GET_ADMIN_CUSTOM1, {
    endpoint: `/admin/products/${action.payload.platform_id}`,
  });

  if (result.data.data.length > 0) {
    for (const product of result.data.data) {
      //yield put({ type: "ADMIN_DROPI_REQUEST", })
      const resultItem = yield call(POST_ADMIN_CUSTOM, {
        token: action.payload.token,
        endpoint: `/api/v1/resync/dropi`,
        data: product,
      });
    }
    yield put({ type: "ADMIN_DROPI_SUCCESS", payload: result.data.data });
  } else {

  }
  yield put({ type: "ADMIN_DROPI_RESET" });
  // if (result === false) {
  //     yield put({ type: "ADMIN_DEBUG_ERROR" })
  // } else {
  //    
  //     yield localStorage.setItem('depoimentos@login', JSON.stringify(result.data.data))
  //     yield put({ type: "ADMIN_DEBUG_SUCCESS", payload: result.data.data })
  // }
  // yield put({ type: "ADMIN_DEBUG_RESET" })
}

export function* addMonths(action) {
  
  yield put({ type: "ADMIN_ADD_TIME_REQUEST", })
  const result = yield call(POST_ADMIN, { endpoint: `/admin/addmonths`, data: action.payload })
  if (result === false) {
    yield put({ type: "ADMIN_ADD_TIME_ERROR" })
  } else {
    
    yield localStorage.setItem('depoimentos@login', JSON.stringify(result.data.data))
    yield put({ type: "ADMIN_ADD_TIME_SUCCESS", payload: result.data.data })

    //const result2 = yield call(GET_ADMIN, { endpoint: `/users?search=&perPage=20&page=1&isIntegrated=false&isDesactived=false&platforms=` })
    //yield put({ type: "ADMIN_LIST_SUCCESS_control", payload: result2.data.data })
  }
  yield put({ type: "ADMIN_ADD_TIME_RESET" })
}

export function* verifyWebhook(action) {
  yield put({ type: "ADMIN_VERIFY_WEBHOOK_CARD_REQUEST" });
  const result = yield call(GET_ADMIN, { endpoint: `/webhook?user_id=${action.payload.id}`, });
  if (result === false) {
    yield put({ type: "ADMIN_VERIFY_WEBHOOK_CARD_ERROR" });
  } else {
    yield put({ type: "ADMIN_VERIFY_WEBHOOK_CARD_SUCCESS", payload: result.data });
  }
}
import styled from "styled-components";

export const Container = styled.div`
  /* width: calc(100% - 310px); */
  /* width: 100%; */
  //min-height: 75px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  gap: 1rem;
  position: sticky;
  //margin-top: 35px;
  //border-bottom: 1px solid #D3DAE3;
  //background-color: #fff;
  height: 60px;
  z-index: 2;
  background-color: transparent;
  margin-top: 20px;
`;

export const BoxBarApps = styled.div`
  //background-color: red ;
  //display: flex;
`;

export const Title = styled.h3`
  display: flex;
  min-width: 150px;
  text-transform: capitalize;
  font-weight: bold;
  color: gray;

  //background-color: red ;

  @media screen and (max-width: 768px) {
    /* justify-content: center; */
    align-items: center;
    width: 100%;
  }

  img {
    position: absolute;
    @media (min-width: 768px) {
      display: none;
    }
  }
`;

export const SectionOptions = styled.div`
  font-size: 18px;
  display: flex;
  flex: 1;
  height: 90%;
  justify-content: flex-end;
  align-items: center;
  //background-color: red ;

  @media screen and (max-width: 489px) {
    /* justify-content: center; */
    align-items: center;
  }
`;

export const IconMenu = styled.span`
  display: flex;
  margin-left: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  background-color: ${(props) => props.selectedMenu && "#D3D3D3"};

  :hover {
    background-color: #d3d3d3;
  }
`;

export const UserDropdown = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  label {
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  @media screen and (max-width: 440px) {
    /* .user__name {
      display: none;
    } */
  }

  div {
  }
`;

//Dropdown

export const UserDropdownSubMenu = styled.div`
  z-index: 1;
  position: absolute;
  margin-top: 45px;
  flex-direction: column;
  display: ${(props) => (props.visible ? "flex" : "none")};
  background-color: white;
  box-shadow: 0 0 2em #dcdbda;
  padding: 20px;
  border-radius: 5px;
  right: 0px;
  top: 0px;
  color: #5e6278;
  //background-color: red;

  div:has(div) {
    background-color: blue;
  }

  span {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    //background-color: gray ;

    :hover {
      opacity: 0.7;
    }
    label {
      display: flex;
      margin-left: 0px;
      font-size: 14px;
      white-space: nowrap;
      //color: #5e6278;
      //background-color: green ;
    }
    label:nth-child(2) {
      font-size: 16px;
    }
  }
`;

export const UserDropdownSubMenuInfor = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  //background-color: red ;

  label {
    font-size: 12px;
    margin-bottom: 1px;
    white-space: nowrap;
    cursor: auto;
  }

  label:nth-child(1) {
    font-size: 16px;
  }
`;

export const SubMenu = styled.div`
  display: none;
  :hover {
  }
`;

export const Nav = styled.nav`
  display: inline-block;
  margin: 2rem auto 0;
  background: red;
  color: #fff;
  text-align: left;

  ul {
    line-height: 45px;
    font-weight: 700;
    text-transform: uppercase;

    li {
      display: inline-block;
      position: relative;

      &:hover {
        //background-color: purple;

        .nav__submenu {
          display: block;
        }
      }
    }
  }
`;

export const ChaveEmpreender = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  //cursor: pointer;
  flex-direction: column;

  span {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;

    label {
      margin-left: 5px;
      font-size: 14px;
      cursor: pointer;
      color: ${(props) => (props.selectedMenu ? "#20D489" : "#5e6278")};
    }
  }

  span:nth-child(2) {
    cursor: pointer;
    label {
      font-size: 14px;
    }
  }

  span:nth-child(1) {
    cursor: pointer;
    label {
      font-size: 14px;
      color: #20d489;
    }
  }
`;

export const Notify = styled.div`
  margin: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center !important;
  cursor: pointer;
  position: relative;
`;

export const SubMenuNotify = styled.span`
  z-index: 1;
  position: absolute;
  flex-direction: column;
  display: ${(props) => (props.visible ? "flex" : "none")};
  background-color: white;
  box-shadow: 0 0 2em #dcdbda;
  padding: 20px;
  border-radius: 5px;
  top: 0px;
  right: 0px;
  margin-top: 35px;
  color: #5e6278;
  cursor: auto;

  span {
    display: flex;
    align-items: center;
    :hover {
      opacity: 0.7;
    }
    label {
      margin-left: 0px;
      font-size: 14px;
      cursor: pointer;
      white-space: nowrap;
    }
  }
`;

export const QtdNotify = styled.div`
  //background: red;
  position: absolute;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    display: flex;
    font-size: 7px;
    width: 15px;
    height: 15px;
    background-color: #f13f6c;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: -25px -25px 0px 0px;
    color: white;
    padding: 0px;
  }
`;

export const TooltipMenu = styled.div`
  //background-color: green;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  :hover {
    label {
      visibility: visible;
    }
  }

  label {
    display: flex;
    position: absolute;
    background: black;
    visibility: hidden;
    border-radius: 5px;
    max-width: 350px !important;
    width: max-content;
    padding: 5px 10px;
    transform: translateX(-100%);
    margin-top: -70px;
    margin-left: 35px !important;
    font-size: 12px !important;
    color: white;

    :after {
      content: "";
      position: absolute;
      top: 100%;
      right: 10px;
      border-top: 8px solid black;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }

  svg {
    margin-left: 5px;
    position: relative;
  }
`;


export const Trial = styled.label`
  display: flex;
  background-color: #FFC400;
  padding: 10px 20px;
  color: #5e6278;
  margin-right: 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  span{
    margin-left: 5px;
  }

  @media screen and (max-width: 765px) {
    display: none;
  }
`

export const BarStorage = styled.div`
  display: flex;
  margin-right: 20px;
  //background-color: purple;
  //padding: 20px;
  align-items: center;
  gap: 15px;
  position: relative;

  p{
    display: flex;
    margin: 0px;
    padding: 0px;
    background-color: #FFC400;
    font-size: 11px;
    margin-left: 5px ;
    cursor: pointer;
    padding: 0px 10px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

  }

  @media screen and (max-width: 765px) {
    display: none;
  }

`

export const Storage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  :hover{
    span:nth-child(3){
      //background-color: red !important;
      display: flex;
    }
  }

  
`

export const Bar = styled.div`
    display: flex;
    min-width: 200px;
    min-height: 30px;
    max-height: 30px;
    background-color: #dcdcdc;
    //border-radius: 15px;
    position: relative;

  label{
    display: flex;
    font-size: 14px;
    position: absolute;
    height: 30px;
    //background-color: red;
    width: 200px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color:  #000000BB;
  }

  div{
    display: flex;
    min-width: ${props => props.size};
    background-color: ${props => props.color};
    position: absolute;
    min-height: 30px;
    //border-radius: 15px 0px 0px 15px;
    
  }
`

export const BarInfor = styled.div`
  display: flex;
  //background-color: yellow;
  justify-content: space-between;
  padding: 0px 5px;
  margin-bottom: 8px;

  label{
    display: flex;
    font-size: 9px;
    font-weight: bold;
    color: #00000099;
  }

`

export const TitleDetail = styled.label`
  display: flex;
  font-weight: bold;
  color: #000000AA;
  font-size: 32px;
`

export const DetailStorage = styled.span`
  display: none;
  position: absolute;
  width: 300px;
  //height: 500px;
  background-color: white;
  top: 43px;
  left: 0px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 0 2em #dcdbda;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;

`

export const CircleInfor = styled.span`
  display: flex;
  min-width: 200px;
  min-height: 200px;
  //background-color: red;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  //background: conic-gradient(#F13F6C 0% 10% , #0BACFF 11% 20%, #21D487 21% 30%,  #f0ad4e 31% 40%,  #80808099 41% 99%, #ffffff 0% 100%);
  background: ${props => `conic-gradient(${props.imageColor} ${props.imageStart} ${props.imageEnd} ,  ${props.pdfColor} ${props.pdfStart} ${props.pdfEnd}, ${props.videoColor} ${props.videoStart} ${props.videoEnd}, ${props.othersColor} ${props.othersStart} ${props.othersEnd},  #80808099 41% 99%, #ffffff 0% 100%);`};

  div{
    display: flex;
    min-width: 170px;
    min-height: 170px;
    background-color: #fff;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    //flex-direction: column;

    label{
      font-size: 16px;
      font-weight: initial;
      strong{
        font-size: 70px;
        font-weight: bold;
        margin-right: 5px;
        color: #000000AA;
      }
    }

  }

`

export const InforDetail = styled.div`
  display: flex;
  position: initial;
  //background-color: red;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  gap: 5px;
  width: 100%;
  padding: 0px 20px;

`

export const ItemDetail = styled.span`
    display: flex;
    background-color: ${props => `${props.color}33`};
    position: initial;
    //background-color: yellow;
    gap: 10px;
    height: 30px;
    align-items: center;
    padding: 0px 10px 0px 0px;
    border-radius: 4px;


    span{
      display: flex;
      min-height: 30px;
      min-width: 30px;
      border-radius: 4px 0px 0px 4px;
      background-color: ${props => `${props.color}`};
      position: initial;

      div{

      }
    }

    div{
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      align-items: center;

      label{
        display: flex;
        //background-color: green;
        position: initial;
        justify-content: left;
        align-items: center;
        height: 100%;
        font-size: 11px;
        color: ${props => `${props.color}`};
      }

      p{
        display: flex;
        margin: 0px;
        padding: 0px;
        background-color: transparent;
        font-size: 13px;
        color: ${props => `${props.color}`};
        font-weight: 500;
      }
    }

    
`
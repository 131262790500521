import styled from "styled-components";


export const MenuItens = styled.span`
    display: ${props => props.visible ? "flex" : "none"};
    background-color: #ffffff;
    position: absolute;
    flex-direction: column;

    box-shadow: 0 0 2em #dcdbda; 
    //padding: 20px;
    border-radius: 5px;
    right: -5px;
    top: 45px;
    z-index: 99999;
    gap: 5px;
    width: max-content;
    height: max-content;
    padding-top: 5px;
    

    p{
        font-size: 12px;
        //background-color: red;
        margin: 0px;
        padding: 5px 20px;
        border-bottom: 1px solid #f1f1f1;
        :hover{
            opacity: 0.7;
        }
    }

`

export const MenuItensText = styled.span`
    display: ${props => props.visible ? "flex" : "none"};
    background-color: #ffffff;
    position: absolute;
    flex-direction: column;

    box-shadow: 0 0 2em #dcdbda; 
    //padding: 20px;
    border-radius: 5px;
    right: -5px;
    top: 45px;
    z-index: 99999;
    gap: 5px;
    width: max-content;
    height: max-content;
    padding-top: 5px;
    

    p{
        font-size: 12px;
        //background-color: red;
        margin: 0px;
        padding: 5px 20px !important;
        border-bottom: 1px solid #f1f1f1;
        color: #000000 !important;
        :hover{
            opacity: 0.7;
        }
    }

`


export const SectionInfor = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 5px;
`

export const SectionAnexos = styled.div`
    display: flex;
    gap: 10px;

`

export const Name = styled.label`
    display: flex;
    margin-left: 5px;
    font-size: 14px;
    //color: ${props => props.active ? "red" : "#d3d3d3"};
`

export const SectionActions = styled.div`
    display: flex;
    //background-color: red;
    align-items: center;
    gap: 20px;

`

export const ItemAction = styled.span`
    display: flex;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    border:  ${props => props.border ? "1px solid #1B76FF" : "none"};
    position: relative;
   
    label{
        display: none;
        position: absolute;
        bottom: 52px;
        right: 0;
        background-color: #000000;
        padding: 10px 20px;
        border-radius: 8px;
        color: #ffffff;
        width: max-content;
        font-size: 12px;

        :after {
            content: "";
            position: absolute;
            top: 100%;
            right: 12px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
         }
    }

    :hover{
        background-color: #ffffff;

        label{
            display: flex;
        }
    }
`

export const BoxOptions = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding-top: ${props => props.open ? "20px" : "0px"};
    max-height: ${props => props.open ? "initial" : "0px"};
    transition: padding-top 0.5s ease-out, max-height 0.5s ease-out;
    overflow: hidden;

    label{
        color: gray;
    }

`

export const ItemOption = styled.div`
    display: flex;
    //background-color: gray;
    border-radius: 8px;
    gap: 5px;
    border: 1px solid #f1f1f1;
    padding: 20px !important;
    //flex-direction: column;

        
`



export const ItemActionText = styled.span`
    display: flex;
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    //border:  ${props => props.border ? "1px solid #1B76FF" : "none"};
    position: relative;
    gap: 5px;

    background-color: ${props => props.active ? "#1B76FF" : "#d3d3d3"} ;

    p{
        display: flex;
        margin: 0px;
        padding: 0px;
        font-size: 14px;
        color: #FFFFFF;
    }
   
    label{
        display: none;
        position: absolute;
        bottom: 55px;
        right: 0;
        background-color: #000000;
        padding: 10px 20px;
        border-radius: 8px;
        color: #ffffff;
        width: max-content;
        font-size: 12px;

        :after {
            content: "";
            position: absolute;
            top: 100%;
            right: 12px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
         }
    }

    :hover{
        //background-color: #ffffff;

        label{
            display: flex;
        }
    }
`
const INITIAL_STATE = [];

const ControlSelectProducts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PRODUCTS_CONTROL_SELECT_GET":
      return state;
    case "PRODUCTS_CONTROL_SELECT_SET":
      for (let iterator of state) {
        if (iterator === action.payload.id) return state;
      }
      return [...state, action.payload.id];
    case "PRODUCTS_CONTROL_SELECT_REMOVE":
      let filter = state.filter((value) => {
        return value !== action.payload.id;
      });
      return filter;
    case "PRODUCTS_CONTROL_SELECT_RESET":
      return [];
    default:
      return state;
  }
};

export default ControlSelectProducts;

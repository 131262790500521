import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    min-height: 200x;
    margin: 15px 0px;
    flex-direction: column; 
    position: relative;
`;

export const BoxContents = styled.div`
    background-color: white;
    display: flex;
    flex: 1;
    padding: 10px 10px 10px 10px;
    border-radius: 0px 5px 5px 0px;
    text-align: justify;
    border-left: 2px solid ${props => props.color};
    align-items: center;
    //flex-direction: column;
`;

export const Img = styled.img`
    max-width: 200px;
    border-radius: 5px;
`;

export const SubTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    label{
        margin: 0px 10px;
        font-size: 14px;
    }

    span{
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
`;

export const BoxContact = styled.span`
    //background-color: red;

    label{
        font-size: 14px;
        margin-left: 0px;

        a{
            margin-left: 5px;
            outline: none;
            text-decoration: none;
            color: ${props => props.theme.colors.font.active};
            font-weight: 500;
   
            :hover{
                text-decoration: underline;
            }
        }
    }
`;

export const BoxOptions = styled.div`
    display: flex;
    height: 50px;
    align-items: center;


`;

export const ButtonAnswered = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 90px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.active ? "#19B674" : "#E4FFF4"};
    color: ${props => props.active ? "white" : "#21D487"};
    //cursor: ${props => props.active ? "pointer" : "no-drop"};
    cursor: pointer;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }
`;

export const ButtonDelete = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 90px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    background-color: #F13F6C;
    color: white;
    cursor: pointer;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }
`;

export const ButtonHidden = styled.button`
    display: flex;
    border: none;
    outline: none;
    width: 90px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.active ? "#0098DA" : "#F1FAFF"};
    color: ${props => props.active ? "white" : "#0098DA"};
    //cursor: ${props => props.active ? "pointer" : "no-drop"};
    cursor: pointer;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
    }

`;

export const BoxContentsLeft = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

// export const Status = styled.div`
//     background-color: ${props => props.color};
//     position: absolute;
//     right: 0px;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 10px;
//     margin: 5px;
// `;


export const BoxCode = styled.div`
    
    height: 75px;
    display: flex;
    align-items: center;
    

    p{
        height: 40px;
        min-width: 120px;
        border-radius: 5px;
        //border: 1px dashed #138062;
        border: ${props => props.active ? "1px dashed #138062" : "1px dashed #F1BC00;"};
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: #ECFAE5; #f0ad4e
        background-color: ${props => props.active ? "#ECFAE5" : "#FFF4CC"} ; 
        color: ${props => props.active ? "#138062" : "#F1BC00"} ;
        padding: 0px 5px;
        text-transform: uppercase;
    }

    label{
        font-size: 12px;
        margin: 0px 10px;
    }
`;

export const BoxMessage = styled.label`
    

    strong{
        margin-right: 10px;
    }
`;

export const BoxDescription = styled.label`
    strong{
        margin-right: 10px;
    }
`;

export const BoxIntegration = styled.div`
    background-color: #2C3357;
    display: flex;
    justify-content: center;
    color: white;
    height: 30px;
    align-items: center;
    margin: 5px 0px;
`;


export const BoxInfor = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`;


export const Status = styled.div`
    background-color: ${props => props.color};
    position: absolute;
    right: 0px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 5px;
    cursor: help;

    :hover div{
        visibility: visible;
        opacity: 0.9;
        top: 30px;
        z-index: 999;
    }
`;

export const Tooltip = styled.div`

        display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 5px 10px;
        transform: translateX(-100%);
        left: 0;
        margin-top: -70px;
        margin-left: 35px;

        label{
            font-size: 12px;
            color: white;
        }
        :after{
            content: '';
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    
`;
import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  height: ${props => props.large ? "300px": "160px"};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  font-weight: 500;
  gap: 20px;
  //justify-content: space-between;
`;

export const Title = styled.label`
  font-size: 18px;
  color: #000;
  ///margin-bottom: 20px;
`;

export const SubTitle = styled.label`
  font-size: 12px;
  //margin-top: -40px;
`;

export const Search = styled.div`
  //background-color: yellow;
  height: 40px;
  display: flex;
  justify-content: space-between;

  button {
    width: 40px;
    height: 40px;
    margin-left: 20px;
    background-color: #1b76ff; //#FBF9F6 ;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    display: flex;
    width: 100px;
    flex: 1;
    background-color: #fbf9f6;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 0px 5px;
  }
`;

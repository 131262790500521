
const INITIAL_STATE = ""

const SearchQuestionProduct = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PRODUCT_ADD_SEARCH_QUESTIONS':
            return action.payload
        default:
            return state;
    }
}

export default SearchQuestionProduct
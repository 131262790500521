import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    overflow: auto;
    max-height: 90vh;
    gap: 20px;
`

export const Close = styled.div`
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`

export const TitleModal = styled.div`
    display: flex;
    //height: 50px;
    //background-color: yellow;
    flex-direction: column;
    justify-content: center;
`



export const BoxActions = styled.div`
    display: flex;
    //background-color: red;
    justify-content: end;


    label{
        display: flex;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        background-color:  #19B674;
        color: #ffffff;
        gap: 10px;
    }
`

export const BoxForm = styled.div`
    display: flex;
    //background-color: red;
`

export const InputCustom = styled.div`
    display: flex;
    width: 100%;
    height:40px ;
   

    input{
        display: flex;
        flex: 1;
        padding: 0px 10px;
        outline: none;
        border: none;
        background-color: #f1f1f1;
    }

    span{
        display: flex;
        width: 60px;
        height: 40px;
        justify-content: center;
        align-items: center;
        background-color:  #1B76FF;
        

        label{
            font-size: 14px;
            color: #ffffff;
        }
    }
`
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  background: #fff;
  ${(props) => `height: ${props.height}px`}
`;

export const Link = styled.a`
  text-decoration: none;
  color: #000;
  ${(props) => `color: ${props.color};`}
`;

export const Button = styled.div`
  /* ${(props) => `background-color: ${props.background}`}
  ${(props) => `border-radius: ${props.borderRadius}`} */
  text-decoration: none;
  color: #000;
  /* width: 93px; */
  padding: 4px 16px;
  /* border-radius: 4px; */
`;

export const ButtonBlock = styled.div`
  /* ${(props) => `background-color: ${props.background}`}
  ${(props) => `border-radius: ${props.borderRadius}`} */
  text-decoration: none;
  color: #000;
  /* width: 93px; */
  padding: 4px 16px;
  background: #f5f6fb;
  /* border-radius: 4px; */
`;

export const TextButton = styled.p`
  ${(props) => `color: ${props.color};`}
  margin: 0;
`;

export const Title = styled.h1`
  font-size: 40px;
  margin-bottom: 8px;
  text-align: center;
`;

export const BoxBuy = styled.div`
  width: 100%;
  height: 56px;
  background-color: #00e673;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
`;

export const TextBoxBuy = styled.h1`
  font-size: 16px;
  color: #fff;
  text-align: center;
`;

export const TitleMedias = styled.h1`
  color: #bfbfbf;
  font-size: 24px;
  margin-bottom: 32px;
  text-align: center;
`;

export const BoxProducts = styled.div`
  width: 70%;
  height: 48px;
  background-color: #f5f6fb;
  padding: 10px 32px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #f5f6fb;
  border-bottom: 0px;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const TitleBoxProducts = styled.p`
  color: rgba(0, 0, 0, 0.25);
`;

export const MediaLine = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 32px;
  border-bottom: 1px solid #f5f6fb;
`;

export const MediaName = styled.p`
  margin: 0;
`;

export const BoxMedias = styled.div`
  width: 70%;
  border: 1px solid #f5f6fb;
  border-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  max-height: 300px;
  overflow: auto;
  ${(props) => props.color && `background-color: ${props.color}`};
  @media (max-width: 600px) {
    width: 90%;
  }
`;

import { Alert } from "bootstrap";

const INITIAL_STATE = {
  error: false,
  loading: false,
  products: [],
  medias: [],
};

const Medias = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PRODUCTS_BY_MEDIAS_REQUEST":
      return {
        loading: true,
      };
    case "PRODUCTS_BY_MEDIAS_SUCCESS":
      return {
        products: action.payload,
      };
    case "MEDIAS_BY_PRODUCTS_SUCCESS":
      return {
        medias: action.payload,
      };
    default:
      return state;
  }
};

export default Medias;

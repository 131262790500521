import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: #f5f6fb;
  /* width: 200px; */
  ${(props) => !props.mediaName && `width: 28px`};
  border-radius: 20px;
  margin: 8px;
  cursor: pointer;
  :hover {
    label {
      visibility: visible;
    }
  }

  label {
    display: flex;
    position: absolute;
    background: black;
    visibility: hidden;
    border-radius: 5px;
    max-width: 350px !important;
    width: max-content;
    padding: 5px 10px;
    transform: translateX(-100%);
    margin-top: -70px;
    margin-left: 20px !important;
    font-size: 12px !important;
    color: white;

    :after {
      content: "";
      position: absolute;
      top: 100%;
      right: 10px;
      border-top: 8px solid black;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
`;

export const MediaName = styled.span`
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  @media (max-width: 1355px) {
    max-width: 80px;
  }
  @media (max-width: 1130px) {
    max-width: 50px;
  }
  @media (max-width: 1010px) {
    max-width: 30px;
  }
`;

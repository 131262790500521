import Modal from 'react-modal';
import { useEffect, useRef, useState } from "react";
import { RiCloseLine } from 'react-icons/ri';
import {  CloseIcon, Content, CupomHeader, Container } from './styled'
import Button from '../../../../components/Button/index'
import { POST_ERROR } from '../../../../services/api';
import { toast } from 'react-toastify';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '31px',
      marginRight: '-50%',
      border: 'none',
      borderRadius: '10px',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
    }
  };

const ModalCupom = ({ cupomInitialValue }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [cupomAtivo, setCupomAtivo] = useState(false);
    const cupom = useRef();

    useEffect(() => {
        setCupomAtivo(cupomInitialValue !== '')
    }, [cupomInitialValue])

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    async function setCupom () {
        const id = 0
        toast.loading("Aguarde...", { toastId: id });
        let { data } = await POST_ERROR({
            endpoint: "/payments/coupon", 
            data: { cupom: cupom.current.value }
        });

        toast.update(id, { render: data.description ?? "Erro inesperado", type: data.error?"error":"success", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        if(!data.error) {
            setCupomAtivo(true); 
        }
    }

    return (
        <Container>
            <div>
                <h3>Cupom de Desconto</h3>
                <p>{cupomAtivo ? 'Você tem um cupom de desconto ativo!' : 'Adicione agora um cupom de desconto!'} </p>
            </div>
            <div>
                <Button handleClick={openModal} width="143px" background="#FBF9F6" color="gray" border="#d3d3d3">{cupomAtivo? 'Ver detalhes' : 'Adicionar Cupom'} </Button>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <CloseIcon onClick={closeModal}><RiCloseLine/></CloseIcon>
                    <CupomHeader>
                        <strong>Cupom de desconto</strong>
                        <small>Cupom atual:</small>
                    </CupomHeader>
                    <Content>
                        <input placeholder="XXXXX" defaultValue={cupomInitialValue} ref={cupom} />
                        <Button handleClick={setCupom} width="240px" center="0 auto" marginTop="20px" background="#ffcc00" color="#5e0a07">{cupomAtivo ? 'Consultar/Atualizar Cupom' : 'Adicionar Cupom'} </Button>
                    </Content>
                </Modal>
            </div>
        </Container>
    );   
}

export default ModalCupom
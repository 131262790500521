
const INITIAL_STATE = {
    error: null,
    loading: null,
    data: [],
};

const EmailTeste = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "EMAIL_TESTE_REQUEST":
            return { loading: true };
        case "EMAIL_TESTE_SUCCESS":
            return { loading: false, error: false, data: action.payload };
        case "EMAIL_TESTE_ERROR":
            return { loading: false, error: true, data: action.payload };
        default:
            return state;
    }
}

export default EmailTeste
import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  display: flex;
  height: 686px;
  //width: calc(40% - 17.5px);
  border-radius: 10px;
  padding: 20px;
  flex-direction: column;
  flex: 1;

  @media screen and (max-width: 1200px) {
    margin-top: 30px;
    width: 100%;
  }
`;

export const ContainerLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 78px;
`;

export const Number = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const ContainerNumber = styled.div`
  width: 24px;
  height: 24px;
  background: #e6e7f8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.index === 0 &&
    `
    border-color: #006bf1;
  border-style: solid;
  border-width: 1px;
  `}
  ${(props) =>
    props.index === 1 &&
    `
    border-color: #F0A422;
  border-style: solid;
  border-width: 1px;
  `}
`;

export const ContainerProducts = styled.div`
  display: flex;
  flex-direction: column;
  height: 600px;
  ${(props) =>
    props.top &&
    `
  justify-content: space-between;
  `}
`;

export const Name = styled.p`
  font-size: 16px;
  color: #202223;
  font-weight: 500;
  margin: 0;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Image = styled.img`
  width: 50px;
  height: 70px;
  margin: 25px;
`;

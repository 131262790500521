import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background-color: #FFFFFF;
    height: 210px;
    margin-bottom: 30px;
    border-radius: 10px;
    padding: 16px;
    flex-direction: column;
`

export const ControlButton = styled.div`
    display: flex;
    //background-color: blue;
    height: 100%;
    align-items: center;
    justify-content: center;

    label{
        display: flex;
        background-color: #21D487;
        color: #fff;
        padding: 10px 20px;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
    }
`
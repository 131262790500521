import { toast } from "react-toastify";
import { put, call, delay } from "redux-saga/effects";
import { GET, GET_SCRIPT, PUT } from "../../../../services/api";

export function* getOrderData(action) {
  yield put({ type: "YOUR_MEDIAS_REQUEST", });
  //yield delay(3000)
  const result = yield call(GET_SCRIPT, { endpoint: `/scripts_store/${action.payload.order_id}/${action.payload.user_id}/${action.payload.store_id}`, });
  if (result.error === true) {
  
    yield put({ type: "YOUR_MEDIAS_ERROR", payload: result.data.response.data.description, });
  } else {
    yield put({ type: "YOUR_MEDIAS_SUCCESS", payload: result.data });
  }
}


import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "transparent",
        padding: "0px",
        margin: "0px",
        //borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};


const ModalVideo = (props) => {

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <div>
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/tO_qG87876g?si=0WmtX_vZoF9PKfPR"
                    //title="YouTube video player"
                    frameborder="0"
                    allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen="allowfullscreen"
                >
                </iframe>
            </div>
        </Modal>
    )
}

export default ModalVideo

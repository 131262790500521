import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const ContainerMedias = styled.div`
  display: flex;
  min-height: 60px;
  background-color: white;
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  justify-content: space-around;
  align-items: center;
`;

export const BlockMedias = styled.div`
  width: calc(100% - 60px);
  /* height: 400px; */
  border-radius: 8px;
  background-color: white;
  padding: 40px;
  margin: 30px;
  @media (max-width: 740px) {
    padding: 10px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ButtonAdd = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f2f2fd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const TitleButtonAdd = styled.p`
  color: #858585;
  size: 16px;
  display: flex;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const SubTitle = styled.p`
  color: #b8b8b8;
  font-weight: 500;
  size: 14px;
  display: flex;
  margin-bottom: 4px;
  align-items: center;
`;

export const LineLegend = styled.tr`
  background-color: white;
  height: 40px;
`;

export const Legend = styled.th`
  color: #6d7175;
  font-weight: 500;
  padding: 10px;
  font-size: 14px;
  @media (max-width: 525px) {
    padding: 10px;
    font-size: 10px;
  }
`;

export const Line = styled.tr`
  /* ${(props) => !props.noHeight && `height: 50px`}; */
  background: #fff;
  border-width: 2px 0px 0px 0px;
  border-color: #f5f6fb;
  width: 100%;
`;

export const TextLine = styled.th`
  padding: 10px;
  font-weight: 500;
  margin-top: 40px;
  display: flex;
`;

export const ButtonSeeAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b76ff;
  width: 120px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
`;

export const TitleButton = styled.p`
  font-size: 15px;
  color: #fff;
  margin: 0;
`;

export const Image = styled.img`
  ${(props) =>
    props.mediaOpen
      ? `  height: 38px;
  width: 38px;
  margin-right: 14px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #f5f6fb;
  border-radius: 3px;`
      : `  height: 0px;
  width: 0px;`}
  margin-right: 14px;
`;

export const ImageProduct = styled.img`
  height: 38px;
  width: 38px;
  margin-right: 14px;
`;

export const ContainerOptions = styled.div`
  /* height: 0px; */
  transition: 300ms ease;
  background: green;
  display: flex;
  /* width: 100%; */
  /* position: absolute; */
  /* position: absolute; */
  /* ${(props) => (props.mediaOpen ? ` height: 100px  ` : `height: 100px`)}; */
`;

export const ContainerOptionsFile = styled.div`
  /* display: none; */
  ${(props) =>
    props.mediaOpen
      ? `
  border: dashed #1B76FF;
  padding: 10px;
  width: 700px;
`
      : `
  width: 0%;
  height: 0px;
  opacity: 0;
`}
  transition: 300ms ease;
  border-radius: 14px;
  /* background-color: #fff; */
  align-items: center;
  justify-content: center;
  /* margin-bottom: 10px; */
  @media (max-width: 1250px) {
    width: 350px;
  }
  @media (max-width: 1010px) {
    width: 250px;
  }
  @media (max-width: 650px) {
    width: 0px;
    border: none;
  }
`;

export const LineOptions = styled.div`
  display: none;
  ${(props) =>
    props.mediaOpen &&
    `
      display: flex;
`}
  display: flex;
  border-radius: 14px;
  align-items: center;
  margin-bottom: 8px;
`;

export const ContainerActions = styled.div`
  background: #f9fafe;
  width: 100%;
  padding: 2px;
  @media (max-width: 600px) {
    width: 20px;
  }
  @media (max-width: 410px) {
    width: 20px;
  }
`;

export const OptionsFile = styled.div`
  display: none;
  background-color: #fff;
  margin-left: 20px;
  box-shadow: 0px 1px #c4c9f0;
  ${(props) =>
    props.mediaOpen &&
    `
      display: flex;
`}
  ${(props) =>
    props.last &&
    `
    background-color: red;
`}
  ${(props) =>
    props.first &&
    `
    margin-left: 0px;
`}
  transition: 300ms ease;
  padding: 8px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  /* @media (max-width: 550px) {
    margin-left: 10px;
  } */
`;

export const TitleOptions = styled.p`
  font-size: 0px;
  ${(props) =>
    props.last &&
    `
    color: #fff;
`}
  ${(props) =>
    props.mediaOpen &&
    `
    font-size: 14px;
`}
  font-weight: 400;
  margin: 0 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    @media (max-width: 1250px) {
      display: none;
      margin: 0;
    }
  }
`;

export const LegendFile = styled.p`
  font-size: 0px;
  ${(props) =>
    props.last &&
    `
    color: #fff;
`}
  ${(props) =>
    props.mediaOpen &&
    `
    font-size: 16px;
`}
  font-weight: bold;
  margin: 0;
  display: flex;
  @media (max-width: 500px) {
    width: 300px;
  }
  /* width: 300px;
  background: red; */
  /* align-items: center; */
  /* justify-content: center; */
`;

export const ContainerFile = styled.div`
  /* display: none; */
  width: 100%;
  background-color: #f9fafe;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

export const Block = styled.div`
  width: 49%;
  height: 328px;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  @media (max-width: 720px) {
    height: 280px;
  }
`;

export const BlockProducts = styled.div`
  width: 100%;
  /* height: 400px; */
  border-radius: 8px;
  background-color: white;
  padding: 20px;
`;

export const ContainerProducts = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  background: #fafbfd;
  justify-content: center;
  margin-top: 14px;
  flex-direction: column;
`;

export const TitleProducts = styled.div`
  font-size: ${(props) => props.size}px;
  font-weight: 500;
  margin: ${(props) => props.marginTB}px ${(props) => props.marginLR}px;
`;

export const Checkbox = styled.input`
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 4px;
  vertical-align: middle;
  border: 2px solid #000;
  margin-right: 8px;
  /* appearance: none; */
  /* -webkit-appearance: none; */
  /* outline: none; */
  padding: 5px;
  cursor: pointer;
  &:checked {
    background-color: #1b76ff;
  }
`;

export const Input = styled.input`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: #f9f9f9;
  margin-bottom: 20px;
  border-color: #d3d3d3;
  border-style: solid;
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`;

export const BoxLoading = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  //height: 100% ;
  //background-color: yellow ;
  margin-top: 50px;

  span {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Description = styled.td`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  padding: 10px;
  font-size: 14px;
  color: #202223;
  @media (max-width: 950px) {
    max-width: 50px;
  }
  /* @media (max-width: 525px) {
    max-width: 40px;
  } */
`;

export const Tooltip = styled.div`
  background-color: green;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  width: 1px;

  :hover {
    label {
      visibility: visible;
    }
  }

  label {
    display: flex;
    position: absolute;
    background: black;
    visibility: hidden;
    border-radius: 5px;
    max-width: 350px !important;
    width: max-content;
    padding: 5px 10px;
    transform: translateX(-100%);
    margin-top: -70px;
    margin-left: 35px !important;
    font-size: 12px !important;
    color: white;

    :after {
      content: "";
      position: absolute;
      top: 100%;
      right: 10px;
      border-top: 8px solid black;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }

  svg {
    margin-left: 5px;
    position: relative;
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #fff;
  border-color: #f5f6fb;
  border-width: 0.1;
  border-style: solid;
  border-radius: 50%;
  margin: 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  :hover {
    label {
      visibility: visible;
    }
  }

  label {
    display: flex;
    position: absolute;
    background: black;
    visibility: hidden;
    border-radius: 5px;
    max-width: 350px !important;
    width: max-content;
    padding: 5px 10px;
    transform: translateX(-100%);
    margin-top: -70px;
    margin-left: 130px !important;
    font-size: 12px !important;
    color: white;

    :after {
      content: "";
      position: absolute;
      top: 100%;
      right: 10px;
      border-top: 8px solid black;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }
`;

export const InputLimit = styled.input`
  width: 144px;
  height: 40px;
  border-radius: 4px;
  border-width: 1px;
  border-color: #c4c9f0;
  margin-right: 8px;
  padding: 4px;
  @media (max-width: 675px) {
    width: 50px;
  }
`;

export const TableMedias = styled.div`
  display: flex;
  //background-color: red;
  flex-direction: column;
  gap: 14px;
  
`;

export const TableMediasHeader = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 5px;
  height: 80px;
  border-radius: 4px;
  align-items: center;
  //background-color: red;
  margin-top: 20px;

  @media (max-width: 720px) {
    height: auto;
  }

`;

export const SectionCheck = styled.div`
  display: flex;
  //background-color: yellow;
  height: 100%;
`;

export const SectionBar = styled.div`
  display: flex;
  background-color: #1b76ff11;
  flex: 1;
  padding: 0px 20px;
  margin-left: 10px;
  border-radius: 4px;
  height: 100%;
  align-items: center;
  gap: 20px;

  @media (max-width: 720px) {
    flex-direction: column;
    height: auto;
    padding: 20px 0px;
  }


`;



export const SectionActions = styled.div`
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    //background-color: red;

    span{
        display: flex;
        width: 40px;
        height: 40px;
        //background-color: #f1f1f1;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        border: 1px solid #dcdcdc;
        position: relative;
        cursor: pointer;

        svg{
            color: gray;
        }

        :hover{
            background-color: #F13F6C;

            svg{
                color: #ffffff;
            }
            label{
                display: flex;
            }
        }

        label{
            display: none;
            position: absolute;
            font-size: 12px;
            background-color: #000000;
            width: max-content;
            left: 0px;
            top: -50px;
            padding: 10px 20px;
            border-radius: 4px;
            z-index: 999999;
            color: #ffffff;

            :after {
                content: "";
                position: absolute;
                top: 100%;
                left: 10px;
                border-top: 8px solid #000000;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
    }
`

export const CheckCustom = styled.div`
    display: flex;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    border: 1px solid #dcdcdc;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg{
        cursor: pointer;
        color: #1B76FF;
    }    
`

export const SearchCustom = styled.div`
    display: flex;
    //background-color: red;
    align-items: center;



    input{
        background-color: #ffffff;
        display: flex;
        outline: none;
        border: none;
        height: 40px;
        padding: 0px 10px;
        width: 300px;
        border-radius: 4px 0px 0px 4px;

          @media (max-width: 720px) {
            width: 100%;
          }
    }  
    
    span{
      display: flex;
      background-color: #1B76FF;
      height: 40px;
      width: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 0px 4px 4px 0px;
      cursor: pointer;
    }
`

export const IconFile = styled.span`
    display: flex;
    position: relative;
    cursor: pointer;

    img{
      display: flex;
      width: 30px;
      //height: 50px;
    }

    :hover{

        label{
            display: flex;
        }
    }

    label{
        display: none;
        position: absolute;
        bottom: 50px;
        left: -2px;
        background-color: #000000;
        padding: 8px 20px;
        border-radius: 8px;
        color: #ffffff;
        width: max-content;
        font-size: 12px;

        :after {
            content: "";
            position: absolute;
            top: 100%;
            left: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
         }
    }
`

export const ItemFilterSelect = styled.select`
  display: flex;
  padding: 0px 10px;

  outline: none;
  border: none;
  height: 40px;

  font-size: 14px;
  cursor: pointer;

  @media (max-width: 720px) {
    min-width: 100%;
    
    //background-color: red;
  }
  
  option{
    font-size: 14px;
  }
 
`

export const BoxSelect = styled.div`
  display: flex;
  position: relative;

  @media (max-width: 720px) {
    min-width: 100%;
    padding: 0px 10px;
    //background-color: yellow;
  }

  label{
    font-size: 10px;
    position: absolute;
    top: -14px;
    left: 0px;
  }
`

export const BoxControl = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 720px) {
    //background-color: yellow;
    flex-direction: column;
    height: auto;
  }

`

export const Upload = styled.div`
  display: flex;
  background-color: #fff;
  position: relative;
  align-items: center;
  padding: 0px 20px;
  border-radius: 4px;
  gap: 10px;
  cursor: pointer;

  label{
    cursor: pointer;
    font-size: 14px;
    color: #222;
  }
`

const INITIAL_STATE = {
  error: false,
  loading: false,
  data: [],
};

const UploadUrlNew= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPLOAD_URL_NEW_REQUEST":
      return { loading: true, error: false, data: [] };
    case "UPLOAD_URL_NEW_SUCCESS":
      return { loading: false, error: false, data: "success" };
    case "UPLOAD_URL_NEW_ERROR":
      return { loading: false, error: true, data: [] };
    case "UPLOAD_URL_NEW_RESET":
      return { loading: false, error: false, data: [] };
    default:
      return state;
  }
};

export default UploadUrlNew;

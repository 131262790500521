import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    overflow: auto;
    max-height: 90vh;
    gap: 20px;
`

export const Close = styled.div`
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`

export const TitleModal = styled.div`
    display: flex;
    //height: 50px;
    //background-color: yellow;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 10px;
    padding-bottom: 15px;
`



export const BoxActions = styled.div`
    display: flex;
    //background-color: red;
    justify-content: end;


    label{
        display: flex;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        background-color:  #19B674;
        color: #ffffff;
        gap: 10px;
    }
`

export const BoxForm = styled.div`
    display: flex;
    //background-color: red;
`
export const BoxUploads = styled.div`
    display: flex;
    flex: 1;
    //background-color: purple;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`

export const BoxUploadsDrag = styled.div`
    display: flex;
    width: 300px;
    height: 300px;
    //background-color: blue;
    border-radius: 4px;
    border: 2px dashed ${props => props.active ? "#12479B" : "gray"} ;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;

    label{
        font-size: 14px;
        text-align: center;
        color: #12479B;
        font-weight: 600;
    }
`

export const BoxUploadsInput = styled.div`
    display: flex;
    
    cursor: pointer;

    label{
        display: flex;
        cursor: pointer;
        background-color:  #19B674;
        padding: 0px 20px;
        height: 40px;
        align-items: center;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
    }
`

export const BoxFiles = styled.div`
    display: flex;
    flex: 1;
    //background-color: green;
    flex-direction: column;
`

export const BoxFilesList = styled.div`
    display: flex;
    //background-color: red;
    flex-direction: column;
    gap: 4px;
`

export const Upload = styled.div`
  display: flex;
  background-color: #fff;
  position: relative;
  align-items: center;
  padding: 0px 20px;
  border-radius: 4px;
  gap: 10px;
  cursor: pointer;

  label{
    cursor: pointer;
    font-size: 14px;
    color: #222;
  }
`

export const BoxFilesTitle = styled.div`
    display: flex;
    margin-bottom: 20px;

`
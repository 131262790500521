import { put, call } from "redux-saga/effects";
import { GET } from "../../../../services/api";

export function* listPayments(action) {
  yield put({ type: "PAYMENTS_REQUEST" });

  const result = yield call(GET, { endpoint: `/payments` });

  if (result === false) {
    yield put({ type: "PAYMENTS_ERROR" });
  } else {
    yield put({ type: "PAYMENTS_SUCCESS", payload: result.data.data });
  }
}

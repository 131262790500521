
import { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { POST_ERROR } from '../../../services/api';
import IuguModal from '../components/IuguModal/index';
import ModalPix from '../components/ModalPix/index';

const PagamentoContext = createContext({});

export const PagamentoProvider = ({ children }) => {
  const [modalIuguIsOpen, setIuguIsOpen] = useState(false);
  const [modalPixIsOpen, setModalPixIsOpen] = useState(false);
  const [dadosPix, setDadosPix] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [apenasCaptura, setApenasCaptura] = useState(true);
  const dispatch = useDispatch();

  function handleToggleIugu (isOpen) {
    setIuguIsOpen(isOpen);
  }
  function handleTogglePix (isOpen) {
    setModalPixIsOpen(isOpen);
  }

  function setCaptura (captura) {
    setApenasCaptura(captura);
  }

  function salvarUltimaTentativaPagamento() {
    localStorage.setItem('payment@last-try', ~~(Date.now() / 1000));
  }

  function verificarSeEstaLiberadoParaPagar() {
    const lastTime = localStorage.getItem('payment@last-try');
    if(lastTime) {
      return (Number(lastTime) + 120) < ~~(Date.now() / 1000);
    } 

    return true;
  }

  async function adicionarCartao (token, plan, config = {}) {

    let promiseRequest = POST_ERROR({
        endpoint: "/payments", 
        data: {
            service: "LIM",
            token: token,
            plan: plan,
            apenasCaptura: apenasCaptura ? 'captura' : ''
        }
    });

    return await gerenciarRequest(promiseRequest, config.idToast, apenasCaptura ? false : true);
  }

  async function regularizar () {

    let promiseRequest = POST_ERROR({
        endpoint: "/payments", 
        data: {
            service: "LIM",
            origin: "current"
        }
    });

    return await gerenciarRequest(promiseRequest, undefined, true);
  }

  async function gerenciarRequest (requestPromise, idToast = undefined, saveLastPayment = false) {
    setIsLoading(true);
    let id = Date.now();

    if(idToast === undefined) {
      toast.loading("Aguarde...", { toastId: id });
    } else {
      id = idToast;
    }

    let retornoIniciarPagamento = await requestPromise;

    if(retornoIniciarPagamento.data.error) {
      if(retornoIniciarPagamento.data.next_step) {
          if(retornoIniciarPagamento.data.next_step.id == 'generate_pix')
              processarPix(retornoIniciarPagamento.data.next_step);
      }
      
      toast.update(id, { render: retornoIniciarPagamento.data.description ?? "Erro inesperado", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    } else {
        if(saveLastPayment) {
          salvarUltimaTentativaPagamento();
        }

        toast.update(id, { render: retornoIniciarPagamento.data.description ?? "Atualize a página", type: "success", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        
        if(retornoIniciarPagamento.data.redirect) {
            let delay = retornoIniciarPagamento.data.redirect?.delay ?? 50;
            setTimeout(() => {
                dispatch({
                    type: 'SAGA_LIST_PAYMENTS'
                })
            }, delay)
        }
    }
    setIsLoading(false);
    return retornoIniciarPagamento;
  }

  async function gerarPix() {
    const request = POST_ERROR({
        endpoint: '/payments',
        data: {
          service: dadosPix.service,
          origin: dadosPix.origin,
          payment_method: dadosPix.method,
        }
    })

    const {data} = await gerenciarRequest(request)
    return data?.data.pix_url
  }

  const processarPix = ({ data }) => {
    setDadosPix(data)
    handleTogglePix(true)
    // $('#atualizarDados').modal('hide');
    // $('#resModalPix > div > div > div').html(data.error);
    // $('#resModalPix').modal('show');
    // $('#btnGerarPix').on('click', async function() {

    //     let retornoGerarPix = await api
    //     .post($('#btnGerarPix').data('href'))

    //     if (retornoGerarPix.error) {
    //         $('#resModalPix > div > div > div').html(retornoGerarPix.error);
    //         $('#resModalPix').modal('show');
    //     }
    // })
  }

  return (
    <PagamentoContext.Provider value={
      {
        adicionarCartao,
        regularizar,
        isLoading,
        handleToggleIugu,
        apenasCaptura,
        setCaptura,
        modalIuguIsOpen,
        handleTogglePix,
        modalPixIsOpen,
        gerarPix,
        verificarSeEstaLiberadoParaPagar
      }
    }>
      {children}
      <IuguModal />
      <ModalPix />
    </PagamentoContext.Provider>
  );
}

export const usePagamento = () => {
  const context = useContext(PagamentoContext);

  return context;
}
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    //padding: 40px;
    overflow: auto;
    max-height: 90vh;
    //gap: 20px;
    background-color: #fff;

    ::-webkit-scrollbar {
        width: 8px; /* largura da barra de rolagem */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #c1c1c188; /* cor da barra de rolagem */
        border-radius: 1px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1; /* cor do track */
    }
`
export const Title = styled.div`
    display: flex;
    background-color: #005AFF;
    flex-direction: column;
    padding: 20px;
    color: #fff;

    div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;

        label{
            font-size: 14px;
        }
    }

    label{
        font-size: 12px;
    }
`
export const Contents = styled.div`
    display: flex;
    background-color: #fff;
    padding: 20px;
    flex-direction: column;
    gap: 20px;

`

export const BoxUpload = styled.div`
    display: flex;
    flex-direction: column;
    //background-color: #fff;
    padding: 20px;
    border: 2px dashed ${props => props.active ? "#005AFF": "#64748b"} ;;
    border-radius: 8px;
    align-items: center;

    label{
        text-align: center;
        font-size: 12px;
        max-width: 360px;
        color: #64748b;
    }

`

export const BoxFiles = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    

    label{
        font-size: 12px;
        color: #64748b;
    }
`
export const ListFiles = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`
export const FileItem = styled.div`
    display: flex;
    //background-color: red;
    height: 58px;
    border: 1px solid #f2f2f2;
    padding: 8px;
    border-radius: 4px;

    div{
        display: flex;
        gap: 8px;
        align-items: center;
        flex: 1;

        /* img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
        } */
    }
`

export const FileItemLoad = styled.div`
    display: flex;
    width: 100%;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
    padding: 8px;
    gap: 8px;
    align-items: center;

    /* img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 4px;
    } */

    div{
        display: flex;
        flex-direction: column;
        width: 100%;

        span{
            display: flex;
            justify-content: space-between;
            //background-color: red;

            label{
                font-size: 12px;
            }
        }
    }
    
`

export const DividerLine = styled.div`
    display: flex;
    min-width: 100%;
    min-height: 1px;
    background-color: #f2f2f2;

`

export const BoxActions = styled.div`
    display: flex;
    justify-content: end;
    gap: 20px;
    //margin: 20px;

`

export const Loading = styled.div`
    display: flex;
    min-width: 100%;
    min-height: 4px;
    background-color: #34d399;


`

export const ProgressContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
`;

// Barra interna que representa o progresso
export const ProgressBarFill = styled.div`
  height: 100%;
  width: ${props => props.progress}% !important;
  background-color: #4caf50;
  //transition: width 0.3s ease;
`;
import { useSelector } from "react-redux";
import { Container } from './styled'
import { formatTimestamp, formatDate } from "../../../../utils/date";

const FormaPagamentoInfo = () => {
    const payments = useSelector(state => state.reducerPayments)
    const user = payments.data.user

    return (
        <Container>
            <div>
                <h3>Data da Instalação</h3>
                <span>{user?.created_at ? formatDate(user.created_at, "dd/MM/yyyy") : '-'}</span>
            </div>
            <div>
                <h3>Último pagamento</h3>
                <span>{user?.cred_last_date ? formatTimestamp(user.cred_last_date, "dd/MM/yyyy") : '-'}</span>
            </div>
            <div>
                <h3>Próxima fatura</h3>
                <span>{user?.cred_expire ? formatTimestamp(user.cred_expire, "dd/MM/yyyy") : '-'}</span>
            </div>
        </Container>
    );
}

export default FormaPagamentoInfo
const INITIAL_STATE = {
    status: null,
    integration: null
}

const ControlIntegration = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONTROL_INTEGRATION_CHANGE':
            return action.payload
        default:
            return state;
    }
}

export default ControlIntegration
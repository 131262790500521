import React, { useEffect, useState } from "react";
import {
  Container,
  ModalBlock,
  Title,
  Input,
  Legend,
  Button,
  ContainerInput,
  ButtonTitle,
  Header,
} from "./styled";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/Loading";

function Modal(props) {
  const [date, setDate] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [credExpire, setCredExpire] = useState("");
  const dispatch = useDispatch();
  const expire = useSelector((state) => state.reducerAdminExpire);
  var timeStamp = Number(props.date + "000");
  var dateFormat = new Date(timeStamp);

  const diffInMs = new Date() - new Date(dateFormat);
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  useEffect(() => {
  
    setDate(
      dateFormat.getDate() +
        "/" +
        (dateFormat.getMonth() + 1) +
        "/" +
        dateFormat.getFullYear()
    );
    setCurrentDate(
      new Date().getFullYear() +
        "-" +
        "0" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate()
    );
    
  }, []);

  useEffect(() => {
    // 
    //   currentDate.split("-")[2] +
    //     currentDate.split("-")[1] +
    //     currentDate.split("-")[0]
    // );
    var myDate =
      currentDate.split("-")[2] +
      "-" +
      currentDate.split("-")[1] +
      "-" +
      currentDate.split("-")[0];
    
    myDate = myDate.split("-");
    var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
    setCredExpire(newDate.getTime());
  }, [currentDate]);
  return (
    <Container>
      <ModalBlock>
        <Header>
          {/* <Title>Vencimento</Title> */}
          <AiOutlineClose
            onClick={() => props.closeModal()}
            size={20}
            style={{ cursor: "pointer" }}
          />
          {/* <Title onClick={() => props.closeModal()}>X</Title> */}
        </Header>
        <ContainerInput>
          <Legend>Vencimento atual</Legend>
          <Input value={props.date > 0 ? date : 0} disabled />
          {/* {props.date} */}
        </ContainerInput>
        <ContainerInput>
          <Legend>Novo vencimento</Legend>
          <Input
            type="date"
            id="start"
            name="trip-start"
            value={currentDate}
            min={currentDate}
            onChange={(a) => setCurrentDate(a.target.value)}
          />
        </ContainerInput>
        <Button
          onClick={() => {
            dispatch({
              type: "SAGA_EXPIRE_ADMIN",
              payload: {
                cred_expire: credExpire.toString().slice(0, -3),
                userId: props.userId,
                closeModal: () => props.closeModal(),
              },
            });
          }}
        >
          {expire.loading ? <Loading /> : <ButtonTitle>Salvar</ButtonTitle>}
        </Button>
      </ModalBlock>
    </Container>
  );
}

export default Modal;

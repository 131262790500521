import { useSelector } from "react-redux";
import { Container, InforPlus, InforPlusBtn, InforPlusText } from "./styled";
import { usePagamento } from "../../hooks/usePagamento";
import Button from "../../../../components/Button/index";
import { translations } from "../../../../utils/language";

const Avisos = ({ avisoPlus }) => {
  const pagamento = usePagamento();
  const payments = useSelector((state) => state.reducerPayments);
  const language = useSelector((state) => state.reducerLanguage);

  if (!payments?.data?.user) {
    return <></>;
  }
  const { user } = payments.data;

  async function regularizarAgora() {
    if (!pagamento.isLoading) await pagamento.regularizar();
  }

  function AdicionarCartaoRegularizar() {
    if (!pagamento.isLoading) {
      pagamento.setCaptura(false);
      pagamento.handleToggleIugu(true);
    }
  }

  //   if (avisoPlus) {
  return (
    <InforPlus>
      <InforPlusText>
        <video width={300} muted autoplay={"autoplay"} loop={true}  >
          <source src={"https://tools.empreender.com.br/empreender-plus/logo-animation.webm"} type="video/webm" />
        </video>
        <label>
          {translations["assinatura-assine"][language.data]} <strong style={{ color: "#47fffe" }}> EMPREENDER PLUS </strong>   {translations["assinatura-descontos-app"][language.data]}
        </label>
      </InforPlusText>
      <InforPlusBtn>
        <label onClick={() => { window.open(process.env.REACT_APP_EPLUS_DOMAIN + `/planos`) }}>
          {translations["assinatura-acesse-ja"][language.data]}
        </label>
      </InforPlusBtn>
    </InforPlus >
    // <Layout>
    //   <h1>
    //     {
    //       translations["Pagamentos são feitos através do Empreender Plus"][
    //         language.data
    //       ]
    //     }
    //   </h1>
    //   <p>
    //     {
    //       translations[
    //         "Empreender Plus é uma central de cadastros e pagamentos da Empreender, onde você receberá descontos por utilizar nossos aplicativos."
    //       ][language.data]
    //     }
    //   </p>
    //   <div
    //     style={{
    //       display: "flex",
    //       flexWrap: "wrap",
    //       gap: "1rem",
    //       marginTop: "16px",
    //     }}
    //   >
    //     <p>
    //       <Button background={"#1B76FF"} color={"#fff"} width={"150px"}>
    //         <a
    //           rel="noreferrer"
    //           href={process.env.REACT_APP_EPLUS_PAGINA_ASSINATURA}
    //           target="_blank"
    //           style={{ color: "#fff", background: "transparent" }}
    //         >
    //           Ir para Plus
    //         </a>
    //       </Button>
    //     </p>
    //   </div>
    // </Layout>
  );
  //   }

  const dateNow = ~~(Date.now() / 1000);

  if (
    user["cred_expire"] < dateNow - 3600 * 8 &&
    !pagamento?.verificarSeEstaLiberadoParaPagar()
  ) {
    return (
      <Layout>
        <h1>Seu Pagamento esta sendo processado</h1>
        <p>Por favor aguarde alguns minutos e atualize a página.</p>
      </Layout>
    );
  }

  if (user["inter_pay"] == "" && user["cred_expire"] < dateNow - 3600 * 8) {
    return (
      <Layout>
        <h1>Cadastre um Cartão para ativar o plano</h1>
        <p></p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            marginTop: "16px",
          }}
        >
          <p>
            <Button
              handleClick={AdicionarCartaoRegularizar}
              background={"#20d489"}
              color={"#fff"}
              width={"150px"}
            >
              Adicionar Cartão
            </Button>
          </p>
        </div>
      </Layout>
    );
  }

  if (user["inter_pay"] !== "" && user["cred_expire"] < dateNow - 3600 * 8) {
    return (
      <Layout>
        <h1>Cobrança da Mensalidade Atrasada</h1>
        <p>
          Ops, não conseguimos renovar o seu plano, clique no botão verde para
          tentar cobrar novamente,
          <br /> caso o problema persista sugerimos tentar outro cartão de
          crédito
        </p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            marginTop: "16px",
          }}
        >
          <p>
            <Button
              style={{ height: 80 }}
              handleClick={regularizarAgora}
              background={"#20d489"}
              color={"#fff"}
              width={"150px"}
            >
              Regularizar Agora
            </Button>
          </p>
          <p>
            <Button
              style={{ height: 80 }}
              handleClick={AdicionarCartaoRegularizar}
              background="#FBF9F6"
              color="gray"
              border="#d3d3d3"
              width={"150px"}
            >
              Trocar de Cartão
            </Button>
          </p>
        </div>
      </Layout>
    );
  }

  return <></>;
};

const Layout = (props) => {
  return (
    <Container>
      <div>{props.children}</div>
    </Container>
  );
};

export default Avisos;

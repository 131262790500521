import styled from "styled-components";

export const ButtonCustom = styled.button`
    display: flex;
    background-color: ${props => props.background};
    color: ${props => props.color};
    padding: 5px 10px;
    cursor: pointer;
    margin: 2px;
    font-size: 12px;
    border-radius: 4px;
    cursor: ${props => props.active ? "pointer" : "not-allowed"};
    justify-content: center;
    align-items: center ;
    border: none ;

    svg{
        animation: spin 1s linear infinite;
    }

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
`;

export const BoxIcon = styled.span`

    //background-color: yellow ;
    display: flex ;
    align-items: center ;

    img{
        height: 20px ;
        margin-left: 10px ;
        cursor: pointer;
    }
`

export const BoxLoading = styled.div`

    display: flex;
    //flex: 1;
    justify-content: center;
    align-items: center;
    width: 100% ;
    height: 100% ;
    background-color: red ;

    span{
        animation: spin 1s linear infinite;
    }

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
`;

export const TooltipAccount = styled.div`
    background-color: yellow ;
    display: flex ;
`

export const ActionsDropdown = styled.div`
    background-color: #19B674 ;
    display: flex ;
    padding: 5px 10px;
    cursor: pointer;
    margin: 2px;
    font-size: 12px;
    border-radius: 4px;
    justify-content: space-around;
    position: relative ;
    cursor: pointer;
    color: white;

    label{
        cursor: pointer;
    }

    div{
        display: ${props => props.visible ? "flex" : "none"} ;
        position: absolute ;
        background-color: white ;
        flex-direction: column ;
        top: 32px;
        right: 0;
        z-index: 99999999;
        border-radius: 5px ;
        box-shadow: 0 0 2em #DCDBDA;

        label{
            display: flex ;
            //background-color: yellow ;
            width: 80px ;
            height: 30px ;
            align-items: center ;
            justify-content: center ;
            cursor: pointer;
            color: gray;
        }
    }
`
import styled from "styled-components";

export const Container = styled.div`
  height: 300px;
  border-radius: 10px;
  display: flex;
  flex: 1;
  background-color: #fff;
  width: 100%;
  justify-content: space-between;

  /* @media screen and (max-width: 1200px) {
    width: 100%;
  } */
  @media (max-width: 660px) {
    img {
      display: none;
    }
  }
`;

export const BoxLeft = styled.div`
  //background-color: violet ;
  height: 100%;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const BoxRight = styled.div`
  //background-color: purple ;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: end;

  @media screen and (max-width: 768px) {
    display: none;
  }

  video {
    height: 350px;
    clip-path: inset(0.1px 0.1px);
  }
`;

export const Title = styled.label`
  display: flex;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const SubTitle = styled.label`
  display: flex;
  font-size: 16px;
  margin-bottom: 100px;
  /* text-align: justify; */
  //background-color: yellow ;
  font-weight: 500;
  flex: 1;
  color: #b8b8b8;
`;

export const Button = styled.button`
  display: flex;
  height: 50px;
  width: 150px;
  background-color: #ffc400; //#FBF9F6 ;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: #1c1c1c;
`;

const INITIAL_STATE = ""

const QueryHelp = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_QUERY_HELP':
            return action.payload
        default:
            return state;
    }
}

export default QueryHelp
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    min-height: 80px;
    background-color: ${props => props.backgroundColor};
    border-radius: 8px;
    align-items: center;
    padding: 0px 20px;
    justify-content: space-between;

    @media screen and (max-width: 720px) {
        flex-direction: column;
        gap: 20px;
        padding: 20px;
    }

    label{
        color: ${props => props.color};
        word-break: break-all;
    }

    span{
        display: flex;
        min-width: 120px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: ${props => props.backgroundColorButton};
        cursor: pointer;

        label{
            font-size: 13px;
            color: ${props => props.colorButton};
            cursor: pointer;
        }
    }

`
import styled from 'styled-components'
import iugu1 from '../../../../assets/imgs/iugu/iugu1.png'
import iugu2 from '../../../../assets/imgs/iugu/iugu2.png'
import iugu3 from '../../../../assets/imgs/iugu/iugu3.png'
import iugu4 from '../../../../assets/imgs/iugu/iugu4.png'

export const CloseIcon = styled.button`
    position: absolute; 
    top: 26px; 
    font-size: 30px;
    right: 26px; 
    background: none; 
    border: none;
    cursor: pointer;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    input {
        width: 530px; 
        height: 47px;
        border-radius: 5px;
        border: 1px solid #eee;
        margin-top: 10px;
        padding: 0 15px;
    }

    .usable-creditcard-form {
    position: relative;
    padding: 0px;
    margin-left: 0;
    margin-right: 0;
    }

    .usable-creditcard-form input {
    /* background: none;
    display: block; */
    width: 100%;
    /* padding: 12px; */
    margin: 0px;
    padding-left: 42px;
    /* border: 1px solid gray; */
    /* border-bottom: 2px solid gray;
    border-radius: 4px; */
    margin-bottom: 8px;
    transition: all 0.2s;
    }

    .usable-creditcard-form .input-group .icon {
    position: absolute;
    width: 24px;
    height: 24px;
    background: gray;
    left: 10px;
    top: 12px;
    }

    .input-group {
        position: relative;
    }


    .usable-creditcard-form .input-group .icon.ccic-name {
    background: url(${iugu1}) no-repeat;
    }

    .usable-creditcard-form .input-group .icon.ccic-exp {
    background: url(${iugu2}) no-repeat;
    }

    .usable-creditcard-form .input-group .icon.ccic-brand {
    background: url(${iugu3}) no-repeat;
    }

    .usable-creditcard-form .input-group .icon.ccic-cvv {
    background: url(${iugu4}) no-repeat;
    }

    .usable-creditcard-form .input-group .icon.ccic-cvv,
    .usable-creditcard-form .input-group .icon.ccic-brand {
    -webkit-transition: background-position 0.2s ease-in;
    -moz-transition: background-position 0.2s ease-in;
    -o-transition: background-position 0.2s ease-in;
    transition: background-position 0.2s ease-in;
    }

    .amex .usable-creditcard-form .input-group .icon.ccic-cvv {
    background-position: 0px -22px;
    }

    .amex .usable-creditcard-form .input-group .icon.ccic-brand {
    background-position: 0px -110px;
    }

    .visa .usable-creditcard-form .input-group .icon.ccic-brand {
    background-position: 0px -22px;
    }

    .diners .usable-creditcard-form .input-group .icon.ccic-brand {
    background-position: 0px -88px;
    }

    .mastercard .usable-creditcard-form .input-group .icon.ccic-brand {
    background-position: 0px -66px;
    }
`

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    strong {
        margin-bottom: 10px;
    }
`

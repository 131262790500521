import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background-color: #fff;
    height: 120px;
    border-radius: 10px;
    padding: 20px;

    @media screen and (max-width: 1200px) {
        margin-top: 30px;
        //width: 100%;
    }
    
`

export const Trial = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    //background-color: purple;
    width: 100%;

    label{
        //background-color: red;
        font-weight: 700;
        color:  #000000;
    }

    span{
        display: flex;
        background-color: #FFC400;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 4px;
        width: max-content;
        color: #1c1c1c;
    }
`
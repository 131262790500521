import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    //min-height: 50px;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #f1f1f1;
    gap: 10px;
    flex: 1;

    @media (max-width: 900px) {
        flex-direction: column;
    }
    
`

export const SectionIcon = styled.div`
    display: flex;
`

export const BoxControl = styled.div`
    display: flex;
    gap: 20;


`

export const SectionInfor = styled.div`
    display: flex;
    flex: 1;
    align-items: center;

    @media (max-width: 900px) {
        flex-direction: column;
    }
   
    label{
        display: flex;
        font-size: 12px;
        color: gray;
    }

    
    span{
        //display: flex;
        flex-direction: column;
        flex: 1;

        @media (max-width: 900px) {
            display: flex;
            flex-direction: column;
        }

        label{
            display: flex;
            font-size: 14px;
            font-weight: 600;
            width: max-content;
            align-items: center;
            color: #000000bb;

            span{
                display: flex;
                background-color: #1b76ff88;
                margin-left: 5px;
                padding: 4px 8px;
                border-radius: 4px;
                font-size: 11px;
                align-items: center;
                justify-content: center;
                color: #ffffff;

                @media (max-width: 900px) {
                    display: none;
                }
            }
        }

        p{
            display: flex;
            font-size: 11px;
            margin: 0px;
            padding: 0px;
            color: gray;

           
        }
    }
`

export const SectionActions = styled.div`
    display: flex;
    //background-color: red;
    gap: 14px;
    margin-left: 5px;
`

export const ItemAction = styled.div`
    
    display: flex;
    width: 40px;
    height: 40px;
    //background-color: #f1f1f1;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    //border: 1px solid  ${props => props.color}33;
    border: 1px solid  #f1f1f1;
    position: relative;
    cursor: pointer;

    svg{
        //color: ${props => props.color};
        color: gray;
    }

    :hover{
        background-color: ${props => props.color};
        //background-color: #1B76FF;

        svg{
            color: #ffffff;
        }
        label{
            display: flex;
        }
    }


    label{
        display: none;
        position: absolute;
        font-size: 12px;
        background-color: #000000;
        width: max-content;
        right: 0px;
        top: -50px;
        padding: 10px 20px;
        border-radius: 4px;
        z-index: 999999;
        color: #ffffff;

        :after {
            content: "";
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid #000000;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
    
`

export const CheckCustom = styled.div`
    display: flex;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    border: 1px solid #dcdcdc;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg{
        cursor: pointer;
        color: #1B76FF;
    }    
`
import { useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";

const Code = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const route = useRouteMatch();

  useEffect(() => {
    //("teste route", route)

    alert("teste code pag")

    if (route.params.type == "sak") {
      alert("teste code pag sak")
      const urlParams = new URLSearchParams(window.location.search);
      dispatch({
        type: "CONTROL_PLATFORM_SUCCESS", payload: {
          typeIntegration: "sak",
          token: urlParams.get("tokenSak"),
          webhook: urlParams.get("webhookSak"),
        }
      })
      history.push('/integracoes');
    } else {
      let shop = location.search.substring(location.search.indexOf("shop=") + 5);
      let url = location.search.substring(location.search.indexOf("url=") + 4);
      let clientId = location.search
        .substring(location.search.indexOf("client_id=") + 10)
        .split("&")[0];
      let clientSecret = location.search
        .substring(location.search.indexOf("client_secret=") + 14)
        .split("&")[0];
      let basic = location.search
        .substring(location.search.indexOf("basic=") + 6)
        .split("&")[0];
      let store = location.search
        .substring(location.search.indexOf("store=") + 6)
        .split("&")[0];
      let token = location.search
        .substring(location.search.indexOf("token=") + 6)
        .split("&")[0];
      dispatch({
        type: "CONTROL_PLATFORM_SUCCESS",
        payload: {
          typeIntegration: route.params.type,
          code: location.search.substring(6, location.search.length),
          shop: shop.split("&")[0],
          url: url,
          client_id: clientId,
          client_secret: clientSecret,
          basic: basic,
          store: store,
          token: token,
        },
      });
      history.push("/integracoes");
    }


  }, []);

  return <div></div>;
};

export default Code;

const INITIAL_STATE = {
  error: false,
  loading: false,
  data: [],
};

const Top = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TOP_REQUEST":
      return { loading: true, error: false, data: [] };
    case "TOP_SUCCESS":
      return { loading: false, error: false, data: action.payload };
    default:
      return state;
  }
};

export default Top;

import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    //padding: 40px;
    overflow: auto;
    max-height: 90vh;
    //gap: 20px;
    background-color: #fff;

    ::-webkit-scrollbar {
        width: 8px; /* largura da barra de rolagem */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #c1c1c188; /* cor da barra de rolagem */
        border-radius: 1px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1; /* cor do track */
    }
`
export const Title = styled.div`
    display: flex;
    background-color: #005AFF;
    flex-direction: column;
    padding: 20px;
    color: #fff;

    div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;

        label{
            font-size: 14px;
        }
    }

    label{
        font-size: 12px;
    }
`
export const Contents = styled.div`
    display: flex;
    background-color: #fff;
    padding: 20px;
    //flex-direction: column;
    gap: 20px;

`

export const BarSearch = styled.div`
    display: flex;
    min-height: 80px;
    width: 100%;
    background-color: #F0F6FF;
    align-items: center;
    padding: 0px 20px;
    border-radius: 8px;
    margin-bottom: 20px;

    div{
        display: flex;
        height: 40px;
        width: 40px;
        background-color: #1B76FF;
        align-items: center;
        justify-content: center;
        border-radius: 0px 8px 8px 0px;
        cursor: pointer;
        
    }

    input{
        display: flex;
        height: 40px;
        flex: 1;
        border: none;
        outline: none;
        background-color: #ffffff;
        border-radius: 8px 0px 0px 8px;
        padding: 0px 10px;
    }
`

export const ColumnItems = styled.div`
    display: flex;
    width: 300px;
    //background-color: red;
    flex-direction: column;
`

export const ColumnLeft = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
`
export const ListFiles = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 550px;
`
export const ListSelected = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const FileItem = styled.div`
    display: flex;
    //background-color: red;
    min-height: 58px;
    border: 1px solid #f2f2f2;
    padding: 8px;
    border-radius: 4px;
    align-items: center;
    max-width: 550px;

    div{
        display: flex;
        gap: 8px;
        align-items: center;
        flex: 1;

        /* img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
        } */
    }
`

export const TitleText = styled.label`
    display: flex;
    font-size: 12px;
    margin-bottom: 4px;
`

export const Action = styled.label`
    display: flex;
    font-size: 12px;
    height: 30px;
    color: #fff;
    align-items: center;
    border-radius: 4px;
    padding: 0px 8px;
    cursor: pointer;
    margin-left: 8px    ;
`

import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosClose } from "react-icons/io";

import { Container, Close, BoxImage, BoxInfor, ItemMenu, BoxMenu } from "./styled";
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { translations } from '../../../../utils/language';
import { ArrowDownOnSquareIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, EnvelopeIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { useHistory } from 'react-router-dom';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalMenuItem = (props) => {

    const dispatch = useDispatch()
    const language = useSelector((state) => state.reducerLanguage);
    const history = useHistory()
    //const statusSendEmail = useSelector((state) => state.reducerEmails);

    const sendEmail = () => {
        // dispatch({
        //     type: "SAGA_RESEND_EMAIL",
        //     payload: { order: props.item },
        // });
    }

    // useEffect(() => {
    //     if (statusSendEmail.loading == false && statusSendEmail.error == false && statusSendEmail?.data?.length == 0) {

    //     } else {
    //         const id = "statusSendEmail"
    //         if (statusSendEmail.loading) {
    //             toast.loading(translations["Aguarde..."][language.data], { toastId: id })
    //         } else {
    //             if (statusSendEmail.error) toast.update(id, { render: "Erro ao tentar enviar.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    //             else {
    //                 props.setVisibleModal(false)
    //                 toast.update(id, { render: translations["sales-enviado-sucesso"][language.data], type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
    //             }
    //         }
    //     }
    // }, [statusSendEmail])

    const sincProduct = () => {
        dispatch({
            type: "SAGA_UPDATE_DATA_PRODUCT",
            payload: {
                integration: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration,
                product: props.item.products,

            }
        })
    }

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <Container>
                <Close onClick={() => { props.setVisibleModal(false) }}><IoIosClose size={25} color={"gray"} /></Close>
                <BoxMenu>

                    <Item
                        image={<PaperClipIcon style={{ width: "32px" }} />}
                        action={() => {
                            history.push(`produtos/${props.item.products.product_id}/variacoes`); props.setVisibleModal(false)
                        }}
                        item={props.item}
                        title={"Anexar mídias"}
                        desc={"Anexar arquivos ou URLs nesse produto."} />

                    <Item
                        image={<ArrowPathIcon style={{ width: "32px" }} />}
                        action={() => { sincProduct(); props.setVisibleModal(false) }}
                        item={props.item}
                        title={"Sincronizar"}
                        desc={"Sincronizar os dados desse produto com a sua plataforma."} />

                </BoxMenu>
            </Container>
        </Modal>
    )
}

export default ModalMenuItem


const Item = (props) => {

    const dispatch = useDispatch()

    useEffect(() => {
        //console.log("props_", props)
    }, [props])


    const [hover, setHover] = useState(false)


    return (
        <ItemMenu onClick={() => { props.action() }} hover={hover} onMouseLeave={() => { setHover(false) }} onMouseEnter={() => { setHover(true) }}>
            <BoxImage hover={hover}>
                {props.image}
            </BoxImage>
            <BoxInfor>
                <label>{props.title}</label>
                <p>{props.desc}</p>
            </BoxInfor>
        </ItemMenu>
    )
}


import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 30px;
    padding-top: 0;
    background: white;
    border-radius: 0 0 10px 10px;
`
const INITIAL_STATE = {
    qtdTotal: 0,
    qtdSuccess: 0,
}

const ControlSyncProducts = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONTROL_SYNC_PRODUCTS_UPDATE':
            return { qtdTotal: action.payload.qtdTotal, qtdSuccess: state.qtdSuccess + 1 }
        case 'CONTROL_SYNC_PRODUCTS_RESET':
            return { qtdTotal: 0, qtdSuccess: 0 }
        default:
            return state;
    }
}

export default ControlSyncProducts
import { applyMiddleware, createStore } from "redux";

import rootReducers from "./rootReducers";
import rootSagas from "./rootSagas";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
    rootReducers,
    applyMiddleware(sagaMiddleware)
)

sagaMiddleware.run(rootSagas);

export default store;
import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  display: flex;
  width: 275px;
  height: 150px;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #f1f1f1 ;

  :hover {
    transition: all 0.5s ease-in-out;
    transform: scale(1.05);
    box-shadow: 0 0 2em #dcdbda;
  }

  div {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 20px;

    label {
      text-align: center;
      font-size: 11px;
      cursor: pointer;
      color: gray;
      font-weight: bold;
      //background-color: yellow ;
      height: 50px;
    }
  }

  span {
    display: flex;
    background-color: ${(props) => props.titleColor ?? "#2C3357"};
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 5px 5px;

    label {
      color: white;
      font-size: 12px;
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

export const Btn = styled.div`
  position: absolute;
  height: 40px;
  width: 75px;
  margin-left: 200px;
  padding: 10px !important;

  label {
    background-color: #5e6278;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center !important;
    align-items: center;
    border-radius: 10px;
    color: white !important;
    font-size: 11px !important;
  }

  span {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
  }
`;

export const Title = styled.div`
  display: flex;
  height: 75px;
  width: 750px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid gray;
  padding: 0px 20px;

  div {
    display: flex;
    align-items: center;
    label {
      font-size: 18px;
      font-weight: bold;
      color: black;
      margin-left: 10px;
    }
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

export const Body = styled.div`
  display: flex;

  flex-direction: column;
  max-width: 750px;
  padding: 10px;

  h5 {
    padding-left: 30px;
    margin-bottom: 10px;
  }

  h3 {
    color: gray;
    margin: 10px;
  }

  label {
    font-size: 14px;
    margin: 10px 0px;
    color: red;
    background-color: #fff9cc;
    justify-content: center;
    padding: 10px 0px;
    font-weight: bold;
  }

  strong {
    font-weight: bold;
    font-size: 14px;
  }
`;

export const Action = styled.div`
  display: flex;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 10px;

  span {
    display: flex;
    background-color: ${(props) => props.theme.colors.font.active};
    width: 100px;
    height: 30px;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }

    label {
      font-size: 14px;
      color: white;
      cursor: pointer;
    }
  }

  span:nth-child(1) {
    background-color: transparent;
    border: 2px solid gray;

    label {
      color: gray;
      font-weight: bold;
    }
  }
`;

//modal conectar
export const ContainerModal = styled.form`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 25px;
  border-radius: 5px;
  @media screen and (max-width: 500px) {
    width: 300px;
  }
`;

export const Item = styled.label`
  //display: flex;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: justify;
`;

export const ItemTitle = styled.p`
  //display: flex;
  //font-size: 12px;
  margin-bottom: 10px;
`;

export const ButtonCustom = styled.span`
  display: flex;
  background-color: #ffcc00;
  outline: none;
  border: none;
  border-radius: 5px;
  margin-top: 15px;
  height: 50px;
  align-items: center;
  justify-content: center;
  color: #5e0a07;
  font-weight: 600;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

export const Close = styled.span`
  display: flex;
  position: absolute;
  right: 0;
  margin-right: 25px;
  cursor: pointer;
  z-index: 9999;
`;

export const TitleModal = styled.div`
  display: flex;
  position: relative;
  color: #5e6278;
  margin-bottom: 10px;
`;


import { useState } from 'react'
import { BarColors, BarConfig, BarNav, Body, BoxButtons, BoxButtonsItem, BoxContent, BoxImage, BoxLoadingCustom, BoxOptions, BoxPreview, ButtonHelp, ButtonReset, ButtonSave, Container, CounterItemsCircle, InputFileCustom, InputTextColor, InputTextCustom, Item, ItemColor, ItemOption, ItemText, ItemTextArea, ItemUpload, MenuOption, Section, SelectCustom, SendEmail, Title } from './styled'
import imgBackground from "../../../../assets/imgs/preview-background.png";
import IconBossVideo from "../../../../assets/imgs/boss-video.svg";
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import IconCam from "../../../../assets/imgs/icon-camera.svg";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AlertSave } from '../BoxCheckout/styled';
import IconBossSave from '../../../../assets/imgs/boss-save.svg';

import { AiOutlineLoading } from "react-icons/ai";
import { FaUpload } from "react-icons/fa";
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';
import { translations } from '../../../../utils/language';

const BoxEmail = () => {

    const dispatch = useDispatch()

    const [navOption, setNavOption] = useState("")
    const [theme, setTheme] = useState("")

    const controlConfig = useSelector((state) => state.reducerControlConfig);
    const config = useSelector((state) => state.reducerTemplate);
    const controlSaveEmail = useSelector(state => state.reducerControlSaveEmail)

    const [logo_url, setlogo_url] = useState("")
    const [uploading, setUploading] = useState(false)
    const language = useSelector((state) => state.reducerLanguage);

    const [email, setEmail] = useState(controlConfig.email)

    const statusEmailTeste = useSelector(state => state.reducerEmailTeste)

    useEffect(() => {
        if (!(statusEmailTeste.loading == null && statusEmailTeste.error == null && statusEmailTeste.data.length == 0)) {
            const id = "statusEmailTeste"
            if (statusEmailTeste.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusEmailTeste.error) toast.update(id, { render: "Erro ao tentar enviar.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translations["sales-enviado-sucesso"][language.data], type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                }
            }
        }
    }, [statusEmailTeste])

    const saveColors = () => {

        dispatch({
            type: "SAGA_UPDATE_TEMPLATE", payload: {
                id: config?.data[0]?.id,
                color_email_background: controlConfig.color_email_background,
                color_title_email: controlConfig.color_title_email,
                color_button: controlConfig.color_button,
                color_message_button: controlConfig.color_message_button

            }
        })

    }

    const saveConfig = () => {
        if (typeof controlConfig?.email_logo_url == "string") {
            dispatch({
                type: "SAGA_UPDATE_TEMPLATE", payload: {
                    id: config?.data[0]?.id,
                    subject_email: controlConfig.subject_email,
                    message_email: controlConfig.message_email,
                    message_button: controlConfig.message_button,
                }
            })
        } else {
            dispatch({
                type: "SAGA_UPDATE_TEMPLATE", payload: {
                    id: config?.data[0]?.id,
                    subject_email: controlConfig.subject_email,
                    message_email: controlConfig.message_email,
                    message_button: controlConfig.message_button,
                    logo_url: controlConfig.logo_url

                }
            })
        }



    }

    const isSaveEmail = () => {
        if (
            config?.data[0]?.color_email_background == controlConfig.color_email_background &&
            config?.data[0]?.color_title_email == controlConfig.color_title_email &&
            config?.data[0]?.color_button == controlConfig.color_button &&
            config?.data[0]?.color_message_button == controlConfig.color_message_button &&

            config?.data[0]?.subject_email == controlConfig.subject_email &&
            config?.data[0]?.message_email == controlConfig.message_email &&
            config?.data[0]?.message_button == controlConfig.message_button
        ) {

            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_SET", payload: true })

            return true
        } else {

            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_SET", payload: false })

            return false
        }
    }

    const getDefaultColors = () => {
        const themaDefault = {
            id: config?.data[0]?.id,
            color_email_background: "#ffffff",
            color_title_email: "#000000",
            color_button: "#1b76ff",
            color_message_button: "#ffffff"
        }
        dispatch({ type: "SAGA_UPDATE_TEMPLATE", payload: themaDefault })
    }

    const getDefaultConfig = () => {
        const themaDefault = {
            id: config.data[0].id,
            subject_email: "Seu produto chegou!",
            message_email: "Seu produto chegou!\nObrigado por comprar conosco!.\nAcesso no botão abaixo.",
            message_button: "Ver",
            logo_url: ""
        }
        dispatch({ type: "SAGA_UPDATE_TEMPLATE", payload: themaDefault })
    }


    useEffect(() => {
        switch (theme) {
            case "dark":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        color_email_background: "#292929",
                        color_title_email: "#ffffff",
                        color_button: "#000000",
                        color_message_button: "#ffffff"
                    }
                })
                break;
            case "light":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        color_email_background: "#ffffff",
                        color_title_email: "#000000",
                        color_button: "#1b76ff",
                        color_message_button: "#ffffff"
                    }
                })
                break;

            default:
                break;
        }
    }, [theme])

    useEffect(() => {
        if (
            controlConfig.color_email_background == "#ffffff" &&
            controlConfig.color_title_email == "#000000" &&
            controlConfig.color_button == "#1b76ff" &&
            controlConfig.color_message_button == "#ffffff"
        ) {
            setTheme("light")
        }
        else if (
            controlConfig.color_email_background == "#292929" &&
            controlConfig.color_title_email == "#ffffff" &&
            controlConfig.color_button == "#000000" &&
            controlConfig.color_message_button == "#ffffff"
        ) {
            setTheme("dark")
        }
        else {
            setTheme("custom")
        }
    }, [controlConfig])

    async function handleImageUpload(imageFile) {
        setUploading(true)

        const options = {
            maxSizeMB: 0.6,
            maxWidthOrHeight: 1280,
            useWebWorker: true
        }

        try {
            const compressedFile = await imageCompression(imageFile, options);
            //setlogo_url(compressedFile)
            dispatch({ type: "CONFIG_CONTROL_SET", payload: { logo_url: compressedFile } })
            setUploading(false)
        } catch (error) {
            setUploading(false)

        }
    }




    const getLogo = () => {
        if (typeof controlConfig?.logo_url == "undefined" || controlConfig?.logo_url == null) {
            return <label>LOGO</label>
        } else if (typeof controlConfig?.logo_url == "string") {
            if (controlConfig?.logo_url.length == 0) {
                return <label>LOGO</label>
            } else {
                return <img src={controlConfig?.logo_url} />
            }
        } else {
            return <img src={URL.createObjectURL(controlConfig?.logo_url)} />
        }
    }


    const senEmail = () => {
        dispatch({
            type: "SAGA_EMAIL_TESTE_TEMPLATES",
            payload: {
                email_logourl: controlConfig.logo_url,
                email_content: controlConfig.message_email,
                //product_image_url: "https://app.dijital.com.br/static/media/icon-camera.00ecdc1ac90893e500d21a88937eda44.svg",
                email_subject: controlConfig.subject_email,
                product_name: "Nome do produto",
                email_buttontext: controlConfig.message_button,
                email: email,
                message_email: controlConfig.message_email,
                color_button: controlConfig.color_button,
                color_message_button: controlConfig.color_message_button,
                color_title_email: controlConfig.color_title_email,
                color_email_background: controlConfig.color_email_background


            }
        })
    }

    return (
        // JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id == 914 ?
        <iframe width={"100%"} height={"100%"} src={`${process.env.REACT_APP_URL_EDITOR_EMAIL}/editor?name=template_pedido&app=dijital&user_id_ref=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id}&token=${process.env.REACT_APP_TOKEN_EDITOR_EMAIL}`} />
        // :
        // <Container>
        //     <BoxOptions>
        //         <BarNav>
        //             <Title>
        //                 <label>
        //                     E-mail
        //                 </label>
        //             </Title>
        //             <MenuOption>
        //                 <ItemOption active={navOption != "config" ? true : false} onClick={() => { isSaveEmail() && setNavOption("color") }}>
        //                     Cores
        //                 </ItemOption>
        //                 <ItemOption active={navOption == "config" ? true : false} onClick={() => { isSaveEmail() && setNavOption("config") }}>
        //                     Avançado
        //                 </ItemOption>
        //             </MenuOption>
        //         </BarNav>
        //         {
        //             navOption != "config" ?
        //                 <BarColors>
        //                     <Section>
        //                         <label>Combinação de cores:</label>
        //                         <SelectCustom onChange={(event) => { setTheme(event.target.value) }}                        >
        //                             <option value="light" selected={theme == "light" ? true : false}>Light</option>
        //                             <option value="dark" selected={theme == "dark" ? true : false}>Dark</option>
        //                             <option disabled value="custom" selected={theme == "custom" ? true : false}>Personalizado</option>
        //                         </SelectCustom>
        //                     </Section>
        //                     <Section>
        //                         <label>Cores:</label>
        //                         <ItemColor>
        //                             <label>Fundo</label>
        //                             <span>
        //                                 <input type={"color"} value={controlConfig.color_email_background} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_email_background: e.target.value } }) }} />
        //                                 {/* <label>{controlConfig.color_email_background}</label> */}
        //                                 <InputTextColor type='text' value={controlConfig.color_email_background} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_email_background: e.target.value } }) }} />

        //                             </span>
        //                         </ItemColor>

        //                         <ItemColor>
        //                             <label>Texto</label>
        //                             <span>
        //                                 <input type={"color"} value={controlConfig.color_title_email} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_title_email: e.target.value } }) }} />
        //                                 {/* <label>{controlConfig.color_title_email}</label> */}
        //                                 <InputTextColor type='text' value={controlConfig.color_title_email} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_title_email: e.target.value } }) }} />
        //                             </span>
        //                         </ItemColor>

        //                         <ItemColor>
        //                             <label>Botão</label>
        //                             <span>
        //                                 <input type={"color"} value={controlConfig.color_button} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_button: e.target.value } }) }} />
        //                                 {/* <label>{controlConfig.color_button}</label> */}
        //                                 <InputTextColor type='text' value={controlConfig.color_button} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_button: e.target.value } }) }} />
        //                             </span>
        //                         </ItemColor>

        //                         <ItemColor>
        //                             <label>Texto botão</label>
        //                             <span>
        //                                 <input type={"color"} value={controlConfig.color_message_button} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_message_button: e.target.value } }) }} />
        //                                 {/* <label>{controlConfig.color_message_button}</label> */}
        //                                 <InputTextColor type='text' value={controlConfig.color_message_button} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_message_button: e.target.value } }) }} />
        //                             </span>
        //                         </ItemColor>
        //                     </Section>

        //                     <ButtonHelp>
        //                         <img src={IconBossVideo} />
        //                         <label>Precisa de ajuda</label>
        //                         <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
        //                     </ButtonHelp>

        //                     <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

        //                     <BoxButtons>
        //                         <BoxButtonsItem onClick={() => { getDefaultColors() }}>
        //                             Restaurar
        //                         </BoxButtonsItem>
        //                         <BoxButtonsItem color={true} onClick={() => { saveColors() }}>
        //                             Salvar
        //                         </BoxButtonsItem>
        //                     </BoxButtons>
        //                 </BarColors> :
        //                 <BarConfig>
        //                     <Section>
        //                         <ItemUpload>
        //                             <label>Logo:</label>
        //                             <InputFileCustom>
        //                                 <input style={{ display: "none" }} id="request_email_logourl" type={"file"} onChange={async (event) => { await handleImageUpload(event.currentTarget.files[0]) }} />
        //                                 <input
        //                                     type={"text"}
        //                                     value={typeof controlConfig?.logo_url == "string" || typeof controlConfig?.logo_url == "undefined" ? controlConfig?.logo_url : controlConfig?.logo_url?.name}
        //                                 />
        //                                 <label for="request_email_logourl">
        //                                     {uploading ?
        //                                         <BoxLoadingCustom>
        //                                             <div>
        //                                                 <AiOutlineLoading style={{ margin: "0px" }} size={12} color={"black"} />
        //                                             </div>
        //                                         </BoxLoadingCustom> :
        //                                         <FaUpload style={{ margin: "0px", cursor: "pointer" }} size={15} />}
        //                                 </label>
        //                             </InputFileCustom>
        //                         </ItemUpload>
        //                     </Section>

        //                     <Section>
        //                         <SendEmail>
        //                             <label>E-mail teste</label>
        //                             <InputTextCustom >
        //                                 <input type={"text"} value={email} onChange={(e) => { setEmail(e.target.value) }} />
        //                                 <label style={{ cursor: "pointer" }} onClick={() => { senEmail() }} >Enviar</label>
        //                             </InputTextCustom>
        //                         </SendEmail>
        //                     </Section>
        //                     <Section>
        //                         <ItemTextArea style={{ position: "relative", }}>
        //                             <label>Assunto:</label>
        //                             <textarea
        //                                 value={controlConfig.subject_email}
        //                                 rows={5}
        //                                 maxLength="255"
        //                                 placeholder={"Assunto do e-mail."}
        //                                 onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { subject_email: e.target.value } }) }}
        //                             />
        //                             <CounterItemsCircle porc={`${Math.round(controlConfig.subject_email?.length / 255 * 100)}%`} colorBorder={controlConfig.subject_email?.length < 255 ? "green" : "red"}>
        //                                 <label>{255 - controlConfig.subject_email?.length}</label>
        //                             </CounterItemsCircle>
        //                         </ItemTextArea>
        //                     </Section>
        //                     <Section>
        //                         <ItemTextArea style={{ position: "relative", }}>
        //                             <label>Conteúdo:</label>
        //                             <textarea
        //                                 value={controlConfig.message_email}
        //                                 rows={5}
        //                                 maxLength="255"
        //                                 placeholder={"Conteúdo do e-mail"}
        //                                 onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { message_email: e.target.value } }) }}
        //                             />
        //                             <CounterItemsCircle porc={`${Math.round(controlConfig.message_email?.length / 255 * 100)}%`} colorBorder={controlConfig.message_email?.length < 255 ? "green" : "red"}>
        //                                 <label>{255 - controlConfig.message_email?.length}</label>
        //                             </CounterItemsCircle>
        //                         </ItemTextArea>
        //                     </Section>
        //                     <Section>
        //                         <ItemText>
        //                             <label>Botão</label>
        //                             <input
        //                                 type={"text"}
        //                                 value={controlConfig.message_button}
        //                                 onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { message_button: e.target.value } }) }}
        //                                 placeholder={"Baixar"}
        //                             />
        //                         </ItemText>
        //                     </Section>

        //                     <ButtonHelp>
        //                         <img src={IconBossVideo} />
        //                         <label>Precisa de ajuda</label>
        //                         <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
        //                     </ButtonHelp>

        //                     <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

        //                     <BoxButtons>
        //                         <BoxButtonsItem onClick={() => { getDefaultConfig() }}>
        //                             Restaurar
        //                         </BoxButtonsItem>
        //                         <BoxButtonsItem color={true} onClick={() => { saveConfig() }}>
        //                             Salvar
        //                         </BoxButtonsItem>
        //                     </BoxButtons>
        //                 </BarConfig>
        //         }
        //     </BoxOptions>
        //     <BoxPreview style={{ position: "relative" }} img={imgBackground}>
        //         <Body color={controlConfig.color_title_email} colorBorder={"green"} background={controlConfig.color_email_background} >
        //             <BoxImage active={typeof controlConfig?.logo_url == "undefined" || controlConfig?.logo_url == null ? false : true}>
        //                 {getLogo()}

        //             </BoxImage>
        //             <BoxContent color={controlConfig.color_title_email}>
        //                 <label><pre>{controlConfig.message_email}</pre></label>
        //                 <Item backgroundColorButton={controlConfig.color_button} colorButton={controlConfig.color_message_button} color={controlConfig.color_title_email}>
        //                     <img width={150} style={{ borderRadius: "5px" }} src={IconCam} />
        //                     <label style={{ marginTop: "10px" }}><strong>{"Nome do produto"}</strong></label>
        //                     <button type='button'>{controlConfig.message_button}</button>
        //                 </Item>
        //             </BoxContent>
        //         </Body>

        //         {
        //             !controlSaveEmail &&
        //             <span style={{ position: "absolute", left: "50%", marginLeft: "-350px" }}>
        //                 <AlertSave>
        //                     <img src={IconBossSave} />
        //                     <span>
        //                         <label>{"DJSAKDHAS D KJSA HDKJSA HDKJSA HDKJASDH SAKJD H"}</label>
        //                         <div>
        //                             <label onClick={() => { dispatch({ type: "SAGA_GET_TEMPLATES" }) }} >
        //                                 DESCARTAR
        //                             </label>
        //                             <span onClick={() => { navOption != "config" ? saveColors() : saveConfig() }} >
        //                                 {/* {translation.thema.alertSalvar} */}
        //                                 SALVAR
        //                             </span>
        //                         </div>
        //                     </span>
        //                 </AlertSave>
        //             </span>
        //         }

        //     </BoxPreview>
        // </Container>

    )
}

export default BoxEmail
import { Title, BoxScript, ButtonCustom, Container, Close } from "./styled";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  AiOutlineLoading,
  AiFillCheckCircle,
  AiFillCaretRight,
} from "react-icons/ai";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import { MdError } from "react-icons/md";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { platforms } from "../../pages/home/dataCards";
import { BsTools } from "react-icons/bs";
import { MdOutlineContentCopy } from "react-icons/md";
import { GrFormClose } from "react-icons/gr";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "0px",
    margin: "0px",
  },
  overlay: {
    backgroundColor: "rgba(10,23,55,0.5)",
    zIndex: "99999999"
  },
};

const ModalComponent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const statusSync = useSelector((state) => state.reducerSync);
  const statusIntegration = useSelector((state) => state.reducerIntegration);
  const controlPlatform = useSelector((state) => state.reducerControlPlatform);
  const controlSyncProducts = useSelector(
    (state) => state.reducerControlSyncProducts
  );

  const dispatch = useDispatch();

  const getIconIntegration = () => {
    if (
      !statusIntegration.loading &&
      !statusIntegration.error &&
      statusIntegration.data.length == 0
    ) {
      //return <AiFillCaretRight size={20} color={"gray"} />
      return <RiCheckboxBlankCircleFill size={12} color={"gray"} />;
    } else {
      if (statusIntegration.loading === true)
        return <AiOutlineLoading size={20} color={"green"} />;
      else {
        if (statusIntegration.error === true)
          return <MdError size={20} color={"red"} />;
        else return <AiFillCheckCircle size={20} color={"green"} />;
      }
    }
  };

  const getIconSync = () => {
    if (
      !statusSync.loading &&
      !statusSync.error &&
      statusSync.data.length == 0
    ) {
      //return <AiFillCaretRight size={20} color={"gray"} />
      return <RiCheckboxBlankCircleFill size={12} color={"gray"} />;
    } else {
      if (statusSync.loading == true)
        return <AiOutlineLoading size={20} color={"green"} />;
      else {
        if (statusSync.error === true)
          return <MdError size={20} color={"red"} />;
        else return <AiFillCheckCircle size={20} color={"green"} />;
      }
    }
  };

  useEffect(() => {
    if (controlPlatform.data.typeIntegration === "woocommerce") {
      setModalIsOpen(false);
      // dispatch({ type: "SAGA_INTEGRATE_INTEGRATION", payload: controlPlatform.data })
    } else {
      setModalIsOpen(false);
    }
  }, []);

  useEffect(() => {
  
    if (statusIntegration.error || statusSync.error) {
     
      let mail =
        statusIntegration.data.data.substring(0, 3) +
        "*****" +
        statusIntegration.data.data.substring(
          statusIntegration.data.data.indexOf("@")
        );
      //let mail = statusIntegration.data?.data.substring(0, 3) + "*****" + data.data.substring(statusIntegration.data?.data.indexOf("@"));
      statusIntegration.data.length == 0
        ? toast.error("Erro na integração.", {
            onClose: setModalIsOpen(false),
            toastId: 0,
          })
        : toast.error(statusIntegration.data?.description + ` (${mail})`, {
            onClose: setModalIsOpen(false),
            toastId: 0,
          });
    } else if (
      statusIntegration.data.length != 0 &&
      statusSync.data.length != 0
    ) {
      toast.success(
        `${
          controlPlatform.data.typeIntegration == "custom"
            ? "Integração Manual"
            : controlPlatform.data.typeIntegration
        }  integrada com sucesso.`,
        { onClose: setModalIsOpen(false), toastId: 0 }
      );
    }
    // else if (controlPlatform.data.typeIntegration == "custom" && statusIntegration.data.length != 0) {
    //     toast.success(`${controlPlatform.data.typeIntegration} integrada com sucesso.`, { onClose: setModalIsOpen(false), toastId: 0 })
    // }
  }, [statusIntegration, statusSync]);

  return (
    <Modal
      isOpen={modalIsOpen}
      //isOpen={true}
      //onRequestClose={() => setModalIsOpen(false)}
      style={customStyles}
      ariaHideApp={false}
    >
      <Container>
        <Close onClick={() => setModalIsOpen(false)}>
          <GrFormClose size={25} color={"#5e6278"} />
        </Close>
        <>
          <Title>
            <strong>Configuração Woocommerce</strong>
          </Title>
          <div style={{ display: "flex" }}>
            <iframe
              width="420"
              height="315"
              src="https://www.youtube.com/embed/tO_qG87876g"
            ></iframe>
            <div style={{ width: 450, marginLeft: 50 }}>
              <p style={{ marginBottom: 0, marginTop: 10 }}>
                Adicione esse script no seu arquivo header.php:
              </p>

              <BoxScript>
                <p>
                  {`
                  <div class="dijital" style="width: 50%">
                  </div>
                  <script>
                  var woocommerceThankYouScript = document.createElement('script');
                  woocommerceThankYouScript.setAttribute('src',
                  'https://api.dijital.com.br/scripts/script-checkout-woocommerce.js');
                  woocommerceThankYouScript.setAttribute('type', 'text/javascript');
                  document.getElementsByClassName("section-inner")[0].appendChild(woocommerceThankYouScript);
                </script>
                  `}
                </p>
              </BoxScript>
              <ButtonCustom
                onClick={() => {
                  navigator.clipboard.writeText(
                    `<div class="dijital" style="width: 50%">
                    </div>
                    <script>
                  var woocommerceThankYouScript = document.createElement('script');
                  woocommerceThankYouScript.setAttribute('src',
                  'https://api.dijital.com.br/scripts/script-checkout-woocommerce.js');
                  woocommerceThankYouScript.setAttribute('type', 'text/javascript');
                  document.getElementsByClassName("section-inner")[0].appendChild(woocommerceThankYouScript);
                </script>`
                  );
                  toast.success("Script copiado com sucesso!");
                }}
              >
                <MdOutlineContentCopy
                  size={20}
                  color="#fff"
                  style={{ marginRight: 10 }}
                />
                Copiar
              </ButtonCustom>
            </div>
          </div>
        </>
      </Container>
    </Modal>
  );
};

export default ModalComponent;

import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 1000px;
  padding: 25px;
  border-radius: 5px;
  @media screen and (max-width: 1000px) {
    width: 500px;
  }
  @media screen and (max-width: 500px) {
    width: 300px;
  }
  //background-color: purple ;
`;

export const Code = styled.div`
  //background-color: red ;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  label {
    background-color: #f5f8fa;
    padding: 10px 10px;
    font-size: 12px;
    flex: 1;
  }

  svg {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
  }
`;

export const Item = styled.label`
  //display: flex;
  font-size: 12px;
  margin-bottom: 5px;
  text-align: justify;
`;

export const ItemTitle = styled.p`
  //display: flex;
  //font-size: 12px;
  margin-bottom: 10px;
`;

export const ButtonCustom = styled.span`
  display: flex;
  background-color: #1b76ff;
  outline: none;
  border: none;
  border-radius: 5px;
  margin-top: 15px;
  height: 50px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

export const Close = styled.span`
  display: flex;
  position: absolute;
  right: 0;
  margin-right: 25px;
  cursor: pointer;
  z-index: 9999;
`;

export const Title = styled.div`
  display: flex;
  position: relative;
  color: #5e6278;
  margin-bottom: 10px;
`;

export const InputCustomShopify = styled.input`
  height: 35px;
  padding: 0px 10px;
  margin-bottom: 10px;
  border: none;
  outline: none;
  background-color: #f5f8fa;
  border-radius: 5px;
`;

export const Input = styled.input`
  padding: 8px;
  border-radius: 6px;
  margin-top: 10px;
  border-color: gray;
  background: #f7f7f7;
  width: 100%;
`;

export const BlockPermissions = styled.div`
  padding: 8px;
  border-radius: 6px;
  margin-top: 10px;
  border-color: gray;
  background: #f7f7f7;
  width: 100%;
  margin-bottom: 10px;
  /* height: 40px; */
`;

export const Badge = styled.div`
  width: 100%;
  background: #006bec60;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-top: 10px;
  border-radius: 4px;
  p {
    font-size: 10px;
    margin: 0;
  }
`;

export const BoxScript = styled.div`
  width: 100%;
  background: #f7f7f7;
  padding: 10px;
  p {
    font-size: 14px;
    margin: 0;
    line-height: 2;
    color: #7e7e85;
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const BlockInfo = styled.div`
  width: 450px;
  margin-left: 50px;
  @media screen and (max-width: 1000px) {
    margin-left: 0px;
  }
`;

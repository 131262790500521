import FormaPagamentoInfo from '../FormaPagamentoInfo';
import { Container } from './styled'
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from '../../../../components/Button/index';
import { usePagamento } from '../../hooks/usePagamento';

const FormaPagamento = () => {
    const pagamento = usePagamento();
    const {data: { dataCard }} = useSelector(state => state.reducerPayments)
    const [image, setImage] = useState(null)

    useEffect(() => {
        if(dataCard?.bandeira) {
            const fetchImage = async () => {
                try {
                    const response = await import(`../../../../assets/imgs/bandeiras/${dataCard?.bandeira.toLowerCase()}.png`)
                    setImage(response.default)
                } catch (err) {
                    
                }
            }
    
            fetchImage()
        }
    }, [dataCard?.bandeira])

    return (
        <>
        <Container>
            <div>
                <h3>Forma de Pagamento</h3>
                <p style={{margin: '16px 0'}}>Seu cartão atual utilizado nos pagamentos</p>
                {dataCard?.numero ? 

                    <p style={{display: 'flex', alignItems: 'center'}}>
					  <img src={image} alt={dataCard.bandeira} />
					  {dataCard.numero.replace(/[\-X]/g,'*')}
					</p>

                :
                    <p style={{fontWeight:"500",fontSize:"1rem"}}>Nenhuma forma de pagamento cadastrada </p>
                }
				
            </div>
            
            <Button handleClick={() => {
                if(!pagamento.isLoading) { 
                    pagamento.setCaptura(true);
                    pagamento.handleToggleIugu(true);
                }
            }} width="143px" background="#FBF9F6" color="gray" border="#d3d3d3">Alterar cartão</Button>
        </Container>
        <FormaPagamentoInfo />
        </>
    );
}

export default FormaPagamento

const INITIAL_STATE = {
    error: null,
    loading: null,
    data: []
}

const ControlRemoveLink = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PRODUCT_CONTROL_REMOVE_LINK_REQUEST':
            return { loading: true, error: false, data: [] }
        case 'PRODUCT_CONTROL_REMOVE_LINK_ADD':
            return { loading: true, error: false, data: [...state.data, action.payload] }
        case 'PRODUCT_CONTROL_REMOVE_LINK_ERROR':
            return { loading: false, error: true, data: state };
        case 'PRODUCT_CONTROL_REMOVE_LINK_SUCCESS':
            return { loading: false, error: false, data: [...state.data, action.payload] };
        case 'PRODUCT_CONTROL_REMOVE_LINK_RESET':
            return { loading: false, error: false, data: [] };
        default:
            return state;
    }
}

export default ControlRemoveLink
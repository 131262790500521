import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { POST_ERROR } from "../../../../services/api";
import { formatTimestamp } from "../../../../utils/date";
import { Container } from "./styled";
import { translations } from "../../../../utils/language";

const cores_protesto = ["", "#ffebeb", "#ebebeb", "#ebebeb"];

const TablePagamentos = ({ faturasInitialValue }) => {
  const tableElement = useRef(null);
  const container = useRef(null);
  const language = useSelector((state) => state.reducerLanguage);

  const dataPayments = useSelector((state) => state.reducerPayments?.data?.user);

  useEffect(() => {
    console.log("payments", dataPayments)
  }, [dataPayments])


  useEffect(() => {
    if (tableElement.current.hasChildNodes()) {
      container.current.style.minHeight = "-webkit-fill-available";
    } else {
      container.current.style.minHeight = "150px";
    }
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();

  function acoesFatura(fatura) {
    if (fatura.protest === 0) {
      return (
        <>
          <td>
            <span>Pago</span>
          </td>
          <td>-</td>
        </>
      );
    }

    if (fatura.protest === 1) {
      async function repetirCobranca(transaction_id) {
        const id = 0;
        toast.loading("Aguarde...", { toastId: id });
        let { data } = await POST_ERROR({
          endpoint: "/payments/regularize-protest",
          data: { transacao: transaction_id },
        });

        toast.update(id, {
          render: data.description
            ? data.description
            : data.error
              ? "Erro inesperado"
              : "Sucesso",
          type: data.error ? "error" : "success",
          isLoading: false,
          hideProgressBar: false,
          autoClose: 3000,
          closeOnClick: true,
        });

        if (data?.redirect) {
          let delay = data?.redirect?.delay ? data.redirect.delay : 50;
          setTimeout(() => {
            if (data?.redirect.to === "self") {
              dispatch({
                type: "SAGA_LIST_PAYMENTS",
              });
            } else {
              history.push(data.redirect.to);
            }
          }, delay);
        }
      }

      return (
        <>
          <td>
            <span>Protestado</span>
          </td>
          <td>
            <button
              onClick={() => {
                repetirCobranca(fatura.transaction_id);
              }}
              type="button"
            >
              Repetir Cobrança
            </button>
          </td>
        </>
      );
    }

    if (fatura.protest === 2) {
      return (
        <>
          <td>
            <span>Fatura com protesto regularizada</span>
          </td>
          <td>-</td>
        </>
      );
    }

    if (fatura.protest === 3) {
      return (
        <>
          <td>
            <span>Pagamento estornado</span>
          </td>
          <td>-</td>
        </>
      );
    }
  }

  return (
    <Container ref={container}>
      <table>
        <tr>
          <th>Data da Instalação</th>
          <th>Último pagamento</th>
          <th>Vencimento atual</th>
        </tr>
        <tr>
          <th style={{ color: "#808080" }}>{new Date(dataPayments?.created_at).toLocaleDateString()}</th>
          <th style={{ color: "#808080" }}>{dataPayments?.cred_last_date == null ? "" : new Date(+dataPayments?.cred_last_date * 1000).toLocaleDateString()}</th>
          <th style={{ color: "#808080" }}>{new Date(dataPayments?.cred_expire * 1000).toLocaleDateString()}</th>
        </tr>
      </table>
      <h3>{translations["Pagamentos"][language.data]}</h3>
      <table>
        <thead>
          <tr>
            <th>{translations["Data"][language.data]}</th>
            <th>{translations["Plano Contratado"][language.data]}</th>
            <th>{translations["Total"][language.data]}</th>
            <th>{translations["Desconto"][language.data]}</th>
            <th>{translations["Validade"][language.data]}</th>
            <th>{translations["Quantidade"][language.data]}</th>
            <th>{translations["Nota Fiscal"][language.data]}</th>
            <th>{translations["Status"][language.data]}</th>
            <th>{translations["Ações"][language.data]}</th>
          </tr>
        </thead>
        <tbody ref={tableElement}>
          {faturasInitialValue.map((fatura, index) => (
            <tr
              key={index}
              style={
                cores_protesto[fatura.protest] !== ""
                  ? { backgroundColor: cores_protesto[fatura.protest] }
                  : {}
              }
            >
              <td>
                {fatura.date ? formatTimestamp(fatura.date, "dd/MM/yyyy") : "-"}
              </td>
              <td>{fatura.description}</td>
              <td>{fatura.value}</td>
              <td>{fatura.discount}</td>
              <td>{fatura.months} Mês</td>
              <td>{fatura.quantity}</td>
              <td>
                {fatura?.invoice_url ? (
                  <a rel="noreferrer" href={fatura.invoice_url} target="_blank">
                    Download
                  </a>
                ) : (
                  <span>Aguarde</span>
                )}
              </td>

              {acoesFatura(fatura)}
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default TablePagamentos;


const INITIAL_STATE = {
    data: []
}

const ControlLinks = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LINKS_CONTROL_ADD':
            return { data: [...state.data, ...action.payload] }
        // case 'LINKS_CONTROL_MEDIA_REMOVE':
        //     const data = state.data.filter()
        //     return { loading: false, error: false, data: [...state.data, action.payload] };
        case 'LINKS_CONTROL_RESET':
            return { data: [] };
        default:
            return state;
    }
}

export default ControlLinks
import { ArrowTopRightOnSquareIcon, CheckCircleIcon, CloudArrowDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useEffect } from "react";
//import Pagination from "../components/pagination";
import { useState } from "react";
import { useSelector } from "react-redux";
//import { BarFilters, BarFiltersIcon, BarFiltersLeft, Search } from "./styled";
//import FiltersOrder from "../components/filters-order";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PreviewCheckout() {

    const [search, setsearch] = useState("")
    const controlConfig = useSelector((state) => state.reducerControlConfig);

    useEffect(() => {
        console.log("controlConfig", controlConfig)
    }, [controlConfig])

    const getColorButton = () => {
        switch (controlConfig.checkout_color) {
            case "gray":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-gray-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            case "red":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            case "yellow":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-yellow-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
            case "green":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            case "blue":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            case "purple":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-purple-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
            case "pink":
                return "inline-flex items-center gap-x-1.5 rounded-md bg-pink-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
            default:
                return "inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        }
    }

    const getColorType = () => {
        switch (controlConfig.checkout_color) {
            case "gray":
                return "rounded bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm hover:bg-gray-100"
            case "red":
                return "rounded bg-red-50 px-2 py-1 text-xs font-semibold text-red-600 shadow-sm hover:bg-red-100"
            case "yellow":
                return "rounded bg-yellow-50 px-2 py-1 text-xs font-semibold text-yellow-600 shadow-sm hover:bg-yellow-100"
            case "green":
                return "rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100"
            case "blue":
                return "rounded bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
            case "purple":
                return "rounded bg-purple-50 px-2 py-1 text-xs font-semibold text-purple-600 shadow-sm hover:bg-purple-100"
            case "pink":
                return "rounded bg-pink-50 px-2 py-1 text-xs font-semibold text-pink-600 shadow-sm hover:bg-pink-100"
            default:
                return "rounded bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
        }
    }

    const getColorMedia = () => {
        switch (controlConfig.checkout_color) {
            case "gray":
                return "rounded bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm hover:bg-gray-100"
            case "red":
                return "rounded bg-red-50 px-2 py-1 text-xs font-semibold text-red-600 shadow-sm hover:bg-red-100"
            case "yellow":
                return "rounded bg-yellow-50 px-2 py-1 text-xs font-semibold text-yellow-600 shadow-sm hover:bg-yellow-100"
            case "green":
                return "rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100"
            case "blue":
                return "rounded bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
            case "purple":
                return "rounded bg-purple-50 px-2 py-1 text-xs font-semibold text-purple-600 shadow-sm hover:bg-purple-100"
            case "pink":
                return "rounded bg-pink-50 px-2 py-1 text-xs font-semibold text-pink-600 shadow-sm hover:bg-pink-100"
            default:
                return "rounded bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
        }
    }


    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "34px 0px" }}>
            <div style={{ borderRadius: "4px", maxWidth: "1200px", width: "100%" }} className="bg-white py-10">
                <div style={{ margin: "0px 20px" }}>
                    <h1 className="text-base font-semibold leading-6 text-gray-900">{controlConfig.checkout_message_title}</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {controlConfig.checkout_message}
                    </p>
                </div>
                {/* <BarFilters>
                    <span style={{ display: "flex", justifyContent: "space-between" }}>
                        <BarFiltersLeft>
                            <Search>
                                <span>
                                    <MagnifyingGlassIcon style={{ width: "20px" }} />
                                </span>
                                <input onKeyPress={(e) => { e.key == "Enter" && alert("teste") }} type='text' placeholder='Pesquisar por nomes...' value={search} onChange={(e) => { setsearch(e.target.value) }} />
                            </Search>
                        </BarFiltersLeft>
                        <BarFiltersIcon>
                            <div style={{ display: "flex" }}>
                                <FiltersOrder

                                />
                            </div>
                        </BarFiltersIcon>
                    </span>
                </BarFilters> */}
                <table className="mt-6  whitespace-nowrap text-left">
                    <colgroup>
                        <col className="sm:w-4/12" />
                        <col className="lg:w-4/12" />
                        <col className="lg:w-1/12" />
                        <col className="lg:w-1/12" />
                    </colgroup>
                    <thead className="border-b border-gray/10 text-sm leading-6 text-gray-700">
                        <tr>
                            <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                                Produto
                            </th>
                            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                                Mídia
                            </th>
                            <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                                Tipo
                            </th>
                            <th scope="col" className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                                Download
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray/5">

                        {Array(window.screen.height > 800 ? 3 : 2).fill(
                            <tr key={"item.commit"}>
                                <td className="py-2 pl-4 pr-8 sm:pl-6 lg:pl-8">
                                    <div className="flex items-center gap-x-4">
                                        <img alt="" src={"https://cdn-icons-png.flaticon.com/512/9913/9913420.png"} className="h-8 w-8 rounded bg-gray-800" />
                                        <div className="truncate text-sm font-medium leading-6 text-gray">{"Nome do produto"}</div>
                                    </div>
                                </td>
                                <td className="hidden py-2 pl-0 pr-4 sm:table-cell sm:pr-8">
                                    <div className="flex gap-x-3">
                                        <div className="font-mono text-sm leading-6 text-gray-700">{"eBook"}</div>
                                        {"item.size" != null && <div className={getColorMedia()}>
                                            {"10MB"}
                                        </div>}
                                    </div>
                                </td>
                                <td className="hidden py-2 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                                    <div className="flex gap-x-3">
                                        <div className={getColorType()}>
                                            {"PDF"}
                                        </div>
                                    </div>
                                </td>
                                <td className="py-4 pl-0 pr-0 text-sm leading-6 sm:pr-0 lg:pr-6"
                                >
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <button
                                            //onClick={() => { download(item) }}
                                            type="button"
                                            className={getColorButton()}
                                        >
                                            < CloudArrowDownIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                            {controlConfig.checkout_button}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )}

                        {Array(window.screen.height > 800 ? 3 : 1).fill(
                            <tr key={"item.commit"}>
                                <td className="py-2 pl-4 pr-8 sm:pl-6 lg:pl-8">
                                    <div className="flex items-center gap-x-4">
                                        <img alt="" src={"https://freeiconshop.com/wp-content/uploads/edd/image-outline-filled.png"} className="h-8 w-8 rounded bg-gray-800" />
                                        <div className="truncate text-sm font-medium leading-6 text-gray">{"Nome do produto"}</div>
                                    </div>
                                </td>
                                <td className="hidden py-2 pl-0 pr-4 sm:table-cell sm:pr-8">
                                    <div className="flex gap-x-3">
                                        <div className="font-mono text-sm leading-6 text-gray-700">{"Kit imagens"}</div>
                                        {null != null && <div className="rounded bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600 shadow-sm hover:bg-blue-100">
                                            {""}
                                        </div>}
                                    </div>
                                </td>
                                <td className="hidden py-2 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                                    <div className="flex gap-x-3">
                                        <div className={getColorType()}>
                                            {"URL"}
                                        </div>
                                    </div>
                                </td>
                                <td className="py-4 pl-0 pr-0 text-sm leading-6 sm:pr-0 lg:pr-6"
                                >
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <button
                                            //onClick={() => { download(item) }}
                                            type="button"
                                            className={getColorButton()}
                                        >
                                            < ArrowTopRightOnSquareIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
                                            {controlConfig.checkout_button_link}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )}

                    </tbody>
                </table>
                {/* <Pagination setPage={setpage} items={filterProducts} /> */}
            </div>
        </div>
    )
}

import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Header from "../../../../components/Header";
import Contents from "../../../../components/Contents";
import { BoxContentStep, BoxOptions, Btn, Container, ContainerBlock, Description, DescriptionBlock, ItemOption, SectionCardsIntegration, SectionContents, StepCircle, TextHelp, TextSupport, Title, TitleBlock, ContainerMedias, Line, Legend, ImagePersonalize, } from "./styled";
import { isVisibleScript } from "../../../../utils/displayScript";
import { AiFillCheckCircle, AiFillPlayCircle, AiOutlinePaperClip, } from "react-icons/ai";
import { BiEdit, BiBox } from "react-icons/bi";
import { useEffect, useState } from "react";
import CardIntegration from "../../components/card-integration";

import nuvemshopImg from "../../../../assets/imgs/nuvem-shop-640x480.png";
import yampiImg from "../../../../assets/imgs/logo-yampi.png";
import wooImg from "../../../../assets/imgs/logo-woo.png";
import shopifyImg from "../../../../assets/imgs/shopify-logo.png";
import { platforms } from "../../../integrations/pages/home/dataCards";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ModalIntegration from "../../../integrations/components/Modal";
import example from "../../../../assets/imgs/example.PNG";
import checkout from "../../../../assets/imgs/checkout.PNG";
import email from "../../../../assets/imgs/email.PNG";
import { translations } from "../../../../utils/language";
import ModalVideo from "../../components/modal-video";
import LayoutNew from "../../../../components/tailwind/layout-new";
import { ContainerNew } from "../../../dashboard/pages/home-new/styled";

const Home = () => {
  const statusDeleteCupom = useSelector((state) => state.reducerDeleteCupom);
  const statusUpdateCupom = useSelector((state) => state.reducerUpdateCupom);
  const language = useSelector((state) => state.reducerLanguage);

  const [menuActive, setmenuActive] = useState("integration");

  const [visibleModalInforVideoIntgration, setVisibleModalInforVideoIntgration,] = useState(false);
  const [visibleModalInforVideoCupom, setVisibleModalInforVideoCupom] = useState(false);
  const [visibleModalInforVideo, setVisibleModalInforVideo] = useState(false);

  const [statusNuvemshop, setStatusNuvemshop] = useState(false);
  const [statusYampi, setStatusYampi] = useState(false);
  const [statusWoocommerce, setStatusWoocommerce] = useState(false);
  const [statusShopify, setStatusShopify] = useState(false);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const statusPlatforms = useSelector((state) => state.reducerGetPlatforms);
  const medias = useSelector((state) => state.reducerMedias);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (statusPlatforms.data.length > 0) {
      for (let item of statusPlatforms.data) {
        switch (item.platform) {
          case "Nuvemshop":
            setStatusNuvemshop(true);
            break;
          case "Yampi":
            setStatusYampi(true);
            break;
          case "Woocommerce":
            setStatusWoocommerce(true);
            break;
          case "Shopify":
            setStatusShopify(true);
            break;
          default:
            break;
        }
      }
    } else {
      setStatusNuvemshop(false);
    }
  }, [statusPlatforms]);

  useEffect(() => {
    dispatch({
      type: "SAGA_LIST_MEDIA",
      payload: {
        search: search,
        perPage: perPage,
        page: page,
      },
    });
  }, [perPage, dispatch, search, page]);

  useEffect(() => {
    dispatch({ type: "SAGA_GET_PLATFORMS_INTEGRATION" });
  }, []);

  useEffect(() => {
    if (
      !(
        statusDeleteCupom.loading == false &&
        !statusDeleteCupom.error == false &&
        statusDeleteCupom.data.length == 0
      )
    ) {
      if (statusDeleteCupom.loading) {
      } else {
        if (statusDeleteCupom.error) {
        } else {
          dispatch({
            type: "SAGA_LIST_CUPONS",
            payload: { search: "", perPage: 20, page: 1 },
          });
        }
      }
    }
  }, [statusDeleteCupom]);

  useEffect(() => {
    if (
      !(
        statusUpdateCupom.loading == false &&
        !statusUpdateCupom.error == false &&
        statusUpdateCupom.data.length == 0
      )
    ) {
      const id = "statusAddCupom";
      if (statusUpdateCupom.loading) {
      } else {
        if (statusUpdateCupom.error) {
        } else {
          dispatch({
            type: "SAGA_LIST_CUPONS",
            payload: { search: "", perPage: 20, page: 1 },
          });
        }
      }
    }
  }, [statusUpdateCupom]);

  useEffect(() => {
    dispatch({ type: "SAGA_GET_PLATFORMS_INTEGRATION" });
  }, [dispatch]);

  return (

    <LayoutNew>
      <ContainerNew>



        <ModalIntegration />
        <ContainerBlock>
          <TitleBlock>
            {translations["Bem vindo ao Dijital"][language.data]}
          </TitleBlock>
          <DescriptionBlock>
            {translations["Siga o passa a passo e comece a vender."][language.data]}
          </DescriptionBlock>
          <Container>
            <BoxOptions>
              <ItemOption
                active={menuActive == "integration" ? true : false}
                onClick={() => { setmenuActive("integration"); }} >
                {statusNuvemshop ? (
                  <AiFillCheckCircle size={32} color={"green"} />
                ) : (
                  <StepCircle>
                    <label>1</label>
                  </StepCircle>
                )}
                <label
                  onClick={() => {
                    setmenuActive("integration");
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  {translations["Integrações"][language.data]}
                </label>
              </ItemOption>

              <ItemOption
                active={menuActive == "cupom" ? true : false}
                onClick={() => {
                  setmenuActive("cupom");
                }}
              >
                {medias?.data?.data?.length > 0 ? (
                  <AiFillCheckCircle size={32} color={"green"} />
                ) : (
                  <StepCircle>
                    <label>2</label>
                  </StepCircle>
                )}
                <label
                  onClick={() => {
                    setmenuActive("cupom");
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  {translations["Vincular arquivo"][language.data]}
                </label>
              </ItemOption>

              <ItemOption
                active={menuActive == "dropi" ? true : false}
                onClick={() => {
                  setmenuActive("dropi");
                }}
              >
                <label
                  onClick={() => {
                    setmenuActive("dropi");
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  {translations["Personalizar"][language.data]}
                </label>
              </ItemOption>
            </BoxOptions>
            <BoxContentStep>
              <SectionContents>
                {menuActive == "integration" && (
                  <>
                    <Title>
                      {translations["Conecte sua loja"][language.data]}
                    </Title>
                    <Description>
                      {
                        translations[
                        "Escolha a plataforma de sua preferência e conecte ou crie a sua loja para começar a usar o Dijital."
                        ][language.data]
                      }
                    </Description>
                    <SectionCardsIntegration>
                      <CardIntegration
                        install={statusNuvemshop}
                        platform={platforms.nuvemshop}
                        img={nuvemshopImg}
                        height={48}
                        width={64}
                        tooltip={translations["Nuvemshop"][language.data]}
                      />
                      {language.data === "pt" && (
                        <CardIntegration
                          install={statusYampi}
                          platform={platforms.yampi}
                          img={yampiImg}
                          height={48}
                          width={48}
                          tooltip={"Yampi"}
                        />
                      )}
                      {/* <CardIntegration
                          install={statusWoocommerce}
                          platform={platforms.woocommerce}
                          img={wooImg}
                          height={40}
                          width={48}
                          tooltip={"Woocommerce"}
                        /> */}

                      <CardIntegration
                        install={statusShopify}
                        platform={platforms.shopify}
                        img={shopifyImg}
                        height={40}
                        width={48}
                        tooltip={"Shopify"}
                      />

                    </SectionCardsIntegration>
                  </>
                )}

                {menuActive == "cupom" && (
                  <>
                    <Title>
                      {translations["Vincular arquivo"][language.data]}
                    </Title>
                    <Description>
                      {
                        translations[
                        "Adicione uma mídia (anexo) ao produto."
                        ][language.data]
                      }
                    </Description>
                    <SectionCardsIntegration>
                      <ContainerMedias>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <StepCircle
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: 20,
                            }}
                          >
                            <label>1</label>
                          </StepCircle>{" "}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: 20,
                              height: 60,
                            }}
                          >
                            <BiBox
                              size={30}
                              color={"#000"}
                            // style={!burgerMenu && { marginLeft: 10 }}
                            />{" "}
                            <Description
                              style={{ fontWeight: "normal", marginLeft: 10 }}
                            >
                              Produtos
                            </Description>
                          </div>
                          <Legend>
                            {translations["Vá em produtos"][language.data]}
                          </Legend>
                        </div>
                        <Line />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <StepCircle
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: 20,
                            }}
                          >
                            <label>2</label>
                          </StepCircle>{" "}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: 20,
                            }}
                          >
                            <div
                              style={{
                                height: 60,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  borderColor: "#1B76FF",
                                  borderStyle: "solid",
                                  borderRadius: 500,
                                  padding: 5,
                                }}
                              >
                                <AiOutlinePaperClip
                                  size={30}
                                  color="#1B76FF"
                                />
                              </div>
                            </div>
                          </div>
                          <Legend>
                            {translations["Clique no ícone"][language.data]}
                          </Legend>
                        </div>
                        <Line />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <StepCircle
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: 20,
                            }}
                          >
                            <label>3</label>
                          </StepCircle>{" "}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: 20,
                            }}
                          >
                            <img
                              src={example}
                              style={{ width: 250, height: 60 }}
                            />
                          </div>
                          <Legend>
                            {
                              translations[
                              "Selecione a mídia que deseja vincular"
                              ][language.data]
                            }
                          </Legend>
                        </div>
                      </ContainerMedias>
                    </SectionCardsIntegration>
                  </>
                )}

                {menuActive == "dropi" && (
                  <>
                    <Title>
                      {translations["Personalize - opcional"][language.data]}{" "}
                    </Title>
                    <Description>
                      {
                        translations[
                        "Personalize seu email e sua página de checkout."
                        ][language.data]
                      }
                    </Description>
                    <SectionCardsIntegration>
                      <ContainerMedias>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => history.push("/personalizar/email")}
                        >
                          <Description
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            {
                              translations["Personalize seu email"][
                              language.data
                              ]
                            }
                          </Description>
                          <ImagePersonalize src={email} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            history.push("/personalizar/checkout")
                          }
                        >
                          <Description
                            style={{
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            {
                              translations["Personalize seu checkout"][
                              language.data
                              ]
                            }
                          </Description>
                          <ImagePersonalize src={checkout} />
                        </div>
                      </ContainerMedias>
                    </SectionCardsIntegration>
                  </>
                )}
              </SectionContents>

              <TextHelp>
                {translations["Precisa de ajuda?"][language.data]}
              </TextHelp>
              <div style={{ display: "flex", gap: "20px" }}>
                <Btn onClick={() => { setVisibleModalInforVideo(true); }}                  >
                  <AiFillPlayCircle color={"blue"} size={20} />
                  {translations["Assistir vídeo"][language.data]}
                </Btn>
                <Btn
                  onClick={() => {
                    history.push("/ajuda");
                  }}
                >
                  <BiEdit color={"green"} size={20} />
                  {translations["Ir ao FAQ"][language.data]}
                </Btn>
              </div>
              <TextSupport>
                {translations["Precisa de mais ajuda?"][language.data]}
                <strong
                  onClick={() => {
                    window.open(
                      "https://api.whatsapp.com/send?phone=558171033488"
                    );
                  }}
                >
                  {translations["Falar com o suporte"][language.data]}
                </strong>
              </TextSupport>
            </BoxContentStep>
          </Container>
        </ContainerBlock>

        {isVisibleScript(false)}
        <ModalVideo visible={visibleModalInforVideo} setVisibleModal={setVisibleModalInforVideo} />
      </ContainerNew>
    </LayoutNew>
  );
};

export default Home;

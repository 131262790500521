import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;

    @media(max-width: 450px) {
        div + div {
            width: 100%;
        }
    }
`

export const CloseIcon = styled.button`
    position: absolute; 
    top: 26px; 
    font-size: 30px;
    right: 26px; 
    background: none; 
    border: none;
    cursor: pointer;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    input {
        width: 530px; 
        height: 47px;
        border-radius: 5px;
        border: 1px solid #eee;
        margin-top: 10px;
        padding: 0 15px;
    }
`

export const CupomHeader = styled.div`
    display: flex;
    flex-direction: column;
    strong {
        margin-bottom: 10px;
    }
`
import styled from 'styled-components'
import { darken, lighten } from 'polished'


export const Container = styled.div`
    display: ${props => props.visible ? "flex" : "none"};
    gap: 1rem;
    align-items: center;
    font-weight: 500;
    padding: ${props => props.padding};
    font-size: 0.9rem;
    background: ${props => lighten(0.4, props.background ?? '#ffc700')};
    border-radius: 5px;
    color: ${props => darken(0.2, props.background ?? '#ffc700')};
    border: 1px dashed ${props => lighten(0.04, props.background ?? '#ffc700')};
    /* margin-bottom: ${props => props.marginBottom};
    margin-top: ${props => props.marginTop}; */
    margin: ${props => props.margin == null ? "20px 0px 0px 0px" : props.margin};
    align-items:  center !important;
    /* margin-left: 0px ;
    margin-right: 0px ;
    margin-top: 20px; */
    border-left: ${props => props.borderleft ? "4px solid #FFC400": "1px dashed #ffc700"} ;
    font-family: "poppins" ;
    position: relative;
    span {
        svg {
            color: ${props => lighten(0.03, props.background ?? '#ffc700')};
            position: initial ;
            cursor: initial ;
        }
    }

    svg{
        position: absolute ;
        right: 10px;
        cursor: pointer;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    //background-color: purple ;
    align-items: center !important;
    justify-content: space-between ;
    width: 100% ;
    margin-right: 30px;
    

    
    @media screen and (max-width: 768px) {
        flex-direction: column ;
        gap: 20px;
    }
`
import Modal from "react-modal";
import { BarUpload, BoxActions, BoxFiles, BoxFilesList, BoxFilesListItem, BoxFilesTitle, BoxForm, BoxUploads, BoxUploadsDrag, BoxUploadsInput, Close, Container, InputCustom, StatusFile, TitleModal, UploadFIle, UploadInfor } from "./styled";
import Title from "../../../../components/Title";
import { CloseCircle, Trash } from "iconsax-react";
import Loading from "../../../../components/Loading";
import { useState } from "react";
import { useEffect } from "react";
import { translations } from "../../../../utils/language";
import { useDispatch, useSelector } from "react-redux";
import { FaCloudUploadAlt, FaTrash } from "react-icons/fa";
import IconDefault from "../../../../assets/imgs/icons/default.svg"
import { IconFile } from "../../pages/home-new/styled";
import { GrFormClose } from "react-icons/gr";
import CardUploadMedia from "../card-upload-media";
import ModalConfirmation from "../../../../components/Modal-Confirmation";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "100%",
        maxWidth: "900px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    },
};





const ModalUploadFiles = (props) => {

    const language = useSelector((state) => state.reducerLanguage);
    const [dragging, setDragging] = useState(false);

    const [dataFiles, setDataFiles] = useState([])
    const [visibleModalClose, setVisibleModalClose] = useState(false)

    const dispatch = useDispatch()


    const setUploadFile = (files) => {
        console.log("fiiles", files)
        setDataFiles([...dataFiles, ...files,])
    }

    const closeMOdal = () => {

        props.setVisible(false);
        setDataFiles([])
        dispatch({ type: "SAGA_LIST_MEDIA", payload: { search: "", perPage: 10, page: 1, }, });
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(false);
        setDataFiles([...dataFiles, ...e.dataTransfer.files]);
    };

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { }} 
        >
            <Container>

                <ModalConfirmation
                    confirmation={closeMOdal}
                    setVisibleModal={setVisibleModalClose}
                    visible={visibleModalClose}
                    title={`Deseja fechar o modal?`}
                />

                <Close onClick={() => { dataFiles.length > 0 ? closeMOdal() : props.setVisible(false); }}>
                    <CloseCircle color="gray" size={30} />
                </Close>
                <TitleModal>
                    <Title marginBottom="16px">
                        {/* {translations["medias-renomear-midia"][language.data]} */}
                        {translations["medias-upload-arquivos"][language.data]} <span style={{ backgroundColor: "#FFF4CC", color: "#997700", fontSize: "12px", padding: "5px 20px", marginLeft: "10px", borderRadius: '4px' }}>Máximo de 100mb por {translations["medias-arquivo"][language.data]}.</span>
                    </Title>
                </TitleModal>

                {/* <hr style={{ backgroundColor: "yellow" }} /> */}

                <BoxForm>
                    <BoxUploads>
                        <BoxUploadsDrag active={dragging}
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            <FaCloudUploadAlt size={40} color="#12479B" />
                            <label> {translations["medias-arraste-arquivos"][language.data]}</label>
                        </BoxUploadsDrag>
                        <BoxUploadsInput>
                            <label for="upload-file" > {translations["medias-escolher-midias"][language.data]}</label>
                            <input onChange={(e) => { setUploadFile(e.target.files) }} style={{ display: "none" }} id="upload-file" type="file" multiple />
                        </BoxUploadsInput>
                    </BoxUploads>
                    <BoxFiles>
                        <BoxFilesTitle>
                            {dataFiles.length > 0 ? translations["medias-arquivo"][language.data] : translations["medias-nenhum-arquivo"][language.data]}
                        </BoxFilesTitle>
                        <BoxFilesList>
                            {dataFiles.reverse().map((item, index) => {
                                return (<CardUploadMedia getIconFile={props.getIconFile} item={item} key={index} position={index} />)
                            })}
                        </BoxFilesList>
                    </BoxFiles>
                </BoxForm>
            </Container>

        </Modal >
    )
}

export default ModalUploadFiles
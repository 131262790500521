import styled from 'styled-components'

export const ContainerForm = styled.form`
    background: white;
    padding: 30px;
    margin-top: 30px;
    border-radius: 10px;

    .cep-input {
        flex: 2.1;
    }
`

export const InputContainer = styled.div`
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
`

export const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    label {
        margin-top: 1rem; 
        margin-bottom: 8px;
    }

    select {
        padding: 8px 16px;
        border-radius: 5px; 
        border: 1px solid #d8dadd;
    }
`
import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  height: 550px;
  width: 100%;
  border-radius: 10px;
  padding: 15px;
  flex-direction: column;
  margin-top: 30px;

  @media screen and (max-width: 1200px) {
    margin-top: 30px;
    width: 100%;
  }
`;

export const ContainerLine = styled.div`
  display: flex;
  padding-left: 5px;
  width: 100%;
  /* align-items: center; */
  flex-direction: column;
  /* justify-content: center; */
`;

import { Card, Price } from "./styled";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import Button from "../../../../components/Button/index";
import { useEffect } from "react";
import { translations } from "../../../../utils/language";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const CardPlanoNew = (props, { idPlan }) => {

  const language = useSelector((state) => state.reducerLanguage);

  useEffect(() => {
    
  }, [props])


  const linkPLus = () => {
    toast.loading("Aguarde...",  "djsadhjksadhskj")
    window?.empreenderPlusApiMethods?.verifyIfLoggedinBeforeRedirect(process.env.REACT_APP_EPLUS_DOMAIN + `/planos?app=DIJITAL&p=${btoa(props.item[1].id)}&chave=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}`)
  }


  return (
    <Card>
      <h3>{props.item[1].name}</h3>
      <Price>
        <span>R$ {props.item[1].value}</span>
        <small> / mês</small>
      </Price>

      <ul>

        {
          props.features.map((funcionalidade, index) => {
            if (!funcionalidade['ativo']) return '';

            let textoFormatado = funcionalidade.nome;
            let temAcesso = false;

            if (props.item[1].id in funcionalidade.planos) {
              temAcesso = true;

              let dadosSubstituir = funcionalidade.planos[props.item[1].id];

              Object.entries(dadosSubstituir).forEach(values => {
                let regex = new RegExp(values[0].replace('[', '\\[').replace(']', '\\]'), 'g');
                textoFormatado = textoFormatado.replace(regex, values[1]);
              });

            } else {
              let regex = new RegExp(`\\[[^\\]]+\\]`, 'g');
              textoFormatado = textoFormatado.replace(regex, '-');
            }

            return <li key={index}>{temAcesso ? <AiOutlineCheck /> : <AiOutlineClose />} {textoFormatado}</li>
          })
        }

      </ul>

      {/* <ul>
        <li>
          <AiOutlineCheck />
          Gerenciar produto digital
        </li>
        <li>
          <AiOutlineCheck />
          {props.item[1].id == 1 && "150 MB para upload"}
          {props.item[1].id == 2 && "1 GB para upload"}
        </li>

      </ul> */}

      {process.env.REACT_APP_HABILITAR_PLUS && props.current.id != props.item[1].id ?
        <Button type="button" width="100%" marginTop="20px" background="#ffc400" color="#5e6278">
          <a rel="noreferrer" onClick={() => { linkPLus() }} style={{ color: '#651006' }}>{translations["assinatura-trocar-plano"][language.data]}</a>
        </Button> :
        <Button type="button" width="100%" marginTop="20px" background={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.cred_expire == 0 ? "#ffc400" : "#FEE79E"} color={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.cred_expire == 0 ? '#651006' : "#5e6278"}>
          <a rel="noreferrer" onClick={() => { JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.cred_expire == 0 && linkPLus() }} style={{ color: '#651006' }}>{translations["assinatura-plano-atual"][language.data]}</a>
        </Button>
        // <Button
        //   onClick={() => { alert("teste") }} type="button" width="100%" marginTop="20px" background={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.cred_expire == 0 ? "#ffc400" : "#FEE79E"}
        //   color={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.cred_expire == 0 ? '#651006' : "#5e6278"}
        //   style={{ pointerEvents: JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.cred_expire == 0 ? "pointer" : 'none' }}>
        //   {"Plano Atual"}
        // </Button>
      }

      {/* {
        process.env.REACT_APP_HABILITAR_PLUS && props.current.id == props.item[1].id ?
          <Button type="button" width="100%" marginTop="20px" background="#ffc400" color="#fff"        >
            <a
              rel="noreferrer"
              href={process.env.REACT_APP_EPLUS_DOMAIN + `/planos?app=LILY&p=${props.item.id}&chave=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}`}
              target="_blank"
              style={{ color: "#651006" }}>
              Ver Plano
            </a>
          </Button> :
          <Button type="button" width="100%" marginTop="20px" background="#567ebb" color="#fff" style={{ pointerEvents: "none", cursor: "pointer" }}>
            {props.current.id != props.item.id ? "Trocar Plano" : translations["Plano atual"][language.data]}
          </Button>
      } */}
    </Card >
  );
};

export default CardPlanoNew;

import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 25px;
    border-radius: 5px;
    

    @media screen and (max-width: 450px) {
        width: 300px;
    }
`;

export const Close = styled.span`
    display: flex;
    position: absolute;
    right: 4px;
    top:4px;
    //margin-right: 25px;
    cursor: pointer;
    z-index: 9999;
`;

export const BoxMenu = styled.div`
    column-count: 2;
    column-gap: 20px;
    max-width: 700px;
    //background-color: red;

`;

export const ItemMenu = styled.div`
    display: flex;
    background-color: ${props => !props.hover ? "#fff": "#F9FAFB"};
    //margin-bottom: 20px;
    break-inside: avoid;
    gap: 20px;
    padding: 20px;
    //border: ${props => props.hover ? "1px solid gray": "1px solid tranparent"};
    cursor: pointer;
    border-radius: 4px;
`;

export const BoxImage = styled.div`
    display: flex;
    background-color: ${props => props.hover ? "#fff": "#F9FAFB"};
    min-width: 60px;
    min-height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;


`;

export const BoxInfor = styled.div`
    display: flex;
    //background-color: yellow;
    flex-direction: column;

    p{
        font-size: 12px;
        padding: 0px;
        margin: 0px;
        color: #5e6278;
    }

    label{
        font-size: 14px;
        color: #111827;
        font-weight: 500;
    }

`;

import { combineReducers } from "redux";

import reducerLogin from "../modules/login/redux/reducers/reducerLogin";
import reducerRegister from "../modules/login/redux/reducers/reducerRegister";
import reducerPassword from "../modules/login/redux/reducers/reducerPassword";

import reducerIntegration from "../modules/integrations/redux/reducers/reducerIntegration";
import reducerSync from "../modules/integrations/redux/reducers/reducerSync";
import reducerControlPlatform from "../modules/integrations/redux/reducers/reducerControlPlatform";
import reducerGetPlatforms from "../modules/integrations/redux/reducers/reducerGetPlatforms";
import reducerDeleteIntegration from "../modules/integrations/redux/reducers/reducerDeleteIntegration";
import reducerControlSyncProducts from "../modules/integrations/redux/reducers/reducerControlSyncProducts";

import reducerControlIntegration from "../modules/integrations/redux/reducers/reducerControlIntegration";

import reducerCupons from "../modules/cupons/redux/reducers/reducerCupons";
import reducerUpdateCupom from "../modules/cupons/redux/reducers/reducerUpdateCupom";
import reducerDeleteCupom from "../modules/cupons/redux/reducers/reducerDeleteCupom";
import reducerAddCupom from "../modules/cupons/redux/reducers/reducerAddCupom";

import reducerPayments from "../modules/assinatura/redux/reducers/reducerPayments";

import reducerUpdateAccount from "../modules/account/redux/reducers/reducerUpdateAccount";
import reducerDeleteAccount from "../modules/account/redux/reducers/reducerDeleteAccount";

import reducerDashboard from "../modules/dashboard/redux/reducers/reducerDashboard";
import reducerChart from "../modules/dashboard/redux/reducers/reducerChart";
import reducerTop from "../modules/dashboard/redux/reducers/reducerTop";
import reducerQueryHelp from "../modules/dashboard/redux/reducers/reducerQueryHelp";

import reducerDashboardInforMedias from "../modules/dashboard/redux/reducers/reducerDashboardInforMedias";
import reducerDashboardInforProducts from "../modules/dashboard/redux/reducers/reducerDashboardInforProducts";

import reducerAdminList from "../modules/admin/redux/reducers/reducerAdminList";
import reducerAdminDeleteCard from "../modules/admin/redux/reducers/reducerAdminDeleteCard";
import reducerAdminDebug from "../modules/admin/redux/reducers/reducerAdminDebug";
import reducerAdminDropi from "../modules/admin/redux/reducers/reducerAdminDropi";
import reducerAdminExpire from "../modules/admin/redux/reducers/reducerAdminExpire";

import reducerAdminVerifyHebhookCard from "../modules/admin/redux/reducers/reducerAdminVerifyHebhookCard";

import reducerAdminAddTime from "../modules/admin/redux/reducers/reducerAdminAddTime";

import reducerListNotify from "../components/Header/redux/reducers/reducerListNotify";

import reducerLanguage from "../components/Header/redux/reducers/reducerLanguage";

import reducerProducts from "../modules/products/redux/reducers/reducerProducts";
import reducerVariants from "../modules/products/redux/reducers/reducerVariants";
import reducerTemplate from "../modules/thema/redux/reducers/reducerTemplate";
import reducerControlConfig from "../modules/thema/redux/reducers/reducerControlConfig";
import reducerControlSaveEmail from "../modules/thema/redux/reducers/reducerControlSaveEmail";
import reducerControlSaveCheckout from "../modules/thema/redux/reducers/reducerControlSaveCheckout";
import reducerMedias from "../modules/medias/redux/reducers/reducerMedias";

import reducerMediasUpload from "../modules/medias/redux/reducers/reducerMediasUpload";

import reducerProductsByMedia from "../modules/medias/redux/reducers/reducerProductsByMedia";
import reducerUpdateProduct from "../modules/products/redux/reducers/reducerUpdateProduct";
import reducerDeleteProduct from "../modules/products/redux/reducers/reducerDeleteProduct";
import reducerAddProduct from "../modules/products/redux/reducers/reducerAddProduct";

import reducerAddMedias from "../modules/products/redux/reducers/reducerAddMedias";
import reducerLinkMedias from "../modules/products/redux/reducers/reducerLinkMedias";
import reducerUrlMedias from "../modules/products/redux/reducers/reducerUrlMedias";
import reducerControlMedias from "../modules/products/redux/reducers/reducerControlMedias";
import reducerControlRemoveLink from "../modules/products/redux/reducers/reducerControlRemoveLink";
import reducerControlRemoveMedia from "../modules/products/redux/reducers/reducerControlRemoveMedia";

import reducerControlVariants from "../modules/products/redux/reducers/reducerControlVariants";
import reducerControlLinks from "../modules/products/redux/reducers/reducerControlLinks";

import reducerControlCloseModal from "../modules/products/redux/reducers/reducerControlCloseModal";
import reducerControlCloseModalLink from "../modules/products/redux/reducers/reducerControlCloseModalLink";
import reducerControlCloseModalUrl from "../modules/products/redux/reducers/reducerControlCloseModalUrl";

import reducerSearchPlatformAllProducts from "../modules/products/redux/reducers/reducerSearchPlatformAllProducts";
import reducerSearchPlatformItemsProducts from "../modules/products/redux/reducers/reducerSearchPlatformItemsProducts";
import reducerVariantsList from "../modules/products/redux/reducers/reducerVariantsList";


import reducerDeleteMedias from "../modules/medias/redux/reducers/reducerDeleteMedias";
import reducerUpdateMedias from "../modules/medias/redux/reducers/reducerUpdateMedias";

import reducerUploadUrl from "../modules/medias/redux/reducers/reducerUploadUrl";

import reducerUploadFiles from "../modules/medias/redux/reducers/reducerUploadFiles";

import reducerControlSelectProducts from "../modules/products/redux/reducers/reducerControlSelectProducts";
import reducerUpdateSelectProducts from "../modules/products/redux/reducers/reducerUpdateSelectProducts";
import reducerDeleteSelectProducts from "../modules/products/redux/reducers/reducerDeleteSelectProducts";
import reducerSearchQuestionProduct from "../modules/products/redux/reducers/reducerSearchQuestionProduct";
import reducerSearchReviewProduct from "../modules/products/redux/reducers/reducerSearchReviewProduct";
import reducerSettings from "../modules/settings/redux/reducers/reducerSettings";
import reducerIntegrationUser from "../modules/integrations/redux/reducers/reducerIntegrationUser";
import reducerYourMedias from "../modules/yourmedias/redux/reducers/reducerYourMedias";
import reducerSales from "../modules/sales/redux/reducers/reducerSales";
import reducerEmails from "../modules/sales/redux/reducers/reducerEmails";
import reducerWhats from "../modules/sales/redux/reducers/reducerWhats";
import reducerUpdateEmailSales from "../modules/sales/redux/reducers/reducerUpdateEmailSales";

import reducerGetOrder from "../modules/sales/redux/reducers/reducerGetOrder";

import reducerSearchPlatform from "../modules/sales/redux/reducers/reducerSearchPlatform";
import reducerSearchPlatformAll from "../modules/sales/redux/reducers/reducerSearchPlatformAll";
import reducerSearchPlatformItems from "../modules/sales/redux/reducers/reducerSearchPlatformItems";

import reducerEmailsMulti from "../modules/sales/redux/reducers/reducerEmailsMulti";

import reducerEmailTeste from "../modules/thema/redux/reducers/reducerEmailTeste";

import reducerChangeStatusDownload from "../modules/thema/redux/reducers/reducerChangeStatusDownload";

import reducerUpdateDataProduct from "../modules/products/redux/reducers/reducerUpdateDataProduct";

const rootReducer = combineReducers({
  reducerVariantsList,
  reducerLogin,
  reducerPassword,
  reducerRegister,
  reducerIntegration,
  reducerIntegrationUser,
  reducerSync,
  reducerGetPlatforms,
  reducerControlPlatform,
  reducerControlSyncProducts,
  reducerDeleteIntegration,
  reducerCupons,
  reducerUpdateCupom,
  reducerDeleteCupom,
  reducerAddCupom,
  reducerPayments,
  reducerUpdateAccount,
  reducerDeleteAccount,
  reducerDashboard,
  reducerChart,
  reducerTop,
  reducerQueryHelp,
  reducerAdminList,
  reducerAdminDeleteCard,
  reducerAdminDebug,
  reducerAdminDropi,
  reducerAdminExpire,
  reducerListNotify,
  reducerLanguage,
  reducerProducts,
  reducerVariants,
  reducerTemplate,
  reducerMedias,
  reducerSales,
  reducerEmails,
  reducerProductsByMedia,
  reducerUpdateProduct,
  reducerDeleteProduct,
  reducerAddProduct,
  reducerDeleteSelectProducts,
  reducerUpdateSelectProducts,
  reducerControlSelectProducts,
  reducerSearchQuestionProduct,
  reducerSearchReviewProduct,
  reducerSettings,
  reducerYourMedias,
  reducerControlConfig,
  reducerControlSaveEmail,
  reducerControlSaveCheckout,

  reducerMediasUpload,
  reducerAddMedias,
  reducerLinkMedias,
  reducerControlMedias,
  reducerUrlMedias,
  reducerControlRemoveLink,

  reducerDeleteMedias,
  reducerUpdateMedias,

  reducerEmailsMulti,
  reducerEmailTeste,

  reducerUpdateDataProduct,

  reducerAdminAddTime,

  reducerControlIntegration,

  reducerAdminVerifyHebhookCard,
  reducerUpdateEmailSales,

  reducerControlCloseModal,
  reducerControlCloseModalLink,
  reducerControlCloseModalUrl,

  reducerSearchPlatform,
  reducerSearchPlatformAll,
  reducerSearchPlatformItems,

  reducerChangeStatusDownload,

  reducerSearchPlatformAllProducts,
  reducerSearchPlatformItemsProducts,

  reducerWhats,
  reducerUploadFiles,

  reducerDashboardInforMedias,
  reducerDashboardInforProducts,

  reducerControlRemoveMedia,

  reducerGetOrder,

  reducerUploadUrl,
  reducerControlVariants,
  reducerControlLinks
  
});

export default rootReducer;

import React from 'react'
import { ContainerLoading, Loader01,  } from './styled'
import logo from "../../assets/animation/dijital-loading-100x100.gif";

const LoadingLogo = () => {
    return (
        <ContainerLoading>
            <Loader01 />
            <img src={logo} />
            <span />
        </ContainerLoading>
    )
}

export default LoadingLogo
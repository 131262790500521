import styled from "styled-components";

export const Container = styled.div`
    /* display: flex ;
    width: 80px ;
    height: 80px ;
    justify-content: center ;
    align-items: center ;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    cursor: pointer;
    position: relative;

    :hover {
        label{
            visibility: visible;
            opacity: 0.9;
            //top: 30px;
            z-index: 999;
        }

    }

        label{
            display: flex;
            position: absolute;
            background: black;
            visibility: hidden;
            border-radius: 5px;
            max-width: 300px !important; 
            width: max-content;
            padding: 10px;
            //transform: translateX(-100%);
            bottom: -60px;
            margin-bottom: 0px;
            margin-left: -5px;
        }
  

        :after{
            content: '';
            position: absolute;
            top: 150%;
            left: 10px;
            border-bottom: 18px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        
        }     */

    display: flex ;
    //background-color: red ;
    width: 80px ;
    height: 80px ;
    justify-content: center ;
    align-items: center ;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    cursor: pointer;
    position: relative;

    :hover{
        label{
            visibility: visible;
        }
    }

    label{
        display: flex;
        position: absolute;
        background: #000000;
        visibility: hidden;
        border-radius: 5px;
        //max-width: 300px !important; 
        width: max-content;
        padding: 10px 10px;
        //transform: translateX(-100%);
        //left: 0;
        margin-top: 140px;
        //margin-left: 40px;
        color: white ;
        font-size: 11px !important;
        z-index: 99999 ;
        min-width: 128px;
        justify-content: center ;

        :after{
            content: '';
            position: absolute;
            bottom: 100%;
            right: 55px;
            //left: 50% ;
            border-bottom: 8px solid #000000;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
`
export const InstallIntegration = styled.span`
    background-color: #34C94D ;
    display: flex;
    height: 15px ;
    width: 15px ;
    border-radius: 50% ;
    position: absolute ;
    right: 5px;
    top: 5px;
`
import React from 'react'
import { Actions, CheckCustom, Container, Icon, Infor } from './styled'

import { BsCheck } from "react-icons/bs";
import { useState } from 'react';
import { useEffect } from 'react';


const CardCheck = (props) => {

    const [isCheked, setisCheked] = useState(false)

    useEffect(() => {
        if (isCheked) {
            props.add(props.item)
        } else {
            props.remove(props.item)
        }
    }, [isCheked])





    return (
        <Container>
            <CheckCustom onClick={() => { setisCheked(!isCheked) }}>
                {isCheked && <BsCheck size={20} color='gray' />}
            </CheckCustom>
            <Icon>
                {props.getIconFile("png", props.item.media_name)}
            </Icon>
            <Infor>
                {props.item.type == "url" ?
                    <>
                        <p>{props.item.media}</p>
                    </> :
                    <>
                        <p>{props.item.media_name}</p>
                        {/* <label>{sizeFiles(props?.item?.size)} </label> */}
                    </>
                }

            </Infor>
        </Container>
    )
}

export default CardCheck

import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosClose } from "react-icons/io";

import { Container, Title, FormCustom, Close, ButtonCustom, InputCustomText, BoxInput } from "./styled";
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { translations } from '../../../../utils/language';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalEditEmail = (props) => {

    //const statusAddProduct = useSelector(state => state.reducerAddProduct)
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")
    const language = useSelector((state) => state.reducerLanguage);
    const reducerUpdateEmailSales = useSelector((state) => state.reducerUpdateEmailSales);

    useEffect(() => {
        if (!(reducerUpdateEmailSales?.loading == false && !reducerUpdateEmailSales?.error == false && reducerUpdateEmailSales?.data?.length == 0)) {
            const id = "reducerUpdateEmailSales"
            if (reducerUpdateEmailSales.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (reducerUpdateEmailSales.error) toast.update(id, { render: "Erro ao atualizar email", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translations["sales-atualizado-sucesso"][language.data], type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    props.setVisibleModal(false)
                    setEmail("")
                    
                }
            }
        }
    }, [reducerUpdateEmailSales])

    const saveEmail = () => {
        console.log("saveEmail", props.id, email)
        dispatch({ type: "SAGA_UPDATE_EMAIL_SALES", payload: { id: props.id, customer_email: email }, });
    }
    

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <Container>
                <Close onClick={() => { props.setVisibleModal(false) }}><IoIosClose size={25} color={"gray"} /></Close>
                <Title><strong>Editar e-mail</strong></Title>
                <FormCustom>
                    <BoxInput>
                        <label>E-mail:</label>
                        <InputCustomText>
                            {/* <span>
                                <AiOutlineNumber size={20} color={"gray"} />
                            </span> */}
                            <input style={{ padding: "0px 10px" }} type={"text"} placeholder={"E-mail"} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        </InputCustomText>
                    </BoxInput>
                </FormCustom>
                <ButtonCustom onClick={() => { saveEmail() }}>{translations["sales-salvar"][language.data]}</ButtonCustom>
            </Container>
        </Modal>
    )
}

export default ModalEditEmail

const INITIAL_STATE = {
    error: false,
    loading: false,
    data: {
        plans: []
    }
}

const Payments = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PAYMENTS_REQUEST':
            return { loading: true, error: false, data: {} }
        case 'PAYMENTS_ERROR':
            return { loading: false, error: true, data: {} };
        case 'PAYMENTS_SUCCESS':
            return { loading: false, error: false, data: action.payload };
        case 'PAYMENTS_RESET':
            return { loading: false, error: false, data: {} }; 
        default:
            return state;
    }
}

export default Payments
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: 100%;
  flex-direction: column;
  width: 100%;
`;

export const ContainerLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
  @media (max-width: 525px) {
    padding: 10px 20px 10px 20px;
  }
`;

export const Block = styled.div`
  width: 49%;
  height: 328px;
  border-radius: 8px;
  background-color: white;
  padding: 40px;
  ${(props) =>
    props.type === "video" &&
    `
  padding: 20px;
  `};
  @media (max-width: 525px) {
    padding: 10px;
    height: 280px;
  }
`;

export const BlockProducts = styled.div`
  width: 100%;
  /* height: 400px; */
  border-radius: 8px;
  background-color: white;
  padding: 40px;
  @media (max-width: 525px) {
    padding: 20px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ButtonAdd = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f2f2fd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const TitleButtonAdd = styled.p`
  color: #858585;
  size: 16px;
  display: flex;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const SubTitle = styled.p`
  color: #b8b8b8;
  font-weight: 500;
  size: 12px;
  display: flex;
  margin-bottom: 4px;
  @media (max-width: 460px) {
    margin-bottom: 24px;
    text-align: center;
  }
`;

export const LineLegend = styled.tr`
  background-color: white;
  height: 40px;
`;

export const Legend = styled.th`
  color: #6d7175;
  font-weight: 500;
  padding: 10px;
  font-size: 14px;
  @media (max-width: 525px) {
    padding: 1px;
  }
`;

export const Line = styled.tr`
  height: 50px;
  background: #fff;
  border-width: 2px 0px 0px 0px;
  border-color: #f5f6fb;
  width: 100%;
`;

export const TextLine = styled.th`
  padding: 10px;
  font-weight: 500;
  text-align: center;
  margin-top: 40px;
  display: flex;
`;

export const ButtonSeeAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b76ff;
  width: 120px;
  height: 35px;
  border-radius: 4px;
  cursor: pointer;
`;

export const TitleButton = styled.p`
  font-size: 15px;
  color: #fff;
  margin: 0;
`;

export const Image = styled.img`
  height: 38px;
  width: 38px;
  margin-right: 14px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #f5f6fb;
  border-radius: 3px;
`;

export const ContainerProducts = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  background: #fafbfd;
  justify-content: center;
  margin-top: 14px;
  flex-direction: column;
`;

export const TitleProducts = styled.div`
  font-size: ${(props) => props.size}px;
  font-weight: 500;
  margin: ${(props) => props.marginTB}px ${(props) => props.marginLR}px;
`;

export const Checkbox = styled.input`
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #000;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  padding: 5px;
  cursor: pointer;
  &:checked {
    background-color: #1b76ff;
  }
`;

export const Input = styled.input`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: #f9f9f9;
  margin-bottom: 20px;
  border-color: #d3d3d3;
  border-style: solid;
  padding: 2px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`;

export const BoxLoading = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  //height: 100% ;
  //background-color: yellow ;
  margin-top: 50px;

  span {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Description = styled.td`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  padding: 10px;
  font-size: 14px;
  color: #202223;
  /* @media (max-width: 950px) {
    max-width: 50px;
  } */
  @media (max-width: 470px) {
    max-width: 50px;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 470px) {
    flex-direction: column;
  }
`;

export const ContainerTag = styled.div`
  display: grid;
  width: 50%;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 930px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 620px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContainerSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 310px;
  @media (max-width: 386px) {
    flex-direction: column;
    align-items: flex-start;
    width: 280px;
  }
`;

export const ContainerAdd = styled.div`
  padding: 2px;
  background-color: #f4f5fe;
`;

export const Tooltip = styled.div`
  //background-color: green;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  :hover {
    label {
      visibility: visible;
    }
  }

  label {
    display: flex;
    position: absolute;
    background: black;
    visibility: hidden;
    border-radius: 5px;
    max-width: 350px !important;
    width: max-content;
    padding: 5px 10px;
    transform: translateX(-100%);
    margin-top: -70px;
    margin-left: 35px !important;
    font-size: 12px !important;
    color: white;

    :after {
      content: "";
      position: absolute;
      top: 100%;
      right: 10px;
      border-top: 8px solid black;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }

  svg {
    margin-left: 5px;
    position: relative;
  }
`;

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #fff;
  border-color: ${(props) => props.borderColor};
  border-width: 0.1px;
  border-style: solid;
  border-radius: 50%;
  margin: 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  :hover {
    label {
      visibility: visible;
    }
  }

  label {
    display: flex;
    position: absolute;
    background: black;
    visibility: hidden;
    border-radius: 5px;
    max-width: 350px !important;
    width: max-content;
    padding: 5px 10px;
    transform: translateX(-100%);
    margin-top: -70px;
    margin-left: 130px !important;
    font-size: 12px !important;
    color: white;

    :after {
      content: "";
      position: absolute;
      top: 100%;
      right: 10px;
      border-top: 8px solid black;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

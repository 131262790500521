import Modal from "react-modal";
import { BoxActions, BoxFiles, BoxUpload, Container, Contents, DividerLine, FileItem, FileItemLoad, ListFiles, Loading, ProgressBarFill, ProgressContainer, Title } from "./styled";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CloudArrowUpIcon, LockClosedIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { border } from "polished";
import IconImage from "../../../../assets/imgs/icons/image.svg";

import IconUrl from "../../../../assets/imgs/icons/code.svg";
import IconDefault from "../../../../assets/imgs/icons/default.svg"
import IconVideo from "../../../../assets/imgs/icons/videoplay.svg";
import IconPdf from "../../../../assets/imgs/icons/pdf.svg";

import axios from 'axios';
import { IconFile } from "../card-product/styled";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "500px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "transparent",
        padding: "0px",
        margin: "0px",
        borderRadius: "8px",
        border: "none"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    },
};



const ModalUploadAnexoNew = (props) => {

    //const [files, setFiles] = useState([])
    const dispatch = useDispatch()
    const controlMedias = useSelector((state) => state.reducerControlMedias);
    const language = useSelector((state) => state.reducerLanguage);
    const [dragging, setDragging] = useState(false);
    const [dataFiles, setDataFiles] = useState([])
    const [files, setFiles] = useState([])
    const { id } = useParams();
    const fileInputRef = useRef(null);
    const [dataFilesCount, setDataFilesCount] = useState(0)

    useEffect(() => {
        console.log("files", files)
    }, [files])


    const setUploadFile = (ValueFiles) => {
        console.log("fiiles", files)
        setDataFilesCount(dataFilesCount + ValueFiles.length)
        setDataFiles([...dataFiles, ...ValueFiles,])
    }

    const setUploadFileCompleted = (fileCompleted, index) => {
        setDataFilesCount(dataFilesCount - 1)
        let data = [...dataFiles]
        data[index] = null
        setDataFiles(data)
        setFiles([...files, fileCompleted,])
    }

    const removeItemCompleted = (itemFile) => {
        setFiles(files.filter((file) => !(file.name === itemFile.name)))
    }

    const handleDragEnter = (e) => {
        //console.log("handleDragEnter")
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        //console.log("handleDragLeave")
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDragOver = (e) => {
        //console.log("handleDragOver")
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDrop = (e) => {
        //console.log("handleDrop", e)
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        setDataFilesCount(dataFilesCount + e.dataTransfer.files.length)
        setDataFiles([...dataFiles, ...e.dataTransfer.files]);
    };

    useEffect(() => {
        if (controlMedias.loading == false && controlMedias.data.length > 0) {
            closeModal()
        }
    }, [controlMedias])

    const closeModal = () => {
        props.setVisible(false)
        //setFiles([])
        setDataFiles([])
        dispatch({ type: "PRODUCT_CONTROL_MEDIA_RESET" })
        //props.goSearch()
        if (props?.close) {
            props.close()
        } else {
            dispatch({ type: "SAGA_LIST_VARIANTS", payload: id, });
        }

        dispatch({ type: "VARIANTS_CONTROL_RESET" });
    }

    useEffect(() => {
        console.log("dataFiles", dataFiles)
    }, [dataFiles])


    const getIconFile = (type, tooltip, media) => {

        //console.log("getIconFile", type, tooltip, media)
        switch (type?.toLowerCase()) {
            case "png": case "jpg": case "jpeg": case "gif": case "bmp": case "psd": case "svg": case "WEBP":
                return (
                    <IconFile
                    //onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        <img src={IconImage} />
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
            case "pdf":
                return (
                    <IconFile
                    //onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        <img src={IconPdf} />
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
            case "mp4": case "avi": case "mkv":
                return (
                    <IconFile
                    //onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        <img src={IconVideo} />
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
            case "url":
                return (
                    <IconFile
                    // onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        {/* <BsLink45Deg color={iconFile.color} size={iconFile.size} /> */}
                        <img src={IconUrl} />
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )

            default:
                return (
                    <IconFile
                    //onClick={() => { tooltip?.length > 0 && clickMedia(media) }}
                    >
                        {/* <BsFillFileEarmarkFill color={iconFile.color} size={iconFile.size} /> */}
                        <img src={IconDefault} />
                        {tooltip?.length > 0 &&
                            <label>
                                {tooltip}
                            </label>}
                    </IconFile>
                )
        }
    }


    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { }}
        >
            <Container>
                <Title>
                    <div>
                        <label>Upload arquivos</label>
                        <XMarkIcon onClick={() => { closeModal() }} style={{ cursor: "pointer" }} width={20} />
                    </div>
                    <label>
                        Faça o upload dos arquivos que voce deseja anexar.
                    </label>
                </Title>
                <Contents>
                    <BoxUpload onClick={() => { fileInputRef.current.click() }} active={dragging}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}

                    >
                        <CloudArrowUpIcon color="#64748b" width={40} />
                        <label >
                            Para iniciar upload, clique aqui ou arraste e solte seus arquivos de tamanho máximo 100MB.
                        </label>
                        <input ref={fileInputRef} onChange={(e) => {
                            setUploadFile(e.target.files)
                        }} style={{ display: "none" }} id="upload-file" type="file" multiple />
                    </BoxUpload>
                    {dataFilesCount > 0 && <BoxFiles>
                        <label>Carregando arquivos</label>
                        <ListFiles>
                            {dataFiles.map((item, index) => {
                                if (item != null) {
                                    return (
                                        <CardLoading
                                            setUploadFileCompleted={setUploadFileCompleted}
                                            item={item}
                                            //variant={props.item}
                                            index={index}
                                            getIconFile={getIconFile}
                                        //product_id={props.item.product_id}
                                        // variant_id={props.item.variant_id}
                                        />
                                    )
                                }
                            })}
                        </ListFiles>
                    </BoxFiles>}

                    {files.length > 0 && <BoxFiles>
                        <label>Arquivos carregados</label>
                        <ListFiles>
                            {files.map((item) => {

                                return (
                                    <CardFile
                                        removeItemCompleted={removeItemCompleted}
                                        item={item}
                                        getIconFile={getIconFile}
                                    />
                                )
                            })}
                        </ListFiles>
                    </BoxFiles>}

                    {/* <BoxActions>
                        <button
                            onClick={() => { closeModal() }}
                            type="button"
                            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            Cancelar
                        </button>

                        <button
                            type="button"
                            className="rounded-md bg-emerald-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-emerald-400"
                        >
                            salvar
                        </button>
                    </BoxActions> */}
                </Contents>
            </Container>

        </Modal >
    )
}

export default ModalUploadAnexoNew


const CardLoading = (props) => {

    const [progress, setProgress] = useState(0);
    const reducerControlVariants = useSelector(state => state.reducerControlVariants)

    const uploadFileS3 = async () => {
        const data = await getUrlUpload()
        if (data) {
            const result = await uploadFile(data)
        } else {
            console.log("erro uploadFileS3")
        }

    }

    const uploadFile = async (data) => {
        const url = data.url + "/" + data.fields.key
        const formData = new FormData();
        Object.keys(data.fields).forEach(key => { formData.append(key, data.fields[key]); });
        formData.append("file", props.item);
        const options = {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
            },
        };
        const result = await axios.post(data.url, formData, options).then(async response => {
            //console.log('Arquivo enviado com sucesso:', response);
            if (reducerControlVariants.data.length > 0) {
                // Promise.all(reducerControlVariants.data.map(variant => setUrlUpload(variant, url)))
                //     .then(results => {
                //         console.log("Todos os itens foram processados em paralelo.");
                //         console.log(results); // resultados de cada promessa resolvida
                //         setCompleted(true)
                //     })
                //     .catch(error => {
                //         console.error("Ocorreu um erro no processamento:", error);
                //     });
                for await (const variant of reducerControlVariants.data) {
                    await setUrlUpload(variant, url)
                }
                setCompleted(true)

            } else {
                setUrlUpload(null, url)
            }

        }).catch(error => {
            if (axios.isCancel(error)) { console.log('Upload cancelado', props.position); }
            else { console.log('Erro no upload:', error); }
        });
        return result
    }

    const getUrlUpload = async () => {

        const token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;
        const url = `${process.env.REACT_APP_API_URL}/getSignedUrl?extFile=${props.item.name?.split(".")[props.item.type?.split()?.length]}&mimeType=${props.item.type}&sizeFile=${props.item.size}`;
        const headers = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });

        const result = await fetch(url, { method: 'GET', headers: headers }).then((response, data) => {
            //console.log("res", response, data);
            return response.json()
        }).catch(error => {
            console.error('Erro na busca:', error);
        });
        //console.log("result", result)
        return result.data
    };

    const setUrlUpload = async (variant, url) => {
        const token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;
        const urlRequest = `${process.env.REACT_APP_API_URL}/upload_media`;
        const headers = new Headers({ 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' });

        const dataRequest = {
            limit: 0,
            message: "",
            media_name: props.item.name,
            media_url: url,
            media_extname: props.item.name?.split(".")[props.item.type?.split()?.length],
            media_size: props.item.size
        };

        //console.log("props.variant", props.variant)
        if (variant != null) {
            dataRequest.product_id = variant.variants.product_id
            dataRequest.variant_id = variant.variants.variant_id
        }

        const result = await fetch(urlRequest, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(dataRequest)
        })
            .then((response, data) => {
                // console.log("res", response, data);
                if (variant == null) {
                    setCompleted(true)
                }
                return response.json()
            })
            .catch(error => {
                console.error('Erro na busca:', error);
            });

        //console.log("result", result)
        return result.data
    };

    useEffect(() => {
        if (props?.item) { uploadFileS3() }
    }, [])


    const [completed, setCompleted] = useState(false)

    useEffect(() => {
        if (completed) {
            props.setUploadFileCompleted(props.item, props.index)
        }
    }, [completed])




    return (
        <FileItemLoad>
            {props.getIconFile(props.item.name?.split(".")[props.item.type?.split()?.length], props.item.name)}
            <div>
                <span>
                    <label>{props.item.name}</label>
                    <label>{progress == 100 ? 99 : progress}%</label>
                </span>
                <ProgressContainer>
                    <ProgressBarFill progress={progress} />
                </ProgressContainer>
            </div>
        </FileItemLoad>
    )
}

const CardFile = (props) => {

    useEffect(() => {
        //console.log("CardFile props", props)
    }, [props])


    return (
        <FileItem>
            <div>
                {props.getIconFile(props.item.name?.split(".")[props.item.type?.split()?.length], props.item.name)}
                <label>{props.item.name}</label>
            </div>
            {/* <XMarkIcon onClick={() => { props.removeItemCompleted(props.item) }} style={{ cursor: "pointer" }} width={12} /> */}
        </FileItem>
    )
}

const INITIAL_STATE = { status: false }

const ControlRemoveMedia = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PRODUCT_CONTROL_REMOVE_MEDIA':
            return { status: action.payload }
        default:
            return state;
    }
}

export default ControlRemoveMedia
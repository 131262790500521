import { toast } from "react-toastify";
import { put, call, delay } from "redux-saga/effects";
import { GET, POST, PUT, PUT_CUSTOM } from "../../../../services/api";

export function* updateTemplate(action) {
  const result = yield call(PUT_CUSTOM, {
    endpoint: `/templates/image`,
    data: action.payload,
  });
  if (result === false) {
    yield put({ type: "CONFIG_UPDATE_ERROR" });
  } else {
    yield put({ type: "CONFIG_UPDATE_SUCCESS", payload: result.data });
    toast.success("Suas atualizações foram salvas com sucesso!");

    yield put({ type: "SAGA_GET_TEMPLATES" });

    // yield put({ type: "CONFIG_CONTROL_SET", payload: result.data[0] })

    // yield put({ type: "CONFIG_CONTROL_SAVE_CHECKOUT_SET", payload: true })

    // yield put({ type: "CONFIG_CONTROL_SAVE_EMAIL_SET", payload: true })
  }
}

export function* getTemplate() {
  const result = yield call(GET, { endpoint: `/templates`, });
  yield put({ type: "GET_TEMPLATES_SUCCESS", payload: result.data });

  yield put({ type: "CONFIG_CONTROL_SET", payload: result.data[0] })

  yield put({ type: "CONFIG_CONTROL_SAVE_CHECKOUT_SET", payload: true })

  yield put({ type: "CONFIG_CONTROL_SAVE_EMAIL_SET", payload: true })
}


export function* sendEmailTeste(action) {
  yield put({ type: "EMAIL_TESTE_REQUEST" });
  const result = yield call(POST, { endpoint: `/emailpreview`, data: action.payload });
  if (result == false) {
    yield put({ type: "EMAIL_TESTE_ERROR" });
  } else {
    yield put({ type: "EMAIL_TESTE_SUCCESS", payload: result.data });
  }
}

export function* changeStatusDownload(action) {
  yield put({ type: "CHANGE_STATUS_DOWNLOAD_REQUEST" });
  //yield delay(2000)
  const result = yield call(PUT, { endpoint: `integrations/scriptdownload`, data: action.payload });
  if (result == false) {
    yield put({ type: "CHANGE_STATUS_DOWNLOAD_ERROR" });
  } else {
    yield put({ type: "CHANGE_STATUS_DOWNLOAD_SUCCESS", payload: result.data });
  }
}